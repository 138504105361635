import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MobileInputs from "./mobileInputs";
import { FORM_FIELD_TYPE } from "./const";
import InputFieldSwitcher from "./internalFields/InputFieldSwitcher";
import { useCreateFieldPlaceholder } from "./mobileInputs/hooks";
import NotSupported from "./internalFields/NotSupported";

function MobileInputsPreview({ type, employeeInfo, workDetailInfo, ...rest }) {
    const info = useCreateFieldPlaceholder(rest.data, {
        useValue: rest.useValue,
        employeeInfo,
        workDetailInfo
    });

    const Component = useMemo(() => {
        if (MobileInputs[type]) {
            return MobileInputs[type];
        }
        return NotSupported;
    }, [type]);

    return (
        <div className="form-mobile-inputs">
            <InputFieldSwitcher type={type} employeeInfo={employeeInfo} workDetailInfo={workDetailInfo} {...rest}>
                <Component type={type} placeholder={info.placeholder} employeeInfo={employeeInfo} workDetailInfo={workDetailInfo} {...rest} />
            </InputFieldSwitcher>
        </div>
    );
}

export default MobileInputsPreview;

MobileInputsPreview.propTypes = {
    type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE)),
    showTypeBesideLabelOnActive: PropTypes.bool,
    useValue: PropTypes.bool,
    employeeInfo: PropTypes.object,
    workDetailInfo: PropTypes.object
};
