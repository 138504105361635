import React, { useMemo } from "react";
import PropTypes from "prop-types";
import InfoIcon from "@mui/icons-material/Info";
import MyTooltip from "./Tooltip";

function Capsule({ classNames = {}, title, tooltip, children, styles = {}, small, noBorder, noShadow }) {
    const cname = useMemo(() => {
        let temp = "capsule ";
        if (small) temp += "semi-bold xs-font ";
        else temp += "bold small-font ";
        if (noBorder) temp += "with-border ";
        if (noShadow) temp += "no-shadow ";
        if (classNames.parent) temp += classNames.parent;
        return temp.trim();
    }, [noBorder, noShadow, classNames]);

    return (
        <div className={cname} style={styles.parent || {}}>
            <div className={`capsule__title ${classNames.title || ""}`.trim()} style={styles.title || {}}>
                {title}
            </div>
            <div className={`capsule__content flex gap-05 align-center w100 ${classNames.content || ""}`.trim()} style={styles.content || {}}>
                {children}
                {tooltip && (
                    <MyTooltip className="flex" message={tooltip}>
                        <InfoIcon className="info-icon hover-svg" />
                    </MyTooltip>
                )}
            </div>
        </div>
    );
}

export default Capsule;

Capsule.propTypes = {
    classNames: PropTypes.shape({
        parent: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string
    }),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        title: PropTypes.object
    }),
    title: PropTypes.any,
    tooltip: PropTypes.any,
    children: PropTypes.any,
    small: PropTypes.bool,
    noBorder: PropTypes.bool,
    noShadow: PropTypes.bool
};
