import React from "react";
import PositionTab from "./Position";
import PropTypes from "prop-types";

function PositionSettings({ styles }) {
    return (
        <div className="w100 flex column gap-1" style={{ maxWidth: "none" }}>
            <PositionTab styles={styles} />
        </div>
    );
}

export default PositionSettings;

PositionSettings.propTypes = {
    styles: PropTypes.shape({
        content: PropTypes.object
    })
};
