import React from "react";
import PropTypes from "prop-types";

function FieldPreviewWrapper({ children }) {
    return <div className="form-mobile-view__field-preview">{children}</div>;
}

export default FieldPreviewWrapper;

FieldPreviewWrapper.propTypes = {
    children: PropTypes.any
};
