import React from "react";
import PropTypes from "prop-types";
import ControlFields from "./ControlFields";
import ButtonBack from "../../../common/components/extra/ButtonBack";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";

function SidePanel({ form, fields, onSearch, isLoading, hasDroppedItems }) {
    return (
        <div className="form-side-panel">
            <ButtonBack hasAlert={hasDroppedItems} alertMessage="Are you sure you want to discard your changes and go back to form page?" />
            <Input
                type={INPUT_TYPE.SEARCH}
                parentStyle={{ minWidth: "auto", maxHeight: "2.5rem", height: "5rem" }}
                placeholder="Search Elements"
                wrapperStyle={{ width: "100%" }}
                onChange={onSearch}
            />
            <ControlFields form={form} fields={fields} isLoading={isLoading} />
        </div>
    );
}

export default SidePanel;

SidePanel.propTypes = {
    form: PropTypes.object,
    fields: PropTypes.object,
    onSearch: PropTypes.func,
    isLoading: PropTypes.bool,
    hasDroppedItems: PropTypes.bool
};
