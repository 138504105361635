import React, { useState } from "react";
import PropTypes from "prop-types";
import Departments from "../departments/Departments";
import Designations from "../designations/Designations";
import Card from "../../../common/components/extra/Card";
import Tabs from "../../../common/components/extra/Tabs";
import { POSITION_TABS } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { createMiniTootlip } from "../../../common/utilities/helper";

function PositionTab({ loading, setParentLoading, styles = {} }) {
    const [activeTab, setTab] = useState(POSITION_TABS.ADMIN_DEPTS.id);

    const user = useAppSelector(selectUser);
    const hasInvalidDepartment = user.hasInvalidDepartment;
    const hasInvalidAdminDepartment = user.hasInvalidAdminDepartment;

    const props = {
        loading,
        setParentLoading
    };

    return (
        <div className="work-tab flex column gap-1" style={styles.parent || {}}>
            <Card className="position-settings box-shadow-mini">
                <Tabs
                    items={[
                        {
                            id: POSITION_TABS.ADMIN_DEPTS.id,
                            label: (
                                <div className="flex gap-05 center">
                                    {POSITION_TABS.ADMIN_DEPTS.label}
                                    {hasInvalidAdminDepartment && (
                                        <MyTooltip
                                            message={createMiniTootlip(
                                                "There should be at least one admin department and must have both a Manager and a Supervisor."
                                            )}
                                        >
                                            <WarningColorSwitcher style={{ width: "1.3rem" }} warning />
                                        </MyTooltip>
                                    )}
                                </div>
                            ),
                            component: <Departments key={activeTab} {...props} admin />,
                            style: { width: "14rem" }
                        },
                        {
                            id: POSITION_TABS.OTHER_DEPTS.id,
                            label: (
                                <div className="flex gap-05 center">
                                    {POSITION_TABS.OTHER_DEPTS.label}
                                    {hasInvalidDepartment && (
                                        <MyTooltip message={createMiniTootlip("A Department must have both a Manager and a Supervisor.")}>
                                            <WarningColorSwitcher style={{ width: "1.3rem" }} warning />
                                        </MyTooltip>
                                    )}
                                </div>
                            ),
                            component: <Departments key={activeTab} admin={false} {...props} />,
                            style: { width: "14rem" }
                        },
                        {
                            id: POSITION_TABS.DESIGNATIONS.id,
                            label: POSITION_TABS.DESIGNATIONS.label,
                            component: <Designations {...props} />,
                            style: { width: "14rem" }
                        }
                    ]}
                    onChange={(tab) => setTab(tab.id)}
                    useStorage
                    isCapsule
                />
            </Card>
        </div>
    );
}

export default PositionTab;

PositionTab.propTypes = {
    loading: PropTypes.bool,
    setParentLoading: PropTypes.func,
    styles: PropTypes.shape({
        parent: PropTypes.object
    })
};
