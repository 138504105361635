import React from "react";
import PropTypes from "prop-types";
import MobileField from "../mobileInputs/MobileField";

function InputFieldSwitcher({ details = {}, children, ...rest }) {
    if (details.forAdmin) {
        return <MobileField {...rest} name={details.label} details={details} showTypeBesideLabelOnActive />;
    }
    return React.cloneElement(children, { ...rest });
}

export default InputFieldSwitcher;

InputFieldSwitcher.propTypes = {
    details: PropTypes.shape({
        forAdmin: PropTypes.bool
    }),
    name: PropTypes.string,
    children: PropTypes.any
};
