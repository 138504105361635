import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/form";

const END_POINTS = {
    createCompanyForms: { method: "post", path: "create" },
    updateCompanyForms: { method: "put", path: "update" },
    deleteCompanyForms: { method: "delete", path: "delete" },
    loadAllCompanyForms: { method: "post", path: "load-all" },
    loadAllLazyCompanyForms: { method: "post", path: "load-all-lazy" },
    loadAllLazyCompanyFormTemplates: { method: "post", path: "load-all-templates-lazy" },
    getCompanyForms: { method: "get", path: "get-details" },
    getActiveFields: { method: "get", path: "get-fields" },
    validateCompanyForms: { method: "get", path: "validate" },
    checkTargetSendFormAvailability: { method: "post", path: "check-target-availability" }
};

export const companyFormsAPI = apiSlice.injectEndpoints({
    reducerPath: "companyFormsAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useCreateCompanyFormsMutation,
    useUpdateCompanyFormsMutation,
    useDeleteCompanyFormsMutation,
    useGetCompanyFormsMutation,
    useLoadAllCompanyFormsMutation,
    useLoadAllLazyCompanyFormsMutation,
    useLoadAllLazyCompanyFormTemplatesMutation,
    useGetActiveFieldsMutation,
    useValidateCompanyFormsMutation,
    useCheckTargetSendFormAvailabilityMutation
} = companyFormsAPI;

export default companyFormsAPI.reducer;
