import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";
import Input from "../../../../../common/components/extra/form/Input";
import { sanitizeWords } from "../../../../../common/utilities/helper";

function MobileDropdown({ details, useValue }) {
    if (useValue) {
        return <Input label={sanitizeWords(details.label)} renderValue={details.value?.label} parentStyle={{ gap: ".5rem" }} readOnly alignRight />;
    }

    return (
        <div className="mobile-input mobile-input--dropdown input-size">
            <div className="input-box flex center">
                <span className="fade italic">Dropdown</span>
            </div>
            <div className="dropdown-indicator">
                <ArrowDropDownIcon className="primary-color" style={{ width: "3rem", height: "auto" }} />
            </div>
        </div>
    );
}

export default MobileDropdown;

MobileDropdown.propTypes = {
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
