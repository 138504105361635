import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import { createClass, createConfirmAlert, createGroup } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { BASE_CLASS, SUBMITTED_FORM_ACTION_TYPE } from "./const";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";

function UpdateStatusModal({ open, onClose, onConfirm, actionType, isLoading, error }) {
    const [form, setForm] = useState({ remarks: "" });

    const isRemarksRequired = SUBMITTED_FORM_ACTION_TYPE.COMPLETE !== actionType;

    const info = useMemo(() => {
        const temp = {
            title: "Update Status",
            confirmMessage: "Please confirm before you proceed, this cannot be undone"
        };
        switch (actionType) {
            case SUBMITTED_FORM_ACTION_TYPE.CANCEL:
                temp.title = "Cancel Submitted Form";
                temp.confirmMessage = "Are you sure you want to cancel this form? This cannot be undone.";
                break;
            case SUBMITTED_FORM_ACTION_TYPE.REJECT:
                temp.title = "Reject Submitted Form";
                temp.confirmMessage = "Are you sure you want to reject this form? This cannot be undone.";
                break;
            case SUBMITTED_FORM_ACTION_TYPE.COMPLETE:
                temp.title = "Complete Submitted Form";
                temp.confirmMessage = "Are you sure you want to complete this form? This cannot be undone.";
                break;
            default:
                break;
        }
        return temp;
    }, [actionType]);

    const onChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Modal
            title={info.title}
            open={open}
            onClose={onClose}
            onSave={() =>
                createConfirmAlert({
                    title: "Are you sure?",
                    content: info.confirmMessage,
                    onConfirm: async (close) => {
                        close();
                        onConfirm?.(form);
                    }
                })
            }
            disableSave={(!form.remarks && isRemarksRequired) || error}
            styles={{ content: { minWidth: "25rem" } }}
            isLoading={isLoading}
            saveLabel="Confirm"
            hasHeaderStyle
            small
            isForm
        >
            <div className={createClass("", BASE_CLASS)}>
                <div className={createClass("__inner", BASE_CLASS)}>
                    <div className={createClass("__inner-row", BASE_CLASS)}>
                        {createGroup({
                            base: BASE_CLASS,
                            body: (
                                <>
                                    {error && (
                                        <SectionCollapseError style={{ marginBottom: "1rem" }} show alwaysOpen>
                                            <span style={{ maxWidth: "20rem" }} className="small-font">
                                                {error}
                                            </span>
                                        </SectionCollapseError>
                                    )}
                                    <Input
                                        name="remarks"
                                        label="Remarks"
                                        type={INPUT_TYPE.TEXTAREA}
                                        onChange={onChange}
                                        value={form.remarks}
                                        required={isRemarksRequired}
                                        parentStyle={{ height: "7rem", minHeight: "5rem" }}
                                    />
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

UpdateStatusModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.number,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    actionType: PropTypes.oneOf(Object.values(SUBMITTED_FORM_ACTION_TYPE)),
    isLoading: PropTypes.bool,
    error: PropTypes.string
};

export default UpdateStatusModal;
