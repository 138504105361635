import PropTypes from "prop-types";
import React from "react";
import Input from "../../../../../common/components/extra/form/Input";
import { sanitizeWords } from "../../../../../common/utilities/helper";

function MobileTextArea({ details, useValue, isProbYearly }) {
    if (useValue) {
        return (
            <Input
                label={sanitizeWords(details.label)}
                renderValue={details.value}
                parentStyle={{
                    gap: ".5rem",
                    ...(isProbYearly
                        ? {
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start"
                          }
                        : {})
                }}
                readOnly
                alignRight={!isProbYearly}
            />
        );
    }

    return (
        <div className="mobile-input mobile-input--text-area-input">
            <div className="input-box flex center">
                <span className="fade italic">{useValue ? details.value : "Text Area"}</span>
            </div>
        </div>
    );
}

export default MobileTextArea;

MobileTextArea.propTypes = {
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool,
    isProbYearly: PropTypes.bool
};
