import React, { useState } from "react";
import PropTypes from "prop-types";
import isNumber from "lodash/isNumber";
import Select from "./Select";
import { createOptions } from "./helper";

function SelectConstant({ base, transformBase, value, isMulti, ...rest }) {
    const [options] = useState(createOptions(base, transformBase));

    const getValue = () => {
        if (isMulti) {
            return options.filter((option) =>
                (value || []).map((od) => od.toString().toLowerCase().trim()).includes(option.value.toString()?.toLowerCase().trim())
            );
        }
        return (
            options.find(
                (opt) =>
                    (isNumber(opt?.value) ? opt.value : opt?.value || "").toString().trim().toLowerCase() ==
                    (isNumber(value) ? value : value || "").toString().trim().toLowerCase()
            ) || ""
        );
    };

    return <Select {...rest} isMulti={isMulti} options={options} value={getValue()} isRtl={isMulti} />;
}

export default SelectConstant;

SelectConstant.propTypes = {
    base: PropTypes.object,
    transformBase: PropTypes.func,
    value: PropTypes.any,
    transformValue: PropTypes.func,
    isMulti: PropTypes.bool,

    onChange: PropTypes.func,
    label: PropTypes.any,
    style: PropTypes.object,
    isClearable: PropTypes.bool,
    noborder: PropTypes.bool,
    isCustom: PropTypes.bool,
    async: PropTypes.bool,
    isOutlined: PropTypes.bool,
    disabledOutline: PropTypes.bool,
    customNoOption: PropTypes.any,
    virtualize: PropTypes.bool,
    tooltip: PropTypes.any,
    readOnly: PropTypes.bool,
    subtext: PropTypes.shape({
        style: PropTypes.object,
        className: PropTypes.string,
        message: PropTypes.any,
        hide: PropTypes.bool
    })
};
