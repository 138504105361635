import PropTypes from "prop-types";
import React from "react";
import { sanitizeWords, toReadablePhone } from "../../../../../common/utilities/helper";
import Input from "../../../../../common/components/extra/form/Input";

function MobilePhone({ placeholder, details, useValue }) {
    if (useValue) {
        return (
            <Input
                label={sanitizeWords(details.label)}
                renderValue={details.value && toReadablePhone(details.value)}
                parentStyle={{ gap: ".5rem" }}
                readOnly
                alignRight
            />
        );
    }
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade italic">{placeholder}</span>
            </div>
        </div>
    );
}

export default MobilePhone;

MobilePhone.propTypes = {
    placeholder: PropTypes.any,
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
