import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useCreateFieldPlaceholder } from "./hooks";
import { sanitizeWords } from "../../../../common/utilities/helper";

function MobileField({ details = {}, data, showTypeBesideLabelOnActive, type, useValue, employeeInfo, workDetailInfo }) {
    const info = useCreateFieldPlaceholder(data, {
        useValue,
        employeeInfo,
        workDetailInfo
    });

    const isBold = details.isBold;
    const isColumn = details.isColumn;

    const classname = useMemo(() => {
        let temp = "mobile-input mobile-input--field ";
        if (isColumn) temp += "column-layout ";
        if (showTypeBesideLabelOnActive) temp += "show-type ";
        return temp.trim();
    }, [isColumn, showTypeBesideLabelOnActive]);

    return (
        <div className={classname}>
            <div className="field-info-box semi-bold">
                <span className="fade flex gap-03 column w100">
                    <span style={(!isColumn && { maxWidth: "7rem" }) || {}}>{info.name}</span>
                    {showTypeBesideLabelOnActive && (
                        <span className="show-field-type xs-font" style={{ fontStyle: "italic" }}>
                            ({sanitizeWords(type)})
                        </span>
                    )}
                </span>
                <span
                    style={{
                        ...(isBold ? { fontWeight: "bold" } : {}),
                        ...(!isColumn ? { minWidth: "7rem", textAlign: "right" } : {})
                    }}
                >
                    {info.placeholder}
                </span>
            </div>
        </div>
    );
}

export default MobileField;

MobileField.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    details: PropTypes.object,
    data: PropTypes.object,
    showTypeBesideLabelOnActive: PropTypes.bool,
    useValue: PropTypes.bool,
    employeeInfo: PropTypes.object,
    workDetailInfo: PropTypes.object
};
