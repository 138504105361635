import React from "react";
import PropTypes from "prop-types";
import EmployeeAvatar from "../../../common/components/extra/avatar/EmployeeAvatar";
import { USER_LEVEL } from "../companyForms/const";
import Loader from "../../../common/components/extra/Loader";
import { SHIFT_TYPE, WORK_HISTORY_SHIFT_STATUS } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import ShiftStatus from "./ShiftStatus";
import { useGetWorkHistoryStatusByRangeAndStatus } from "./hooks";

const Box = ({ isLoading, label, value, hidden, status, style = {} }) => {
    return (
        <div className="box" style={hidden ? { opacity: 0 } : { ...style, paddingTop: !status ? "1rem" : "1.5rem" }}>
            <div className="flex column gap-03 h100" style={{ justifyContent: "center" }}>
                {isLoading ? (
                    <Loader style={{ width: "2rem" }} relative />
                ) : (
                    <span className="box__value bold">{value.toString().toUpperCase()}</span>
                )}
                <span className="box__label fade xs-font bold">{label}</span>
            </div>

            {status && !isLoading && (
                <div className="box__tag semi-bold">
                    <Tag className="solid">
                        <span style={{ lineHeight: "1px" }}>{status}</span>
                    </Tag>
                </div>
            )}
        </div>
    );
};

function Overview({ row, isLoading, children }) {
    const status = useGetWorkHistoryStatusByRangeAndStatus({
        recordStatus: {
            isPending: row.isPending,
            isRejected: row.isRejected,
            isApproved: row.isApproved,
            isCreatedByAdmin: !!row.created_by_admin_id,
            isModifiedByAdmin: !!row.modified_by_admin_id,
            submittedForm: row.submittedForm
        },
        overtime: row.overtime,
        statuses: row?.statuses,
        isLoading
    });

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const recordStatus = status.recordStatus || "---";
    const overtimeStatus = status.overtime || "---";

    return (
        <div className="flex wrap gap-1">
            <div className="tk-employees-work-history__modal-content-view__top-extra">
                <div className="flex column gap-1 w100">
                    <EmployeeAvatar
                        id={row?.id}
                        filename={row?.photo?.filename}
                        fullname={row?.fullname}
                        designation={row?.designation}
                        userLevel={row.user_level}
                        isLoading={isLoading}
                    />
                    <div className="tk-employees-work-history__modal-content-view__top-extra__content flex wrap">
                        <div className="top flex w100 gap-05 wrap">
                            <Box label="Shift Date" value={row.dateText} isLoading={isLoading} status={row.isOffdayOT ? "Off-Day OT" : "NORMAL"} />
                            <Box
                                label="Record Status"
                                value={recordStatus.label}
                                isLoading={isLoading}
                                status={recordStatus.tag}
                                style={recordStatus.color ? { background: recordStatus.color } : {}}
                            />
                        </div>
                        <ShiftStatus
                            statuses={row?.statuses}
                            firstShift={row?.firstShift}
                            secondShift={row?.secondShift}
                            isLoading={isLoading}
                            timezone={timezone}
                        />
                        <div className="bottom flex w100 gap-05 wrap">
                            <Box label="Overtime Status" value={overtimeStatus.toUpperCase()} isLoading={isLoading} />
                            <Box label="" value="" isLoading={isLoading} hidden />
                        </div>
                    </div>
                    {row.note && (
                        <div className="tk-employees-work-history__modal-content-view__top-extra__footer">
                            <div className="note flex small-font column">
                                <span className="fade">Note:</span>
                                <p>{row.note}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {children}
        </div>
    );
}

export default Overview;

Overview.propTypes = {
    children: PropTypes.any,
    isLoading: PropTypes.bool,
    row: PropTypes.shape({
        id: PropTypes.number,
        dateText: PropTypes.string,
        fullname: PropTypes.string,
        designation: PropTypes.string,
        note: PropTypes.string,
        user_level: PropTypes.oneOf(Object.values(USER_LEVEL)),
        isOffdayOT: PropTypes.bool,
        isRejected: PropTypes.bool,
        isApproved: PropTypes.bool,
        isPending: PropTypes.bool,
        photo: PropTypes.shape({
            filename: PropTypes.string
        }),
        overtime: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string
        }),
        firstShift: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string
        }),
        secondShift: PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string
        }),
        statuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(WORK_HISTORY_SHIFT_STATUS))),
        created_by_admin_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        modified_by_admin_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        submittedForm: PropTypes.shape({
            formName: PropTypes.string,
            isCreated: PropTypes.bool
        }),
        shiftType: PropTypes.oneOf(Object.values(SHIFT_TYPE))
    })
};

Box.propTypes = {
    isLoading: PropTypes.bool,
    hidden: PropTypes.bool,
    label: PropTypes.any,
    value: PropTypes.any,
    status: PropTypes.string,
    style: PropTypes.object
};
