import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/employee/leaves";

const END_POINTS = {
    loadAllEmployeeLeaves: { method: "post", path: "load-all" },
    loadAllLazyEmployeeLeaves: { method: "post", path: "load-all-lazy" },
    getEmployeeLeaves: { method: "get", path: "get-details" },
    getEmployeeLeaveSummary: { method: "post", path: "get-leave-summary" },
    createEmployeeLeaves: { method: "post", path: "create" },
    updateEmployeeLeaves: { method: "put", path: "update" },
    updateStatusEmployeeLeaves: { method: "put", path: "update-status" },
    updateRejoinedDetailsEmployeeLeaves: { method: "put", path: "update-rejoined-details" },
    uploadProofEmployeeLeaves: { method: "put", path: "upload-proof" }
};

export const employeeLeavesAPI = apiSlice.injectEndpoints({
    reducerPath: "employeeLeavesAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    if (queryParams.formData) conf.formData = true;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useLoadAllEmployeeLeavesMutation,
    useLoadAllLazyEmployeeLeavesMutation,
    useGetEmployeeLeavesMutation,
    useGetEmployeeLeaveSummaryMutation,
    useCreateEmployeeLeavesMutation,
    useUpdateEmployeeLeavesMutation,
    useUploadProofEmployeeLeavesMutation,
    useUpdateStatusEmployeeLeavesMutation,
    useUpdateRejoinedDetailsEmployeeLeavesMutation
} = employeeLeavesAPI;

export default employeeLeavesAPI.reducer;
