import { useEffect, useMemo, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    selectEmployeeSubmittedFormData,
    setCurrent,
    setState,
    defaultConfig,
    reset,
    updateEmployeeSubmittedFormData
} from "./slice";
import {
    useGetEmployeeSubmittedFormsMutation,
    useLoadAllEmployeeSubmittedFormsMutation,
    useSendEmployeeSubmittedFormsMutation,
    useUpdateStatusEmployeeSubmittedFormMutation,
    useDoFollowUpEmployeeSubmittedFormMutation
} from "./api";
import { SUBMITTED_FORM_TYPE, SUBMITTED_FORM_STATUS, SUBMITTED_FORM_CLASS } from "./const";
import { useGetWorkHistory } from "../employeeWorkHistories/hooks";
import { EDIT_TYPE } from "../employeeWorkHistories/const";
import { reset as resetHistory } from "../employeeWorkHistories/slice";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { TIMELINE_STATUS } from "../../../common/components/extra/timeline/const";

export const useGetSubmittedForm = (id) => {
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetEmployeeSubmittedFormsMutation();

    const [history, { fetch: fetchHistory, clearCurrent: clearHistory }] = useGetWorkHistory();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const getHistoryEditType = () => {
        let editType = EDIT_TYPE.DEFAULT;
        if (!current) {
            return editType;
        } else if (current.type == SUBMITTED_FORM_TYPE.WH_TIMING || current.type == SUBMITTED_FORM_TYPE.WH_ABSENT) {
            editType = EDIT_TYPE.TIMING;
        } else if (current.type == SUBMITTED_FORM_TYPE.WH_OVERTIME) {
            editType = EDIT_TYPE.OVERTIME;
        } else if (current.type == SUBMITTED_FORM_TYPE.WH_OT_OFF_DAY) {
            editType = EDIT_TYPE.OT_OFF_DAY;
        } else {
            editType = EDIT_TYPE.READ_ONLY;
        }
        return editType;
    };

    const createVars = (data) => {
        if (!data) {
            return {};
        }
        return {
            toUpdateHistoryId: data.formData?.historyId,
            historyDate: data.formData?.date,
            isRejected: data.status == SUBMITTED_FORM_STATUS.REJECTED,
            isApproved: data.status == SUBMITTED_FORM_STATUS.APPROVED,
            isPending: data.status == SUBMITTED_FORM_STATUS.PENDING,
            history,
            historyEditType: getHistoryEditType(),
            isHistoryClass: data.class == SUBMITTED_FORM_CLASS.WORK_HISTORY
        };
    };

    const fetch = async ({ force } = {}) => {
        try {
            if (!force && current && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch submitted forms. Please try again later");
            }
            const finRes = cloneDeep(result.data.data);
            const historyId = finRes && finRes.formData?.historyId;
            await handleFetchHistory(historyId);
            const employee = finRes.targetEmployee || finRes.sourceEmployee;
            finRes.fullName = employee.first_name + " " + employee.last_name;
            dispatch(setCurrent(finRes));
            return finRes;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const handleFetchHistory = (newid) => {
        const historyId = newid || (current && current.formData?.historyId);
        if (historyId) {
            return fetchHistory(historyId);
        }
    };

    const updateCurrent = (newCurrent = {}) => dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));

    const clearCurrent = ({ onlyHistory } = {}) => {
        !onlyHistory && dispatch(setCurrent(null));
        clearHistory();
    };

    const timelineData = useMemo(() => {
        const items = cloneDeep(current?.EmployeeFormStatusLogs || []).reverse();
        const statuses = items.map((item) => {
            switch (item.status) {
                case SUBMITTED_FORM_STATUS.APPEALED:
                    return TIMELINE_STATUS.WAITING;
                case SUBMITTED_FORM_STATUS.APPROVED:
                    return TIMELINE_STATUS.POSITIVE;
                case SUBMITTED_FORM_STATUS.CANCELED:
                    return TIMELINE_STATUS.CANCELED;
                case SUBMITTED_FORM_STATUS.COMPLETED:
                    return TIMELINE_STATUS.POSITIVE;
                case SUBMITTED_FORM_STATUS.PENDING:
                    return TIMELINE_STATUS.WAITING;
                case SUBMITTED_FORM_STATUS.RECEIVED:
                    return TIMELINE_STATUS.STALE;
                case SUBMITTED_FORM_STATUS.REJECTED:
                    return TIMELINE_STATUS.NEGATIVE;
                default:
                    return TIMELINE_STATUS.STALE;
            }
        });

        return {
            statuses,
            statusesWithInfoObject: items.reduce((prev, curr, idx) => {
                prev[idx] = curr;
                return prev;
            }, {})
        };
    }, [current?.EmployeeFormStatusLogs]);

    useEffect(() => {
        fetch();
    }, []);

    return [
        current,
        {
            isLoading: fetching,
            config: createVars(current),
            updateCurrent,
            fetch,
            refetch: () => fetch({ force: true }),
            clearCurrent,
            fetchHistory: handleFetchHistory,
            timelineData
        }
    ];
};

export const usePaginateSubmittedForms = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, onSort, onUpdate, tableConfig }] = usePaginateFetch(
        useLoadAllEmployeeSubmittedFormsMutation,
        {
            redux: {
                dataSelector: selectEmployeeSubmittedFormData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly
        }
    );

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch }];
};

export const useSendSubmittedForm = () => {
    const [sendForm, { isLoading }] = useSendEmployeeSubmittedFormsMutation();
    const dispatch = useAppDispatch();

    const method = async ({ employee_id, target_employee_id, target_work_detail_id, form_id, site_id = null, fieldValues = {} } = {}) => {
        try {
            const result = await sendForm({
                body: {
                    employee_id,
                    target_employee_id,
                    target_work_detail_id,
                    form_id,
                    site_id,
                    // i.e: { [FIELD.INPUT_TEXT]: "Sample Text"  } this adds a value to the fields inside of formData
                    fieldValues
                }
            });

            if (result.error) {
                throw new Error(result.error?.data?.message);
            }

            dispatch(reset());
            return result.data;
        } catch (error) {
            return { error };
        }
    };

    return [method, isLoading, { resetCache: () => dispatch(reset()) }];
};

export const useUpdateSubmittedFormStatus = (id) => {
    const [updateStatus, { isLoading }] = useUpdateStatusEmployeeSubmittedFormMutation();

    const dispatch = useAppDispatch();

    const method = async (updateId, newObject = {}) => {
        try {
            updateId = updateId || id;
            if (!updateId) {
                return;
            }
            const clonedform = cloneDeep(newObject);
            const result = await updateStatus({
                body: {
                    status: clonedform.status,
                    remarks: clonedform.remarks,
                    leaveId: clonedform.leaveId,
                    historyId: clonedform.historyId
                },
                extraPath: updateId
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data.data) {
                dispatch(resetHistory());
                dispatch(
                    updateEmployeeSubmittedFormData({
                        id: updateId,
                        data: result.data.data
                    })
                );
            }
            return true;
        } catch (error) {
            return { error };
        }
    };

    return [method, isLoading];
};

export const useDoFollowUp = (id) => {
    const [doFollowUp, { isLoading }] = useDoFollowUpEmployeeSubmittedFormMutation();

    const dispatch = useAppDispatch();

    const method = async (updateId) => {
        try {
            updateId = updateId || id;
            if (!updateId) {
                return;
            }
            const result = await doFollowUp({
                extraPath: updateId
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data.data) {
                dispatch(resetHistory());
            }
            return true;
        } catch (error) {
            return { error };
        }
    };

    return [method, isLoading];
};
