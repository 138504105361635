import React from "react";
import Capsule from "../../../common/components/extra/Capsule";
import { sanitizeWords } from "../../../common/utilities/helper";
import PropTypes from "prop-types";

const renderArr = (arr = [], isSupervisor) =>
    (arr.length && (
        <div className="flex column gap-05" style={{ justifyContent: "flex-end" }}>
            <span>{isSupervisor ? "Supervising" : "Managing"}:</span>
            <ul className="flex column" style={{ gap: ".1rem" }}>
                {arr.map((v, idx) => (
                    <li key={idx} style={{ marginLeft: ".5rem" }}>
                        <span className="text-ellipsis warning-color">{sanitizeWords(v)}</span>
                    </li>
                ))}
            </ul>
        </div>
    )) ||
    "";

function SitePositionCapsule({ supervising = [], managing = [], width, small, noBorder, noShadow }) {
    const createLabel = () => {
        return (
            <div className="flex gap-03">
                {!!supervising.length && <span>SV</span>}
                {!!supervising.length && !!managing.length && <span>/</span>}
                {!!managing.length && <span>MG</span>}
            </div>
        );
    };

    if (!supervising.length && !managing.length) {
        return <></>;
    }

    return (
        <Capsule
            style={(width && { parent: { width: `${width}rem` } }) || {}}
            title="SITE"
            tooltip={
                <div className="flex column gap-05" style={{ justifyContent: "flex-end" }}>
                    {renderArr(supervising || [], true)}
                    {renderArr(managing || [])}
                </div>
            }
            small={small}
            noBorder={noBorder}
            noShadow={noShadow}
        >
            {createLabel()}
        </Capsule>
    );
}

export default SitePositionCapsule;

SitePositionCapsule.propTypes = {
    supervising: PropTypes.arrayOf(PropTypes.string),
    managing: PropTypes.arrayOf(PropTypes.string),
    width: PropTypes.number,
    small: PropTypes.bool,
    noBorder: PropTypes.bool,
    noShadow: PropTypes.bool
};
