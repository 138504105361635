import React from "react";
import PropTypes from "prop-types";
import useFetchCountries from "../../../hooks/useFetchCountries";
import Select from "./Select";

function SelectNationality({ value, onChange, ...rest }) {
    const fetchedCountries = useFetchCountries({ rtl: false, mobile: true, isDenonym: true });

    const countries = fetchedCountries.nationalities.map((nationality) => ({
        ...nationality,
        label: <div className="semi-bold">{nationality.label}</div>
    }));

    return (
        <Select
            label="Nationality"
            options={countries}
            onChange={onChange}
            value={countries.find((gen) => gen.value === value)}
            filterOption={(options, newInputs) => {
                const search = newInputs.toLowerCase().trim();
                if (
                    options.value.includes(search) ||
                    options.data.name.common.toLowerCase().includes(search) ||
                    options.data.demonyms?.eng?.m.toLowerCase().includes(search)
                ) {
                    return true;
                } else {
                    return false;
                }
            }}
            isOutlined
            disabledOutline
            {...rest}
        />
    );
}

export default SelectNationality;

SelectNationality.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
};
