import React, { useMemo } from "react";
import PropTypes from "prop-types";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CloseIcon from "@mui/icons-material/Close";
import { useSortable } from "@dnd-kit/sortable";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import { createConfirmAlert } from "../../../utilities/helper";

function SortableItem(props) {
    const isSimpleLayout = props.simple;
    const isFixed = props.fixed;

    const idx = props.idx;
    const draggedItemIndex = props.draggedItemIndex;

    const { transform } = useDraggable({
        id: props.id
    });

    const { attributes, listeners, isOver, isDragging, transition, setNodeRef, setActivatorNodeRef, isSorting } = useSortable({
        id: props.id,
        data: props.data,
        transition: {
            duration: 150,
            easing: "cubic-bezier(0.25, 1, 0.5, 1)"
        }
    });

    const style = {
        position: "relative",
        display: "flex",
        margin: `${props.spacing || 0}rem 0`,
        zIndex: isDragging ? 999 : 5,
        transform: CSS.Translate.toString(transform),
        transition
    };

    if (props.temp) {
        style.background = "transparent";
    }

    if (props.hasOverlay) {
        delete style.transform;
        delete style.transition;
    }

    const classname = useMemo(() => {
        let classNames = "sort-item ";
        if (isOver) {
            classNames += "sort-item--is-dropping ";
            classNames += draggedItemIndex >= 0 && draggedItemIndex < idx ? "sort-item--is-dropping-to-bottom " : "sort-item--is-dropping-to-top ";
        }
        if (props.isActive) {
            classNames += "sort-item--is-active ";
        }
        if (props.temp) {
            classNames += "temp ";
        }
        if (props.hasError) {
            classNames += "sort-item--has-error ";
        }
        if (isSimpleLayout) {
            classNames += "sort-item--simple ";
        }
        if (props.className) {
            classNames += props.className;
        }
        return classNames.trim();
    }, [isOver, props.isActive, props.temp, props.hasError, props.simple, props.className]);

    return (
        <div ref={setNodeRef} data-sorting={isSorting} className={classname} style={style}>
            <div className="left" style={{ minWidth: "1.5rem" }}>
                {!props.temp && (
                    <div
                        ref={setActivatorNodeRef}
                        className={`drag-icon primary-color hover-svg ${props.iconClassName || ""}`.trim()}
                        {...attributes}
                        {...listeners}
                    >
                        <DragIndicatorIcon />
                    </div>
                )}
            </div>
            {props.children}
            {props.onRemove && !isFixed && (
                <div className="right">
                    <CloseIcon
                        className="pointer remove-icon danger-color hover-svg"
                        onClick={() =>
                            createConfirmAlert({
                                title: "Are you sure?",
                                content: "This action will remove the item and cannot be undone.",
                                onConfirm: async (close) => {
                                    close();
                                    props?.onRemove?.();
                                }
                            })
                        }
                    />
                </div>
            )}
        </div>
    );
}

export default SortableItem;

SortableItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    data: PropTypes.object,
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    spacing: PropTypes.number,
    hasOverlay: PropTypes.bool,
    iconClassName: PropTypes.string,
    isActive: PropTypes.bool,
    temp: PropTypes.bool,
    hasError: PropTypes.bool,
    fixed: PropTypes.bool,
    simple: PropTypes.bool,
    onRemove: PropTypes.func,
    idx: PropTypes.number,
    draggedItemIndex: PropTypes.number
};
