import { sanitizeWords } from "../../../common/utilities/helper";
import { SUBMITTED_FORM_STATUS } from "./const";

export const createStatusInfoFromStatusLog = (status, fullName, userType) => {
    const info = {
        tagColor: "",
        message: ""
    };

    fullName = sanitizeWords(fullName) + ` (${sanitizeWords(userType)})`;
    info.status = status;

    switch (status) {
        case SUBMITTED_FORM_STATUS.APPEALED: {
            info.tagColor = "yellow";
            info.message = `Appealed by ${fullName}`;
            break;
        }
        case SUBMITTED_FORM_STATUS.APPROVED: {
            info.tagColor = "green";
            info.message = `Approved by ${fullName}`;
            break;
        }
        case SUBMITTED_FORM_STATUS.CANCELED: {
            info.tagColor = "dark-gray";
            info.message = `Canceled by ${fullName}`;
            break;
        }
        case SUBMITTED_FORM_STATUS.COMPLETED: {
            info.tagColor = "green";
            info.message = "Completed";
            break;
        }
        case SUBMITTED_FORM_STATUS.PENDING: {
            info.tagColor = "gray";
            info.message = "Waiting for Feedback";
            break;
        }
        case SUBMITTED_FORM_STATUS.RECEIVED: {
            info.tagColor = "";
            info.message = `Received by ${fullName}`;
            info.status = "Waiting for Reviewer";
            break;
        }
        case SUBMITTED_FORM_STATUS.REJECTED: {
            info.tagColor = "red";
            info.message = `Rejected by ${fullName}`;
            break;
        }
        default:
            break;
    }

    return info;
};
