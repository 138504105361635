import React from "react";
import moment from "moment-timezone";

import countries from "./const.countries.json";
import currencies from "./const.currencies.json";
import { creditCardType } from "card-validator";

import { ReactComponent as ID_SVG } from "../../assets/images/id-icon.svg";
import { ReactComponent as PHOTO_SVG } from "../../assets/images/photo-icon.svg";
import { ReactComponent as PASSPORT_SVG } from "../../assets/images/passport-icon.svg";
import { ReactComponent as CONTRACT_SVG } from "../../assets/images/contract-icon.svg";

export const COUNTRIES = countries;
export const CURRENCIES = currencies;
export const TIMEZONES = moment.tz.names();

const cardValidatorTypes = creditCardType.types;

export const STANDARD_REF_DATE = "2000-01-01"; // starting point for Timekeeper // YYYY-MM-DD
export const STANDARD_DATE_FORMAT = "YYYY-MM-DD";

// default len for text
export const NOTES_MAX_LEN = 500;
export const TEXT_MAX_LEN = 255;

// we do this just to get the inteliscence
export const CREDIT_CARD_TYPE = {
    VISA: cardValidatorTypes["VISA"],
    MASTERCARD: cardValidatorTypes["MASTERCARD"],
    AMERICAN_EXPRESS: cardValidatorTypes["AMERICAN_EXPRESS"],
    DINERS_CLUB: cardValidatorTypes["DINERS_CLUB"],
    DISCOVER: cardValidatorTypes["DISCOVER"],
    JCB: cardValidatorTypes["JCB"],
    UNIONPAY: cardValidatorTypes["UNIONPAY"],
    MAESTRO: cardValidatorTypes["MAESTRO"],
    ELO: cardValidatorTypes["ELO"],
    MIR: cardValidatorTypes["MIR"],
    HIPER: cardValidatorTypes["HIPER"],
    HIPERCARD: cardValidatorTypes["HIPERCARD"]
};

export const ROLES = {
    SUPER_ADMIN: 0x1,
    COMPANY_ADMIN: 0x2
};

export const ROLE_TYPE = {
    SUPER_ADMIN: "SUPER_ADMIN",
    COMPANY: "COMPANY",
    EMPLOYEE: "EMPLOYEE"
};

export const ROLE_LEVEL = {
    HIGH: "HIGH",
    LOW: "LOW"
};

export const RECURRENCE = {
    TRIAL: "TRIAL",
    MONTHLY: "MONTHLY",
    QUARTERLY: "QUARTERLY",
    SEMI_ANNUAL: "SEMI_ANNUAL",
    ANNUALLY: "ANNUALLY"
};

export const RECURRENCE_WITH_VALUE = {
    TRIAL: {
        label: "Trial",
        value: 0
    },
    MONTHLY: {
        label: "Monthly",
        value: 1,
        moment: "months"
    },
    QUARTERLY: {
        label: "Quarterly",
        value: 3,
        moment: "months"
    },
    SEMI_ANNUAL: {
        label: "6 Months",
        value: 6,
        moment: "months"
    },
    ANNUALLY: {
        label: "12 Months",
        value: 12,
        moment: "months"
    }
};

export const VIEW_TYPES = {
    LIST: "List View",
    GRID: "Grid View",
    TABLE: "Table View",
    CARD: "Card View"
};

export const CONTRACT_TYPE = {
    FULL_TIME: "FULL_TIME",
    PART_TIME: "PART_TIME"
};

export const CONTRACT_STATUS = {
    ACTIVE: "ACTIVE",
    RESIGNED: "RESIGNED",
    TERMINATED: "TERMINATED"
};

export const CONTRACT_TERM = {
    SECONDMENT: "SECONDMENT",
    FIXED_TERM: "FIXED_TERM",
    UNLIMITED: "UNLIMITED",
    RENEWABLE: "RENEWABLE"
};

export const SALARY_FREQUENCY = {
    DAILY: "DAILY",
    WEEKLY: "WEEKLY",
    MONTHLY: "MONTHLY"
};

export const GENDER = {
    MALE: "MALE",
    FEMALE: "FEMALE"
};

export const MARITAL_STATUS = {
    SINGLE: "SINGLE",
    MARRIED: "MARRIED",
    DIVORCED: "DIVORCED",
    WIDOWED: "WIDOWED",
    SEPARATED: "SEPARATED",
    DOMESTIC_PARTNERSHIP: "DOMESTIC_PARTNERSHIP",
    CIVIL_UNION: "CIVIL_UNION"
};

export const DAY_ABBREVIATION = {
    MONDAY: "MON",
    TUESDAY: "TUE",
    WEDNESDAY: "WED",
    THURSDAY: "THURS",
    FRIDAY: "FRI",
    SATURDAY: "SAT",
    SUNDAY: "SUN"
};

export const FILE_MIME_TYPES = {
    FOLDER: "folder",
    EXCEL: {
        XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        XLS: "application/vnd.ms-excel",
        CSV: "text/csv"
    },
    IMAGE: {
        PNG: "image/png",
        JPG: "image/jpg",
        JPEG: "image/jpeg"
    },
    PDF: "application/pdf"
};

export const COMMON_DOCUMENT_MIME_TYPES_ACCEPTS = [...Object.values(FILE_MIME_TYPES.IMAGE), FILE_MIME_TYPES.PDF];
export const DOC_MIME_TYPES_ACCEPTS = [FILE_MIME_TYPES.PDF].concat(Object.values(FILE_MIME_TYPES.EXCEL));

export const EMPLOYEE_FILES = {
    PHOTO: {
        key: "photo",
        required: true,
        accepts: Object.values(FILE_MIME_TYPES.IMAGE),
        size: 1,
        icon: <PHOTO_SVG />
    },
    PASSPORT: {
        key: "passport",
        required: true,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS,
        size: 3,
        icon: <PASSPORT_SVG style={{ width: "1.2rem" }} />
    },
    CONTRACT: {
        key: "contract",
        required: true,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS,
        size: 3,
        icon: <CONTRACT_SVG style={{ width: "1.4rem" }} />
    },
    RESIDENCE: {
        key: "residence",
        required: true,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS,
        size: 3,
        icon: <ID_SVG />
    },
    VISA: {
        key: "visa",
        required: false,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS,
        size: 3,
        icon: <ID_SVG />
    },
    LABOR_CARD: {
        key: "labor-card",
        required: false,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS,
        size: 3,
        icon: <ID_SVG />
    }
};

export const COMPANY_FILES = {
    LICENSE_TRADE: {
        key: "trade-license",
        required: false,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS
    },
    LICENSE_COMMERCIAL: {
        key: "commercial-license",
        required: false,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS
    },
    LOGO: {
        key: "logo",
        required: false,
        accepts: Object.values(FILE_MIME_TYPES.IMAGE)
    },
    ESTABLISHMENT_ID: {
        key: "eid",
        required: false,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS
    },
    COMPANY_CONTRACT: {
        key: "company-contract",
        required: false,
        accepts: COMMON_DOCUMENT_MIME_TYPES_ACCEPTS
    }
};

export const SORT_ORDER = {
    ASC: "ASC",
    DESC: "DESC"
};

export const FALSE_VALUES = [undefined, null];

export const WORK_TYPE = {
    PROJECT: "PROJECT",
    OFFICE: "OFFICE",
    BRANCH: "BRANCH"
};

export const SHIFT_TYPE = {
    NORMAL: "NORMAL",
    SPLIT: "SPLIT"
};

export const SCHEDULE_TYPE = {
    NORMAL: "NORMAL",
    FLEXIBLE_OFF: "FLEXIBLE_OFF",
    FLEXIBLE_TIME: "FLEXIBLE_TIME"
};

export const WORK_HISTORY_SHIFT_STATUS = {
    LATE: "LATE",
    ON_TIME: "ON_TIME",
    LATE_SECOND_SHIFT: "LATE_SECOND_SHIFT",
    ON_TIME_SECOND_SHIFT: "ON_TIME_SECOND_SHIFT",
    UNDERTIME: "UNDERTIME",
    OVERTIME: "OVERTIME",
    FIRST_SHIFT_DONE: "FIRST_SHIFT_DONE",
    SECOND_SHIFT_DONE: "SECOND_SHIFT_DONE",
    OVERTIME_DONE: "OVERTIME_DONE",
    OVER_BREAK: "OVER_BREAK",
    HALF_DAY: "HALF_DAY"
};

export const WORK_HISTORY_SHIFT_STATUS_ABNORMALITIES = [
    WORK_HISTORY_SHIFT_STATUS.HALF_DAY,
    WORK_HISTORY_SHIFT_STATUS.LATE,
    WORK_HISTORY_SHIFT_STATUS.OVER_BREAK,
    WORK_HISTORY_SHIFT_STATUS.UNDERTIME,
    WORK_HISTORY_SHIFT_STATUS.LATE_SECOND_SHIFT
];

export const WORK_HISTORY_TYPE = {
    NORMAL: "NORMAL",
    OT_OFF_DAY: "OT_OFF_DAY" //(ot_off day - 1.5)
};

export const WORK_HISTORY_STATUS = {
    AUTO_VERIFIED: "AUTO_VERIFIED",
    PENDING: "PENDING", // NOTE: Make the company admin confirms pending work histories
    ADMIN_VERIFIED: "ADMIN_VERIFIED",
    REJECTED: "REJECTED"
};

export const SETTING_SETUP = {
    PROFILE: {
        id: "PROFILE",
        name: "Profile",
        label: "Customize Your Company Profile",
        description: "Tailor your company's profile to reflect its unique identity and branding, ensuring a professional image."
    },
    GENERAL: {
        id: "GENERAL",
        name: "General",
        label: "Configure Employee Time Tracking",
        description: "Efficiently track employee work hours and attendance with customizable time tracking settings."
    },
    SALARY: {
        id: "SALARY",
        name: "Work/Salary",
        label: "Define Salary Calculation Methods",
        description: "Define how employee salaries are calculated, ensuring accuracy and compliance with company policies."
    },
    POSITION: {
        id: "DEPT_DESIG",
        name: "Department/Designation",
        label: "Manage Departments and Designations",
        description:
            "Organize your company's structure by defining various departments and employee designations to streamline management and reporting."
    },
    PENALTY: {
        id: "PENALTY",
        name: "Penalties",
        label: "Establish Employee Penalty Policies",
        description: "Set clear policies for employee penalties to maintain discipline and adherence to company rules."
    }
};

export const TABLE_ACTION_TYPE_COMMON = {
    EDIT: "EDIT",
    PREVIEW: "PREVIEW",
    REMOVE: "REMOVE"
};

// dont edit and interchange
export const PENALTY_LEVEL = {
    WARNING: "WARNING",
    DAY_DEDUCTION: "DAY_DEDUCTION",
    FIX_VALUE: "FIXED_VALUE",
    CANCEL_DAY_RECORD: "CANCEL_DAY_RECORD",
    TERMINATION: "TERMINATION"
};

export const PENALTY_DEDUCTION_TYPE = {
    FULL_SALARY: "FULL_SALARY",
    BASIC_SALARY: "BASIC_SALARY"
};

export const PAYMENT_TYPE = {
    BANK_TRANSFER: "BANK_TRANSFER",
    CREDIT_CARD: "CREDIT_CARD"
};

export const PAYMENT_STATUS = {
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    CANCELED: "CANCELED"
};

export const COMPANY_STATUS = {
    ACTIVE: "ACTIVE",
    APPROVED: "APPROVED",
    PENDING_PAYMENT: "PENDING_PAYMENT",
    VERIFIED_EMAIL: "VERIFIED_EMAIL",
    REQUESTING_CONTRACT: "REQUESTING_CONTRACT"
};

export const COMPANY_SUBSCRIPTION_STATUS = {
    ACTIVE: "ACTIVE",
    VERIFIED: "VERIFIED"
};

export const TIMEOUT_STATUS = {
    IN_SITE: "IN_SITE",
    OUT_OF_SITE: "OUT_OF_SITE"
};

export const DATE_FILTER = {
    TODAY: "TODAY",
    CUSTOM: "CUSTOM"
};

export const FILTER_ALL_VALUE = {
    value: "",
    label: "All"
};

export const DATE_RANGE = {
    TYPE: "type",
    FROM: "from",
    TO: "to"
};

export const FILTER_BOOLEAN = {
    TRUE: "1",
    FALSE: "0"
};

export const PAYROLL_MONTH_OFFSET = 1;
