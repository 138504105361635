import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { useAppDispatch } from "./reduxHooks";
import cloneDeep from "lodash/cloneDeep";

export const checkIfNoNewFilter = (filter) => {
    if (!filter) return true;
    return (
        filter &&
        Object.values(filter).every((item) => {
            if (item && typeof item == "object") {
                if (item.type && !!item?.from == false && !!item?.to == false) {
                    return false;
                }
                return (!!item?.from == false && !!item?.to == false) || !item.type;
            } else {
                return !!item == false;
            }
        })
    );
};

const createFilter = ({ filter, exclude = [] } = {}) => {
    const cloneFilter = cloneDeep(filter);
    exclude.forEach((key) => delete cloneFilter[key]);
    return cloneFilter;
};

function useFilterManager({ onFilter, filterConfig, defaultFilter, filter, isLoading, setFilter, resetFilter, exclude }) {
    defaultFilter = createFilter({ filter: defaultFilter, exclude });
    filter = createFilter({ filter: filter, exclude });

    const [newFilters, setNewFilters] = useState(defaultFilter);

    const dispatch = useAppDispatch();
    const isFilterBackToDefault = isEqual(defaultFilter, filter);
    const hasFilterChanges = !isEqual(newFilters, filter);

    const getValueFromOpt = (filterType, val) => filterConfig[filterType].options.find((opt) => isEqual(opt.value, val));

    useEffect(() => {
        setNewFilters(filter);
    }, []);

    const updateNewFilters = (name, value) => setNewFilters({ ...(newFilters || {}), [name]: value });

    const handleChange = (name, value) => updateNewFilters(name, value);

    const handleReset = () => {
        dispatch(resetFilter());
        setNewFilters(defaultFilter);
        typeof onFilter == "function" && onFilter(defaultFilter);
    };

    const handleCustomDateChange = (name, value, key) => {
        updateNewFilters(key, { ...newFilters[key], [name]: value });
    };

    const handleFilter = () => {
        dispatch(setFilter({ newObject: newFilters }));
        typeof onFilter == "function" && onFilter(newFilters);
    };

    return [
        newFilters,
        {
            isClearDisabled: isFilterBackToDefault || isLoading,
            isFilterDisabled: !hasFilterChanges,
            getValueFromOpt,
            handleChange,
            handleReset,
            handleCustomDateChange,
            handleFilter
        }
    ];
}

export default useFilterManager;

useFilterManager.propTypes = {
    onFilter: PropTypes.func,
    filterConfig: PropTypes.object,
    defaultFilter: PropTypes.object,
    filter: PropTypes.object,
    isLoading: PropTypes.bool,
    setFilter: PropTypes.func,
    resetFilter: PropTypes.func
};
