import React from "react";
import PropTypes from "prop-types";

function Loader({ centered, relative, white, style = {}, svgStyle, overlayStyle, hasOverlay, absolute, children }) {
    let classname = "tk-loader";
    if (centered) classname = classname + " centered-fixed";
    if (relative) classname = classname + " relative";
    if (relative && white) classname = classname + " white";
    return (
        <div className={classname} style={absolute ? { ...style, position: "absolute" } : style}>
            <div className="tk-loader__inner">
                {hasOverlay && <div className="tk-loader__overlay" style={overlayStyle}></div>}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    style={{
                        margin: "auto",
                        background: "none",
                        display: "block",
                        shapeRendering: "auto",
                        ...(svgStyle || {})
                    }}
                    width="200px"
                    height="200px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    fill="currentColor"
                >
                    <g transform="rotate(0 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.9166666666666666s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(30 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.8333333333333334s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(60 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite" />
                        </rect>
                    </g>
                    <g transform="rotate(90 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.6666666666666666s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(120 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.5833333333333334s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(150 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite" />
                        </rect>
                    </g>
                    <g transform="rotate(180 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.4166666666666667s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(210 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.3333333333333333s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(240 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite" />
                        </rect>
                    </g>
                    <g transform="rotate(270 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.16666666666666666s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(300 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate
                                attributeName="opacity"
                                values="1;0"
                                keyTimes="0;1"
                                dur="1s"
                                begin="-0.08333333333333333s"
                                repeatCount="indefinite"
                            />
                        </rect>
                    </g>
                    <g transform="rotate(330 50 50)">
                        <rect x={47} y={24} rx={3} ry={6} width={6} height={12} fill="#currentColor">
                            <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite" />
                        </rect>
                    </g>
                </svg>
            </div>
            {children}
        </div>
    );
}

Loader.propTypes = {
    centered: PropTypes.bool,
    relative: PropTypes.bool,
    hasOverlay: PropTypes.bool,
    white: PropTypes.bool,
    style: PropTypes.object,
    absolute: PropTypes.bool,
    overlayStyle: PropTypes.object,
    svgStyle: PropTypes.object,
    children: PropTypes.any
};

export default Loader;
