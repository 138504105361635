import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import { TOAST_TYPE, createClass, createToast, sanitizeWords } from "../../../common/utilities/helper";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import ViewModal from "./ViewModal";
import { useAssignEmployeesMutation } from "./api";
import { reset } from "./slice";
import Transfer, { TRANSFER_CONTROL } from "../../../common/components/extra/transfer/Transfer";
import { useLazyEmployeeManager } from "./hooks";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { ASSIGN_TYPE, BASE_CLASS, UNIQUE_KEY } from "./const";

function TransferAssignEmployeesModal({
    data: selected,
    open,
    onChange,
    onClose,
    onConfirm,
    readOnly,
    type = ASSIGN_TYPE.WORK_SHIFT,
    excludeIds = [],
    enableUncheckedOnshift,
    disableSupervisors,
    showSites
}) {
    const [, setDefaultCheckedIds] = useState([]);

    const isSite = type === ASSIGN_TYPE.SITE;
    const isWorkshift = type === ASSIGN_TYPE.WORK_SHIFT;

    const leftData = useLazyEmployeeManager({
        title: "Available Employees",
        excludeIds,
        enableUncheckedOnshift,
        uniqueKey: UNIQUE_KEY,
        onChange,
        onMount: (param) => setDefaultCheckedIds(param.defaultCheckedIds),
        selected,
        readOnly,
        type,
        excludeWorkShiftId: isWorkshift && selected.id,
        excludeWorkDetailIds: isSite && [selected.work_detail_id],
        hasInternalSelections: true,
        disableSupervisors,
        showSites
    });

    const rightData = useLazyEmployeeManager({
        title: "Selected Employees",
        excludeIds,
        enableUncheckedOnshift,
        uniqueKey: UNIQUE_KEY,
        onChange,
        onMount: (param) => setDefaultCheckedIds(param.defaultCheckedIds),
        selected,
        readOnly,
        type,
        workShiftId: isWorkshift && selected.id,
        workDetailIds: isSite && [selected.work_detail_id],
        hasInternalSelections: true,
        disableSupervisors,
        showSites
    });

    const createTopExtra = (isLeft) => {
        return (
            <SectionCollapseInfo alwaysOpen show>
                {isLeft
                    ? `Select the employees you want to assign. Click the > to proceed.`
                    : `Select the employees you want to remove. Click the < to proceed.`}
            </SectionCollapseInfo>
        );
    };

    leftData.topExtra = createTopExtra(true);
    rightData.topExtra = createTopExtra();

    leftData.isTotalRecordsLeft = true;

    const objectSelected = leftData.object.selected || rightData.object.selected;
    const objectViewSites = leftData.object.viewSites || rightData.object.viewSites;

    const [assignEmployees, { isLoading: assignIsLoading }] = useAssignEmployeesMutation();

    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch();
    const setting = user.Setting || {};

    const refetch = async () => {
        await Promise.all([leftData.reset(), rightData.reset()]);
        leftData.updateObject({ checked: [], unchecked: [] });
        rightData.updateObject({ checked: [], unchecked: [] });
        return;
    };

    const handleConfirm = async ({ add, remove, isAddAll, isRemoveAll }) => {
        let retval = null;
        try {
            if (!selected) {
                throw new Error("Data is required before assigning employees.");
            }
            const body = {
                addToEmployees: add,
                removeToEmployees: remove,
                isAddAll,
                isRemoveAll
            };
            if (isSite) {
                body.siteId = selected.id;
            } else if (isWorkshift) {
                body.workShiftId = selected.id;
            }
            const res = await assignEmployees({ body });
            if (res.error) {
                throw new Error(res.error?.data?.message || "Failed to assign employees.");
            }
            retval = res.data?.data;
            dispatch(reset());
            leftData.updateConfig({ search: "" });
            rightData.updateConfig({ search: "" });
            await refetch();
        } catch (error) {
            createToast("Failed to assign employees.", TOAST_TYPE.ERROR);
        }
        typeof onConfirm === "function" && onConfirm({ added: add, removed: remove }, retval);
    };

    const handleTransfer = (type, items) => {
        switch (type) {
            case TRANSFER_CONTROL.ALL_TO_RIGHT:
                return handleConfirm({ isAddAll: true }).then(() =>
                    createToast(<span>Successfully assigned all employees to {sanitizeWords(selected.title)}</span>, TOAST_TYPE.SUCCESS)
                );
            case TRANSFER_CONTROL.TO_RIGHT:
                return handleConfirm({ add: items }).then(() =>
                    createToast(<span>Successfully assigned selected employees to {sanitizeWords(selected.title)}</span>, TOAST_TYPE.SUCCESS)
                );
            case TRANSFER_CONTROL.TO_LEFT:
                return handleConfirm({ remove: items }).then(() =>
                    createToast(<span>Successfully removed selected employees from {sanitizeWords(selected.title)}</span>, TOAST_TYPE.SUCCESS)
                );
            case TRANSFER_CONTROL.ALL_TO_LEFT:
                return handleConfirm({ isRemoveAll: true }).then(() =>
                    createToast(<span>Successfully removed all employees from {sanitizeWords(selected.title)}</span>, TOAST_TYPE.SUCCESS)
                );
            default:
                break;
        }
    };

    const createModalHeader = () => {
        return (
            <div className="flex center">
                {selected.title && (isSite || isWorkshift) && <span>{sanitizeWords(selected.title)} -&nbsp;</span>} <span>Assign Employees</span>
            </div>
        );
    };

    return (
        <>
            <Modal
                title={createModalHeader()}
                open={open}
                onChange={onChange}
                onClose={onClose}
                styles={{
                    content: { width: "90vw", height: "90vh" },
                    body: { overflow: "auto" },
                    form: { padding: "0 5rem" },
                    footer: { paddingTop: "1rem" }
                }}
                hasHeaderStyle
            >
                <div className={createClass("__transfer", BASE_CLASS)}>
                    <div className={createClass("__inner flex column gap-1", BASE_CLASS)}>
                        <Transfer
                            left={leftData}
                            right={rightData}
                            height={24}
                            onTransfer={handleTransfer}
                            controls={{
                                [TRANSFER_CONTROL.ALL_TO_RIGHT]: {
                                    tooltip: "Assign All Employees",
                                    confirmAlert: {
                                        title: "Assign All",
                                        content: (
                                            <div className="flex column gap-1">
                                                <p style={{ margin: 0 }}>
                                                    Are you sure? This will assign all employees to <strong>{sanitizeWords(selected.title)}</strong>.
                                                    This cannot be undone.
                                                </p>
                                                {isWorkshift && (
                                                    <span className="danger-color small-font">
                                                        Warning: If the employee have already an existing work shift they will be transfered to this
                                                        work shift.
                                                    </span>
                                                )}
                                            </div>
                                        )
                                    }
                                },
                                [TRANSFER_CONTROL.TO_RIGHT]: {
                                    tooltip: "Assign Selected Employees",
                                    confirmAlert: {
                                        title: "Assign Selections",
                                        content: (
                                            <div className="flex column gap-1">
                                                <p style={{ margin: 0 }}>
                                                    Are you sure? This will assign the selected employees to{" "}
                                                    <strong>{sanitizeWords(selected.title)}</strong>. This cannot be undone.
                                                </p>
                                                {isWorkshift && (
                                                    <span className="danger-color small-font">
                                                        Warning: If the employee have already an existing work shift they will be transfered to this
                                                        work shift.
                                                    </span>
                                                )}
                                            </div>
                                        )
                                    }
                                },
                                [TRANSFER_CONTROL.TO_LEFT]: {
                                    tooltip: "Remove Selected Employees",
                                    confirmAlert: {
                                        title: "Remove Selections",
                                        content: (
                                            <p style={{ margin: 0 }}>
                                                Are you sure? This will remove the selected employees from{" "}
                                                <strong>{sanitizeWords(selected.title)}</strong>. This cannot be undone.
                                            </p>
                                        )
                                    }
                                },
                                [TRANSFER_CONTROL.ALL_TO_LEFT]: {
                                    tooltip: "Remove All Employees",
                                    confirmAlert: {
                                        title: "Remove All",
                                        content: (
                                            <p style={{ margin: 0 }}>
                                                Are you sure? This will remove all employees from <strong>{sanitizeWords(selected.title)}</strong>.
                                                This cannot be undone.
                                            </p>
                                        )
                                    }
                                }
                            }}
                            isLoading={assignIsLoading}
                        />
                    </div>
                </div>
            </Modal>
            {!!objectSelected && !objectViewSites && (
                <ViewModal
                    open={!!objectSelected}
                    onClose={() => {
                        leftData.updateObject({
                            selected: null,
                            viewSites: false
                        });
                        rightData.updateObject({
                            selected: null,
                            viewSites: false
                        });
                    }}
                    id={objectSelected.id}
                    setting={setting}
                    readOnly
                />
            )}
            {objectViewSites && (
                <ViewGeozonesModal
                    open={objectViewSites}
                    onClose={() => {
                        (leftData.objectselected || leftData.object.viewSites) &&
                            leftData.updateObject({
                                selected: null,
                                viewSites: false
                            });
                        (rightData.objectselected || rightData.object.viewSites) &&
                            rightData.updateObject({
                                selected: null,
                                viewSites: false
                            });
                    }}
                    data={objectSelected.CompanySites.map((cs) => cs.id) || []}
                />
            )}
        </>
    );
}

TransferAssignEmployeesModal.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.any,
    onChange: PropTypes.func,
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    type: PropTypes.oneOf(Object.values(ASSIGN_TYPE)),
    excludeIds: PropTypes.array,
    withoutShift: PropTypes.bool,
    disableSupervisors: PropTypes.bool,
    enableUncheckedOnshift: PropTypes.bool,
    showSites: PropTypes.bool,
    workShiftId: PropTypes.any
};

export default TransferAssignEmployeesModal;
