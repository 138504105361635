import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { selectCurrent, setCurrent } from "./slice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { useDeleteCompanyAnnouncements, usePaginateCompanyAnnouncements } from "./hooks";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";

function Announcements({ openAddModal, onAddModalClose }) {
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(openAddModal);

    const [data, { isLoading: isPaginating, fetch, tableConfig }] = usePaginateCompanyAnnouncements();
    const [remove] = useDeleteCompanyAnnouncements();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const showViewModal = !!(openViewModal && current);
    const showUpdateModal = openEditModal;

    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = (newData) => {
        fetch();
        dispatch(setCurrent(newData));
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handleAction = (row, actionType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.PREVIEW:
                setOpenViewModal(true);
                dispatch(setCurrent({ id: row.id }));
                break;
            case TABLE_ACTION_TYPE_COMMON.REMOVE:
                remove(row.id).then(fetch);
                break;
            default:
                break;
        }
    };

    const handleViewModalClose = () => setOpenViewModal(false);

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenEditModal(false);
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                tableConfig={tableConfig}
                isLoading={isPaginating}
            />
            {showViewModal && <ViewModal id={current?.id} open={showViewModal} onClose={handleViewModalClose} onEdit={handleModalEdit} />}
            {showUpdateModal && (
                <UpdateModal
                    onBack={handleModalBack}
                    open={showUpdateModal}
                    onClose={handleUpdateModalClose}
                    onFinish={handleFinish}
                    id={current?.id}
                />
            )}
        </>
    );
}

Announcements.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default Announcements;
