import React, { useState } from "react";
import PropTypes from "prop-types";
import { setCurrent } from "./slice";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { useDeleteCompanyForm, usePaginateCompanyForm } from "./hooks";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import useNavigate from "../../../common/hooks/useNavigate";
import Navigation from "../../../common/classes/Navigation";
import { CUSTOM_ACTION } from "./const";
import SendFormModal from "../employeeSubmittedForms/SendFormModal";

function CompanyForms({ isHR, isEMP }) {
    const [toSendForm, setToSendForm] = useState(null);
    const [openSendFormModal, setOpenSendFormModal] = useState(false);
    const [data, { isLoading, fetch, tableConfig }] = usePaginateCompanyForm({ isHR, isEMP });
    const [remove] = useDeleteCompanyForm();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleAction = async (row, actionType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.EDIT:
                dispatch(setCurrent(row));
                navigate(Navigation.Routes.COMPANY_FORM_UPDATE.path.replace(":id", row.id));
                break;
            case TABLE_ACTION_TYPE_COMMON.REMOVE:
                await remove(row.id).then(() => fetch({ isHR, isEMP }));
                break;
            case CUSTOM_ACTION.SEND_FORM:
                setOpenSendFormModal(true);
                setToSendForm(row);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction, isHR, isEMP })}
                refetch={(conf) => fetch({ ...conf, isHR, isEMP })}
                tableConfig={tableConfig}
                isLoading={isLoading}
            />
            {openSendFormModal && <SendFormModal open={openSendFormModal} onClose={() => setOpenSendFormModal(false)} selected={toSendForm} />}
        </>
    );
}

CompanyForms.propTypes = {
    isHR: PropTypes.bool,
    isEMP: PropTypes.bool
};

export default CompanyForms;
