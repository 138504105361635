import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, addCommasToMoney, createToast, toReadableSelectOptions, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectEmployeeDeductionsData,
    defaultConfig
} from "./slice";
import {
    useCancelEmployeeDeductionsMutation,
    useCreateEmployeeDeductionsMutation,
    useDeleteEmployeeDeductionsMutation,
    useGetEmployeeDeductionsMutation,
    useLoadAllEmployeeDeductionsMutation,
    useUpdateEmployeeDeductionsMutation
} from "./api";
import Tag from "../../../common/components/extra/Tag";
import { CUSTOM_ACTION, DEDUCTION_PAYMENT_TYPE, DEDUCTION_STATUS, DEDUCTION_TYPE, FIELD } from "./const";
import { selectUserSetting } from "../../common/slice";
import { createStatusTag, isPastMonth } from "./helper";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";

const { EMPLOYEE, TYPE, PAYMENT_TYPE, AMOUNT, START_DATE, END_DATE, STATUS, CONFIRMED_DATE, NOTES } = FIELD;

export const useGetEmployeeDeductions = (id, callback) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);
    const [getDetails] = useGetEmployeeDeductionsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    // this means it only have the id key inside means we have to fetch more
    const isInitial = current && Object.keys(current).length == 1;
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = (data) => {
        if (!data) return {};

        return {
            confirmedDate: (data?.[CONFIRMED_DATE] && toTimeWithTimeZone(data?.[CONFIRMED_DATE], timezone)) || "",
            start_date: (data?.[START_DATE] && toTimeWithTimeZone(data?.[START_DATE], timezone)) || "",
            end_date: (data?.[END_DATE] && toTimeWithTimeZone(data?.[END_DATE], timezone)) || "",
            amount: addCommasToMoney(data.amount),
            isPastMonth: data?.[START_DATE] && isPastMonth(data, timezone),
            isOneTimePayment: data?.[PAYMENT_TYPE] == DEDUCTION_PAYMENT_TYPE.ONE_TIME
        };
    };

    const fetch = async () => {
        if (!id) return;

        try {
            if (!isInitial && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch record. Please try again later");
            }
            dispatch(setCurrent(result.data.data));
            callback?.(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateEmployeeDeductions = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, tableConfig, onSort, onUpdate }] = usePaginateFetch(useLoadAllEmployeeDeductionsMutation, {
        redux: {
            dataSelector: selectEmployeeDeductionsData,
            tableConfigSelector: selectTableConfig,
            currentSelector: selectCurrent,
            setState
        },
        defaultConfig,
        onMountConfig: {},
        runOnMount: !readOnly
    });

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSearch, onFilter, tableConfig, onSort }];
};

export const useUpsertEmployeeDeductions = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [EMPLOYEE]: "",
        [TYPE]: "",
        [PAYMENT_TYPE]: DEDUCTION_PAYMENT_TYPE.INSTALLMENT,
        [AMOUNT]: "",
        [START_DATE]: "",
        [END_DATE]: "",
        [NOTES]: "",
        [STATUS]: DEDUCTION_STATUS.ACTIVE
    });

    const [data, { isLoading }] = useGetEmployeeDeductions(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateEmployeeDeductionsMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateEmployeeDeductionsMutation();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = () => {
        const statusOpt = toReadableSelectOptions(DEDUCTION_STATUS).map((t) => ({ ...t, label: createStatusTag(t.value) }));
        const paymentTypeOpt = toReadableSelectOptions(DEDUCTION_PAYMENT_TYPE).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));
        const typeOpt = toReadableSelectOptions(DEDUCTION_TYPE).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));

        const status = statusOpt.find((status) => status.value == form[STATUS]) || "";
        const paymentType = paymentTypeOpt.find((category) => category.value == form[PAYMENT_TYPE]) || "";
        const type = typeOpt.find((type) => type.value == form[TYPE]) || "";

        return {
            statusOpt,
            paymentTypeOpt,
            typeOpt,
            [STATUS]: status,
            [PAYMENT_TYPE]: paymentType,
            [TYPE]: type,
            [START_DATE]: form[START_DATE],
            [END_DATE]: form[END_DATE],
            employee: { ...(data?.employee || {}), id: form[EMPLOYEE] },
            isPastMonth: !isCreate && isPastMonth(current, timezone),
            hasConfirmedDate: !isCreate && !!data?.[CONFIRMED_DATE],
            isCancelled: form[STATUS] == DEDUCTION_STATUS.CANCELED
        };
    };

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            const isOneTime = clonedform[PAYMENT_TYPE] == DEDUCTION_PAYMENT_TYPE.ONE_TIME;
            if (isOneTime) {
                clonedform[END_DATE] = null;
            }

            if (isCreate) {
                clonedform[EMPLOYEE] = clonedform[EMPLOYEE]?.id;
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Record ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} record. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        const temp = {
            [EMPLOYEE]: current?.[EMPLOYEE] || form[EMPLOYEE],
            [TYPE]: current?.[TYPE] || form[TYPE],
            [PAYMENT_TYPE]: current?.[PAYMENT_TYPE] || form[PAYMENT_TYPE],
            [AMOUNT]: current?.[AMOUNT] || form[AMOUNT],
            [START_DATE]: (current?.[START_DATE] && toTimeWithTimeZone(current?.[START_DATE], setting.timezone).format()) || form[START_DATE],
            [END_DATE]: (current?.[END_DATE] && toTimeWithTimeZone(current?.[END_DATE], setting.timezone).format()) || form[END_DATE],
            [STATUS]: current?.[STATUS] || form[STATUS],
            [NOTES]: current?.[NOTES] || form[NOTES]
        };
        setForm(temp);
        setOld(temp);
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord: isLoading,
            isUpserting: createIsLoading || updateIsLoading,
            config: createVars(),
            old,
            hasChanges: !!(old && !isCreate && !isEqual(form, old))
        }
    ];
};

export const useDeleteEmployeeDeductions = () => {
    const dispatch = useAppDispatch();

    const [deleteRecord] = useDeleteEmployeeDeductionsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteRecord({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete deduction.");
            }
            createToast("Deduction successfully removed.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useCancelEmployeeDeductions = () => {
    const [notes, setNotes] = useState("");

    const dispatch = useAppDispatch();

    const [cancelRecord] = useCancelEmployeeDeductionsMutation();

    const isLoading = useAppSelector(selectLoading);
    const current = useAppSelector(selectCurrent);

    const cancel = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await cancelRecord({ extraPath: id, body: { notes } });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to cancel deduction.");
            }
            if (current) {
                dispatch(
                    setCurrent({
                        ...current,
                        status: DEDUCTION_STATUS.CANCELED,
                        notes
                    })
                );
            }
            createToast("Deduction successfully cancelled.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [cancel, { isLoading, setNotes, notes }];
};

export const useManageActions = ({ cb } = {}) => {
    const [cancelId, setCancelId] = useState(null);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [, { fetch }] = usePaginateEmployeeDeductions({ readOnly: true });
    const [remove] = useDeleteEmployeeDeductions();

    const dispatch = useAppDispatch();

    const handleAction = async (row, actionType) => {
        switch (actionType) {
            case CUSTOM_ACTION.PREVIEW:
                setOpenViewModal(true);
                dispatch(setCurrent({ id: row.id }));
                return;
            case CUSTOM_ACTION.REMOVE:
                return await remove(row.id).then(async (result) => {
                    await fetch();
                    typeof cb == "function" && cb(result);
                });
            case CUSTOM_ACTION.CANCEL:
                setCancelId(row.id);
                return;
            default:
                return;
        }
    };

    return { onAction: handleAction, cancelId, setCancelId, openViewModal, setOpenViewModal };
};
