import React, { useState } from "react";
import PropTypes from "prop-types";
import { BASE_CLASS_UPDATE_MODAL } from "./const";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { useUpdateEmployeeDepartment } from "./hooks";
import {
    TOAST_TYPE,
    createClass,
    createToast,
    formattedJoiErrorMessage,
    isObjectEqualWithBase,
    sanitizeWords
} from "../../../common/utilities/helper";
import UpdateDepartmentInputs from "./UpdateDepartmentInputs";

function UpdateDepartmentModal({ open, onClose, onBack, onFinish, id, transparentOverlay, readOnly }) {
    const [error, setError] = useState(null);

    const [updateDept, isLoading, { onFormChange, form, current, isGettingEmployee, refetch }] = useUpdateEmployeeDepartment(id);

    const handleSave = async () => {
        try {
            const result = await updateDept();

            if (result) {
                createToast(
                    `Employee [${sanitizeWords(result?.first_name)} ${sanitizeWords(result?.last_name)}] department updated succesfully.`,
                    TOAST_TYPE.SUCCESS
                );
            } else {
                createToast(result.data.message, TOAST_TYPE.SUCCESS);
            }
            if (result && typeof onFinish === "function") {
                onFinish(result);
            }
            setError(null);
            onBack?.();
        } catch (error) {
            setError({ message: error?.message || error });
            createToast(
                `Failed to Update department: ${sanitizeWords(form.first_name)} ${sanitizeWords(form.last_name)}!. ${
                    error?.message || "Please try again later or contact support."
                } `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    return (
        <BaseUpdateModal
            title={readOnly ? "View Department Details" : "View/Update Department Details"}
            open={open}
            onClose={onClose}
            onSave={(!readOnly && handleSave) || null}
            isLoading={isLoading}
            disableSave={readOnly || isLoading || isGettingEmployee || isObjectEqualWithBase(form, current)}
            styles={{
                content: {
                    margin: "1rem",
                    maxHeight: "90vh",
                    minWidth: "25rem",
                    maxWidth: "40rem"
                },
                body: {
                    overflow: "auto"
                }
            }}
            transparentOverlay={transparentOverlay}
            onBack={onBack}
            isForm
            small
        >
            <div className={createClass(BASE_CLASS_UPDATE_MODAL)} style={{ margin: "0 1rem" }}>
                <div className={createClass("__inner", BASE_CLASS_UPDATE_MODAL)}>
                    <SectionCollapseError show={!!error}>{formattedJoiErrorMessage({ error, isCreate: false })}</SectionCollapseError>
                    <div className={createClass("__inner-row flex column", BASE_CLASS_UPDATE_MODAL)} style={{ marginTop: "1rem" }}>
                        <UpdateDepartmentInputs
                            base={BASE_CLASS_UPDATE_MODAL}
                            form={form}
                            current={current}
                            isFetching={isGettingEmployee}
                            onChange={onFormChange}
                            onFinish={refetch}
                            readOnly={readOnly}
                        />
                    </div>
                </div>
            </div>
        </BaseUpdateModal>
    );
}

UpdateDepartmentModal.propTypes = {
    id: PropTypes.any,
    open: PropTypes.bool,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    onClose: PropTypes.func,
    transparentOverlay: PropTypes.bool
};

export default UpdateDepartmentModal;
