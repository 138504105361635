import React from "react";
import PropTypes from "prop-types";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { createClass } from "../../../common/utilities/helper";
import { BASE_CLASS, TIMELINE_ACTION } from "./const";

function ViewExtraModal({ open, onClose, isLoading, data }) {
    const renderSwitch = () => {
        switch (data.type) {
            case TIMELINE_ACTION.VIEW_ATTACHMENTS:
                return data.value;
            case TIMELINE_ACTION.VIEW_REMARKS:
                return <p>{data.value}</p>;
            default:
                break;
        }
    };

    return (
        <BaseViewModal
            open={open}
            onClose={onClose}
            disableEdit={false}
            styles={{
                content: {
                    height: "max-content",
                    minHeight: "7rem",
                    maxHeight: "17rem",
                    width: "max-content",
                    maxWidth: "10rem"
                },
                body: { flexGrow: 1, overflow: "auto" }
            }}
            title="Remarks"
            hasHeaderStyle
            isLoading={isLoading}
            small
        >
            <div className={createClass("__view-extra-modal", BASE_CLASS)} style={{ padding: "1rem 2rem" }}>
                {renderSwitch()}
            </div>
        </BaseViewModal>
    );
}

ViewExtraModal.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.shape({
        type: PropTypes.oneOf(Object.values(TIMELINE_ACTION)),
        value: PropTypes.PropTypes.string
    })
};

export default ViewExtraModal;
