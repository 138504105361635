import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import ReactDatePicker from "react-datepicker";
import { toTimeWithTimeZone } from "../../../utilities/helper";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectUserSetting } from "../../../../features/common/slice";

const MONTH_YEAR_FORMAT = "MM/yyyy";
const DATE_FORMAT = "MM/d/yyyy";

function InputDate({
    noCurrent,
    noFuture,
    noPast,
    isMonthYear,
    isFixed,
    timezone,
    minDate,
    maxDate,
    placeholder,
    dateFormat,
    rangeSpan,
    rangeSpanBase,
    ...props
}) {
    const closeDocs = [document, document.querySelector("tbody")];
    const setting = useAppSelector(selectUserSetting);
    const tz = timezone || setting?.timezone;
    const isEnd = props.selectsEnd;

    const isDateRange = !!props.selectsRange;

    const getMax = () => {
        if (noCurrent) {
            return moment().subtract("1", "days").format("YYYY-MM-DD");
        }
        if (noFuture) {
            return moment().format("YYYY-MM-DD");
        }
        if (maxDate) {
            return maxDate;
        }
    };

    const getMin = () => {
        if (noPast) {
            return moment().format("YYYY-MM-DD");
        }
        if (minDate) {
            return minDate;
        }
    };

    const handleChange = (date) => {
        let value = "";
        if (date && !isDateRange && !props.showTimeSelect) {
            if (isEnd) {
                value = toTimeWithTimeZone(new Date(date), tz)
                    .endOf(isMonthYear ? "month" : "day")
                    .format();
            } else {
                value = toTimeWithTimeZone(new Date(date), tz)
                    .startOf(isMonthYear ? "month" : "day")
                    .format();
            }
        } else if (isDateRange) {
            if (rangeSpanBase && rangeSpan) {
                const [start] = date || [];
                date = [start, new Date(moment(start).add(rangeSpan, rangeSpanBase).format())];
            }
        }
        props?.onChange?.(isDateRange || props.showTimeSelect ? date : value);
    };

    return (
        <div className="tk-input__date flex w100" style={{ justifyContent: "flex-end" }}>
            <ReactDatePicker
                {...props}
                style={{ width: "14rem", flex: "unset" }}
                placeholderText={placeholder || (isMonthYear ? "mm/yyyy" : "mm/dd/yyyy")}
                maxDate={getMax()}
                minDate={getMin()}
                popperPlacement="bottom-left"
                closeOnScroll={(e) => closeDocs.includes(e.target)}
                onChange={handleChange}
                autoComplete="off"
                showPopperArrow={false}
                {...(isMonthYear ? { showMonthYearPicker: true, dateFormat: MONTH_YEAR_FORMAT } : { dateFormat: dateFormat || DATE_FORMAT })}
                {...(isFixed ? { popperProps: { strategy: "fixed" } } : {})}
                dropdownMode="select"
                showMonthDropdown
                showYearDropdown
                focusSelectedMonth
            />
        </div>
    );
}

export default InputDate;

InputDate.propTypes = {
    noCurrent: PropTypes.bool,
    noFuture: PropTypes.bool,
    isMonthYear: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    isFixed: PropTypes.bool,
    noPast: PropTypes.bool,
    timezone: PropTypes.string,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    selectsEnd: PropTypes.bool,
    selectsRange: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    dateFormat: PropTypes.string,
    rangeSpan: PropTypes.number,
    rangeSpanBase: PropTypes.string
};
