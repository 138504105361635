import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg";
import { createLabel } from "../companyForms/UserLevelSelect";
import { USER_LEVEL } from "../companyForms/const";

function UserLevel({ value }) {
    const level = useMemo(() => {
        return createLabel(value, { isRawLabel: true }).label.split(" ").shift();
    }, [value]);

    return (
        <div className="user-level flex align-center xs-font">
            <div className="value flex align-center">
                <StarIcon />
                <span className="xs-font bold abs-center">{value}</span>
            </div>
            <span className="level bold fade">{level}</span>
        </div>
    );
}

export default UserLevel;

UserLevel.propTypes = {
    value: PropTypes.oneOf(Object.values(USER_LEVEL))
};
