import { useEffect, useMemo, useState } from "react";
import isObject from "lodash/isObject";
import isNumber from "lodash/isNumber";
import cloneDeep from "lodash/cloneDeep";

export const useSelectManageInfoLazy = ({ isMulti, value, data, createRowItem, isLoading, onChange }) => {
    const normalizeValueToProperValue = () =>
        value && (isMulti ? (Array.isArray(value) ? value : [value]) : Array.isArray(value) ? value.shift() : value);

    value = normalizeValueToProperValue();

    const [cachedValue, setCachedValue] = useState(null);
    const [trackDefaultValue, setTrackDefaultValue] = useState(value);

    const newValue = useMemo(() => {
        const hasValue = !!value && (!isMulti ? !!Object.keys(value || {}).length : Array.isArray(value) && value.length);
        let temp =
            value &&
            (!isMulti
                ? Object.keys(value).length && data.find((item) => item.id === value.id)
                : data.filter((item) => value.map((v) => v.id).includes(item.id)));

        if (hasValue) {
            if (!isMulti && !temp) {
                temp = createRowItem(cachedValue || value);
            } else if (isMulti) {
                temp = (cachedValue || value || [])
                    .map((item) =>
                        createRowItem(
                            item,
                            trackDefaultValue.map((def) => def.id)
                        )
                    )
                    .filter(Boolean);
            }
        }
        // find the empty option value
        if (!temp) {
            temp = data.find((option) => !isNumber(option.value) && !option.value);
        }
        return temp;
    }, [value, isMulti, cachedValue, trackDefaultValue, data]);

    // use to disable fixed value if its not part of the default value only for multi
    // will only run after fetching which is assumed if isLoading is true
    useEffect(() => {
        value && setTrackDefaultValue(value);
    }, [isLoading]);

    const handleChange = (value, type) => {
        let temp = value && isObject(value) && cloneDeep(value);
        if (temp) {
            delete temp.label;
            delete temp.value;
        }
        onChange?.(temp, type);
        setCachedValue(value);
    };

    return [(!isLoading && newValue) || null, handleChange];
};

export default useSelectManageInfoLazy;
