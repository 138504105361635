import React from "react";
import { sanitizeWords, toReadableSelectOptions } from "../../../utilities/helper";

export const createOptions = (base, cb) => {
    if (!base) {
        return [];
    }
    let options = toReadableSelectOptions(base, (label) => (
        <div className="semi-bold">{typeof label == "string" ? sanitizeWords(label) : label}</div>
    ));
    if (typeof cb == "function") {
        options = Object.values(base).map((opt) => cb(opt));
    }
    return options;
};
