import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import RemoveIcon from "@mui/icons-material/RemoveCircle";
import Tooltip, { PLACES } from "../Tooltip";
import Button, { BTN_TYPE } from "../Button";
import { createConfirmAlert } from "../../../utilities/helper";
import Text from "../typography/Text";

function InputDynamicList({
    styles,
    defaultObject,
    label,
    value = [],
    transformRender,
    addLabel,
    tooltip,
    min,
    max,
    required,
    disableRemove,
    onChange,
    errors = {},
    warnings = {},
    noBorderIdx = [],
    noBorder,
    useSubBorderColor,
    beforeItemExtra,
    isLayoutColumn,
    isLayoutSingle,
    removeLabel
}) {
    const handleAdd = () => {
        onChange?.(value.concat(defaultObject));
    };

    useEffect(() => {
        if (min && value.length < min) {
            for (let index = 0; index < min; index++) {
                handleAdd();
            }
        }
    }, []);

    const render = useMemo(() => {
        const hasRemove = !disableRemove && min < value.length;

        const handleRemove = (idx) => {
            createConfirmAlert({
                title: "Are you sure?",
                content: "This will remove the selected item and cannot be undone.",
                onConfirm: (close) => {
                    close();
                    return onChange(value.filter((_, vidx) => idx != vidx));
                }
            });
        };

        return value.map((item, idx) => {
            const createClassname = () => {
                let temp = "input-dynamic-list__item ";
                if (noBorderIdx.includes(idx) || noBorder) temp += "no-border ";
                if (errors?.[idx]) temp += "has-error ";
                if (warnings?.[idx]) temp += "has-warning ";
                if (useSubBorderColor) temp += "sub-border ";
                if (isLayoutColumn) temp += "col-layout ";
                if (isLayoutSingle) temp += "single-layout ";
                return temp.trim();
            };

            return (
                <React.Fragment key={idx}>
                    <div key={idx} className={createClassname()}>
                        <div className="input-dynamic-list__render">
                            {beforeItemExtra ? (
                                <div className="flex" style={{ flex: 1, paddingRight: ".5rem", ...(styles?.beforeExtraParent || {}) }}>
                                    {typeof beforeItemExtra == "function" ? beforeItemExtra?.(item, idx) : beforeItemExtra}
                                    {transformRender?.(item, idx) || ""}
                                </div>
                            ) : (
                                transformRender?.(item, idx) || ""
                            )}
                        </div>
                        {hasRemove && (
                            <Tooltip
                                className="input-dynamic-list__extra"
                                onClick={() => handleRemove(idx, item.isNewItem)}
                                message="Remove"
                                position={PLACES.LEFT}
                            >
                                <Button className="danger remove" options={{ style: { padding: 0 } }} transparent mini small>
                                    <div className="flex align-center gap-05">
                                        {isLayoutColumn && removeLabel ? <span style={{ lineHeight: 0 }}>{removeLabel}</span> : ""}
                                        <RemoveIcon className="danger-color hover-svg" />
                                    </div>
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                    {errors && errors[idx] && (
                        <Text className="danger-color" useSubTextStyle>
                            {errors[idx]}
                        </Text>
                    )}
                </React.Fragment>
            );
        });
    }, [value, transformRender, onChange, errors]);

    return (
        <div className="input-dynamic-list" style={styles?.parent || {}}>
            <div className="input-dynamic-list__inner">
                <div className="input-dynamic-list__label fade small-font">
                    {label && (
                        <Tooltip message={tooltip} isIcon>
                            <div>
                                {label}
                                {required && <span className="danger-color bold">*</span>}
                            </div>
                        </Tooltip>
                    )}
                </div>
                {!!render.length && (
                    <div className="input-dynamic-list__content" style={{ gap: isLayoutSingle ? 0 : "1rem", ...(styles?.content || {}) }}>
                        {render}
                    </div>
                )}
                {(render.length < max || !render.length) && (
                    <div className="input-dynamic-list__footer flex align-center">
                        <Button type={BTN_TYPE.ADD} onClick={handleAdd}>
                            {addLabel || "Add"}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default InputDynamicList;

InputDynamicList.propTypes = {
    defaultObject: PropTypes.object,
    label: PropTypes.any,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        label: PropTypes.any,
        content: PropTypes.object,
        beforeExtraParent: PropTypes.object
    }),
    value: PropTypes.array,
    transformRender: PropTypes.func,
    addLabel: PropTypes.any,
    removeLabel: PropTypes.any,
    tooltip: PropTypes.any,
    required: PropTypes.bool,
    disableRemove: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    warnings: PropTypes.object,
    noBorderIdx: PropTypes.arrayOf(PropTypes.number),
    noBorder: PropTypes.bool,
    useSubBorderColor: PropTypes.bool,
    isLayoutColumn: PropTypes.bool,
    isLayoutSingle: PropTypes.bool,
    beforeItemExtra: PropTypes.any
};
