import React, { useMemo } from "react";
import moment from "moment-timezone";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ImageIcon from "@mui/icons-material/Image";
import AddIcon from "@mui/icons-material/Add";
import { DATE_FORMAT_OPTION, DATE_TIME_FORMAT, FORM_FIELD_TYPE, VALIDATION_FIELD, TIME_FORMAT } from "../const";
import { useManageValidationInfo } from "../hooks";
import { createFullName, sanitizeWords, toReadablePhone, toStandardDate, toTimeWithTimeZone } from "../../../../common/utilities/helper";
import { useAppSelector } from "../../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../../common/slice";
import useFetchCountries from "../../../../common/hooks/useFetchCountries";

const { LABEL, VALUE, FORMAT } = VALIDATION_FIELD;

export const useCreateFieldPlaceholder = (data, { useValue = false, employeeInfo = null, workDetailInfo = null } = {}) => {
    const { type, name, description, validation, hasLabelKey, isValueString, isValueArray, isQuestionnaireGroup } = useManageValidationInfo(data);
    const countries = useFetchCountries();
    const setting = useAppSelector(selectUserSetting);

    const timezone = setting.timezone;

    const info = useMemo(() => {
        let value = validation[VALUE];

        const temp = {
            placeholder: isValueArray && !isQuestionnaireGroup ? (value.length > 0 && value[0].label) || "" : isValueString ? value : description,
            name: (hasLabelKey && `${validation[LABEL] || "Label"}`) || ""
        };

        switch (type) {
            case FORM_FIELD_TYPE.EMPLOYEE_ID: {
                temp.placeholder = "XXX-000001";
                temp.name = "Employee ID";
                employeeInfo && (value = employeeInfo.generatedID);
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_NAME: {
                temp.placeholder = "John Doe";
                temp.name = "Full Name";
                employeeInfo && (value = createFullName(employeeInfo.first_name, employeeInfo.last_name));
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_DESIGNATION: {
                temp.placeholder = "IT Manager";
                temp.name = "Designation";
                employeeInfo && (value = sanitizeWords(employeeInfo.CompanyDesignation.title));
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_DEPARTMENT: {
                temp.placeholder = "Information Technology (IT)";
                temp.name = "Department";
                employeeInfo && (value = sanitizeWords(employeeInfo.CompanyDepartment.title));
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_NATIONALITY: {
                temp.placeholder = "Filipino";
                temp.name = "Nationality";
                if (employeeInfo) {
                    const countryInfo = countries.find((ctr) => ctr.cca2 == employeeInfo.nationality);
                    value = countryInfo && (countryInfo?.demonyms?.eng?.m || countryInfo?.name?.common) + ` (${employeeInfo.nationality})`;
                }
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_RESIDENCE_ID: {
                temp.placeholder = "QID789456";
                temp.name = "Residence ID";
                employeeInfo && (value = employeeInfo.residenceID);
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_MOBILE: {
                temp.placeholder = toReadablePhone("+97412345678");
                temp.name = "Mobile Number";
                employeeInfo && (value = toReadablePhone(employeeInfo.mobile_number));
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_JOINING_DATE: {
                temp.placeholder = "2023-04-15";
                temp.name = "Joining Date";
                employeeInfo && (value = toStandardDate(employeeInfo.EmployeeContract.joining_date, timezone));
                break;
            }
            case FORM_FIELD_TYPE.EMPLOYEE_JOB_GRADE: {
                temp.placeholder = "AA";
                temp.name = "Job Grade";
                employeeInfo && (value = employeeInfo.grade);
                break;
            }
            case FORM_FIELD_TYPE.WORK_DETAIL_PROJECT_CODE: {
                temp.placeholder = "Headquarters (HQ)";
                temp.name = "Project Code";
                workDetailInfo &&
                    (value = `${sanitizeWords(workDetailInfo.name)} ${(workDetailInfo.code && `(${workDetailInfo.code})`) || ""}`.trim());
                break;
            }
            // MULTIPLE CHOICE
            case FORM_FIELD_TYPE.TOGGLE: {
                temp.placeholder = "Yes";
                break;
            }
            // NORMAL INPUTS
            case FORM_FIELD_TYPE.TEXT_INPUT: {
                temp.placeholder = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
                break;
            }
            case FORM_FIELD_TYPE.TEXT_AREA: {
                temp.placeholder =
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.";
                break;
            }
            case FORM_FIELD_TYPE.NUMBER_INPUT: {
                temp.placeholder = "1500";
                break;
            }
            case FORM_FIELD_TYPE.ADDRESS: {
                temp.placeholder = "1234 Elm Street, Springfield, IL 62704, US";
                break;
            }
            case FORM_FIELD_TYPE.PHONE_NUMBER: {
                temp.placeholder = "+974 1234 5678";
                value = toReadablePhone(value);
                break;
            }
            case FORM_FIELD_TYPE.EMAIL: {
                temp.placeholder = "john.doe@email.com";
                break;
            }
            // DATE INPUTS
            case FORM_FIELD_TYPE.TIME: {
                temp.placeholder = moment().format(TIME_FORMAT);
                temp.name = temp.name || "Time";
                break;
            }
            case FORM_FIELD_TYPE.DATE_TIME: {
                temp.placeholder = moment().format(DATE_TIME_FORMAT);
                temp.name = temp.name || "Date Time";
                value && (value = toTimeWithTimeZone(value, timezone).format(DATE_TIME_FORMAT));
                break;
            }
            case FORM_FIELD_TYPE.DATE_RANGE: {
                const dateFormat = DATE_FORMAT_OPTION[validation[FORMAT]]?.format || "";
                const placeholderVal = (dateFormat ? `${dateFormat} - ${dateFormat}` : "").toLowerCase();
                temp.placeholder = placeholderVal;
                temp.name = temp.name || "Date Range";
                Array.isArray(value) && (value = value.map((date) => toTimeWithTimeZone(date, timezone).format(dateFormat)).join(" - "));
                break;
            }
            case FORM_FIELD_TYPE.DATE: {
                const dateFormat = DATE_FORMAT_OPTION[validation[FORMAT]]?.format || "";
                temp.placeholder = (dateFormat && moment().format(dateFormat)) || "";
                temp.name = temp.name || "Date";
                value && (value = toTimeWithTimeZone(value, timezone).format(dateFormat));
                break;
            }
            // UPLOAD INPUTS
            case FORM_FIELD_TYPE.IMAGE_UPLOAD:
            case FORM_FIELD_TYPE.FILE_UPLOAD: {
                temp.placeholder = (
                    <div className="file-upload-placeholder">
                        <div className="placeholder-box">{type == FORM_FIELD_TYPE.IMAGE_UPLOAD ? <ImageIcon /> : <FilePresentIcon />}</div>
                        <div className="placeholder-box placeholder-plus">
                            <AddIcon className="primary-color" />
                        </div>
                    </div>
                );
                break;
            }
            default:
                break;
        }

        if (useValue) {
            temp.placeholder = value || "N/A";
        }

        return temp;
    }, [name, type, hasLabelKey, validation[VALUE], validation[LABEL], validation[FORMAT], description, useValue, employeeInfo]);

    return info;
};
