import React from "react";
import PropTypes from "prop-types";
import ApproveIcon from "@mui/icons-material/DoneAll";
import RejectIcon from "@mui/icons-material/Clear";
import FormSection from "../../../common/components/extra/FormSection";
import FileRenderer, { FILE_FETCH_TYPE } from "../../../common/components/extra/FileRenderer";
import Empty from "../../../common/components/extra/Empty";
import Button from "../../../common/components/extra/Button";
import { createConfirmAlert } from "../../../common/utilities/helper";
import { useApproveTimeInPhoto } from "./hooks";

function TimeInPhoto({ historyId, filename, companyId, employeeId, onApprove, isApproved, isRejected }) {
    const [approve] = useApproveTimeInPhoto(historyId);

    if (!filename) {
        return <></>;
    }
    const handleApprove = async (bool) => {
        await approve(bool);
        typeof onApprove == "function" && onApprove(bool);
    };

    return (
        <div className="time-in flex column gap-05">
            <FormSection header="Time-In Photo" style={{ gap: 0 }}>
                <div className="flex column gap-05">
                    <div
                        className="timein-photo flex center"
                        style={{
                            width: "15rem",
                            height: "18rem",
                            backgroundColor: "#e1edff",
                            borderRadius: "15px",
                            overflow: "hidden",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0
                        }}
                    >
                        <FileRenderer
                            fetchType={FILE_FETCH_TYPE.EMPLOYEE}
                            filename={filename}
                            paths={[companyId, employeeId]}
                            emptyRender={<Empty message="No Time In Photo" />}
                        />
                    </div>
                </div>
            </FormSection>
            <div className="flex center gap-05">
                <Button
                    afterExtra={<ApproveIcon style={{ width: "1.2rem" }} />}
                    className="green small-font"
                    options={{ style: { height: "2rem" } }}
                    onClick={() =>
                        createConfirmAlert({
                            title: "Approve Time In Photo",
                            content: "This action will approve the employee's time-in photo and validate their record.",
                            onConfirm: (onClose) => {
                                onClose();
                                handleApprove(true);
                            }
                        })
                    }
                    disabled={isApproved}
                    small
                >
                    {isApproved ? "Approved" : "Approve"}
                </Button>
                <Button
                    afterExtra={<RejectIcon style={{ width: "1.2rem" }} />}
                    className="danger small-font"
                    options={{ style: { height: "2rem" } }}
                    onClick={() =>
                        createConfirmAlert({
                            title: "Reject Time In Photo",
                            content: "This action will reject the employee's time-in photo and invalidate their record.",
                            onConfirm: (onClose) => {
                                onClose();
                                handleApprove(false);
                            }
                        })
                    }
                    disabled={isRejected}
                    small
                >
                    {isRejected ? "Rejected" : "Reject"}
                </Button>
            </div>
        </div>
    );
}

export default TimeInPhoto;

TimeInPhoto.propTypes = {
    src: PropTypes.string,
    filename: PropTypes.string,
    companyId: PropTypes.any,
    employeeId: PropTypes.any,
    isApproved: PropTypes.bool,
    isRejected: PropTypes.bool,
    onApprove: PropTypes.func,
    historyId: PropTypes.any
};
