import React from "react";
import PropTypes from "prop-types";
import { sanitizeWords } from "../../../../../common/utilities/helper";
import Input from "../../../../../common/components/extra/form/Input";

function MobileCheckbox({ details, useValue }) {
    if (useValue) {
        return <Input label={sanitizeWords(details.label)} renderValue={details.value?.label} parentStyle={{ gap: ".5rem" }} readOnly alignRight />;
    }

    return (
        <div className="mobile-input mobile-input--checkbox input-size">
            {!details?.value?.length && (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    <input type="checkbox" name="" id="" value="" checked readOnly />
                    <label className="italic fade">Supply values to populate this fields</label>
                </div>
            )}
            <div className="flex column items">
                {details?.value?.map((conf, idx) => (
                    <div key={conf.value} className="item flex gap-05" style={{ alignItems: "center" }}>
                        <input key={conf.value} type="checkbox" name={conf.label} id="" value={conf.value} checked={idx === 0} readOnly />
                        <label htmlFor={conf.value}>{sanitizeWords(conf.label)}</label>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MobileCheckbox;

MobileCheckbox.propTypes = {
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
