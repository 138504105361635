import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, createConfirmAlert, createToast, sanitizeWords } from "../../../common/utilities/helper";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Group from "../../../common/components/extra/Group";
import Select from "../../../common/components/extra/select/Select";
import Tag from "../../../common/components/extra/Tag";
import { MEASURE_FIELDS } from "./const";
import { PENALTY_LEVEL } from "../../../common/utilities/const";
import { generateLabel, getCorrectLevelOptions } from "./helper";
import Text from "../../../common/components/extra/typography/Text";

const { LABEL, LEVEL, VALUE } = MEASURE_FIELDS;
const { WARNING, DAY_DEDUCTION, FIX_VALUE, TERMINATION, CANCEL_DAY_RECORD } = PENALTY_LEVEL;

const yellow = [WARNING];
const red = [CANCEL_DAY_RECORD, PENALTY_LEVEL, TERMINATION];
const LEVEL_WITH_VALUES = [DAY_DEDUCTION, FIX_VALUE];

const PENALTY_LEVEL_OPT = Object.values(PENALTY_LEVEL).map((level) => ({
    value: level,
    label: (
        <div className="flex">
            <Tag className={`${yellow.includes(level) ? "yellow" : red.includes(level) ? "red" : ""}`}>{sanitizeWords(level)}</Tag>
        </div>
    )
}));

const INITIAL_UPDATE_FORM = Object.values(MEASURE_FIELDS).reduce(
    (prev, curr) => ({
        ...prev,
        [curr.key]: curr.defaultValue
    }),
    {}
);

function MeasuresUpdateModal({ open, onChange, onFinish, data = {}, measures, isByLaw, setting, original }) {
    const isCreate = !data?.occurence;

    const [levelOpt, setLevelOpt] = useState([]);
    const [form, setForm] = useState({ ...INITIAL_UPDATE_FORM, ...(data || {}) });

    const getValueDayConstraint = (disable) => {
        if (!data) return null;
        if (disable) return null;
        const MIN_VALUE = 0.1;
        let min = MIN_VALUE,
            max = data[VALUE.key];
        const currentIdx = measures.findIndex((measure) => measure.occurence == data.occurence);
        const prevIdx = currentIdx - 1;
        const nextIdx = currentIdx + 1;
        if (!max && nextIdx) {
            max = measures[nextIdx][VALUE.key];
        }
        const prevIsFixValue = measures?.[prevIdx]?.[LEVEL.key] == FIX_VALUE;
        min = prevIsFixValue ? MIN_VALUE : prevIdx < 0 ? MIN_VALUE : measures[prevIdx][VALUE.key] || MIN_VALUE;
        return { min, max };
    };

    const getBaseSelections = () => {
        let baseSelections = [];
        if (isByLaw) {
            const allowedLevelOpt = [...new Set(original.map((measure) => measure[LEVEL.key]).concat(WARNING))];
            baseSelections = allowedLevelOpt;
        } else {
            baseSelections = PENALTY_LEVEL_OPT.map((opt) => opt.value);
        }
        return baseSelections;
    };

    const level = levelOpt.find((level) => level.value === form[LEVEL.key]) || "";
    const isFixedValue = level && level.value == FIX_VALUE;
    const valueDayConstraint = getValueDayConstraint(isFixedValue || !isByLaw);

    useEffect(() => {
        const allowedBaseSelections = getBaseSelections();
        const currentIdx = data?.occurence && measures.findIndex((measure) => measure.occurence == data.occurence);
        const prevLevel = isCreate ? measures?.[measures.length - 1]?.level : measures?.[currentIdx - 1]?.level;
        const nextLevel = isCreate ? null : measures?.[currentIdx + 1]?.level;
        const correcOptions = getCorrectLevelOptions(prevLevel, nextLevel, allowedBaseSelections);
        setLevelOpt(PENALTY_LEVEL_OPT.filter((opt) => correcOptions.includes(opt.value)));
    }, []);

    const handleValidation = () => {
        const value = form[VALUE.key];
        // check if value did not pass the constraint
        if (value && valueDayConstraint) {
            const { min, max } = valueDayConstraint;
            if (value < min || value > max) {
                createToast(`Value must be between ${min} and ${max}`, TOAST_TYPE.ERROR);
                return false;
            }
        }
        return true;
    };

    const handleSave = async () => {
        const pass = handleValidation();
        if (pass) {
            createConfirmAlert({
                title: !isCreate ? "Update Penalty" : "Create Penalty",
                content: `Are you sure you want to ${!isCreate ? "update" : "create"} this measure?`,
                onConfirm: async (close) => {
                    close();
                    await onFinish({ ...form, value: form.value ? parseFloat(form.value) : null });
                }
            });
        }
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const obj = { ...form, [name]: value };
        if (name == VALUE.key) {
            obj[LABEL.key] = generateLabel(parseFloat(value), form[LEVEL.key], setting?.currency);
        } else if (name == LEVEL.key) {
            obj[LABEL.key] = generateLabel(parseFloat(form[VALUE.key]), value, setting?.currency);
            obj[VALUE.key] = null;
        }
        setForm(obj);
    };

    return (
        <Modal
            open={open}
            onChange={onChange}
            onSave={handleSave}
            disableSave={isEqual(form, data)}
            styles={{ content: { width: "50vw", maxWidth: "45rem", minWidth: "40rem", height: "max-content" } }}
            isForm
        >
            <div className="update-modal">
                <div className="update-modal__inner">
                    <Group title={`${isCreate ? "Add" : "Update"} Measure`}>
                        <div className="flex column gap-05" style={{ marginBottom: ".5rem" }}>
                            <Input
                                type={INPUT_TYPE.TEXT}
                                label={LABEL.label}
                                renderValue={<span className="fade">{sanitizeWords(form[LABEL.key])}</span>}
                                subtext={{
                                    message: <Text useSubTextStyle>Auto generated based on selection below.</Text>
                                }}
                                readOnly
                            />
                        </div>
                        <Select
                            name={LEVEL.key}
                            label={LEVEL.label}
                            value={level}
                            options={levelOpt}
                            style={{ flex: "30%" }}
                            onChange={(d) => handleChange({ target: { name: LEVEL.key, value: d.value } })}
                            isClearable={false}
                            menuPortalTarget={document.body}
                            required
                            isOutlined
                            disabledOutline
                            autoFocus
                        />
                        {LEVEL_WITH_VALUES.includes(form[LEVEL.key]) && (
                            <div>
                                <Input
                                    type={INPUT_TYPE.NUMBER}
                                    label={VALUE.label}
                                    name={VALUE.key}
                                    value={form[VALUE.key]}
                                    onChange={handleChange}
                                    required={VALUE.required}
                                    autoFocus
                                />
                                {valueDayConstraint && !isFixedValue && (
                                    <span className="fade small-font" style={{ marginLeft: ".5rem" }}>
                                        Note: Allowed value is {valueDayConstraint.max && valueDayConstraint.min && "between"}&nbsp;
                                        <span className="primary-color bold">{valueDayConstraint.min || valueDayConstraint.max}</span>&nbsp;
                                        {valueDayConstraint.min && valueDayConstraint.max && (
                                            <>
                                                and&nbsp;
                                                <span className="primary-color bold">{valueDayConstraint.max}</span>
                                            </>
                                        )}
                                    </span>
                                )}
                            </div>
                        )}
                    </Group>
                </div>
            </div>
        </Modal>
    );
}

MeasuresUpdateModal.propTypes = {
    open: PropTypes.bool,
    isByLaw: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    setting: PropTypes.object,
    measures: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            level: PropTypes.string,
            occurence: PropTypes.number,
            value: PropTypes.any
        })
    ),
    original: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            level: PropTypes.string,
            occurence: PropTypes.number,
            value: PropTypes.any
        })
    )
};

export default MeasuresUpdateModal;
