import React, { useState } from "react";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentEmpIcon from "@mui/icons-material/AssignmentInd";
import MoreIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as EditSVG } from "../../../assets/images/pencil.svg";
import { binaryArrayToBase64, renderNA, sanitizeWords } from "../../../common/utilities/helper";
import MyTooltip from "../../../common/components/extra/Tooltip";
import Popover from "../../../common/components/extra/Popover";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import Tag from "../../../common/components/extra/Tag";

function SiteCard({ data, readOnly, onEdit, onDelete, onlymap, onAssignEmployees, loader }) {
    const [isMoreOptOpen, setIsMoreOptOpen] = useState(false);

    const site = data || {};
    const hasWarning = data && (site.supervisors?.length <= 0 || site.managers?.length <= 0 || !site.work_detail_id);

    const renderItem = (key, value, render) => (
        <div className="tk-site-card__item flex gap-05 align-center" style={{ fontWeight: "500", justifyContent: "space-between" }}>
            {loader ? (
                <Skeleton containerClassName="flex w100" />
            ) : (
                <>
                    <span className="flex align-center fade small-font">{key}</span>
                    <MyTooltip message={value} className="text-ellipsis bold small-font" style={{ maxWidth: "8rem" }}>
                        {!render ? value ? <span>{value}</span> : <span className="small-font">{renderNA("None")}</span> : render}
                    </MyTooltip>
                </>
            )}
        </div>
    );

    const renderArr = (value, isSupervisor) => (
        <div className="flex column gap-05" style={{ justifyContent: "flex-end" }}>
            <span>{isSupervisor ? "Supervisors" : "Managers"}:</span>
            <ul className="flex column" style={{ gap: ".1rem" }}>
                {value.map((v) => (
                    <li key={v.id} style={{ marginLeft: ".5rem" }}>
                        <span className="text-ellipsis warning-color">{sanitizeWords(`${v.first_name} ${v.last_name}`)}</span>
                    </li>
                ))}
            </ul>
        </div>
    );

    const moreControls = (
        <ul className="tk-site-card__more-content">
            <MyTooltip element="li" message={hasWarning ? "Cannot assign employees if site information is incomplete." : ""}>
                <div className={`flex align-center gap-05 ${hasWarning ? "disabled" : ""}`} onClick={() => !hasWarning && onAssignEmployees()}>
                    <AssignmentEmpIcon className="hover-svg" style={{ maxWidth: "1rem" }} />
                    <span>Assign</span>
                </div>
            </MyTooltip>
            <li onClick={onEdit}>
                <EditSVG className="hover-svg" style={{ maxWidth: "1rem" }} />
                <span>Edit</span>
            </li>
            <li onClick={onDelete}>
                <DeleteIcon className="hover-svg" style={{ maxWidth: "1rem" }} />
                <span>Delete</span>
            </li>
        </ul>
    );

    return (
        <div className="tk-site-card">
            <div className="tk-site-card__inner">
                <div className="tk-site-card__header">
                    <div className="tk-site-card__header__inner flex">
                        <span className="title">{site.title}</span>
                        {!readOnly && !loader && (
                            <div className="tk-site-card__more flex center" style={{ gap: ".2rem", justifyContent: "flex-end" }}>
                                <Popover content={moreControls}>
                                    <MoreIcon className="hover-svg" onClick={() => setIsMoreOptOpen(!isMoreOptOpen)} />
                                </Popover>
                            </div>
                        )}
                    </div>
                </div>
                <div className="tk-site-card__content">
                    {!onlymap && (
                        <>
                            <div className="tk-site-card__upper">
                                {renderItem("Title", site.title)}
                                {renderItem("Location", site.location)}
                                {renderItem(
                                    "Supervisors",
                                    site.supervisors?.length && renderArr(site.supervisors, true),
                                    site.supervisors?.length && (
                                        <strong style={{ cursor: "pointer" }}>
                                            <Tag>
                                                <span>{(site.supervisors?.length + "").padStart(2, 0)}</span>
                                            </Tag>
                                        </strong>
                                    )
                                )}
                                {renderItem(
                                    "Managers",
                                    site.managers?.length && renderArr(site.managers),
                                    site.managers?.length && (
                                        <strong style={{ cursor: "pointer" }}>
                                            <Tag>
                                                <span>{(site.managers?.length + "").padStart(2, 0)}</span>
                                            </Tag>
                                        </strong>
                                    )
                                )}
                            </div>
                            <div className="hr"></div>
                        </>
                    )}
                    <div className="tk-site-card__lower">
                        <div className="tk-site-card__map">
                            {loader ? (
                                <Skeleton containerClassName="flex w100 h100" height="7rem" />
                            ) : (
                                <img
                                    src={
                                        (site.thumbnail &&
                                            (typeof data?.thumbnail == "string" ? site.thumbnail : binaryArrayToBase64(site.thumbnail))) ||
                                        "/images/site-placeholder.png"
                                    }
                                    alt="site"
                                />
                            )}
                        </div>
                    </div>
                    {hasWarning && !loader && (
                        <div className="tk-site-card__warning">
                            <WarningColorSwitcher
                                tooltip={{
                                    message: (
                                        <div style={{ maxWidth: "15rem" }}>
                                            Site information is incomplete, must have a supervisor, manager and a work type to be able to use.
                                        </div>
                                    ),
                                    className: "flex"
                                }}
                                style={{ width: "4rem" }}
                                warning
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

SiteCard.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.any,
        title: PropTypes.string,
        thumbnail: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                data: PropTypes.array
            })
        ]),
        location: PropTypes.string,
        coordinates: PropTypes.arrayOf(
            PropTypes.shape({
                lat: PropTypes.number,
                lng: PropTypes.number
            })
        ),
        supervisors: PropTypes.arrayOf(PropTypes.object),
        managers: PropTypes.arrayOf(PropTypes.object),
        work_detail_id: PropTypes.number
    }),
    readOnly: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onAssignEmployees: PropTypes.func,
    onlymap: PropTypes.bool,
    loader: PropTypes.bool
};

export default SiteCard;
