export const FILTER_TYPE = {
    DATE: 0x2,
    SITE: 0x4,
    EMPLOYEE: 0x5
};

export const LOCATION_LOG_ACTIONS = {
    CHECK_IN: "CHECK_IN",
    CHECK_OUT: "CHECK_OUT",
    TIME_IN: "TIME_IN",
    TIME_OUT: "TIME_OUT",
    OVERTIME_IN: "OVERTIME_IN",
    OVERTIME_OUT: "OVERTIME_OUT",
    MOVING: "MOVING"
};
