import React from "react";

import TitleIcon from "@mui/icons-material/Title";
import ParagraphIcon from "@mui/icons-material/Notes";
import AddressIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/ContactPhone";
import NumbersIcon from "@mui/icons-material/Numbers";
import TextInputIcon from "@mui/icons-material/TextFields";
import EmailIcon from "@mui/icons-material/Email";
import DropdownIcon from "@mui/icons-material/ArrowDropDownCircle";
import RadioIcon from "@mui/icons-material/RadioButtonChecked";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateIcon from "@mui/icons-material/Today";
import TimeIcon from "@mui/icons-material/Schedule";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TextAreaIcon from "@mui/icons-material/Rtt";
import FileUploadIcon from "@mui/icons-material/UploadFile";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import WorkIcon from "@mui/icons-material/Work";
import isNull from "lodash/isNull";
import cloneDeep from "lodash/cloneDeep";
import { ReactComponent as DateTimeIcon } from "../../../assets/images/date-time-icon.svg";
import { ReactComponent as ImageUploadIcon } from "../../../assets/images/image-upload-icon.svg";
import { ReactComponent as PersonInfoIcon } from "../../../assets/images/person-info.svg";
import { ReactComponent as AppraisalIcon } from "../../../assets/images/appraisal.svg";
import { ReactComponent as QuestionnaireIcon } from "../../../assets/images/questionnaire.svg";

import { ERROR_QUESTION_KEY, FORM_CLASS, FORM_FIELD_GROUP, FORM_FIELD_TYPE, VALIDATION_FIELD } from "./const";
import { sanitizeWords } from "../../../common/utilities/helper";
const {
    TITLE,
    LABEL: VALIDATION_FIELD_LABEL,
    VALUE,
    MIN,
    MAX,
    REQUIRED,
    IS_BOLD,
    WITH_FINAL_SCORE_RATING,
    RATINGS,
    IS_COLUMN,
    FOR_ADMIN,
    FORMAT,
    NO_FUTURE,
    NO_PAST,
    RANGE_SPAN,
    RANGE_SPAN_BASE
} = VALIDATION_FIELD;

const {
    PARAGRAPH,
    LABEL,
    TEXT_INPUT,
    TEXT_AREA,
    EMAIL,
    NUMBER_INPUT,
    DROPDOWN,
    RADIO,
    CHECKBOX,
    DATE,
    TIME,
    DATE_TIME,
    FILE_UPLOAD,
    IMAGE_UPLOAD,
    PHONE_NUMBER,
    ADDRESS,
    TOGGLE,
    QUESTIONNAIRE,
    DATE_RANGE,
    PROBATIONARY_APPRAISAL,
    YEARLY_APPRAISAL,
    EMPLOYEE_NAME,
    EMPLOYEE_ID,
    EMPLOYEE_DESIGNATION,
    EMPLOYEE_NATIONALITY,
    EMPLOYEE_RESIDENCE_ID,
    EMPLOYEE_MOBILE,
    EMPLOYEE_JOINING_DATE,
    EMPLOYEE_JOB_GRADE,
    EMPLOYEE_DEPARTMENT,
    WORK_DETAIL_PROJECT_CODE
} = FORM_FIELD_TYPE;

const fieldInfoMap = {
    [PARAGRAPH]: { icon: <ParagraphIcon /> },
    [LABEL]: { icon: <TitleIcon /> },
    [TEXT_INPUT]: { icon: <TextInputIcon /> },
    [TEXT_AREA]: { icon: <TextAreaIcon /> },
    [EMAIL]: { icon: <EmailIcon /> },
    [NUMBER_INPUT]: { icon: <NumbersIcon /> },
    [DROPDOWN]: { icon: <DropdownIcon /> },
    [RADIO]: { icon: <RadioIcon /> },
    [CHECKBOX]: { icon: <CheckBoxIcon /> },
    [DATE]: { icon: <DateIcon /> },
    [TIME]: { icon: <TimeIcon /> },
    [DATE_TIME]: { icon: <DateTimeIcon style={{ width: "1.5rem" }} /> },
    [DATE_RANGE]: { icon: <DateRangeIcon /> },
    [FILE_UPLOAD]: { icon: <FileUploadIcon /> },
    [IMAGE_UPLOAD]: { icon: <ImageUploadIcon style={{ width: "1.5rem" }} /> },
    [PHONE_NUMBER]: { icon: <PhoneIcon /> },
    [ADDRESS]: { icon: <AddressIcon /> },
    [TOGGLE]: { icon: <ToggleOnIcon /> },
    [QUESTIONNAIRE]: { icon: <QuestionnaireIcon /> },
    [PROBATIONARY_APPRAISAL]: { icon: <AppraisalIcon /> },
    [YEARLY_APPRAISAL]: { icon: <AppraisalIcon /> },
    [EMPLOYEE_NAME]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_ID]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_DESIGNATION]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_NATIONALITY]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_RESIDENCE_ID]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_MOBILE]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_JOINING_DATE]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_JOB_GRADE]: { icon: <PersonInfoIcon /> },
    [EMPLOYEE_DEPARTMENT]: { icon: <PersonInfoIcon /> },
    [WORK_DETAIL_PROJECT_CODE]: { icon: <WorkIcon /> }
};

export const getFieldInfo = (type) => {
    return fieldInfoMap[type] || { icon: <QuestionMarkIcon />, text: type };
};

export const renderInfoText = (type) => {
    const temp = {
        [VALIDATION_FIELD.REQUIRED]: "Indicates that the selected element must have a value.",
        [VALIDATION_FIELD.LABEL]: "A label for input.",
        [VALIDATION_FIELD.MIN]: null,
        [VALIDATION_FIELD.MAX]: null,
        [VALIDATION_FIELD.VALUE]: null,
        [VALIDATION_FIELD.WITH_FINAL_SCORE_RATING]: null,
        [VALIDATION_FIELD.RATINGS]: null,
        [VALIDATION_FIELD.FOR_ADMIN]: "Toggle when we want information that we need to supply before we send the form to an employee.",
        [VALIDATION_FIELD.IS_COLUMN]: "Changes the orientation of the element to column instead of row.",
        [VALIDATION_FIELD.IS_BOLD]: "Changes the font weight of the value to bold."
    };

    switch (type) {
        case PARAGRAPH:
            temp[VALIDATION_FIELD.VALUE] = "An additional description to show in the form.";
            temp[VALIDATION_FIELD.LABEL] = null;
            temp[VALIDATION_FIELD.REQUIRED] = null;
            break;
        case LABEL:
            temp[VALIDATION_FIELD.VALUE] = "An additional text to show in the form.";
            temp[VALIDATION_FIELD.LABEL] = null;
            temp[VALIDATION_FIELD.REQUIRED] = null;
            break;
        case ADDRESS:
        case TEXT_AREA:
        case TEXT_INPUT: {
            temp[VALIDATION_FIELD.MIN] = "Indicates the minimum number of characters required.";
            temp[VALIDATION_FIELD.MAX] = "Indicates the maximum number of characters allowed.";
            break;
        }
        case NUMBER_INPUT: {
            temp[VALIDATION_FIELD.MIN] = "Defines the minimum acceptable number.";
            temp[VALIDATION_FIELD.MAX] = "Defines the maximum acceptable number.";
            break;
        }
        case DATE_RANGE: {
            temp[VALIDATION_FIELD.RANGE_SPAN] = "Specifies the allowed duration between the start and end dates.";
            temp[VALIDATION_FIELD.RANGE_SPAN_BASE] =
                "Indicates which date (Month, Day, Year or MS) to use as the reference point for calculating the range span.";
            break;
        }
        case IMAGE_UPLOAD:
        case FILE_UPLOAD: {
            temp[VALIDATION_FIELD.MIN] = "Indicates the minimum number of files that can be uploaded.";
            temp[VALIDATION_FIELD.MAX] = "Indicates the maximum number of files that can be uploaded.";
            break;
        }
        case DROPDOWN:
        case RADIO:
        case CHECKBOX:
            temp[VALIDATION_FIELD.VALUE] = "Indicates the values of the element.";
            break;
        case TOGGLE:
            temp[VALIDATION_FIELD.VALUE] = "Indicates the value of the toggled element";
            break;
        default:
        case PHONE_NUMBER:
        case EMAIL:
            temp[VALIDATION_FIELD.MIN] = null;
            temp[VALIDATION_FIELD.MAX] = null;
            break;
        case QUESTIONNAIRE:
            temp[VALIDATION_FIELD.VALUE] = "Specifies the questions to be displayed to the user.";
            temp[VALIDATION_FIELD.WITH_FINAL_SCORE_RATING] = "Indicates whether the final score rating should be included in the questionnaire.";
            temp[VALIDATION_FIELD.RATINGS] = "Represents the selected rating value for each question.";
            break;
    }
    return temp;
};

export const getQuestionCount = (data = [], groupIdx = 0, questionIdx = 0) => {
    const groupQuestions = data.map((group) => group.questions);
    const flatQuestions = groupQuestions.flat();
    const currentQuestionIdx = flatQuestions.findIndex((question) => question === data[groupIdx].questions[questionIdx]);
    return currentQuestionIdx + 1;
};

export const getValidationInfo = (data = {}) => {
    data = cloneDeep(data);

    const type = data.type;
    const name = data.name;
    const description = data.description;
    const group = data.group;
    const validation = data.validation || {};
    const disabledFields = data.disabledFields || [];

    const isFile = type == FORM_FIELD_TYPE.FILE_UPLOAD;
    const isQuestionnaire = type == FORM_FIELD_TYPE.QUESTIONNAIRE;
    const isTypeGroup = type == FORM_FIELD_TYPE.GROUP;

    const isSpecialGroup = group == FORM_FIELD_GROUP.SPECIAL;
    const isQuestionnaireGroup = group == FORM_FIELD_GROUP.QUESTIONNAIRE;
    const isTextGroup = group == FORM_FIELD_GROUP.TEXT;
    const isMediaGroup = group == FORM_FIELD_GROUP.MEDIA;
    const isEmployeeInfoGroup = group == FORM_FIELD_GROUP.EMPLOYEE_DETAILS;

    const infoText = renderInfoText(type);

    const hasTitleKey = TITLE in validation && !disabledFields.includes(TITLE);
    const hasLabelKey = VALIDATION_FIELD_LABEL in validation && !disabledFields.includes(VALIDATION_FIELD_LABEL);
    const hasIsColumnKey = IS_COLUMN in validation && !disabledFields.includes(IS_COLUMN) && !disabledFields.includes(FOR_ADMIN);
    const hasBoldKey = IS_BOLD in validation && !disabledFields.includes(IS_BOLD) && !disabledFields.includes(FOR_ADMIN);
    const hasForAdminKey = FOR_ADMIN in validation && !disabledFields.includes(FOR_ADMIN);
    const hasValueKey = VALUE in validation && !disabledFields.includes(VALUE);
    const hasMinKey = MIN in validation && !isTextGroup && !disabledFields.includes(MIN);
    const hasMaxKey = MAX in validation && !isTextGroup && !disabledFields.includes(MAX);
    const hasRequiredKey = REQUIRED in validation && !disabledFields.includes(REQUIRED);
    const hasFormatKey = FORMAT in validation && !disabledFields.includes(FORMAT);
    const hasFinalScoreKey = WITH_FINAL_SCORE_RATING in validation && !disabledFields.includes(WITH_FINAL_SCORE_RATING);
    const hasRatingsKey = RATINGS in validation && !disabledFields.includes(RATINGS);
    const hasNoFutureKey = NO_FUTURE in validation && !disabledFields.includes(NO_FUTURE);
    const hasNoPastKey = NO_PAST in validation && !disabledFields.includes(NO_PAST);
    const hasRangeSpanKey = RANGE_SPAN in validation && !disabledFields.includes(RANGE_SPAN);
    const hasRangeSpanBaseKey = RANGE_SPAN_BASE in validation && !disabledFields.includes(RANGE_SPAN_BASE);

    const isValueString = hasValueKey && typeof validation[VALUE] == "string";
    const isValueRTE = hasValueKey && typeof validation[VALUE] == "object" && !isNull(validation[VALUE]) && "html" in validation[VALUE];
    const isValueArray = hasValueKey && Array.isArray(validation[VALUE]);

    const isForAdmin = hasForAdminKey && !!validation[FOR_ADMIN];
    const isRequired = hasRequiredKey && !!validation[REQUIRED];

    return {
        type,
        name,
        description,
        group,
        validation,
        hasTitleKey,
        hasLabelKey,
        hasValueKey,
        hasMinKey,
        hasMaxKey,
        hasRequiredKey,
        hasBoldKey,
        hasFinalScoreKey,
        hasRatingsKey,
        isValueString,
        isValueArray,
        infoText,
        isValueRTE,
        isFile,
        hasIsColumnKey,
        hasForAdminKey,
        isQuestionnaire,
        isQuestionnaireGroup,
        isMediaGroup,
        isEmployeeInfoGroup,
        isForAdmin,
        isRequired,
        hasFormatKey,
        isTypeGroup,
        isSpecialGroup,
        hasNoPastKey,
        hasNoFutureKey,
        hasRangeSpanKey,
        hasRangeSpanBaseKey,
        disabledFields
    };
};

export const validateField = (data) => {
    let temp = {};

    let {
        validation,
        hasLabelKey,
        hasValueKey,
        hasMinKey,
        hasMaxKey,
        isValueString,
        isValueRTE,
        isQuestionnaireGroup,
        hasRatingsKey,
        hasRangeSpanKey
    } = getValidationInfo(data);

    validation = cloneDeep(validation);

    if (hasRatingsKey) {
        const invalidRatingsIndexes = validation[RATINGS].filter((rating) => !rating.label).map((rating) =>
            validation[RATINGS].findIndex((inRating) => rating.level == inRating.level)
        );
        if (invalidRatingsIndexes.length) {
            temp[RATINGS] = invalidRatingsIndexes.map((idx) => ({
                index: idx,
                message: "Error: Rating label cannot be empty"
            }));
        }
    }
    if (hasValueKey) {
        if (isQuestionnaireGroup) {
            const values = validation[VALUE];
            const groupOfQuestions = values.map((value) => value.questions);
            const invalidGroupOfQuestionsIndexes = groupOfQuestions.map((questions) =>
                questions.map((question, idx) => (!question.title ? idx : null))
            );
            invalidGroupOfQuestionsIndexes.forEach((questionsIdxs, idx) => {
                if (questionsIdxs.filter((questionsIdx) => !isNull(questionsIdx)).length) {
                    temp[ERROR_QUESTION_KEY + idx] = questionsIdxs.map((idx) => ({
                        index: idx,
                        message: "Error: Question title cannot be empty"
                    }));
                }
            });
        }
        if (Array.isArray(validation[VALUE]) && !validation[VALUE].length) {
            temp[VALUE] = "Error: Value cannot be empty";
        }
        if (isValueString || isValueRTE) {
            const value = isValueString ? validation[VALUE] : validation[VALUE]?.text || "";
            if (!value) {
                temp[VALUE] = "Error: Value cannot be empty";
            }
        }
    }
    if (hasLabelKey) {
        if (!validation[VALIDATION_FIELD_LABEL]) {
            temp[VALIDATION_FIELD_LABEL] = "Error: Label cannot be empty";
        }
    }

    if (hasMinKey) {
        const min = validation[MIN];
        if (typeof min != "number" && !min) {
            temp[MIN] = "Error: Min cannot be empty";
        }
    }

    if (hasMaxKey) {
        const max = validation[MAX];
        if (typeof max != "number" && !max) {
            temp[MAX] = "Error: Max cannot be empty";
        }
    }

    if (hasRangeSpanKey && validation[RANGE_SPAN_BASE]) {
        const rangeSpan = validation[RANGE_SPAN];
        if (typeof rangeSpan != "number" && !rangeSpan) {
            temp[RANGE_SPAN] = "Error: Range span cannot be empty";
        }
    }

    return {
        error: Object.values(temp).flat().length ? temp : null
    };
};

export const isDroppedItemsHasError = (droppedItems = []) =>
    droppedItems.some((item) => (Array.isArray(item.error) ? item.error.length > 0 : !!item.error));

export const createMissingRequiredFormMessage = (missingRequiredFormClasses) => {
    const featureMap = {
        [FORM_CLASS.LEAVE_FORM]: "Submitted Form to Leave Record Creation",
        [FORM_CLASS.PROBATIONARY_APPRAISAL]: "Assigning of Probationary Appraisal",
        [FORM_CLASS.YEARLY_APPRAISAL]: "Assigning of Yearly Appraisal"
    };

    const missingFeatures = missingRequiredFormClasses.map((cs) => featureMap[cs] || sanitizeWords(cs));

    return (
        <ul>
            The following features will not be available because their required form classes are missing:
            {missingFeatures.map((missing, idx) => (
                <li key={idx} className="has-list-style indent-1">
                    {missing}
                </li>
            ))}
        </ul>
    );
};

export const createFormInitials = (name = "") => {
    if (!name.trim()) {
        return "";
    }
    return name
        .split(" ")
        .filter(Boolean)
        .map((word) => word[0].toUpperCase())
        .join("");
};
