import { useState, useEffect, useMemo } from "react";
import isObject from "lodash/isObject";
import Crypto from "../classes/Crypto";

const useLocalStorageEncrypted = (key, initialValue, { encrypt = true, cryptoSecretKey } = {}) => {
    const localStorageValue = window.localStorage.getItem(key);
    const item = key && localStorageValue && JSON.parse(localStorageValue);

    const [storedValue, setStoredValue] = useState(item);
    const [processing, setProcessing] = useState(true);

    const crypto = useMemo(() => {
        return new Crypto(cryptoSecretKey);
    }, []);

    useEffect(() => {
        if (encrypt && storedValue && storedValue.salt && storedValue.iv && storedValue.encryptedData) {
            crypto
                .decrypt(storedValue)
                .then(setStoredValue)
                .finally(() => processing && setProcessing(false));
        } else {
            processing && setProcessing(false);
        }
    }, []);

    useEffect(() => {
        const saveToStorage = async () => {
            !processing && setProcessing(true);
            try {
                let temp = {};
                let serializedValue;
                if (isObject(storedValue)) {
                    temp = storedValue;
                }
                if (encrypt) {
                    const encryptedData = await crypto.encrypt(temp);
                    serializedValue = JSON.stringify(encryptedData);
                } else {
                    serializedValue = JSON.stringify(temp);
                }
                window.localStorage.setItem(key, serializedValue);
            } finally {
                processing && setProcessing(false);
            }
        };

        saveToStorage();
    }, [key, storedValue, encrypt, cryptoSecretKey]);

    return [storedValue || initialValue, setStoredValue, processing];
};

export default useLocalStorageEncrypted;
