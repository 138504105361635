import { COMPANY_FILES } from "../../../common/utilities/const";
import { toFormObject } from "../../../common/utilities/helper";

export const SUPPORTED_ENTITLEMENT_VALUES = [21, 28, 30, 31];

export const SUPPORTED_SETTINGS_IDS = {
    PROFILE: "PROFILE",
    GENERAL: "GENERAL",
    SALARY: "SALARY",
    DEPT_DESIG: "DEPT_DESIG",
    PENALTY: "PENALTY"
};

export const TIME_FORMAT = {
    TWELVE: {
        value: "TWELVE",
        label: 12
    },
    TWENTY_FOUR: {
        value: "TWENTY_FOUR",
        label: 24
    }
};

export const POSITION_TABS = {
    ADMIN_DEPTS: {
        id: "admin-departments",
        label: "Admin Departments",
        isActive: true
    },
    OTHER_DEPTS: {
        id: "other-departments",
        label: "Other Departments",
        isActive: true
    },
    DESIGNATIONS: {
        id: "designations",
        label: "Designations",
        isActive: true
    }
};

export const PROFILE_FIELDS = {
    ENGLISH_NAME: "name",
    ARABIC_NAME: "arabic_name",
    COUNTRY: "country",
    INDUSTRY: "industry_type",
    ADDRESS: "address",
    EMAIL_ADDRESS: "email",

    CONTACT_NAME: "contact_name",
    CONTACT_NUMBER: "contact_number",
    CONTACT_EMAIL: "contact_email",
    CONTACT_POSITION: "contact_position",

    CONTRACT_NUMBER: "contract_number",
    CONTRACT_EXPIRY_DATE: "contract_expiry_date",

    EID_NUMBER: "establishment_id",
    EID_EXPIRY_DATE: "establishment_id_expiry_date",

    LICENSE_TRADE_NUMBER: "license_trade_number",
    LICENSE_TRADE_EXPIRY_DATE: "license_trade_expiry_date",

    LICENSE_COMMERCIAL_NUMBER: "license_commercial_number",
    LICENSE_COMMERCIAL_EXPIRY_DATE: "license_commercial_expiry_date"
};

export const PROFILE_FIELDS_UPLOADS = {
    LOGO: {
        name: "uploads." + COMPANY_FILES.LOGO.key,
        key: COMPANY_FILES.LOGO.key
    },
    CONTRACT_FILE: {
        name: "uploads." + COMPANY_FILES.COMPANY_CONTRACT.key,
        key: COMPANY_FILES.COMPANY_CONTRACT.key
    },
    EID_FILE: {
        name: "uploads." + COMPANY_FILES.ESTABLISHMENT_ID.key,
        key: COMPANY_FILES.ESTABLISHMENT_ID.key
    },
    LICENSE_TRADE_FILE: {
        name: "uploads." + COMPANY_FILES.LICENSE_TRADE.key,
        key: COMPANY_FILES.LICENSE_TRADE.key
    },
    LICENSE_COMMERCIAL_FILE: {
        name: "uploads." + COMPANY_FILES.LICENSE_COMMERCIAL.key,
        key: COMPANY_FILES.LICENSE_COMMERCIAL.key
    }
};

export const PROFILE_FORM_FIELDS = {
    ...toFormObject(PROFILE_FIELDS),
    uploads: Object.values(PROFILE_FIELDS_UPLOADS).reduce(
        (prev, upload) => ({
            ...prev,
            [upload.key]: ""
        }),
        {}
    )
};

export const PASSWORD_FIELDS = {
    OLD_PASSWORD: {
        name: "oldPassword",
        label: "Old Password"
    },
    NEW_PASSWORD: {
        name: "newPassword",
        label: "New Password"
    },
    CONFIRM_PASSWORD: {
        name: "confirmPassword",
        label: "Confirm Password"
    }
};

export const GENERAL_FIELDS = {
    TIMEZONE: "timezone",
    CURRENCY: "currency",
    TIME_FORMAT: "time_format",
    USE_ARABIC_NAME: "use_arabic_name",
    PREFIX: "prefix"
};

export const MOBILE_FIELDS = {
    EARLY_TIME_IN: "earlyTimeIn",
    EARLY_TIME_OUT: "earlyTimeOut",
    BREAK_GRACE_PERIOD: "breakGracePeriod",
    BG_LOCATION_GRACE_PERIOD: "bgLocationGracePeriod",
    DEFAULT_LATE_GRACE_PERIOD: "defaultLateGracePeriod",
    MAX_EMP_TIMEINOUT: "maxEmpTimeInOutSv",
    MAX_SV_TIMEINOUT: "maxSvTimeInOutEmp"
};

export const SALARY_FIELDS = {
    LEAVE: "leave",
    SETTLEMENT: "settlement",
    GRATUITY: "gratuity",
    ELIGIBILITIES: "eligibilities",
    HOLIDAY_OT_RATE: "holidayOtRate",
    REGULAR_OT_RATE: "regularOtRate",
    TIMESHEET_END_DAY: "timesheetEndDay",
    TIMESHEET_START_DAY: "timesheetStartDay",
    MONTHLY_SALARY_DAYS: "monthlySalaryDays"
};

export const SUPPORTED_ENTITLEMENT_YEARS = {
    ONE: 1,
    FIVE: 5,
    TEN: 10
};

export const SUPPORTED_ELIGIBILITY = {
    AIR_TICKET: "airTicket",
    ANNUAL_LEAVE: "annualLeave",
    GRATUITY: "gratuity"
};

export const SUPPORTED_ELIGIBILITY_INFO = {
    [SUPPORTED_ELIGIBILITY.AIR_TICKET]: {
        label: "Air Ticket Eligibillity"
    },
    [SUPPORTED_ELIGIBILITY.ANNUAL_LEAVE]: {
        label: "Annual Leave Eligibillity"
    },
    [SUPPORTED_ELIGIBILITY.GRATUITY]: {
        label: "Gratuity Eligibillity"
    }
};

export const SUPPORTED_ENTITLEMENT_YEARS_INFO = {
    [SUPPORTED_ENTITLEMENT_YEARS.ONE]: {
        key: SUPPORTED_ENTITLEMENT_YEARS.ONE,
        label: "After One Year"
    },
    [SUPPORTED_ENTITLEMENT_YEARS.FIVE]: {
        key: SUPPORTED_ENTITLEMENT_YEARS.ONE,
        label: "After Five Years"
    },
    [SUPPORTED_ENTITLEMENT_YEARS.TEN]: {
        key: SUPPORTED_ENTITLEMENT_YEARS.TEN,
        label: "After Ten Years"
    }
};

export const GENERAL_FORM_FIELDS = {
    ...toFormObject(GENERAL_FIELDS),
    mobile_app: toFormObject(MOBILE_FIELDS),
    salary: {
        ...toFormObject(SALARY_FIELDS),
        leave: {
            entitlement: toFormObject(SUPPORTED_ENTITLEMENT_YEARS)
        },
        gratuity: {
            entitlement: toFormObject(SUPPORTED_ENTITLEMENT_YEARS)
        }
    }
};

export const MONTHLY_SALARY_DAYS = {
    // 32
    FULL_DAYS_OF_THE_MONTH: {
        value: "FULL_DAYS_OF_THE_MONTH",
        label: "Full Days of the Month"
    },
    // 31
    THIRTY_ONE_DAYS: {
        value: "THIRTY_ONE_DAYS",
        label: "Thirty-One Days"
    },
    // 30
    THIRTY_DAYS: {
        value: "THIRTY_DAYS",
        label: "Thirty Days"
    },
    // -8 days - 2 day off/week
    TWO_DAY_OFF_WEEK: {
        value: "TWO_DAY_OFF_WEEK",
        label: "Two Day Off per Week"
    },
    // -4 days - 1 day off/week
    ONE_DAY_OFF_WEEK: {
        value: "ONE_DAY_OFF_WEEK",
        label: "One Day Off per Week"
    }
};

export const TIMESHEET_TYPE = {
    FULL_MONTH: "FULL_MONTH", // automatic 1 to end date
    BY_CUTOFF: "BY_CUTOFF" // supply the start date
};

export const SETTLEMENT_DAILY_RATE = {
    BY_30_DAYS: "BY_30_DAYS", // value / 30 days
    PRO_RATA_YEAR: "PRO_RATA_YEAR" // value * (12/365.25) considering leap year
};
