import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GradeIcon from "@mui/icons-material/Grade";
import { sanitizeWords } from "../../../../../common/utilities/helper";

function StarRating({ rating, ratings }) {
    const findRating = useMemo(() => {
        return ratings.find((r) => r.level == rating);
    }, [ratings]);

    return (
        <div className="star-rating">
            {!findRating ? (
                <div className="star-rating__value">
                    <span className="fade">N/A</span>
                </div>
            ) : (
                <>
                    <div className="star-rating__icon">
                        <GradeIcon />
                        <div className="number-overlay abs-center">
                            <span>{findRating?.level}</span>
                        </div>
                    </div>
                    {findRating?.label && (
                        <div className="star-rating__value small-font">
                            <span>{sanitizeWords(findRating.label)}</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default StarRating;

StarRating.propTypes = {
    ratings: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            level: PropTypes.number
        })
    ),
    rating: PropTypes.number
};
