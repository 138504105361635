import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Input, { INPUT_TYPE } from "../form/Input";
import { STANDARD_REF_DATE } from "../../../utilities/const";
import MiniLoader from "../MiniLoader";
import { getDateSelectionAvailability, isStartAlreadyNextDay } from "./helper";
import cloneDeep from "lodash/cloneDeep";
import { changeToBaseDate, getTimeDuration, toTimeWithTimeZone } from "../../../utilities/helper";
import ConditionWrapper from "../form/ConditionWrapper";

const TYPE = { START: 0x1, END: 0x2 };
const DATE_TYPE = INPUT_TYPE.TIME;
const COMMON_STYLE = { minWidth: "unset", width: "8rem" };
const FORMAT_DATE = "YYYY-MM-DD HH:mm:ssZ";

export const EVENT = { MOUNTING: "mounting", CHANGING: "changing" };

function TimePickerRangeMain({
    range,
    onChange,
    disabled,
    constraint,
    timezone,
    styleDate,
    isPortal,
    menuPlacement,
    interval,
    isLoading,
    noResetOnChange,
    readOnly,
    baseDate,
    limitHoursOptions = 0,
    disableNextDaySelectionEndDate,
    offset = {
        start: { min: 0, max: 0 },
        end: { min: 0, max: 0 }
    },
    isDateWithinConstraint, // use when date should be always within the constraint
    afterDate // use when date is after the specified date
}) {
    const memoizedConstraint = useMemo(() => {
        const temp = {
            start: {
                min: null,
                max: null,
                ...(cloneDeep(constraint)?.start || {})
            },
            end: {
                min: null,
                max: null,
                ...(cloneDeep(constraint)?.end || {})
            }
        };

        if (limitHoursOptions && range?.start) {
            const constraintDiff = temp.end.min && temp.end.max && toTimeWithTimeZone(temp.end.max, timezone).diff(temp.end.min, "hour", true);
            if (constraintDiff && limitHoursOptions < constraintDiff) {
                temp.end.max = toTimeWithTimeZone(range.start, timezone).add(limitHoursOptions, "hour");
            }
        }

        if (disableNextDaySelectionEndDate || isStartAlreadyNextDay(range.start, baseDate, timezone)) {
            temp.end.min = range.start && toTimeWithTimeZone(range.start, timezone);
        }

        return temp;
    }, [range.start, constraint, timezone, limitHoursOptions, disableNextDaySelectionEndDate, baseDate]);

    const parentStyle = useMemo(() => ({ ...COMMON_STYLE, ...(styleDate || {}) }), [styleDate]);

    const memoizedSyncDate = useCallback(
        (date, minBaseTime) => {
            const startBasis = minBaseTime && toTimeWithTimeZone(minBaseTime, timezone);
            if (!startBasis || !date) return date;
            date = toTimeWithTimeZone(date, timezone);
            const startBasisTimeDuration = getTimeDuration(startBasis.format());
            const dateTimeDuration = getTimeDuration(date.format());

            if (startBasisTimeDuration > dateTimeDuration) {
                if (date.get("D") != 2) {
                    return date.add(1, "day");
                } else {
                    return date;
                }
            } else {
                return changeToBaseDate(startBasis.format(), date.format(), timezone);
            }
        },
        [timezone]
    );

    const memoizedSyncWithinDate = useCallback(
        (date, { min, max } = {}) => {
            if (!date || (!min && !max)) return date;
            date = toTimeWithTimeZone(date, timezone);
            min = min && toTimeWithTimeZone(min, timezone);
            max = max && toTimeWithTimeZone(max, timezone);
            const onlyTimeDateDur = getTimeDuration(date.format());
            const onlyMinDateDur = min && getTimeDuration(min.format());
            const onlyMaxDateDur = max && getTimeDuration(max.format());

            if (onlyTimeDateDur >= onlyMinDateDur && onlyTimeDateDur >= onlyMaxDateDur) {
                return changeToBaseDate(min.format(), date.format(), timezone);
            }
            if (onlyTimeDateDur <= onlyMinDateDur && onlyTimeDateDur <= onlyMaxDateDur) {
                return changeToBaseDate(max.format(), date.format(), timezone);
            }
            return date;
        },
        [timezone]
    );

    const handleChange = useCallback(
        (time, type, evt, evt2) => {
            const eventType = evt || EVENT.CHANGING;
            let updatedRange = { ...range };

            switch (type) {
                case TYPE.START: {
                    if (isDateWithinConstraint) {
                        updatedRange.start = memoizedSyncWithinDate(time, memoizedConstraint.start);
                    } else {
                        updatedRange.start = memoizedSyncDate(time, afterDate);
                    }
                    if (!noResetOnChange && updatedRange.start && updatedRange.start.isSameOrAfter(range.end)) {
                        updatedRange.end = null;
                    }
                    break;
                }
                case TYPE.END: {
                    updatedRange.end = memoizedSyncDate(time, range?.start);
                    break;
                }
                default:
                    break;
            }

            onChange?.(updatedRange, eventType, evt2);
        },
        [range, memoizedSyncDate, memoizedSyncWithinDate, memoizedConstraint, isDateWithinConstraint, afterDate, noResetOnChange, onChange]
    );

    const memoizedOffsetStart = useMemo(
        () => ({
            min: offset?.start?.min || 0,
            max: offset?.start?.max || 0
        }),
        [offset?.start?.min, offset?.start?.max]
    );

    const memoizedOffsetEnd = useMemo(
        () => ({
            min: offset?.end?.min || 0,
            max: offset?.end?.max || 0
        }),
        [offset?.end?.min, offset?.end?.max]
    );

    return (
        <>
            <Input
                type={DATE_TYPE}
                parentStyle={parentStyle}
                onChange={(date, eventOne, eventTwo) =>
                    handleChange(
                        date && moment(moment(date).format(FORMAT_DATE)),
                        TYPE.START,
                        eventOne,
                        eventTwo == "cleared" ? "cleared-time-one" : eventOne != "mounting" ? "changing-time-one" : eventOne
                    )
                }
                selected={range.start?.toDate() || null}
                startDate={range.start?.toDate() || null}
                endDate={range.end?.toDate() || null}
                readOnly={readOnly}
                isFixed={isPortal}
                popperPlacement={menuPlacement}
                disabled={disabled}
                timezone={timezone}
                interval={interval}
                filterTime={(fdate) =>
                    getDateSelectionAvailability(fdate, memoizedConstraint?.start, timezone, memoizedOffsetStart.min, memoizedOffsetStart.max)
                }
                baseDate={baseDate}
                isLoading={isLoading}
                constraint={memoizedConstraint?.start}
                isClearable
                selectsStart
                useSimpleClearIcon
            />
            <span className="fade">to</span>
            <Input
                type={DATE_TYPE}
                parentStyle={parentStyle}
                onChange={(date, eventOne, eventTwo) =>
                    handleChange(
                        date && moment(moment(date).format(FORMAT_DATE)),
                        TYPE.END,
                        eventOne,
                        eventTwo == "cleared" ? "cleared-time-two" : eventOne != "mounting" ? "changing-time-two" : eventOne
                    )
                }
                selected={range.end?.toDate() || null}
                startDate={range.start?.toDate() || null}
                endDate={range.end?.toDate() || null}
                readOnly={readOnly}
                disabled={!range.start || disabled}
                timezone={timezone}
                interval={interval}
                filterTime={(fdate) =>
                    getDateSelectionAvailability(fdate, memoizedConstraint?.end, timezone, memoizedOffsetEnd.min, memoizedOffsetEnd.max)
                }
                baseDate={baseDate}
                isLoading={isLoading}
                constraint={memoizedConstraint?.end}
                isClearable
                selectsEnd
                useSimpleClearIcon
            />
        </>
    );
}

function TimePickerRange(props) {
    const { label, required, noBorder, style, isLoading, subtext: subtextTemp, warning, error, useSubTextStyle, errorProps, warningProps } = props;
    const hasCondition = !!warning?.length || !!error?.length;

    const isAlreadyNextDay = useMemo(
        () => isStartAlreadyNextDay(props.range.start, props.baseDate || STANDARD_REF_DATE, props.timezone),
        [props.range.start, props.baseDate, props.timezone]
    );

    const baseDate = useMemo(() => {
        return props.forceNextDayBaseDate
            ? moment(props.baseDate || STANDARD_REF_DATE)
                  .add(1, "day")
                  .format("YYYY-MM-DD")
            : props.baseDate || STANDARD_REF_DATE;
    }, [props.forceNextDayBaseDate, props.baseDate]);

    const subtext = useMemo(() => {
        const updatedSubtext = { ...subtextTemp };
        if (isAlreadyNextDay) {
            updatedSubtext.message = <span>The selected time for this shift begins on the next day.</span>;
            updatedSubtext.hide = false;
        }
        return updatedSubtext;
    }, [subtextTemp, isAlreadyNextDay]);

    const containerStyle = useMemo(
        () => ({
            ...(style || {}),
            ...(noBorder && !hasCondition && (!subtext || subtext.hide) ? { border: "none" } : {})
        }),
        [style, noBorder, hasCondition, subtext]
    );

    return (
        <ConditionWrapper
            warnings={(!isLoading && warning) || []}
            errors={(!isLoading && error) || []}
            subtext={(!isLoading && subtext) || {}}
            useSubTextStyle={useSubTextStyle}
            errorProps={errorProps}
            warningProps={warningProps}
        >
            <div className="tk-timepicker-range" style={containerStyle}>
                <div className="tk-timepicker-range__inner">
                    {label && (
                        <span className="tk-timepicker-range__label">
                            {label}
                            {required && !props.readOnly && !props.disabled ? <span className="danger-color bold">*</span> : ""}
                        </span>
                    )}
                    <div className="flex gap-05 center">
                        {isLoading ? <MiniLoader show /> : <TimePickerRangeMain {...props} baseDate={baseDate} />}
                    </div>
                </div>
                <input
                    key={required}
                    className="hidden-input"
                    value={(props.range.start && props.range.end) || ""}
                    onChange={props.onChange}
                    name=""
                    required={required}
                />
            </div>
        </ConditionWrapper>
    );
}

const props = {
    label: PropTypes.any,
    required: PropTypes.bool,
    disabled: PropTypes.bool,

    onChange: PropTypes.func,
    defaultRange: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    range: PropTypes.shape({
        start: PropTypes.instanceOf(moment),
        end: PropTypes.instanceOf(moment)
    }),
    constraint: PropTypes.shape({
        start: PropTypes.shape({
            min: PropTypes.instanceOf(moment),
            max: PropTypes.instanceOf(moment)
        }),
        end: PropTypes.shape({
            min: PropTypes.instanceOf(moment),
            max: PropTypes.instanceOf(moment)
        })
    }),
    timezone: PropTypes.string,
    style: PropTypes.object,
    styleDate: PropTypes.object,
    noBorder: PropTypes.bool,
    isPortal: PropTypes.bool,
    menuPlacement: PropTypes.string,
    interval: PropTypes.number,
    virtualize: PropTypes.bool,
    isLoading: PropTypes.bool,
    noResetOnChange: PropTypes.bool,
    readOnly: PropTypes.bool,
    limitHoursOptions: PropTypes.number,
    baseDate: PropTypes.string,
    subtext: PropTypes.shape({
        style: PropTypes.object,
        className: PropTypes.string,
        message: PropTypes.any,
        hide: PropTypes.bool
    }),
    offset: PropTypes.shape({
        start: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number
        }),
        end: PropTypes.shape({
            min: PropTypes.number,
            max: PropTypes.number
        })
    }),

    warning: PropTypes.array,
    error: PropTypes.array,
    errorProps: PropTypes.object,
    warningProps: PropTypes.object,
    useSubTextStyle: PropTypes.bool,
    forceNextDayBaseDate: PropTypes.bool,
    isDateWithinConstraint: PropTypes.bool,
    disableNextDaySelectionEndDate: PropTypes.bool,
    afterDate: PropTypes.any
};

TimePickerRange.propTypes = props;
TimePickerRangeMain.propTypes = props;

export default TimePickerRange;
