import React from "react";
import PropTypes from "prop-types";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { USER_LEVEL } from "./const";
import { createOptions } from "../../../common/components/extra/select/helper";
import Tag from "../../../common/components/extra/Tag";
import { sanitizeWords } from "../../../common/utilities/helper";

const createBaseOpt = (exclude) => {
    return Object.keys(USER_LEVEL)
        .filter((key) => !exclude.includes(USER_LEVEL[key]))
        .reduce(
            (prev, key) => ({
                ...prev,
                [key]: USER_LEVEL[key]
            }),
            {}
        );
};

export const createLabel = (value, { fixed, small, bold, isRawLabel } = {}) => {
    let temp = {
        value,
        label: "",
        isFixed: (fixed || []).some((val) => val == value)
    };
    switch (value) {
        case USER_LEVEL.ZERO: {
            temp.label = "Employee (0)";
            break;
        }
        case USER_LEVEL.ONE: {
            temp.label = "Supervisor (1)";
            break;
        }
        case USER_LEVEL.TWO: {
            temp.label = "Manager (2)";
            break;
        }
        case USER_LEVEL.THREE: {
            temp.label = "HR/Finance/Accounts/IT (3)";
            break;
        }
        case USER_LEVEL.FOUR: {
            temp.label = "Top-level (4)";
            break;
        }
        default: {
            break;
        }
    }
    if (!isRawLabel) {
        temp.label = (
            <div className={`flex gap-05 ${bold ? "bold" : "semi-bold"} ${small ? "small-font" : ""}`.trim()}>
                <span>{temp.label}</span>
            </div>
        );
    }
    return temp;
};

export const createUserLevelOptions = ({ exclude, fixed, small, bold, isFilter } = {}) => {
    let result = createOptions(createBaseOpt(exclude || []), (value) =>
        createLabel(value, {
            fixed: fixed || [],
            small,
            bold,
            isFilter
        })
    );
    if (isFilter) {
        result = result.concat({
            label: (
                <div className={`flex gap-05 ${bold ? "bold" : "semi-bold"} ${small ? "small-font" : ""}`.trim()}>
                    <span>All User Level</span>
                </div>
            ),
            value: ""
        });
    }
    return result;
};

function UserLevelSelect({ fixed, exclude, isTag, ...props }) {
    return (
        <SelectConstant
            base={createBaseOpt(exclude || [])}
            transformBase={(value) => {
                if (isTag) {
                    return {
                        value,
                        label: <Tag>{sanitizeWords(createLabel(value, { isRawLabel: true }).label)}</Tag>
                    };
                }
                return createLabel(value, { fixed });
            }}
            {...props}
        />
    );
}

export default UserLevelSelect;

UserLevelSelect.propTypes = {
    isTag: PropTypes.bool,
    fixed: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_LEVEL))),
    exclude: PropTypes.arrayOf(PropTypes.oneOf(Object.values(USER_LEVEL)))
};
