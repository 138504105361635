import React, { useState } from "react";
import PropTypes from "prop-types";
import { addCommasToMoney, createClass, createConfirmAlert, createGroup, toProperMoneyFormat } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import Input from "../../../common/components/extra/form/Input";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import Select from "../../../common/components/extra/select/Select";
import { useDownloadSalary } from "./hooks";
import { BASE_CLASS, COMPANY_SALARY_FILETYPE, WPS_DOWNLOAD_FIELDS } from "./const";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectBanks, selectCountry, selectUserSetting } from "../../common/slice";

function ExportWPSModal({ open, onClose, onFinish }) {
    const [download, isDownloading, { setWpsHeaderData, wpsHeaderData, disableSave, salaryMonth, salaryYear, totalSalaries, totalRecords }] =
        useDownloadSalary();

    const [error, setError] = useState({ all: null });

    const setting = useAppSelector(selectUserSetting);
    const country = useAppSelector(selectCountry);

    const handleSave = async () => {
        const result = await download(COMPANY_SALARY_FILETYPE.WPS_FILE);
        if (!result?.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result?.error) {
            setError({ ...error, all: result.error.message });
        }
        return result?.error;
    };

    const handleFormChange = ({ name, value } = {}) => setWpsHeaderData({ [name]: value });

    const banks = useAppSelector(selectBanks).map((bank) => ({
        ...bank,
        label: (
            <span>
                {bank.label} (<span>{bank.value}</span>)
            </span>
        )
    }));

    return (
        <BaseUpdateModal
            open={open}
            onClose={onClose}
            onSave={(e) =>
                createConfirmAlert({
                    title: "Proceed Export",
                    content: "Please verify the details before proceeding. These details will be utilized for the creation of the WPS data.",
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave(e);
                        if (!hasError) {
                            onClose();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isDownloading}
            isForm
        >
            {createGroup({
                base: createClass("__modal-content-update", BASE_CLASS),
                title: "Export WPS",
                body: (
                    <div className="flex column gap-05">
                        <SectionCollapseInfo title="Notice" style={{ marginBottom: "1rem" }} show alwaysOpen>
                            The exported WPS format is based on the circulated format by{" "}
                            {country === "qa" ? "Qatar Central Bank (QCB)" : "Central Bank of U.A.E (CBUAE)"}.
                        </SectionCollapseInfo>
                        <div className="flex gap-1 wrap">
                            <div className="flex column gap-1" style={{ flexGrow: 1 }}>
                                <Input
                                    renderValue={wpsHeaderData[WPS_DOWNLOAD_FIELDS.EMPLOYER_EID]}
                                    label="Establishment ID (Computer Card)"
                                    readOnly
                                />

                                <Input
                                    name={WPS_DOWNLOAD_FIELDS.PAYER_QID}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Payer QID</span>}
                                    onChange={(e) => handleFormChange({ name: WPS_DOWNLOAD_FIELDS.PAYER_QID, value: e.target.value })}
                                    value={wpsHeaderData[WPS_DOWNLOAD_FIELDS.PAYER_QID]}
                                />
                                <Input
                                    name={WPS_DOWNLOAD_FIELDS.PAYER_IBAN}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Payer IBAN</span>}
                                    onChange={(e) => handleFormChange({ name: WPS_DOWNLOAD_FIELDS.PAYER_IBAN, value: e.target.value })}
                                    value={wpsHeaderData[WPS_DOWNLOAD_FIELDS.PAYER_IBAN]}
                                    required
                                />
                                <Input renderValue={salaryYear} label="Salary Year" readOnly />
                                <Input renderValue={addCommasToMoney(totalRecords)} label="No. Of Records" readOnly />
                                <Input
                                    renderValue={wpsHeaderData[WPS_DOWNLOAD_FIELDS.FILE_CREATION_TIME]?.format?.("hh:mm A")}
                                    label="File Creation Time"
                                    readOnly
                                />
                            </div>
                            <div className="flex column gap-1" style={{ flexGrow: 1 }}>
                                <Input
                                    name={WPS_DOWNLOAD_FIELDS.PAYER_EID}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Payer EID</span>}
                                    onChange={(e) => handleFormChange({ name: WPS_DOWNLOAD_FIELDS.PAYER_EID, value: e.target.value })}
                                    value={wpsHeaderData[WPS_DOWNLOAD_FIELDS.PAYER_EID]}
                                    autoFocus
                                />
                                <Select
                                    style={{ width: "100%" }}
                                    label={<span style={{ whiteSpace: "nowrap" }}>Payer Bank Short Name</span>}
                                    name={WPS_DOWNLOAD_FIELDS.PAYER_BANK_SHORT_NAME}
                                    onChange={(val) => handleFormChange({ name: WPS_DOWNLOAD_FIELDS.PAYER_BANK_SHORT_NAME, value: val.value })}
                                    value={banks.find((gen) => gen.value === wpsHeaderData[WPS_DOWNLOAD_FIELDS.PAYER_BANK_SHORT_NAME])}
                                    options={banks}
                                    required
                                    isOutlined
                                    disabledOutline
                                />
                                <Input renderValue={salaryMonth} label="Salary Month" readOnly />
                                <Input
                                    renderValue={toProperMoneyFormat(totalSalaries)}
                                    label="Total Salary"
                                    afterExtra={<span>{setting.currency}</span>}
                                    readOnly
                                />
                                <Input
                                    renderValue={wpsHeaderData[WPS_DOWNLOAD_FIELDS.FILE_CREATION_DATE]?.format?.("YYYY MMMM DD")}
                                    label="File Creation Date"
                                    readOnly
                                />
                            </div>
                        </div>
                        <SectionCollapseError show={!!error.all} style={{ marginTop: ".5rem" }}>
                            {error.all}
                        </SectionCollapseError>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

ExportWPSModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onFinish: PropTypes.func
};

export default ExportWPSModal;
