import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Button from "../../../common/components/extra/Button";
import { createClass, createConfirmAlert, createToast, TOAST_TYPE, toReadableFromDate, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { BASE_CLASS, FINAL_STATUSES, SUBMITTED_FORM_CLASS, SUBMITTED_FORM_FOLLOW_UP_NOTICE, SUBMITTED_FORM_STATUS } from "./const";
import { FORM_CATEGORY, USER_LEVEL } from "../companyForms/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { useDoFollowUp } from "./hooks";

function FollowUpButton({ id, lastFollowUp, lastUpdatedAt, createdAt, currentFormStatus, targetApprovalUserLevel, disabled, onFollowUp }) {
    const [doFollowUp, isFollowingUp] = useDoFollowUp();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const today = toTimeWithTimeZone(new Date(), timezone);

    const isFollowedUpToday = useMemo(() => {
        if (!lastFollowUp) return false;
        return toTimeWithTimeZone(lastFollowUp, timezone).isSame(today, "day");
    }, [lastFollowUp]);

    const isLastUpdateMoreThanTwoDaysOld = useMemo(() => {
        return today.diff(toTimeWithTimeZone(lastUpdatedAt || createdAt, timezone), "days", true) > SUBMITTED_FORM_FOLLOW_UP_NOTICE;
    }, [lastUpdatedAt, lastFollowUp, createdAt]);

    const disableButton = isFollowedUpToday || (!lastFollowUp && !isLastUpdateMoreThanTwoDaysOld) || disabled || isFollowingUp;
    const showLastUpdateInfo = !!lastFollowUp || !!lastUpdatedAt;

    const handleFollowUp = async () => {
        try {
            const result = await doFollowUp(id);
            if (result.error) {
                throw new Error(result.error);
            }
            onFollowUp?.();
            return result;
        } catch (error) {
            createToast("Failed to do Follow Up, please try again later", TOAST_TYPE.ERROR);
        }
    };

    if (targetApprovalUserLevel == USER_LEVEL.THREE || FINAL_STATUSES.includes(currentFormStatus)) {
        return <></>;
    }

    return (
        <div className={createClass("__follow-up flex gap-03 align-center", BASE_CLASS)}>
            <Button
                className="secondary"
                disabled={disableButton}
                tooltipProps={{
                    message: "Only one follow-up per day is allowed, and only if there have been no updates in the past two days."
                }}
                onClick={() =>
                    !disableButton &&
                    createConfirmAlert({
                        title: "Follow Up",
                        content: "Are you sure you want to do a follow up? This will create a notification to the form owner.",
                        onConfirm: async (close) => {
                            close();
                            handleFollowUp();
                        }
                    })
                }
                isLoading={isFollowingUp}
                small
                mini
            >
                Follow Up
            </Button>
            {showLastUpdateInfo && (
                <span className="small-font fade">Last Updated: {toReadableFromDate(lastFollowUp || lastUpdatedAt, timezone)}</span>
            )}
        </div>
    );
}

export default FollowUpButton;

FollowUpButton.propTypes = {
    id: PropTypes.number,
    lastUpdatedAt: PropTypes.string,
    lastFollowUp: PropTypes.string,
    createdAt: PropTypes.string,
    disabled: PropTypes.bool,
    onFollowUp: PropTypes.func,
    currentFormStatus: PropTypes.oneOf(Object.values(SUBMITTED_FORM_STATUS)),
    targetApprovalUserLevel: PropTypes.oneOf(Object.values(USER_LEVEL)),
    formCategory: PropTypes.oneOf(Object.values(FORM_CATEGORY)),
    formClass: PropTypes.oneOf(Object.values(SUBMITTED_FORM_CLASS))
};
