import React, { useState } from "react";
import {
    DndContext,
    rectIntersection,
    pointerWithin,
    KeyboardSensor,
    useSensor,
    useSensors,
    MouseSensor,
    TouchSensor,
    PointerSensor
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

import SidePanel from "./SidePanel";
import MobileView from "./MobileView";
import Validations from "./Validations";
import { useGetActiveFields, useUpsertCompanyForm, useValidateDrops } from "./hooks";
import FormDetails from "./FormDetails";
import { useNavigate, useParams } from "react-router-dom";
import { TOAST_TYPE, arrayInsert, createConfirmAlert, createToast, sanitizeWords } from "../../../common/utilities/helper";
import Button from "../../../common/components/extra/Button";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import {
    EMPLOYEE_SPECIFIC_CATEGORY_CLASS,
    FIELD,
    FIELDS_NOT_AVAILABLE_BY_CLASS,
    FORM_CATEGORY,
    FORM_CLASS,
    FORM_TYPE,
    HR_SPECIFIC_CATEGORY_CLASS,
    NO_VALIDATION_GROUP
} from "./const";
import { isDroppedItemsHasError, validateField } from "./helper";

function UpsertCompanyForm() {
    const [templates, setTemplates] = useState([]);
    const [template, setTemplate] = useState(null);
    const [openFormDetails, setOpenFormDetails] = useState(true);

    const mouseSensor = useSensor(MouseSensor, { activationConstraint: { distance: 8 } });
    const touchSensor = useSensor(TouchSensor, { activationConstraint: { delay: 200, tolerance: 6 } });
    const pointerSensor = useSensor(PointerSensor);
    const keyboardSensor = useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates });

    const sensors = useSensors(mouseSensor, pointerSensor, touchSensor, keyboardSensor);

    const [customError, setCustomError] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [droppedItems, setDroppedItems] = useState([]);
    const [oldDroppedItems, setOldDroppedItems] = useState([]);
    const [draggedItem, setDraggedItem] = useState(null);

    const navigate = useNavigate();
    const params = useParams();
    const updateId = params.id;

    const validatedDroppedItems = useValidateDrops(droppedItems);

    const [form, setForm, { upsert, isUpserting, isGettingForm, hasChangesToBaseForm }] = useUpsertCompanyForm(updateId, (fetchedForms) => {
        setDroppedItems(fetchedForms.fields);
        setOldDroppedItems(fetchedForms.fields);
    });
    const [fields, isGettingFields, { onSearch }] = useGetActiveFields();

    const hasFieldsChanges = !!updateId && !isEqual(form.fields, droppedItems);
    const preventDrop = isUpserting || isGettingFields;
    const hasError = isDroppedItemsHasError(droppedItems) || !!customError;
    const disableSave = hasError || (!!updateId && !hasChangesToBaseForm && !hasFieldsChanges) || (!updateId && !droppedItems.length) || preventDrop;
    const isNoValidationGroup = selectedItem?.data?.group && NO_VALIDATION_GROUP.includes(selectedItem.data.group);
    const formClass = form[FIELD.CLASS];

    const clearCustomError = () => customError && setCustomError("");

    const handleDragStart = (e) => {
        setDraggedItem({
            id: e.active.id,
            data: e.active?.data?.current
        });
    };

    const handleDragEnd = (e) => {
        const { active, over, collisions } = e;
        const isDroppableArea = !!collisions?.length;

        if (!isDroppableArea || preventDrop) return;

        const len = droppedItems.length;
        // "xxx" is the temp id for the placeholder inside of the mobile view
        const isMainDropZone = over.id == "main-drop-zone" || over.id == "xxx";
        const isAlreadyExist = droppedItems.some((dropped) => dropped.id == active.id);

        if (isMainDropZone && !isAlreadyExist) {
            setDroppedItems(droppedItems.concat(draggedItem));
        } else {
            if (active && over && active.id !== over.id) {
                const oldIndex = droppedItems.map((it) => it.id).indexOf(draggedItem.id);
                const newIndex = droppedItems.map((it) => it.id).indexOf(over.id);
                const isNew = oldIndex < 0;
                if (isNew && !isAlreadyExist) {
                    const newArr = arrayInsert(droppedItems, newIndex, draggedItem);
                    setDroppedItems(newArr);
                } else {
                    // only insert if there are two or more elements
                    if (len > 1 && oldIndex != newIndex) {
                        let updatedItems = cloneDeep([...droppedItems]);
                        const toMoveElement = updatedItems.splice(oldIndex, 1); // Remove from old position
                        const newArr = arrayInsert(updatedItems, newIndex, toMoveElement.pop());
                        setDroppedItems(newArr);
                    }
                }
            }
        }
        setDraggedItem(null);
    };

    const handleRemoveItem = (removeItem) => {
        setDroppedItems(droppedItems.filter((item) => item.id != removeItem.id));
        if (selectedItem && removeItem.id == selectedItem.id) {
            setSelectedItem(null);
        }
    };

    const handleChange = (key, value, extra = {}) => {
        clearCustomError();
        setDroppedItems((prevDroppedItems) => {
            return prevDroppedItems.map((item) => {
                if (item.id === selectedItem?.id) {
                    const clone = cloneDeep(item);
                    const itemData = clone.data;
                    if (!itemData.validation) {
                        clone.data.validation = {};
                    }
                    clone.data.validation[key] = value;
                    for (const extraKey in extra) {
                        if (Object.prototype.hasOwnProperty.call(extra, extraKey)) {
                            clone.data.validation[extraKey] = extra[extraKey];
                        }
                    }
                    const fieldObj = validateField(clone.data);
                    if (!fieldObj.error) {
                        clone.error = null;
                    }
                    return clone;
                }
                return item;
            });
        });

        setSelectedItem((prev) => ({
            ...prev,
            data: {
                ...prev.data,
                validation: {
                    ...prev.data.validation,
                    [key]: value,
                    ...extra
                }
            }
        }));
    };

    const checkForDropErrors = () => {
        if (
            Object.keys(form)
                .filter((key) => key !== FIELD.FIELDS)
                .some((key) => form[key] !== 0 && !form[key])
        ) {
            setOpenFormDetails(true);
            setCustomError("Please satisfy all the required fields");
            createToast("Please satisfy all the required fields", TOAST_TYPE.ERROR);
            return {
                hasError: true
            };
        }

        if (openFormDetails) {
            setOpenFormDetails(false);
        }

        const validatedFieldsError = validatedDroppedItems.error;

        if (validatedFieldsError && !validatedDroppedItems.hasDropError) {
            setCustomError(validatedFieldsError);
            createToast(validatedFieldsError, TOAST_TYPE.ERROR);
            return {
                hasError: true
            };
        }

        const newDroppedItems = validatedDroppedItems.newDroppedItems;

        setDroppedItems(newDroppedItems);
        setForm({ fields: newDroppedItems });

        return {
            newDrop: newDroppedItems,
            hasError: validatedDroppedItems.hasDropError
        };
    };

    const handleSubmit = async (newDrop) => {
        const result = await upsert({ ...form, fields: newDrop });
        if (result.error) {
            setCustomError(result.error?.message || `Failed to ${updateId ? "create" : "update"} form.`);
        } else {
            navigate(-1);
        }
    };

    const handleReset = () => {
        setSelectedItem(null);
        clearCustomError();
        if (oldDroppedItems.length) {
            setTemplate(null);
            setDroppedItems(oldDroppedItems);
        } else {
            if (formClass == FORM_CLASS.YEARLY_APPRAISAL) {
                handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.YEARLY_APPRAISAL));
            } else if (formClass == FORM_CLASS.PROBATIONARY_APPRAISAL) {
                handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.PROBATIONARY_APPRAISAL));
            } else {
                setTemplate(null);
                setDroppedItems([]);
            }
        }
    };

    const handleFormChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        clearCustomError();

        let temp = {
            ...form,
            [name]: value
        };

        if (name == FIELD.CLASS) {
            const prevClass = form[FIELD.CLASS];
            if (value == prevClass) {
                return;
            }
            const isPrevClassYearly = prevClass == FORM_CLASS.YEARLY_APPRAISAL;
            const isPrevClassProb = prevClass == FORM_CLASS.PROBATIONARY_APPRAISAL;
            const isPrevClassLeave = prevClass == FORM_CLASS.LEAVE_FORM;

            const notAvailableFieldsByClassByGroup = FIELDS_NOT_AVAILABLE_BY_CLASS.byGroup[value] || [];
            const notAvailableFieldsByClassByFields = FIELDS_NOT_AVAILABLE_BY_CLASS.byFields[value] || [];

            // validate category and form type by class
            const isEmployeeSpec = EMPLOYEE_SPECIFIC_CATEGORY_CLASS.includes(value);
            const isHRSpec = HR_SPECIFIC_CATEGORY_CLASS.includes(value);

            if (isEmployeeSpec || isHRSpec) {
                temp[FIELD.TYPE] = FORM_TYPE.REQUEST;
                if (isEmployeeSpec) {
                    temp[FIELD.CATEGORY] = FORM_CATEGORY.EMPLOYEE;
                }
                if (isHRSpec) {
                    temp[FIELD.CATEGORY] = FORM_CATEGORY.HR;
                }
            }

            // validate form fields by class
            const invalidFields = droppedItems.filter((item) => {
                return (
                    item?.data &&
                    (notAvailableFieldsByClassByGroup.includes(item.data.group) || notAvailableFieldsByClassByFields.includes(item.data.type))
                );
            });

            if (invalidFields.length || isPrevClassYearly || isPrevClassProb || isPrevClassLeave) {
                const isResetDroppedItems = isPrevClassProb || isPrevClassYearly || isPrevClassLeave;

                createConfirmAlert({
                    title: "Are you sure?",
                    content: (
                        <div className="flex column gap-05">
                            {!isResetDroppedItems ? (
                                <>
                                    <span>
                                        The following fields will be removed because it is not available for the class {sanitizeWords(value)}:
                                    </span>
                                    <ul className="flex column gap-03">
                                        {invalidFields.map((field) => (
                                            <li key={field.id} className="has-list-style indent-1" style={{ color: "red" }}>
                                                {sanitizeWords(field.data.name)}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <span>Dropped items will reset. Are you sure?</span>
                            )}
                        </div>
                    ),
                    onConfirm: (onClose) => {
                        setSelectedItem(null);
                        setForm(temp);
                        if (value == FORM_CLASS.YEARLY_APPRAISAL) {
                            handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.YEARLY_APPRAISAL));
                        } else if (value == FORM_CLASS.PROBATIONARY_APPRAISAL) {
                            handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.PROBATIONARY_APPRAISAL));
                        } else if (value == FORM_CLASS.LEAVE_FORM) {
                            handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.LEAVE_FORM));
                        } else {
                            // reset all
                            if (isResetDroppedItems) {
                                setDroppedItems([]);
                            } else {
                                setDroppedItems((prevDroppedItems) => {
                                    return prevDroppedItems.filter((item) => {
                                        return (
                                            item?.data &&
                                            !notAvailableFieldsByClassByGroup.includes(item.data.group) &&
                                            !notAvailableFieldsByClassByFields.includes(item.data.type)
                                        );
                                    });
                                });
                            }
                        }
                        onClose();
                    }
                });
            } else {
                setSelectedItem(null);
                setForm(temp);
                if (value == FORM_CLASS.YEARLY_APPRAISAL) {
                    handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.YEARLY_APPRAISAL));
                } else if (value == FORM_CLASS.PROBATIONARY_APPRAISAL) {
                    handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.PROBATIONARY_APPRAISAL));
                } else if (value == FORM_CLASS.LEAVE_FORM) {
                    handleTemplateChange(templates.find((tp) => tp.class == FORM_CLASS.LEAVE_FORM));
                }
            }
        } else {
            setForm(temp);
        }
    };

    const handleConfirm = (obj = {}) => {
        if (obj.error) {
            setCustomError("Please complete all required fields");
        } else {
            clearCustomError();
        }
    };

    const customCollisionDetectionAlgorithm = (args) => {
        const pointerCollisions = pointerWithin(args);
        if (pointerCollisions.length > 0) {
            return pointerCollisions;
        }
        return rectIntersection(args);
    };

    const handleTemplateChange = (value) => {
        setTemplate(value || null);
        if (value?.fields?.length) {
            setDroppedItems(value?.fields);
        } else {
            setDroppedItems([]);
        }
    };

    return (
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors} collisionDetection={customCollisionDetectionAlgorithm}>
            <SortableContext items={droppedItems} strategy={verticalListSortingStrategy}>
                <div className="tk-company-forms-upsert__inner">
                    <SidePanel form={form} fields={fields} onSearch={onSearch} isLoading={isGettingFields} hasDroppedItems={!!droppedItems.length} />
                    <form
                        className="tk-company-forms-upsert__content"
                        onSubmit={(e) => {
                            e.preventDefault();
                            const result = checkForDropErrors();
                            if (result?.hasError) return;
                            createConfirmAlert({
                                title: "Save Changes",
                                content: `This action will ${updateId ? "update" : "create"} the current form.`,
                                onConfirm: async (close) => {
                                    close();
                                    handleSubmit(result.newDrop);
                                }
                            });
                        }}
                    >
                        <FormDetails
                            isCreate={!updateId}
                            open={openFormDetails}
                            setOpen={setOpenFormDetails}
                            form={form}
                            onChange={handleFormChange}
                            onTemplateChange={handleTemplateChange}
                            template={template}
                            isLoading={isGettingForm}
                            onTemplatesLoad={setTemplates}
                        />
                        <div className="tk-company-forms-upsert__body">
                            <div className="tk-company-forms-upsert__body__left">
                                <MobileView
                                    activeId={draggedItem && draggedItem.id}
                                    data={draggedItem && draggedItem.data}
                                    items={droppedItems}
                                    onRemove={handleRemoveItem}
                                    onSelect={setSelectedItem}
                                    selected={selectedItem}
                                    setDroppedItems={setDroppedItems}
                                    isLoading={isUpserting || isGettingForm}
                                    loadingMessage={isGettingForm ? "Please wait, fetching data..." : "Please wait, saving data..."}
                                    isDisabled={isGettingFields}
                                />
                                <span className="mobile-view-title fade">Mobile View</span>
                            </div>
                            <div className={`tk-company-forms-upsert__body__right flex column gap-1 ${hasError && "has-error"}`.trim()}>
                                <SectionCollapseError show={hasError}>
                                    {customError || "Invalid fields detected. Please complete all required fields."}
                                </SectionCollapseError>
                                {selectedItem && !isNoValidationGroup && (
                                    <Validations
                                        key={selectedItem.id}
                                        id={selectedItem.id}
                                        data={selectedItem.data}
                                        form={form}
                                        onCancel={() => setSelectedItem(null)}
                                        onChange={(...rest) => handleChange(...rest)}
                                        onConfirm={handleConfirm}
                                    />
                                )}
                            </div>
                            <div className="main-controls flex gap-05">
                                <Button
                                    className="danger"
                                    options={{ type: "button" }}
                                    onClick={() =>
                                        createConfirmAlert({
                                            title: "Reset",
                                            content: "Are you sure you want to remove all dropped items? This cannot be undone.",
                                            onConfirm: async (close) => {
                                                close();
                                                handleReset();
                                            }
                                        })
                                    }
                                    disabled={preventDrop || !droppedItems.length || isEqual(oldDroppedItems, droppedItems)}
                                    small
                                    transparent
                                >
                                    <span className="flex center">Reset</span>
                                </Button>
                                <Button options={{ type: "submit" }} className="primary" disabled={disableSave} isLoading={isUpserting} small>
                                    <span className="flex center">Save</span>
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </SortableContext>
        </DndContext>
    );
}

export default UpsertCompanyForm;
