export const LABEL_MAX_LEN = 255;
export const TEXT_MAX_LEN = 1000;
export const MAX_OPTION_LIMIT = 100;
export const CHAR_OPTION_LIMIT = 500;

export const TABS = {
    EMP_COMPANY_FORM: { id: "employee-c-form", label: "Employees' Forms", isActive: true },
    HR_COMPANY_FORM: { id: "hr-c-form", label: "HR Forms", isActive: true }
};

export const CUSTOM_ACTION = {
    SEND_FORM: "SEND_FORM"
};

export const FILTER_TYPE = {
    TYPE: 0x1,
    APPROVAL_TYPE: 0x2,
    MIN_USER_LEVEL: 0x3,
    STATUS: 0x4,
    DATE: 0x5,
    CATEGORY: 0x6, //  only use for tabs
    CLASS: 0x7
};

export const USER_LEVEL = {
    ZERO: 0x0, // Default as Employee/Staff and upon creation
    ONE: 0x1, // Supervisor/Site Engineer/Foreman
    TWO: 0x2, // Department Manager/Site Manager
    THREE: 0x3, // HR Department/Accounts Department/Finance Department
    FOUR: 0x4 // (form and report viewing and approvals only) - GM/Director/Owner/CEO
};

export const FORM_CATEGORY = {
    EMPLOYEE: "EMPLOYEE", // submitted by the employees from the mobapp
    HR: "HR" // sent by the WebAdmin to a target employee
};

export const FORM_APPROVAL_TYPE = {
    DEPARTMENT: "DEPARTMENT", // if there's no direct and SV and MG, this will be the approval flow
    SITE: "SITE" // this will be used for timing related requests (WorkHistory)
};

export const FIELD = {
    NAME: "name",
    TYPE: "type",
    INITIALS: "initials",
    CLASS: "class",
    FIELDS: "fields",
    STATUS: "status",
    CATEGORY: "category",
    MIN_USER_LEVEL: "min_user_level",
    APPROVAL_TYPE: "approval_type",
    APPROVAL_LEVELS: "approval_levels"
};

export const FORM_TYPE = {
    REQUEST: "REQUEST",
    REPORT: "REPORT"
};

export const FORM_CLASS = {
    NORMAL: "NORMAL",
    PROBATIONARY_APPRAISAL: "PROBATIONARY_APPRAISAL",
    YEARLY_APPRAISAL: "YEARLY_APPRAISAL",
    TERMINATION_FORM: "TERMINATION_FORM",
    TRAINING_REQUEST_FORM: "TRAINING_REQUEST_FORM",
    CLEARANCE_FORM: "CLEARANCE_FORM",
    LEAVE_FORM: "LEAVE_FORM"
};

export const PROBATIONARY_CLASS_PERIOD_SUPPORT = {
    THREE_MONTHS: "THREE_MONTHS",
    SIX_MONTHS: "SIX_MONTHS"
};

export const YEARLY_CLASS_PERIOD_SUPPORT = {
    YEARLY: "YEARLY"
};

export const HR_SPECIFIC_CATEGORY_CLASS = [
    FORM_CLASS.PROBATIONARY_APPRAISAL,
    FORM_CLASS.YEARLY_APPRAISAL,
    FORM_CLASS.TERMINATION_FORM,
    FORM_CLASS.TRAINING_REQUEST_FORM,
    FORM_CLASS.CLEARANCE_FORM
];

export const EMPLOYEE_SPECIFIC_CATEGORY_CLASS = [FORM_CLASS.LEAVE_FORM];

export const FIELD_OBJECT = {
    [FIELD.NAME]: {
        label: "Name",
        default: "",
        required: true
    },
    [FIELD.INITIALS]: {
        label: "Initials",
        default: "",
        required: true
    },
    [FIELD.TYPE]: {
        label: "Type",
        default: "",
        required: true
    },
    [FIELD.CLASS]: {
        label: "Class",
        default: FORM_CLASS.NORMAL,
        required: true
    },
    [FIELD.FIELDS]: {
        label: "Fields",
        default: "",
        required: true
    },
    [FIELD.CATEGORY]: {
        label: "Category",
        default: FORM_CATEGORY.EMPLOYEE,
        required: true
    },
    [FIELD.MIN_USER_LEVEL]: {
        label: "Min User Level",
        default: USER_LEVEL.ZERO,
        required: true
    },
    [FIELD.APPROVAL_LEVELS]: {
        label: "Processing Levels",
        default: [USER_LEVEL.THREE],
        required: true
    },
    [FIELD.STATUS]: {
        label: "Status",
        default: "",
        required: true
    }
};

export const VALIDATION_FIELD = {
    LABEL: "label",
    VALUE: "value", // can be either a string or an array depends on type
    MIN: "min",
    MAX: "max",
    REQUIRED: "required",
    // date specific fields,
    FORMAT: "format",
    NO_PAST: "noPastDate",
    NO_FUTURE: "noFutureDate",
    RANGE_SPAN: "rangeSpan",
    RANGE_SPAN_BASE: "rangeSpanBase",
    // questionnaire specific fields
    WITH_FINAL_SCORE_RATING: "withFinalScoreRating",
    TITLE: "title",
    RATINGS: "ratings",
    // info specific fields,
    IS_BOLD: "isBold",
    IS_COLUMN: "isColumn", // can also be available to input fields when for admin is true
    // input fields
    FOR_ADMIN: "forAdmin"
};

export const ERROR_QUESTION_KEY = VALIDATION_FIELD.VALUE + "questions";

export const VALIDATION_LABEL = {
    [VALIDATION_FIELD.TITLE]: "Title",
    [VALIDATION_FIELD.LABEL]: "Label",
    [VALIDATION_FIELD.VALUE]: "Value",
    [VALIDATION_FIELD.MIN]: "Min",
    [VALIDATION_FIELD.MAX]: "Max",
    [VALIDATION_FIELD.REQUIRED]: "Required",
    [VALIDATION_FIELD.IS_BOLD]: "Bold",
    [VALIDATION_FIELD.WITH_FINAL_SCORE_RATING]: "With Final Score Rating",
    [VALIDATION_FIELD.RATINGS]: "Ratings",
    [VALIDATION_FIELD.FOR_ADMIN]: "For Admin Usage",
    [VALIDATION_FIELD.IS_COLUMN]: "Column",
    [VALIDATION_FIELD.FORMAT]: "Format",
    [VALIDATION_FIELD.NO_PAST]: "Disable Past Date",
    [VALIDATION_FIELD.NO_FUTURE]: "Disable Future Date",
    [VALIDATION_FIELD.RANGE_SPAN]: "Range Span",
    [VALIDATION_FIELD.RANGE_SPAN_BASE]: "Range Span Base"
};

export const FORM_STATUS = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
};

export const EMPLOYEE_DETAILS_FIELDS = {
    EMPLOYEE_NAME: "EMPLOYEE_NAME",
    EMPLOYEE_ID: "EMPLOYEE_ID",
    EMPLOYEE_DESIGNATION: "EMPLOYEE_DESIGNATION",
    EMPLOYEE_NATIONALITY: "EMPLOYEE_NATIONALITY",
    EMPLOYEE_RESIDENCE_ID: "EMPLOYEE_RESIDENCE_ID",
    EMPLOYEE_MOBILE: "EMPLOYEE_MOBILE",
    EMPLOYEE_JOINING_DATE: "EMPLOYEE_JOINING_DATE",
    EMPLOYEE_JOB_GRADE: "EMPLOYEE_JOB_GRADE",
    EMPLOYEE_DEPARTMENT: "EMPLOYEE_DEPARTMENT"
};
export const WORK_DETAILS_FIELDS = {
    WORK_DETAIL_PROJECT_CODE: "WORK_DETAIL_PROJECT_CODE"
};

export const INPUT_FIELDS = {
    EMAIL: "EMAIL",
    PHONE_NUMBER: "PHONE_NUMBER",
    ADDRESS: "ADDRESS",
    TEXT_INPUT: "TEXT_INPUT",
    TEXT_AREA: "TEXT_AREA",
    NUMBER_INPUT: "NUMBER_INPUT",
    DATE: "DATE",
    TIME: "TIME",
    DATE_TIME: "DATE_TIME",
    DATE_RANGE: "DATE_RANGE",
    FILE_UPLOAD: "FILE_UPLOAD",
    IMAGE_UPLOAD: "IMAGE_UPLOAD",
    DROPDOWN: "DROPDOWN",
    RADIO: "RADIO",
    CHECKBOX: "CHECKBOX",
    TOGGLE: "TOGGLE"
};

export const FORM_FIELD_TYPE = {
    PARAGRAPH: "PARAGRAPH",
    LABEL: "LABEL",
    QUESTIONNAIRE: "QUESTIONNAIRE",
    GROUP: "GROUP",
    ...INPUT_FIELDS,
    ...EMPLOYEE_DETAILS_FIELDS,
    ...WORK_DETAILS_FIELDS
};

export const DATE_FORMAT_OPTION = {
    MONTH_YEAR: {
        key: "MONTH_YEAR", // the one that is being saved to DB
        format: "MMM YYYY", // moment format
        fnsFormat: "MM/yyyy" // date-fns format,
    },
    FULL_DATE: {
        key: "FULL_DATE", // the one that is being saved to DB
        format: "MMM DD, YYYY", // moment format
        fnsFormat: "MMM dd, yyyy" // date-fns format
    },
    FULL_DATE_WITH_DAY: {
        key: "FULL_DATE_WITH_DAY", // the one that is being saved to DB
        format: "ddd MMM D, YYYY", // moment format
        fnsFormat: "eee MMM d, yyyy" // date-fns format
    }
};

export const DATE_TIME_FORMAT = "ddd MMM DD YYYY hh:mm:ss a";

export const TIME_FORMAT = "hh:mm:ss a";

export const SPECIFIC_FIELD_CONST = {
    [FORM_FIELD_TYPE.DATE_RANGE]: {
        // this match the unit value in moment.js
        RANGE_SPAN_BASE: {
            MONTH: "month",
            DAY: "day",
            YEAR: "year",
            MILLISECONDS: "milliseconds"
        }
    }
};

export const FORM_FIELD_GROUP = {
    TEXT: "TEXT",
    INPUTS: "INPUTS",
    DATETIME: "DATETIME",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
    MEDIA: "MEDIA",
    QUESTIONNAIRE: "QUESTIONNAIRE",
    // this is for fields that only function as a view to the user
    EMPLOYEE_DETAILS: "EMPLOYEE_DETAILS", // showing an employee info
    WORK_DETAILS: "WORK_DETAILS", // showing an employee work detail info
    // the felds that contains inside of the special group is either not draggable on the client or has its own functionality when used
    // i.e. A group can contains multiple fields inside as its value
    SPECIAL: "SPECIAL"
};

export const NO_VALIDATION_GROUP = [];

export const FIELDS_NOT_AVAILABLE_BY_CLASS = {
    byGroup: {
        [FORM_CLASS.CLEARANCE_FORM]: [],
        [FORM_CLASS.LEAVE_FORM]: [],
        [FORM_CLASS.TERMINATION_FORM]: [],
        [FORM_CLASS.PROBATIONARY_APPRAISAL]: [FORM_FIELD_GROUP.EMPLOYEE_DETAILS, FORM_FIELD_GROUP.QUESTIONNAIRE, FORM_FIELD_GROUP.WORK_DETAILS],
        [FORM_CLASS.YEARLY_APPRAISAL]: [FORM_FIELD_GROUP.EMPLOYEE_DETAILS, FORM_FIELD_GROUP.QUESTIONNAIRE, FORM_FIELD_GROUP.WORK_DETAILS]
    },
    byFields: {
        [FORM_CLASS.CLEARANCE_FORM]: [],
        [FORM_CLASS.LEAVE_FORM]: [],
        [FORM_CLASS.TERMINATION_FORM]: [],
        [FORM_CLASS.PROBATIONARY_APPRAISAL]: [],
        [FORM_CLASS.YEARLY_APPRAISAL]: []
    }
};
