import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import Employees from "../../features/company/employees/Employees";
import { selectLoading } from "../../features/company/employees/slice";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import PageLayout from "../../common/components/layout/PageLayout";
import ModalSubscriptionPlan from "../../features/admin/subscriptions/ModalSubscriptionPlan";
import { useCheckEmployeeLimitReached, useCompanyStatusCheck } from "../../features/company/auth/hooks";
import { usePaginateEmployees } from "../../features/company/employees/hooks";
import Filter from "../../features/company/employees/Filter";
import { getFilterCount } from "../../common/utilities/helper";

function EmployeesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const { limitReached } = useCheckEmployeeLimitReached();
    const [, { isSearching, onFilter, onSearch, tableConfig }] = usePaginateEmployees({ readOnly: true });

    const loading = useAppSelector(selectLoading);
    const check = useCompanyStatusCheck();

    const isRenewUpgradeDisabled = check.isRenewUpgradeDisabled;
    const openEmployeeModal = openAddModal && !limitReached;
    const showPromotion = openAddModal && limitReached;

    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-employees"
            title="Manage Employees"
            isLoading={loading}
            search={{ onSearch, value: tableConfig.search }}
            filter={<Filter onFilter={onFilter} isLoading={isSearching} />}
            onAdd={isRenewUpgradeDisabled ? null : handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            activeFilterCount={getFilterCount(tableConfig.filter, [], (t) => {
                let cloneFilter = cloneDeep(t);
                return Object.values(cloneFilter)
                    .map((temp) => {
                        if (!isNull(temp) && !isUndefined(temp) && typeof temp == "object") {
                            if ((temp.value.length && !temp.value.includes("ALL_IDS")) || Object.values(temp.config).filter(Boolean).length > 0) {
                                return "NEW";
                            }
                            return "";
                        }
                        return temp;
                    })
                    .filter((temp) => Boolean(temp.toString())).length;
            })}
            hasFilter
        >
            <Employees openAddModal={openEmployeeModal} onAddModalClose={() => !limitReached && setOpenAddModal(false)} />
            {showPromotion && <ModalSubscriptionPlan open={openAddModal} onClose={() => setOpenAddModal(false)} />}
        </PageLayout>
    );
}

export default EmployeesPage;
