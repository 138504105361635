import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Visibility";
import {
    createConfirmAlert,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toReadableFromDate,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import Tag from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { COMPANY_HOLIDAY_CATEGORY, COMPANY_HOLIDAY_TYPE } from "./const";

const HEADERS = {
    NAME: "name",
    DATE: "start_date",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.sortKey = "name";
                temp.label = "Name";
                temp.style.width = "20rem";
                temp.style.margin = ".5rem 0";
                temp.render = (_, row) => {
                    const category = row.category == COMPANY_HOLIDAY_CATEGORY.COMPANY_SPECIFIC ? "custom" : row.category;
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <div className="flex gap-05 text-ellipsis wrap" style={{ alignItems: "center" }}>
                                <div
                                    className="text-ellipsis link-hover"
                                    style={{ fontWeight: "bold" }}
                                    onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)}
                                >
                                    {sanitizeWords(row.name)}
                                </div>
                                <div className="flex gap-05 wrap">
                                    {row.is_yearly && <Tag>Yearly</Tag>}
                                    <Tag className={row.type == COMPANY_HOLIDAY_TYPE.OFF ? "red" : "yellow"}>{sanitizeWords(row.type)}</Tag>
                                </div>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Category:</span>
                                <span className="text-ellipsis semi-bold">{sanitizeWords(category)}</span>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Level:</span>
                                <span className="text-ellipsis semi-bold">{sanitizeWords(row.level)}</span>
                            </div>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.DATE: {
                temp.sortKey = "index1";
                temp.style.width = "20rem";
                temp.label = "Date";
                temp.render = (_, row) => {
                    const timezone = setting.timezone;
                    const startTime = row.start_date && toTimeWithTimeZone(row.start_date, timezone);
                    const endTime = row.end_date && toTimeWithTimeZone(row.end_date, timezone);

                    const startddd = startTime && startTime.format("ddd");
                    const startFormat = startTime && startTime.format("MMM DD, YYYY");

                    const endddd = endTime && endTime.format("ddd");
                    const endFormat = endTime && endTime.format("MMM DD, YYYY");

                    return (
                        <div className="flex column gap-05">
                            <span className="flex gap-05">
                                <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                    <span className="fade semi-bold">{startddd}</span>&nbsp;
                                    <span className="semi-bold">{startFormat}</span>
                                </span>
                                {startddd && endddd && "-"}
                                <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                    <span className="fade semi-bold">{endddd}</span>&nbsp;
                                    <span className="semi-bold">{endFormat}</span>
                                </span>
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                        <DeleteIcon
                            style={{ color: "red" }}
                            className="hover-svg"
                            onClick={async () => {
                                createConfirmAlert({
                                    title: "Remove Holiday",
                                    content: "Are you sure you want to remove this holiday? This cannot be undone.",
                                    onConfirm: (close) => {
                                        close();
                                        typeof onAction === "function" && onAction(row, TABLE_ACTION_TYPE_COMMON.REMOVE);
                                    }
                                });
                            }}
                        />
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
