import { useAppSelector } from "./reduxHooks";
import { selectUser, selectUserCountrySettings } from "../../features/common/slice";
import { trimToLowerJoin } from "../utilities/helper";
import { AE_APPLICABLE_LEAVE_TYPES, QA_APPLICABLE_LEAVE_TYPES, STANDARD_APPLICABLE_LEAVE_TYPES } from "../../features/company/employeeLeaves/const";

function useCurrentCountryGlobals() {
    const user = useAppSelector(selectUser);
    const countrySettings = useAppSelector(selectUserCountrySettings);
    const country = user.country.iso2Alpha;
    const isQA = trimToLowerJoin(country) == trimToLowerJoin("QA");
    const isAE = trimToLowerJoin(country) == trimToLowerJoin("AE");

    const leaveTypesOptions = isQA ? QA_APPLICABLE_LEAVE_TYPES : isAE ? AE_APPLICABLE_LEAVE_TYPES : STANDARD_APPLICABLE_LEAVE_TYPES;
    const leaveTypesObj = leaveTypesOptions.reduce(
        (prev, curr) => ({
            ...prev,
            [curr]: curr
        }),
        {}
    );

    return {
        ...countrySettings,
        isQA,
        isAE,
        country,
        user,
        // conditional constants
        leaveTypesOptions,
        leaveTypesObj
    };
}

export default useCurrentCountryGlobals;
