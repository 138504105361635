import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import GroupInfoFields from "../../internalFields/GroupInfoFields";
import StarRating from "./StarRating";
import FinalScoreRating from "./FinalScoreRating";
import { getQuestionCount } from "../../helper";
import { useGetRatingInfo } from "./hooks";

function MobileQuestionnaire({ details, useValue }) {
    const { title, value, ratings, withFinalScoreRating, finalRating } = details || {};

    const { maxRating } = useGetRatingInfo(ratings);

    const calculateQuestionCount = useCallback(
        (groupIdx, questionIdx) => {
            return getQuestionCount(value, groupIdx, questionIdx);
        },
        [value]
    );

    const getFinalRating = useMemo(() => {
        if (useValue) {
            return finalRating;
        }
        return {
            label: maxRating.label,
            score: value.map((val) => val.questions).flat().length * maxRating.level
        };
    }, [JSON.stringify(value), maxRating, finalRating]);

    return (
        <div className="mobile-input mobile-input--questionnaire">
            <GroupInfoFields
                title={
                    <div className="mobile-input mobile-input--questionnaire__header-info">
                        <span>{title}</span>
                        <span>Rating</span>
                    </div>
                }
            >
                <div className="mobile-input--questionnaire__inner">
                    {value.map((group, groupIdx) => (
                        <div key={groupIdx} className="mobile-input--questionnaire__group">
                            {group.title && (
                                <>
                                    <h2 className="group-title">{group.title}</h2>
                                    {group.description && <p className="fade">{group.description}</p>}
                                </>
                            )}
                            <ul className={`mobile-input--questionnaire__list flex column gap-1 ${(group.title && "has-title") || ""}`.trim()}>
                                {(group?.questions || []).map((question, questionIdx) => {
                                    return (
                                        <li key={questionIdx} className="mobile-input--questionnaire__item">
                                            <div className="mobile-input--questionnaire__item__question">
                                                <h3 style={(!question.description && { margin: 0 }) || {}}>
                                                    {`${calculateQuestionCount(groupIdx, questionIdx)}).`} {question.title}
                                                </h3>
                                                {question.description && <p className="fade">{question.description}</p>}
                                            </div>
                                            <div className="mobile-input--questionnaire__item__value">
                                                <StarRating rating={useValue ? question.rating : maxRating.level} ratings={ratings} />
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            </GroupInfoFields>
            {withFinalScoreRating && <FinalScoreRating finalRating={getFinalRating} />}
        </div>
    );
}

export default MobileQuestionnaire;

MobileQuestionnaire.propTypes = {
    details: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                description: PropTypes.string,
                items: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string,
                        description: PropTypes.string
                    })
                )
            })
        ),
        ratings: PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string,
                level: PropTypes.number
            })
        ),
        withFinalScoreRating: PropTypes.bool,
        finalRating: PropTypes.shape({
            score: PropTypes.number,
            label: PropTypes.string
        })
    }),
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
