import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    TOAST_TYPE,
    createClass,
    createConfirmAlert,
    createGroup,
    createPromiseToast,
    createToast,
    screenshot,
    waitForTimeout
} from "../../../common/utilities/helper";
import Input from "../../../common/components/extra/form/Input";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import WorkDetailSelectLazy from "../employeeWorkDetails/WorkDetailSelectLazy";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import { BASE_CLASS, FIELDS } from "./const";
import { useUpsertWorkSites } from "./hooks";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import GoogleMap from "../../../common/components/extra/map/GoogleMap";
import { MAP_CHANGE_TYPE } from "../../../common/components/extra/map/const";

const { TITLE, LOCATION, COORDINATES, SUPERVISORS, MANAGERS, WORK_DETAIL } = FIELDS;

const MODAL_STYLE = { content: { width: "90vw", maxWidth: "70rem", minWidth: "40rem" } };
const REPO_ID = "repositioning";

function UpdateModal({ open, onChange, id, onFinish, noworkdetail }) {
    const mapRef = useRef(null);

    const [isRepositioning, setRepositioning] = useState(false); // this will fire first
    const [isSaving, setIsSaving] = useState(false); // then this will go if isRepositioning is true
    const [additional, setAdditonal] = useState({
        map: null,
        latlangLiteral: null
    });

    const [form, updateForm, { upsert, isLoading: isWorkSiteLoading, isGettingWorkSites, hasChanges, config, isEditAllowed, allowedTime }] =
        useUpsertWorkSites(id);

    const isCreate = !id;
    const { coordinatesChanged, isIncomplete } = config;
    const isLoading = isRepositioning || isSaving || isWorkSiteLoading;

    const updateAdditional = (config = {}) => setAdditonal((prev) => ({ ...prev, ...config }));

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const config = { ...form };
        config[name] = value;
        if (name === WORK_DETAIL.name) {
            const hasVal = value && !!Object.keys(value).length;
            if (!hasVal) config.work_detail_id = null;
            else config.work_detail_id = value.id;
        }
        updateForm(config);
    };

    const handleMapChange = (data, type) => {
        switch (type) {
            case MAP_CHANGE_TYPE.POLY_REMOVE:
            case MAP_CHANGE_TYPE.POLY_PATH_CHANGED:
            case MAP_CHANGE_TYPE.DRAW_COMPLETE: {
                updateForm({ coordinates: data.coordinates.map((d) => d.polygon).flat() });
                break;
            }
            default:
                break;
        }
        updateAdditional({ latlangLiteral: data.latlangLiteral });
    };

    const repositionMap = () => {
        additional.map.setOptions({
            fullscreenControl: false,
            disableDefaultUI: true
        });
        if (additional.latlangLiteral) {
            additional.map.fitBounds(additional.latlangLiteral);
            additional.map.set(REPO_ID, true);
        }
    };

    const startSavingProcess = async () => {
        await waitForTimeout(2000); // just do 2 sec to make after reposition to make sure
        const thumbnail = await screenshot(mapRef.current.querySelector(".tk-map > div"), 0.2);
        return await upsert(thumbnail);
    };

    const handleMapIdle = (map) => {
        if (map[REPO_ID]) {
            setIsSaving(true);
            setRepositioning(false);
            map.set(REPO_ID, false);
        }
    };

    const handleInitialize = (conf) => {
        updateAdditional({
            map: conf.map,
            latlangLiteral: conf.latlangLiteral
        });
    };

    useEffect(() => {
        if (isSaving) {
            createPromiseToast(startSavingProcess, {
                render: {
                    pending: () => "Processing site please wait...",
                    success: (data) => {
                        data && typeof onFinish === "function" && onFinish(data.data, !isCreate ? "update" : "create");
                    },
                    error: (error) => {
                        setIsSaving(false);
                        return `Failed to ${!isCreate ? "update" : "create"} Work Site. ${
                            error?.data?.message || "Please try again later or contact support."
                        } `;
                    }
                }
            });
        }
    }, [isSaving]);

    return (
        <BaseUpdateModal
            open={open}
            onChange={onChange}
            onSave={() => {
                if (!form[COORDINATES.name].length) {
                    createToast("Geofence is required.", TOAST_TYPE.ERROR);
                } else {
                    createConfirmAlert({
                        title: !isCreate ? "Update Work Site" : "Create Work Site",
                        content: `Are you sure you want to ${isCreate ? "create" : "update"} this work Site? This cannot be undone.`,
                        onConfirm: async (close) => {
                            if (coordinatesChanged) {
                                repositionMap();
                                setRepositioning(true);
                            } else {
                                setIsSaving(true);
                            }
                            close();
                        }
                    });
                }
            }}
            isLoading={isLoading}
            disableSave={hasChanges}
            styles={MODAL_STYLE}
            isForm
        >
            <div className={createClass(BASE_CLASS)}>
                <div className={createClass("__inner flex column gap-1", BASE_CLASS)}>
                    {!isGettingWorkSites && (
                        <SectionCollapseWarning show={isIncomplete || (!isCreate && !isEditAllowed)}>
                            {isIncomplete ? (
                                <span>Site information is incomplete, must have a supervisor, manager and a work type to be able to use.</span>
                            ) : (
                                <span>
                                    Editing capabilities are limited while the work site is active. Editing will be available before{" "}
                                    {allowedTime.before}
                                    &nbsp;or after {allowedTime.after}.
                                </span>
                            )}
                        </SectionCollapseWarning>
                    )}
                    <div className={createClass("__inner-row flex column gap-1", BASE_CLASS)} style={{ marginBottom: "3rem" }}>
                        {createGroup({
                            base: BASE_CLASS,
                            title: "Site Information",
                            body: (
                                <>
                                    <Input
                                        label={TITLE.label}
                                        name={TITLE.name}
                                        onChange={handleChange}
                                        value={form[TITLE.name] || ""}
                                        required={TITLE.required}
                                        isLoading={isGettingWorkSites}
                                    />
                                    <Input
                                        label={LOCATION.label}
                                        name={LOCATION.name}
                                        onChange={handleChange}
                                        value={form[LOCATION.name] || ""}
                                        required={LOCATION.required}
                                        isLoading={isGettingWorkSites}
                                    />

                                    <EmployeeSelectLazy
                                        label={MANAGERS.label}
                                        name={MANAGERS.name}
                                        onChange={(val) =>
                                            handleChange({
                                                target: { name: MANAGERS.name, value: val }
                                            })
                                        }
                                        value={form[MANAGERS.name]}
                                        isLoading={isGettingWorkSites}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        allowSelectOnShift
                                        isMulti
                                        isOutlined
                                        disabledOutline
                                    />
                                    <EmployeeSelectLazy
                                        label={SUPERVISORS.label}
                                        name={SUPERVISORS.name}
                                        onChange={(val) => handleChange({ target: { name: SUPERVISORS.name, value: val } })}
                                        value={form[SUPERVISORS.name]}
                                        isLoading={isGettingWorkSites}
                                        isClearable={false}
                                        backspaceRemovesValue={false}
                                        allowSelectOnShift
                                        isMulti
                                        isOutlined
                                        disabledOutline
                                    />
                                    {!noworkdetail && (
                                        <WorkDetailSelectLazy
                                            label={WORK_DETAIL.label}
                                            name={WORK_DETAIL.name}
                                            onChange={(val) => handleChange({ target: { name: WORK_DETAIL.name, value: val } })}
                                            value={form[WORK_DETAIL.name]}
                                            isDisabled={!isEditAllowed}
                                            isLoading={isGettingWorkSites}
                                            isOutlined
                                            disabledOutline
                                            isClearable
                                        />
                                    )}
                                </>
                            )
                        })}
                        <div ref={mapRef} className="geo-map-update">
                            {!isGettingWorkSites &&
                                createGroup({
                                    base: BASE_CLASS,
                                    title: "Modify Geofence",
                                    body: (
                                        <>
                                            <GoogleMap
                                                id={isCreate ? "createmap" : "updatemap" + (id || "")}
                                                style={{ height: "30rem" }}
                                                coordinates={[{ title: form[TITLE.name], polygon: form?.[COORDINATES.name] || [] }]}
                                                zoom={{ default: 12 }}
                                                onInitialize={handleInitialize}
                                                onChange={handleMapChange}
                                                onIdle={handleMapIdle}
                                                allowedPolyCreateCount={1}
                                                editable={!isSaving && isEditAllowed}
                                                isLoading={isGettingWorkSites}
                                                onlyMap={isSaving}
                                            />
                                        </>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    setting: PropTypes.object,
    noworkdetail: PropTypes.bool
};

export default UpdateModal;
