export const BASE_CLASS = "tk-employees-submitted-forms";
export const BASE_CLASS_SEND_FORM_MODAL = BASE_CLASS + "__modal-content-send-form";
export const SUBMITTED_FORM_FOLLOW_UP_NOTICE = 2; // in days; follow up will be available if more than 2 days

export const FIELD = {
    STATUS: "name"
};

export const FIELD_OBJECT = {
    [FIELD.STATUS]: {
        label: "Status",
        default: "",
        required: true
    }
};

export const SUBMITTED_FORM_CLASS = {
    NORMAL: "NORMAL",
    WORK_HISTORY: "WORK_HISTORY",
    LEAVE: "LEAVE"
};

export const SUBMITTED_FORM_TYPE = {
    CUSTOM: "CUSTOM",
    WH_OVERTIME: "WH_OVERTIME", // update only the OVERTIME section of work history using the historyId
    WH_ABSENT: "WH_ABSENT", // create a NEW HISTORY record for that employee
    WH_TIMING: "WH_TIMING", // update TIMING section of work history using the historyId
    WH_OT_OFF_DAY: "WH_OT_OFF_DAY" // create a NEW HISTORY record for that employee on OT_OFF_DAY
};

export const SUBMITTED_FORM_STATUS = {
    PENDING: "PENDING", // initial status
    APPROVED: "APPROVED", // usually the end status
    REJECTED: "REJECTED", // usually the end status
    APPEALED: "APPEALED",
    CANCELED: "CANCELED", // usually an end status
    RECEIVED: "RECEIVED", // initial status for form sent by the admin NEW
    COMPLETED: "COMPLETED" // only to use by the WebAdmins NEW
};

export const FILTER_TYPE = {
    FORM_TYPE: 0x1,
    FORM_STATUS: 0x2,
    DATE: 0x3,
    EMPLOYEE: 0x4
};

/** The user type that sends the new status */
export const SUBMITTED_FORM_USER_TYPE = {
    DIRECT_SV: "DIRECT_SV",
    DIRECT_MG: "DIRECT_MG",
    DEPT_SV: "DEPT_SV",
    DEPT_MG: "DEPT_MG",
    SITE_SV: "SITE_SV",
    SITE_MG: "SITE_MG",
    ADMINS: "ADMINS",
    TOP_LEVEL: "TOP_LEVEL",
    OWNER: "OWNER"
};

export const FINAL_STATUSES = [SUBMITTED_FORM_STATUS.CANCELED, SUBMITTED_FORM_STATUS.REJECTED, SUBMITTED_FORM_STATUS.COMPLETED];

export const TIMELINE_ACTION = {
    VIEW_REMARKS: 0x1,
    VIEW_ATTACHMENTS: 0x2
};

export const SUBMITTED_FORM_ACTION_TYPE = {
    CANCEL: 0x1,
    REJECT: 0x2,
    COMPLETE: 0x3,
    FOLLOW_UP: 0x4
};
