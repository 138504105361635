import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { FORM_FIELD_TYPE, NO_VALIDATION_GROUP, VALIDATION_FIELD } from "./const";
import { useManageValidationInfo } from "./hooks";
import Text from "../../../common/components/extra/typography/Text";

function DroppedItem({ id, children, data, onSelect, inline, error }) {
    const { validation, hasLabelKey, isEmployeeInfoGroup, isForAdmin, isRequired } = useManageValidationInfo(data);

    const heasHeader = useMemo(() => {
        return !NO_VALIDATION_GROUP.includes(data.group) && hasLabelKey && !isForAdmin && !isEmployeeInfoGroup;
    }, [data.group, hasLabelKey, isEmployeeInfoGroup, isForAdmin]);

    const className = useMemo(() => {
        let str = "dropped-item ";
        if (inline) str += "dropped-item--inline ";
        if (isEmployeeInfoGroup) str += "dropped-item--simple ";
        if (!heasHeader) str += "dropped-item--no-header ";
        return str.trim();
    }, [inline, isEmployeeInfoGroup, heasHeader]);

    const renderError = useMemo(() => {
        if (error?.message || (error && typeof error == "string")) {
            return (
                <Text className="danger-color" style={{ marginBottom: ".4rem" }} useSubTextStyle>
                    {error?.message || error}
                </Text>
            );
        }
        return null;
    }, [error]);

    if (!id) {
        return <></>;
    }

    return (
        <div className={className} onClick={onSelect}>
            {heasHeader && (
                <div className="dropped-item__header text-ellipsis">
                    <div className="dropped-item__label fade">
                        <span>
                            {validation?.[VALIDATION_FIELD.LABEL] && <span>{validation?.[VALIDATION_FIELD.LABEL]}</span>}
                            {validation?.[VALIDATION_FIELD.LABEL] && isRequired && <span className="danger-color">*</span>}
                        </span>
                    </div>
                </div>
            )}
            <div className="dropped-item__content" style={isForAdmin ? { width: "100%" } : {}}>
                {children}
            </div>
            {renderError}
        </div>
    );
}

export default DroppedItem;

DroppedItem.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    inline: PropTypes.bool,
    children: PropTypes.any,
    data: PropTypes.object,
    type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE)),
    onSelect: PropTypes.func,
    error: PropTypes.oneOfType([
        PropTypes.shape({
            message: PropTypes.string
        }),
        PropTypes.string
    ])
};
