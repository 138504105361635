import React from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Cancel";
import Input from "../../../common/components/extra/form/Input";
import { createMiniTootlip } from "../../../common/utilities/helper";
import Popover from "../../../common/components/extra/Popover";
import Button from "../../../common/components/extra/Button";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import { FORM_CATEGORY, FIELD, FIELD_OBJECT, FORM_STATUS, USER_LEVEL, FORM_TYPE, FORM_CLASS } from "./const";
import UserLevelSelect from "./UserLevelSelect";
import TemplatesSelectLazy from "./TemplatesSelectLazy";
import { createFormInitials } from "./helper";

function FormDetails({ isCreate, open, setOpen, form, onChange, onTemplateChange, onTemplatesLoad, template, isLoading }) {
    const templateIsClearable = ![FORM_CLASS.YEARLY_APPRAISAL, FORM_CLASS.PROBATIONARY_APPRAISAL, FORM_CLASS.LEAVE_FORM].includes(form[FIELD.CLASS]);
    return (
        <div className="tk-company-forms-upsert__header">
            <div className="group flex gap-1">
                <Popover
                    open={open}
                    onChange={(bool) => setOpen(bool)}
                    content={
                        <div className="flex column gap-1" style={{ width: "25rem", padding: ".5rem" }}>
                            <h3>Edit Form Details</h3>
                            <div className="left flex column gap-05">
                                <Input
                                    name={FIELD.NAME}
                                    label={FIELD_OBJECT[FIELD.NAME].label}
                                    value={form[FIELD.NAME]}
                                    onChange={(e) =>
                                        onChange({
                                            target: {
                                                name: e.target.name,
                                                value: e.target.value
                                            }
                                        })
                                    }
                                    isLoading={isLoading}
                                    subtext={{
                                        hide: !isCreate || !form[FIELD.NAME],
                                        message: `Form Initials: ${createFormInitials(form[FIELD.NAME])}`
                                    }}
                                    useSubTextStyle
                                    required
                                />
                                <SelectConstant
                                    name={FIELD.CLASS}
                                    label={FIELD_OBJECT[FIELD.CLASS].label}
                                    base={FORM_CLASS}
                                    value={form[FIELD.CLASS]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.CLASS,
                                                value: val.value
                                            }
                                        })
                                    }
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <SelectConstant
                                    name={FIELD.TYPE}
                                    label={FIELD_OBJECT[FIELD.TYPE].label}
                                    base={FORM_TYPE}
                                    value={form[FIELD.TYPE]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.TYPE,
                                                value: val.value
                                            }
                                        })
                                    }
                                    isLoading={isLoading}
                                    isDisabled={form[FIELD.CLASS] != FORM_CLASS.NORMAL}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <SelectConstant
                                    name={FIELD.CATEGORY}
                                    label={FIELD_OBJECT[FIELD.CATEGORY].label}
                                    base={FORM_CATEGORY}
                                    value={form[FIELD.CATEGORY]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.CATEGORY,
                                                value: val.value
                                            }
                                        })
                                    }
                                    tooltip={createMiniTootlip(
                                        "If set to EMPLOYEE, form will be sent from the mobile app. If set to HR, form will be sent directly by the Admin to the selected Employee to review"
                                    )}
                                    isLoading={isLoading}
                                    isDisabled={form[FIELD.CLASS] != FORM_CLASS.NORMAL}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <UserLevelSelect
                                    name={FIELD.MIN_USER_LEVEL}
                                    label={FIELD_OBJECT[FIELD.MIN_USER_LEVEL].label}
                                    value={form[FIELD.MIN_USER_LEVEL]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.MIN_USER_LEVEL,
                                                value: val.value
                                            }
                                        })
                                    }
                                    tooltip={createMiniTootlip(
                                        "The minimum user level for submission, display or listing of forms in the mobile app."
                                    )}
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                                <UserLevelSelect
                                    name={FIELD.APPROVAL_LEVELS}
                                    label={FIELD_OBJECT[FIELD.APPROVAL_LEVELS].label}
                                    value={form[FIELD.APPROVAL_LEVELS]}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.APPROVAL_LEVELS,
                                                value: val.map((val) => val.value)
                                            }
                                        })
                                    }
                                    fixed={[USER_LEVEL.THREE]}
                                    exclude={[USER_LEVEL.ZERO]}
                                    tooltip={createMiniTootlip(
                                        "Determines which user levels will be needing the approval of the submitted form. By default, all Level 3 Departments will always have the access for viewing of the submitted forms."
                                    )}
                                    isLoading={isLoading}
                                    isOutlined
                                    disabledOutline
                                    required
                                    isMulti
                                />
                                <SelectConstant
                                    name={FIELD.STATUS}
                                    label={FIELD_OBJECT[FIELD.STATUS].label}
                                    base={FORM_STATUS}
                                    value={form[FIELD.STATUS]}
                                    tooltip={createMiniTootlip("Make this active if you want to use this form immediately.")}
                                    onChange={(val) =>
                                        onChange({
                                            target: {
                                                name: FIELD.STATUS,
                                                value: val.value
                                            }
                                        })
                                    }
                                    subtext={{
                                        message:
                                            "Only one active form is allowed per class. If this form is set to active, other forms in the same class will automatically be set to inactive.",
                                        hide: form[FIELD.CLASS] == FORM_CLASS.NORMAL
                                    }}
                                    isLoading={isLoading}
                                    useSubTextStyle
                                    isOutlined
                                    disabledOutline
                                    required
                                />
                            </div>
                            <div className="footer flex" style={{ justifyContent: "flex-end" }}>
                                <Button beforeExtra={<CloseIcon />} className="danger" onClick={() => setOpen(false)} small mini>
                                    Close
                                </Button>
                            </div>
                        </div>
                    }
                    controlled
                >
                    <Button secondary small mini>
                        <div className="flex align-center">
                            <span className="semi-bold">Edit Form Details</span>
                            <span className="danger-color">*</span>
                            <EditIcon className="pointer primary-color hover-svg" style={{ width: "1.2rem", opacity: ".7" }} />
                        </div>
                    </Button>
                </Popover>
                {!isLoading && (
                    <TemplatesSelectLazy
                        label="Template"
                        style={{ minWidth: "10rem" }}
                        tooltip="The list of templates that shows depends on the selected class"
                        value={template}
                        onChange={onTemplateChange}
                        filterByClass={form[FIELD.CLASS]}
                        isClearable={templateIsClearable}
                        isLoading={isLoading}
                        onDataLoad={onTemplatesLoad}
                        isFilter
                        noborder
                        disabledOutline
                        isOutlined
                        hideOnEmpy
                    />
                )}
            </div>
            <div className="group-extra"></div>
        </div>
    );
}

export default FormDetails;

FormDetails.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    form: PropTypes.object,
    onChange: PropTypes.func,
    onTemplateChange: PropTypes.func,
    isLoading: PropTypes.bool,
    isCreate: PropTypes.bool,
    template: PropTypes.object,
    onTemplatesLoad: PropTypes.func
};
