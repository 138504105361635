import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Input, { INPUT_TYPE } from "../../../../common/components/extra/form/Input";
import { DATE_FORMAT_OPTION, PROBATIONARY_CLASS_PERIOD_SUPPORT, VALIDATION_FIELD, YEARLY_CLASS_PERIOD_SUPPORT } from "../const";
import SelectConstant from "../../../../common/components/extra/select/SelectConstant";

const InputFieldDateRange = ({ inputProps, field, isProbationaryClass, isYearlyClass, onChange }) => {
    const [period, setPeriod] = useState("");

    const { value, ...rest } = inputProps || {};

    const option = DATE_FORMAT_OPTION[field.format];
    const dateFormat = option.fnsFormat;
    const startDate = value?.[0] && new Date(value?.[0]);
    const endDate = value?.[1] && new Date(value?.[1]);
    const isMonthYear = option.key == DATE_FORMAT_OPTION.MONTH_YEAR.key;

    const rangeSpanBase = field[VALIDATION_FIELD.RANGE_SPAN_BASE];
    const rangeSpan = field[VALIDATION_FIELD.RANGE_SPAN];

    const getPeriodValue = (newPeriod) => {
        if (!newPeriod) return "";
        if (isProbationaryClass) return newPeriod == PROBATIONARY_CLASS_PERIOD_SUPPORT.THREE_MONTHS ? 3 : 6;
        return 1;
    };

    useEffect(() => {
        if (isProbationaryClass || isYearlyClass) {
            if (isProbationaryClass)
                setPeriod(rangeSpan <= 3 ? PROBATIONARY_CLASS_PERIOD_SUPPORT.THREE_MONTHS : PROBATIONARY_CLASS_PERIOD_SUPPORT.SIX_MONTHS);
            else setPeriod(YEARLY_CLASS_PERIOD_SUPPORT.YEARLY);
        }
    }, [field[VALIDATION_FIELD.RANGE_SPAN]]);

    const handlePeriodChange = (newPeriod) => {
        setPeriod(newPeriod);
        onChange?.([], { [VALIDATION_FIELD.RANGE_SPAN]: getPeriodValue(newPeriod) });
    };

    const input = (
        <Input
            {...rest}
            type={INPUT_TYPE.DATE}
            dateFormat={dateFormat}
            placeholder="Select Date Range"
            onChange={(value) => onChange(value)}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            isMonthYear={isMonthYear}
            rangeSpanBase={rangeSpanBase || null}
            rangeSpan={rangeSpan || null}
            noFuture={!!field.noFutureDate}
            noPast={!!field.noPastDate}
            selectsRange
            isFixed
        />
    );

    // we will use start and end date if there is a specified range span for UX
    if (field[VALIDATION_FIELD.RANGE_SPAN]) {
        return (
            <div className="flex column gap-05">
                {isProbationaryClass && (
                    <SelectConstant
                        label="Period"
                        base={PROBATIONARY_CLASS_PERIOD_SUPPORT}
                        onChange={(value) => handlePeriodChange(value?.value)}
                        value={period}
                        isOutlined
                        disabledOutline
                        required
                    />
                )}
                {isYearlyClass && (
                    <SelectConstant
                        label="Period"
                        base={YEARLY_CLASS_PERIOD_SUPPORT}
                        onChange={(value) => setPeriod(value.value)}
                        value={period}
                        isOutlined
                        disabledOutline
                        required
                    />
                )}
                <Input
                    type={INPUT_TYPE.DATE}
                    label={<span style={{ whiteSpace: "nowrap" }}>Start Date</span>}
                    onChange={(value) => onChange([value, new Date(moment(value).add(rangeSpan, rangeSpanBase).format())])}
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date(moment().subtract(field[VALIDATION_FIELD.RANGE_SPAN], field[VALIDATION_FIELD.RANGE_SPAN_BASE]))}
                    isMonthYear={isMonthYear}
                    noFuture={!!field.noFutureDate}
                    noPast={!!field.noPastDate}
                    selectsStart
                    required
                    useSubTextStyle
                    isFixed
                />
                <Input
                    type={INPUT_TYPE.DATE}
                    label={<span style={{ whiteSpace: "nowrap" }}>End Date</span>}
                    onChange={() => {}}
                    selected={endDate}
                    startDate={startDate}
                    endDate={endDate}
                    isMonthYear={isMonthYear}
                    subtext={{ message: "End date will be based on start date and the selected period" }}
                    useSubTextStyle
                    selectsEnd
                    disabled
                    isFixed
                />
            </div>
        );
    }

    return input;
};

export default InputFieldDateRange;

InputFieldDateRange.propTypes = {
    onChange: PropTypes.func,
    inputProps: PropTypes.object,
    field: PropTypes.object,
    isProbationaryClass: PropTypes.bool,
    isYearlyClass: PropTypes.bool
};
