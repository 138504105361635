import PropTypes from "prop-types";
import React from "react";
import Input from "../../../../../common/components/extra/form/Input";
import { sanitizeWords } from "../../../../../common/utilities/helper";

function MobileNumberInput({ details, useValue }) {
    if (useValue) {
        return <Input label={sanitizeWords(details.label)} renderValue={details.value} parentStyle={{ gap: ".5rem" }} readOnly alignRight />;
    }
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade italic">Number</span>
            </div>
        </div>
    );
}

export default MobileNumberInput;

MobileNumberInput.propTypes = {
    placeholder: PropTypes.any,
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
