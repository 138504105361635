import React from "react";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { createMissingRequiredFormMessage } from "./helper";

function MissingRequiredFormInfo() {
    const user = useAppSelector(selectUser);
    const missingClasses = user.missingRequiredFormClass || [];

    if (!missingClasses.length) {
        return <></>;
    }

    return (
        <div className="flex align-center gap-03">
            <WarningColorSwitcher tooltip={{ message: createMissingRequiredFormMessage(missingClasses) }} warning />
            <div className="flex column small-font semi-bold">
                <span>Some Features are not available!</span>
            </div>
        </div>
    );
}

export default MissingRequiredFormInfo;
