import { useMemo } from "react";
import cloneDeep from "lodash/cloneDeep";
import { sanitizeWords } from "../../../../../common/utilities/helper";

export const useGetRatingInfo = (ratings = []) => {
    const maxRating = useMemo(() => {
        let temp = {
            level: 0,
            label: ""
        };
        const maxLevel = Math.max(...ratings.map((rating) => rating.level));

        if (maxLevel) {
            temp = cloneDeep(ratings.find((rating) => rating.level == maxLevel) || temp);
        }
        if (temp?.label) {
            temp.label = sanitizeWords(temp.label);
        }
        return temp;
    }, [JSON.stringify(ratings)]);

    return {
        ratings,
        maxRating
    };
};
