import React, { useMemo } from "react";
import PropTypes from "prop-types";
import LetteredAvatar from "./LetteredAvatar";
import { sanitizeWords } from "../../../utilities/helper";
import { createPublicApi } from "../../../../app/apiSlice";
import { selectUser } from "../../../../features/common/slice";
import { useAppSelector } from "../../../hooks/reduxHooks";
import UserLevel from "../../../../features/company/employees/UserLevel";
import Tag, { TAG_TYPE } from "../Tag";

function EmployeeAvatar({ id, filename, fullname, isLoading, designation, userLevel, onShift }) {
    const user = useAppSelector(selectUser);

    const src = useMemo(() => {
        return !isLoading && filename ? createPublicApi({ companyId: user.id, id, filename }) : "";
    }, [isLoading, filename]);

    return (
        <div className="tk-employee-avatar">
            <div className="avatar-wrapper">
                <LetteredAvatar src={src} isLoading={isLoading} name={fullname} size={80} fetchConfig={{ filename, nofetch: true }} hasborder />
            </div>
            <div className="flex column gap-05">
                {!isLoading && (
                    <div className="flex column gap-05">
                        <div className="flex column gap-05 align-center">
                            <div className="name flex gap-05 wrap center">
                                <h3 style={{ fontSize: "1.5em" }}>{sanitizeWords(fullname)}</h3>
                                {onShift && <Tag type={TAG_TYPE.ON_SHIFT} />}
                            </div>
                            <div className="designation small-font">
                                <span className="fade">{designation || "No Assigned Designation"}</span>
                            </div>
                            <div style={{ marginTop: "-5px" }}>
                                <UserLevel value={userLevel} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default EmployeeAvatar;

EmployeeAvatar.propTypes = {
    id: PropTypes.any,
    filename: PropTypes.string,
    fullname: PropTypes.string,
    isLoading: PropTypes.bool,
    designation: PropTypes.string,
    department: PropTypes.string,
    userLevel: PropTypes.number,
    onShift: PropTypes.bool
};
