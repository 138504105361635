export default class Crypto {
    constructor(secret) {
        this.secret = secret || process.env.REACT_APP_CRYPTO_SECRET_KEY;
        this.salt = window.crypto.getRandomValues(new Uint8Array(16)); // Unique salt for key derivation
    }

    // Helper functions to encode and decode strings
    static textEncoder = new TextEncoder();
    static textDecoder = new TextDecoder();

    // Generate a CryptoKey from the password
    async generateKey() {
        const keyMaterial = await window.crypto.subtle.importKey("raw", Crypto.textEncoder.encode(this.secret), "PBKDF2", false, ["deriveKey"]);

        return window.crypto.subtle.deriveKey(
            {
                name: "PBKDF2",
                salt: this.salt,
                iterations: 100000,
                hash: "SHA-256"
            },
            keyMaterial,
            { name: "AES-GCM", length: 256 },
            true,
            ["encrypt", "decrypt"]
        );
    }

    // Encrypt data
    async encrypt(data) {
        const key = await this.generateKey();
        const iv = window.crypto.getRandomValues(new Uint8Array(12)); // 12-byte IV for AES-GCM

        const encrypted = await window.crypto.subtle.encrypt({ name: "AES-GCM", iv }, key, Crypto.textEncoder.encode(JSON.stringify(data)));

        // Return base64-encoded values for easy storage/transmission
        return {
            iv: btoa(String.fromCharCode(...iv)),
            salt: btoa(String.fromCharCode(...this.salt)),
            encryptedData: btoa(String.fromCharCode(...new Uint8Array(encrypted)))
        };
    }

    // Decrypt data
    async decrypt(encrypted) {
        // Decode the base64-encoded values
        const iv = Uint8Array.from(atob(encrypted.iv), (c) => c.charCodeAt(0));
        this.salt = Uint8Array.from(atob(encrypted.salt), (c) => c.charCodeAt(0));
        const encryptedData = Uint8Array.from(atob(encrypted.encryptedData), (c) => c.charCodeAt(0));

        const key = await this.generateKey();

        const decrypted = await window.crypto.subtle.decrypt({ name: "AES-GCM", iv }, key, encryptedData);

        return JSON.parse(Crypto.textDecoder.decode(decrypted));
    }
}
