import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ArrowRight from "@mui/icons-material/ArrowForward";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import cloneDeep from "lodash/cloneDeep";
import { ReactComponent as ID_SVG } from "../../../assets/images/id-icon.svg";
import { useCompanyUploadFilesMutation, useSignupMutation } from "./companyAPI";
import { useLoadGlobalMutation } from "../../common/api";
import FormAuthTemplate from "../../../common/components/layout/FormAuthTemplate";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Navigation from "../../../common/classes/Navigation";
import Select from "../../../common/components/extra/select/Select";
import Divider from "../../../common/components/extra/Divider";
import {
    TOAST_TYPE,
    containsOnlyArabicCharacters,
    containsOnlyLatinCharacters,
    createConfirmAlert,
    createToast,
    isPhoneValid,
    sanitizeWords,
    transformCountries
} from "../../../common/utilities/helper";
import PasswordStrength from "../../../common/components/extra/passwordStrength/PasswordStrength";
import { UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "../../admin/companies/const";
import ViewModalSub from "../../admin/companies/ViewModalSub";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import Text from "../../../common/components/extra/typography/Text";

const PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY;
const TERMS_CONDITIONS = process.env.REACT_APP_TERMS_CONDITIONS;

const { LICENSE_TRADE, LICENSE_COMMERCIAL, ESTABLISHMENT_ID } = UPLOAD_FIELDS;

function SignUp({ onNext }) {
    const [error, setError] = useState({});
    const [uploads, setUploads] = useState({
        [LICENSE_TRADE.key]: "",
        [LICENSE_COMMERCIAL.key]: "",
        [ESTABLISHMENT_ID.key]: ""
    });
    const [form, setForm] = useState({
        name: "",
        arabic_name: "",
        email: "",
        password: "",
        confirm_password: "",
        industry_type: "",
        address: "",
        country: "",
        employee_count: "",
        contact_name: "",
        contact_number: "",
        contact_email: "",
        contact_position: "",
        license_trade_number: "",
        license_commercial_number: "",
        establishment_id: ""
    });
    const [viewObject, setViewObject] = useState({ type: null, data: null });
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [mobileCode, setMobileCode] = useState("");

    const [signup, { isLoading: signUpLoading }] = useSignupMutation();
    const [loadGlobal, { data, isLoading: loadGlobalLoading }] = useLoadGlobalMutation();
    const [uploadFiles, { isLoading: loadUploadFiles }] = useCompanyUploadFilesMutation();

    const isLoading = signUpLoading || loadGlobalLoading || loadUploadFiles;
    const industriesData = (data && data.data && data.data.industries) || [];
    const countries = (data && data.data && data.data.countries) || [];
    const industries = industriesData.map((ind) => ({ ...ind, value: ind.id, label: ind.name }));
    const countriesOpt = transformCountries(countries);
    const hasError = Object.values(error).filter(Boolean).length > 0;

    const updateError = (name, message) => setError({ ...error, [name]: message });

    useEffect(() => {
        loadGlobal();
    }, []);

    const validate = () => {
        try {
            const clone = cloneDeep(form);
            // check if number is already in correct format
            const phone = isPhoneValid(clone.contact_number);
            let isValid = phone.isValid;
            if (!isValid && mobileCode) {
                const newMobileNumber = mobileCode + clone.contact_number;
                isValid = isPhoneValid(newMobileNumber).isValid;
                if (!isValid) {
                    throw new Error("Contact number is not valid.");
                } else {
                    clone.contact_number = newMobileNumber;
                }
            } else {
                const splitphone = phone.phoneNumber.split(phone.countryCode);
                splitphone[0] = mobileCode;
                const newphone = splitphone.join("");
                if (isPhoneValid(newphone).isValid) {
                    clone.contact_number = newphone;
                } else {
                    throw new Error("Contact number is not valid.");
                }
            }
            if (clone.password !== clone.confirm_password) {
                throw new Error("Password do not match!");
            }
            if (!agreeTerms) {
                throw new Error("Privacy and terms and conditions should be checked.");
            }
            delete clone.confirm_password;
            setError({});
            return clone;
        } catch (error) {
            updateError("all", error.message);
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    const startupload = async (id) => {
        const formData = new FormData();
        for (const field in uploads) {
            if (Object.hasOwnProperty.call(uploads, field)) {
                const file = uploads[field];
                file && formData.append(field, file);
            }
        }
        const result = await uploadFiles({ body: formData, extraPath: id, formData: true });
        if (result.error) {
            throw new Error(result?.error?.data?.message || "Something went wrong!. Please try again later.");
        }
        return result;
    };

    const handleSubmit = async (newdata = {}) => {
        try {
            const response = await signup({ body: newdata });
            if (response.error) {
                throw new Error(response.error.data.message || "Something went wrong!. Please try again later.");
            }
            const respdata = response.data.data;
            const compid = respdata.id;
            await startupload(compid);
            typeof onNext === "function" && onNext(respdata.code);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleUploadsChange = (e) => {
        const name = e.target.name;
        const fileObj = e.target?.files?.[0];
        setUploads({ ...uploads, [name]: fileObj || "" });
    };

    const handleChange = (e) => {
        const name = e.target.name;
        const code = e.target?.mobileCode;
        let value = e.target.value;

        const hasFormError = error[name];

        switch (name) {
            case "contact_number": {
                setMobileCode(code);
                break;
            }
            case "name": {
                if (value && !containsOnlyLatinCharacters(value)) {
                    updateError(name, "Must only contain English words Punctuations and Numeric values.");
                } else {
                    hasFormError && updateError(name, "");
                }
                break;
            }
            case "arabic_name": {
                if (value && !containsOnlyArabicCharacters(value)) {
                    updateError(name, "Must only contain Arabic words Punctuations and Numeric values.");
                } else {
                    hasFormError && updateError(name, "");
                }
                break;
            }
            default:
                if (hasFormError) {
                    updateError(name, "");
                }
                break;
        }
        if (error.all) {
            updateError("all", "");
        }
        setForm({ ...form, [name]: value });
    };

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    return (
        <div className="tk-signup" style={{ pointerEvents: isLoading ? "none" : "auto" }}>
            <FormAuthTemplate
                styles={{
                    title: { textAlign: "left" },
                    subtext: { textAlign: "left", margin: "unset", maxWidth: "unset" }
                }}
                title="Let's Get Started!"
                subtext={
                    <div className="tk-signup__subtext flex column gap-05 w100">
                        <Link to={Navigation.Routes.SIGNIN.path}>
                            <strong className="flex align-center gap-05">
                                Login to your Account <ArrowRight style={{ width: "1.2rem" }} />
                            </strong>
                        </Link>
                        <Divider />
                        <div className="flex column">
                            <span className="bold fade">New to Timekeeper?</span>
                            <p>Create your account to unlock all the powerfull features that Timekeeper has to offer!</p>
                            <p style={{ marginTop: 0 }}>
                                Join now, experience the ultimate tool for efficient employees management and start boosting your productivity
                                effortlessly
                            </p>
                        </div>
                    </div>
                }
                submitLabel={<span style={{ whiteSpace: "nowrap" }}>{`${isLoading ? "Signing..." : "Sign Up"}`}</span>}
                onSubmit={() => {
                    const newdata = validate();
                    if (newdata) {
                        createConfirmAlert({
                            title: "Confirm Signup",
                            content: "Kindly verify the accuracy of the provided details before proceeding further.",
                            onConfirm: async (close) => {
                                close();
                                await handleSubmit(newdata);
                            }
                        });
                    }
                }}
                footer={
                    <div className="tk-signup__footer">
                        <Link to={Navigation.Routes.SIGNIN.path}>
                            Already have an account? <strong>Login</strong>
                        </Link>
                    </div>
                }
                buttonExtra={
                    <div className="tk-signup__terms flex align-center">
                        <Input type={INPUT_TYPE.TOGGLE} value={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} />
                        <span>
                            I agree with all&nbsp;
                            <a href={TERMS_CONDITIONS} target="_blank" rel="noopener noreferrer">
                                <strong>Terms and Conditions</strong>
                            </a>
                            &nbsp;and&nbsp;
                            <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                                <strong>Privacy Policies</strong>
                            </a>
                            &nbsp;of TimeKeeper.
                        </span>
                    </div>
                }
                isLoading={isLoading}
                disabled={hasError}
                customSubtext
            >
                <div className="flex column gap-2">
                    <section className="flex column gap-05">
                        <SectionCollapseError style={{ marginBottom: "1rem", textAlign: "left" }} show={hasError}>
                            <div className="flex column">
                                <span className="semi-bold">Sign Up Error</span>
                                <ul style={{ paddingLeft: "2rem", paddingTop: ".5rem" }}>
                                    {Object.keys(error).map((key, i) =>
                                        error[key] && key == "all" ? (
                                            error[key].split(",").map((message, i) => (
                                                <li key={i} style={{ listStyle: "disc" }}>
                                                    {message}
                                                </li>
                                            ))
                                        ) : (
                                            <li key={i} style={{ listStyle: "disc" }}>
                                                {sanitizeWords(key) + ":"}{" "}
                                                {error[key].split(",").map((message, i) => (
                                                    <li key={i} style={{ listStyle: "disc", marginLeft: "1rem" }}>
                                                        {message}
                                                    </li>
                                                ))}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </SectionCollapseError>
                        <Divider title="Login Credentials" />
                        <Input
                            type={INPUT_TYPE.EMAIL}
                            name="email"
                            placeholder="Email*"
                            value={form.email}
                            onChange={handleChange}
                            autoComplete="username"
                            disabled={isLoading}
                            required
                            autoFocus
                        />
                        <section className="flex gap-05 wrap">
                            <PasswordStrength
                                name="password"
                                placeholder="Password*"
                                value={form.password}
                                onChange={handleChange}
                                parentStyle={{ flex: "50%" }}
                                disabled={isLoading}
                                required
                            />
                            <PasswordStrength
                                name="confirm_password"
                                placeholder="Confirm Password*"
                                value={form.confirm_password}
                                onChange={handleChange}
                                parentStyle={{ flex: "50%" }}
                                disabled={isLoading}
                                required
                                noMeter
                            />
                        </section>
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="Company Details" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="arabic_name"
                            placeholder="Company Name (Arabic)"
                            value={form.arabic_name}
                            onChange={handleChange}
                            autoComplete="arabic_name"
                            minLength={6}
                            disabled={isLoading}
                            subtext={{
                                message: <Text useSubTextStyle>Only Numeric, Arabic words and Punctuations are accepted.</Text>
                            }}
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="name"
                            placeholder="Company Name (English)*"
                            value={form.name}
                            onChange={handleChange}
                            autoComplete="name"
                            minLength={6}
                            disabled={isLoading}
                            subtext={{
                                message: <Text useSubTextStyle>Only Numeric, English words and Punctuations are accepted.</Text>
                            }}
                            required
                        />
                        <Select
                            name="industry_type"
                            placeholder="Company Industry*"
                            value={industries.find((ind) => ind.value === form.industry_type) || form.industry_type}
                            style={{ flex: "30%" }}
                            options={industries}
                            onChange={(d) => handleChange({ target: { name: "industry_type", value: d.value } })}
                            isDisabled={isLoading}
                            required
                        />
                        <Input
                            type={INPUT_TYPE.NUMBER}
                            name="employee_count"
                            placeholder="Number of Employees*"
                            value={form.employee_count}
                            onChange={handleChange}
                            autoComplete="employee_count"
                            disabled={isLoading}
                            required
                        />
                        <section className="flex column gap-05 wrap">
                            <Input
                                type={INPUT_TYPE.TEXT}
                                name="address"
                                placeholder="Company Address*"
                                value={form.address}
                                onChange={handleChange}
                                autoComplete="address"
                                parentStyle={{ flex: "60%" }}
                                disabled={isLoading}
                                required
                            />
                            <Select
                                name="country"
                                placeholder="Country*"
                                value={countriesOpt.find((fc) => fc.cca2 === form.country) || form.country}
                                options={countriesOpt}
                                style={{ flex: "30%" }}
                                onChange={(d) => handleChange({ target: { name: "country", value: d.cca2 } })}
                                isDisabled={isLoading}
                                required
                            />
                        </section>
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="License Trade Details" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="license_trade_number"
                            placeholder="Number (ID)*"
                            value={form.license_trade_number}
                            onChange={handleChange}
                            autoComplete="license_trade_number"
                            minLength={3}
                            disabled={isLoading}
                            required
                        />
                        <Input
                            type={INPUT_TYPE.UPLOAD}
                            name={LICENSE_TRADE.key}
                            icon={<ID_SVG />}
                            onChange={handleUploadsChange}
                            onView={() =>
                                handleViewChange({
                                    type: VIEW_MODAL_TYPE.LICENSE_TRADE_COPY,
                                    data: {
                                        src: uploads[LICENSE_TRADE.key] && URL.createObjectURL(uploads[LICENSE_TRADE.key]),
                                        filename: uploads[LICENSE_TRADE.key].name
                                    }
                                })
                            }
                            value={uploads[LICENSE_TRADE.key]}
                            accept={LICENSE_TRADE.accepts}
                            sizeLimit={LICENSE_TRADE.size}
                            disabled={isLoading}
                            haslabel
                            required
                        />
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="License Commercial Details" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="license_commercial_number"
                            placeholder="Number (ID)*"
                            value={form.license_commercial_number}
                            onChange={handleChange}
                            autoComplete="license_commercial_number"
                            minLength={3}
                            disabled={isLoading}
                            required
                        />
                        <Input
                            type={INPUT_TYPE.UPLOAD}
                            name={LICENSE_COMMERCIAL.key}
                            icon={<ID_SVG />}
                            onChange={handleUploadsChange}
                            onView={() =>
                                handleViewChange({
                                    type: VIEW_MODAL_TYPE.LICENSE_COMMERCIAL_COPY,
                                    data: {
                                        src: uploads[LICENSE_COMMERCIAL.key] && URL.createObjectURL(uploads[LICENSE_COMMERCIAL.key]),
                                        filename: uploads[LICENSE_COMMERCIAL.key].name
                                    }
                                })
                            }
                            value={uploads[LICENSE_COMMERCIAL.key]}
                            accept={LICENSE_COMMERCIAL.accepts}
                            sizeLimit={LICENSE_COMMERCIAL.size}
                            disabled={isLoading}
                            haslabel
                            required
                        />
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="Establishment Details" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="establishment_id"
                            placeholder="Number (ID)*"
                            value={form.establishment_id}
                            onChange={handleChange}
                            autoComplete="establishment_id"
                            minLength={6}
                            disabled={isLoading}
                            required
                        />
                        <Input
                            type={INPUT_TYPE.UPLOAD}
                            name={ESTABLISHMENT_ID.key}
                            icon={<ID_SVG />}
                            onChange={handleUploadsChange}
                            onView={() =>
                                handleViewChange({
                                    type: VIEW_MODAL_TYPE.ESTABLISHMENT_ID_COPY,
                                    data: {
                                        src: uploads[ESTABLISHMENT_ID.key] && URL.createObjectURL(uploads[ESTABLISHMENT_ID.key]),
                                        filename: uploads[ESTABLISHMENT_ID.key].name
                                    }
                                })
                            }
                            value={uploads[ESTABLISHMENT_ID.key]}
                            accept={ESTABLISHMENT_ID.accepts}
                            sizeLimit={ESTABLISHMENT_ID.size}
                            disabled={isLoading}
                            haslabel
                            required
                        />
                    </section>
                    <section className="flex column gap-05">
                        <Divider title="Contact Details" />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="contact_name"
                            placeholder="Contact Person Name*"
                            value={form.contact_name}
                            onChange={handleChange}
                            autoComplete="contact_name"
                            minLength={3}
                            disabled={isLoading}
                            required
                        />
                        <Input
                            type={INPUT_TYPE.EMAIL}
                            name="contact_email"
                            placeholder="Contact Email*"
                            value={form.contact_email}
                            onChange={handleChange}
                            autoComplete="contact_email"
                            disabled={isLoading}
                            required
                        />
                        <Input
                            type={INPUT_TYPE.MOBILE}
                            name="contact_number"
                            placeholder="Contact Number*"
                            onChange={handleChange}
                            style={{ minWidth: "8rem" }}
                            value={form.contact_number}
                            disabled={isLoading}
                            menuPlacement="top"
                            portalCodes
                            required
                        />
                        <Input
                            type={INPUT_TYPE.TEXT}
                            name="contact_position"
                            placeholder="Contact Position*"
                            value={form.contact_position}
                            onChange={handleChange}
                            autoComplete="contact_position"
                            minLength={3}
                            disabled={isLoading}
                            required
                        />
                    </section>
                </div>
            </FormAuthTemplate>
            {!!viewObject.type && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                    nofetch
                />
            )}
        </div>
    );
}

SignUp.propTypes = {
    onNext: PropTypes.func
};

export default SignUp;
