import React, { useState } from "react";
import PropTypes from "prop-types";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { createConfirmAlert, createGroup } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpsertCompanyAnnouncements } from "./hooks";
import { FIELD, FIELD_OBJECT, UPDATE_MODAL_BASE_CLASS } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import Select from "../../../common/components/extra/select/Select";
import Button from "../../../common/components/extra/Button";

function UpdateModal({ open, onClose, onBack, onFinish, id }) {
    const isCreate = !id;

    const [form, updateForm, { upsert, hasChanges, isGettingDetails, isUpserting, config }] = useUpsertCompanyAnnouncements(id);
    const [error, setError] = useState({ all: null });

    const disableSave = isGettingDetails || (!isCreate && !hasChanges) || isUpserting;

    const handleSave = async ({ isPublish } = {}) => {
        const result = await upsert({ isPublish });
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result.error) {
            setError({ ...error, all: result.error.message });
        }
        return result.error;
    };

    const handleFormChange = ({ name, value } = {}) => updateForm({ [name]: value });

    const footAfterExtra = (
        <>
            <Button
                className="green"
                onClick={() =>
                    createConfirmAlert({
                        title: !isCreate ? "Update Announcement" : "Create Announcement",
                        content: `Are you sure you want to ${
                            isCreate ? "create" : "update"
                        } this record and publish the announcement to all employees? This cannot be undone.`,
                        onConfirm: async (close) => {
                            close();
                            const hasError = await handleSave({ isPublish: true });
                            if (!hasError) {
                                onBack();
                            }
                        }
                    })
                }
                beforeExtra={<NotificationsActiveIcon />}
                disabled={disableSave}
                small
                mini
            >
                Save & Announce
            </Button>
        </>
    );

    return (
        <BaseUpdateModal
            className={UPDATE_MODAL_BASE_CLASS}
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={() =>
                createConfirmAlert({
                    title: !isCreate ? "Update Announcement" : "Create Announcement",
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this record? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        const hasError = await handleSave({ isPublish: false });
                        if (!hasError) {
                            onBack();
                        }
                    }
                })
            }
            disableSave={disableSave}
            isLoading={isUpserting}
            footAfterExtra={footAfterExtra}
            isForm
        >
            {createGroup({
                base: UPDATE_MODAL_BASE_CLASS,
                title: `${isCreate ? "Create" : "Update"} Anouncement`,
                body: (
                    <div className="flex column gap-05">
                        <SectionCollapseError show={!!error.all}>{error.all}</SectionCollapseError>
                        <div className="flex column gap-1" style={{ marginTop: "1rem" }}>
                            <Input
                                type={INPUT_TYPE.TEXT}
                                name={FIELD.TITLE}
                                value={form[FIELD.TITLE]}
                                label={FIELD_OBJECT[FIELD.TITLE].label}
                                onChange={(e) => handleFormChange({ name: FIELD.TITLE, value: e.target.value })}
                                required={FIELD_OBJECT[FIELD.TITLE].required}
                                disabled={isGettingDetails}
                                wrapperStyle={{ width: "100%" }}
                            />
                            <Select
                                wrapperStyle={{ width: "100%" }}
                                value={config[FIELD.TYPE]}
                                options={config.typeOpt}
                                label={FIELD_OBJECT[FIELD.TYPE].label}
                                onChange={(target) => handleFormChange({ name: FIELD.TYPE, value: target.value })}
                                disabled={isGettingDetails}
                                required
                                isOutlined
                                disabledOutline
                            />
                            <Input
                                type={INPUT_TYPE.TEXTAREA}
                                placeholder={FIELD_OBJECT[FIELD.CONTENT].placeholder}
                                name={FIELD.CONTENT}
                                label={FIELD_OBJECT[FIELD.CONTENT].label}
                                value={form[FIELD.CONTENT] || FIELD_OBJECT[FIELD.CONTENT].default}
                                parentStyle={{ minHeight: "10rem", maxHeight: "max-content" }}
                                onChange={(e) => handleFormChange({ name: FIELD.CONTENT, value: e.target.value.html })}
                                required={FIELD_OBJECT[FIELD.CONTENT].required}
                                disabled={isGettingDetails}
                                uploadConfig={{ image: { size: FIELD_OBJECT[FIELD.CONTENT].size } }}
                                maxLength={FIELD_OBJECT[FIELD.CONTENT].limit}
                                tooltip={FIELD_OBJECT[FIELD.CONTENT].tooltip}
                                includeImageUpload
                                isRichTextEditor
                                hasHTMLwrapper
                            />
                            <Input
                                type={INPUT_TYPE.TEXTAREA}
                                name={FIELD.DESCRIPTION}
                                label={FIELD_OBJECT[FIELD.DESCRIPTION].label}
                                value={form[FIELD.DESCRIPTION]}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) => handleFormChange({ name: FIELD.DESCRIPTION, value: e.target.value })}
                                maxLength={FIELD_OBJECT[FIELD.DESCRIPTION].limit}
                                required={FIELD_OBJECT[FIELD.DESCRIPTION].required}
                                tooltip={FIELD_OBJECT[FIELD.DESCRIPTION].tooltip}
                                disabled={isGettingDetails}
                            />
                        </div>
                    </div>
                )
            })}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func
};

export default UpdateModal;
