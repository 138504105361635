import { useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { TOAST_TYPE, createToast, isFileObject, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectTableConfig, selectEmployeeLeaveData, setCurrent, setState, defaultConfig } from "./slice";
import {
    useCreateEmployeeLeavesMutation,
    useGetEmployeeLeavesMutation,
    useGetEmployeeLeaveSummaryMutation,
    useLoadAllEmployeeLeavesMutation,
    useUpdateEmployeeLeavesMutation,
    useUpdateRejoinedDetailsEmployeeLeavesMutation,
    useUpdateStatusEmployeeLeavesMutation,
    useUploadProofEmployeeLeavesMutation
} from "./api";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { FIELD } from "./const";
import { selectUserSetting } from "../../common/slice";

export const useGetLeaveExtraInfo = (form) => {
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const isStarted = useMemo(() => {
        if (!form?.[FIELD.START_DATE]) {
            return false;
        }
        const startDate = toTimeWithTimeZone(form?.[FIELD.START_DATE], timezone);
        const today = toTimeWithTimeZone(new Date(), timezone);
        return today.isAfter(startDate);
    }, [form?.[FIELD.START_DATE]]);

    const isEnded = useMemo(() => {
        if (!form?.[FIELD.END_DATE]) {
            return false;
        }
        const endDate = toTimeWithTimeZone(form?.[FIELD.END_DATE], timezone);
        const today = toTimeWithTimeZone(new Date(), timezone);
        return today.isAfter(endDate);
    }, [form?.[FIELD.END_DATE]]);

    return { isStarted, isEnded };
};

export const useGetLeave = (id, { runOnMount } = {}) => {
    const [isMounted, setMounted] = useState(true);
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetEmployeeLeavesMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);

    const fetch = async ({ force } = {}) => {
        try {
            if ((!force && current && current.id === id) || !id) {
                setFetching(false);
                return current;
            }
            if (!fetching) {
                setFetching(true);
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch leave. Please try again later");
            }
            setFetching(false);
            dispatch(setCurrent(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setFetching(false);
        }
    };

    const reset = () => fetch({ force: true });

    const updateCurrent = (newCurrent = {}) =>
        dispatch(
            setCurrent({
                ...current,
                ...(newCurrent || {})
            })
        );

    const clearCurrent = () => {
        dispatch(setCurrent(null));
    };

    useEffect(() => {
        if (runOnMount) {
            if (isMounted) {
                fetch();
            }
        } else if (fetching) {
            setFetching(false);
        }
    }, [isMounted, id]);

    useEffect(() => {
        !isMounted && setMounted(true);
    }, []);

    return [
        current,
        {
            isLoading: fetching,
            updateCurrent,
            fetch,
            reset,
            clearCurrent
        }
    ];
};

export const useGetLeaveSummary = (employeeId, { runOnMount } = {}) => {
    const [isMounted, setMounted] = useState(true);

    const [data, setData] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [getSummary] = useGetEmployeeLeaveSummaryMutation();

    const fetch = async (newEmployeeId) => {
        try {
            let toFetchId = newEmployeeId || employeeId;
            if (!toFetchId) {
                return data;
            }
            if (!fetching) {
                setFetching(true);
            }
            const result = await getSummary({
                extraPath: toFetchId
            });
            if (result.error) {
                throw new Error("Failed to fetch leave summary for employee. Please try again later");
            }
            setData(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (runOnMount) {
            if (isMounted) {
                fetch();
            }
        } else if (fetching) {
            setFetching(false);
        }
    }, [isMounted, employeeId]);

    useEffect(() => {
        !isMounted && setMounted(true);
    }, []);

    return [
        data,
        {
            isLoading: fetching,
            fetch
        }
    ];
};

export const usePaginateLeaves = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, onSort, onUpdate, tableConfig }] = usePaginateFetch(useLoadAllEmployeeLeavesMutation, {
        redux: {
            dataSelector: selectEmployeeLeaveData,
            tableConfigSelector: selectTableConfig,
            currentSelector: selectCurrent,
            setState
        },
        defaultConfig,
        onMountConfig: {},
        runOnMount: !readOnly
    });

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch }];
};

export const useUpsertLeave = (updateId, defaultObject = {}) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [FIELD.EMPLOYEE]: null,
        [FIELD.START_DATE]: null,
        [FIELD.END_DATE]: null,
        [FIELD.REJOINED_DATE]: null,
        [FIELD.STATUS]: null,
        [FIELD.PROOF_FILE]: null,
        [FIELD.REASON]: null,
        [FIELD.REJOINED_NOTES]: null,
        [FIELD.OFFSET_HOURS]: null,
        [FIELD.PAYMENT_TYPE]: null,
        [FIELD.LEAVE_TYPE]: null,
        ...defaultObject
    });

    const [current, { isLoading: isGettingRecord }] = useGetLeave(updateId, { runOnMount: true });

    const [create, { isLoading: createIsLoading }] = useCreateEmployeeLeavesMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateEmployeeLeavesMutation();
    const [uploadProof, { isLoading: isUploading }] = useUploadProofEmployeeLeavesMutation();

    const dispatch = useAppDispatch();

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            const proofFile = clonedform[FIELD.PROOF_FILE];
            const employee = clonedform[FIELD.EMPLOYEE];

            clonedform.employee_id = employee?.id;

            delete clonedform[FIELD.EMPLOYEE];
            delete clonedform[FIELD.RECORDED_DATE];
            delete clonedform[FIELD.REJOINED_DATE];
            delete clonedform[FIELD.STATUS];
            delete clonedform[FIELD.REJOINED_NOTES];
            delete clonedform[FIELD.PROOF_FILE];

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (isFileObject(proofFile)) {
                const formData = new FormData();
                formData.append("leave-proof", proofFile);
                formData.append(
                    "others",
                    JSON.stringify({
                        leaveId: result.data.data.id
                    })
                );
                const uploadres = await uploadProof({
                    formData: true,
                    body: formData,
                    extraPath: clonedform.employee_id
                });
                if (uploadres.error) {
                    throw new Error("Something went wrong and Failed to upload file proof. Please reupload and try again");
                }
                result = uploadres;
            }
            createToast(`Record ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
            dispatch(setCurrent(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} record. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    useEffect(() => {
        const temp = {
            [FIELD.EMPLOYEE]: current?.[FIELD.EMPLOYEE] || form[FIELD.EMPLOYEE],
            [FIELD.START_DATE]: current?.[FIELD.START_DATE] || form[FIELD.START_DATE],
            [FIELD.END_DATE]: current?.[FIELD.END_DATE] || form[FIELD.END_DATE],
            [FIELD.REJOINED_DATE]: current?.[FIELD.REJOINED_DATE] || form[FIELD.REJOINED_DATE],
            [FIELD.STATUS]: current?.[FIELD.STATUS] || form[FIELD.STATUS],
            [FIELD.PROOF_FILE]: current?.[FIELD.PROOF_FILE] || form[FIELD.PROOF_FILE],
            [FIELD.OFFSET_HOURS]: current?.[FIELD.OFFSET_HOURS] || form[FIELD.OFFSET_HOURS],
            [FIELD.PAYMENT_TYPE]: current?.[FIELD.PAYMENT_TYPE] || form[FIELD.PAYMENT_TYPE],
            [FIELD.LEAVE_TYPE]: current?.[FIELD.LEAVE_TYPE] || form[FIELD.LEAVE_TYPE],
            [FIELD.REASON]: current?.[FIELD.REASON] || form[FIELD.REASON],
            [FIELD.REJOINED_NOTES]: current?.[FIELD.REJOINED_NOTES] || form[FIELD.REJOINED_NOTES]
        };
        setForm(temp);
        setOld(temp);
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord,
            isUpserting: createIsLoading || updateIsLoading || isUploading,
            old,
            hasChanges: !!(old && !isCreate && !isEqual(form, old))
        }
    ];
};

export const useUpdateRejoinedDetails = (updateId, details = {}) => {
    const [old, setOld] = useState({
        [FIELD.REJOINED_DATE]: details[FIELD.REJOINED_DATE],
        [FIELD.REJOINED_NOTES]: details[FIELD.REJOINED_NOTES]
    });
    const [form, setForm] = useState({
        [FIELD.REJOINED_DATE]: details[FIELD.REJOINED_DATE],
        [FIELD.REJOINED_NOTES]: details[FIELD.REJOINED_NOTES]
    });

    const [update, { isLoading: updateIsLoading }] = useUpdateRejoinedDetailsEmployeeLeavesMutation();

    const dispatch = useAppDispatch();

    const updateRejoinedDetails = async () => {
        try {
            const result = await update({ body: form, extraPath: updateId });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            createToast("Record updated succesfully.", TOAST_TYPE.SUCCESS);
            dispatch(setCurrent(result.data.data));
            setOld(form);
            return result.data.data;
        } catch (error) {
            createToast(`Failed to update record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    return [
        form,
        updateForm,
        {
            update: updateRejoinedDetails,
            updateIsLoading,
            old,
            hasChanges: !!(old && !isEqual(form, old))
        }
    ];
};

export const useUpdateStatus = (updateId, details = {}) => {
    const [old, setOld] = useState({
        [FIELD.STATUS]: details[FIELD.STATUS]
    });
    const [form, setForm] = useState({
        [FIELD.STATUS]: details[FIELD.STATUS]
    });

    const [update, { isLoading: updateIsLoading }] = useUpdateStatusEmployeeLeavesMutation();

    const dispatch = useAppDispatch();

    const updateStatus = async (newConfig = {}) => {
        try {
            const result = await update({ body: { ...form, ...newConfig }, extraPath: updateId });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            createToast("Record updated succesfully.", TOAST_TYPE.SUCCESS);
            dispatch(setCurrent(result.data.data));
            setOld(form);
            return result.data.data;
        } catch (error) {
            createToast(`Failed to update record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    return [
        form,
        updateForm,
        {
            update: updateStatus,
            updateIsLoading,
            old,
            hasChanges: !!(old && !isEqual(form, old))
        }
    ];
};
