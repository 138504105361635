import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import cloneDeep from "lodash/cloneDeep";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Button from "../../../common/components/extra/Button";
import Select from "../../../common/components/extra/select/Select";
import { sanitizeWords } from "../../../common/utilities/helper";
import {
    LABEL_MAX_LEN,
    TEXT_MAX_LEN,
    CHAR_OPTION_LIMIT,
    FORM_FIELD_TYPE,
    MAX_OPTION_LIMIT,
    VALIDATION_FIELD,
    VALIDATION_LABEL,
    ERROR_QUESTION_KEY,
    FIELD,
    FORM_CLASS,
    FORM_CATEGORY,
    SPECIFIC_FIELD_CONST,
    DATE_FORMAT_OPTION
} from "./const";
import { useManageValidationInfo } from "./hooks";
import InputDynamicList from "../../../common/components/extra/form/InputDynamicList";
import Tag from "../../../common/components/extra/Tag";
import { getQuestionCount, validateField } from "./helper";
import Text from "../../../common/components/extra/typography/Text";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";
import Tooltip from "../../../common/components/extra/Tooltip";

const PARENT_STYLE = {
    minWidth: "auto",
    fontSize: "12px",
    overflow: "unset"
};

const {
    TITLE,
    LABEL,
    VALUE,
    MIN,
    MAX,
    REQUIRED,
    IS_BOLD,
    WITH_FINAL_SCORE_RATING,
    RATINGS,
    IS_COLUMN,
    FOR_ADMIN,
    FORMAT,
    NO_PAST,
    NO_FUTURE,
    RANGE_SPAN,
    RANGE_SPAN_BASE
} = VALIDATION_FIELD;

const RANGE_SPAN_BASE_OPTION = SPECIFIC_FIELD_CONST[FORM_FIELD_TYPE.DATE_RANGE].RANGE_SPAN_BASE;

function Validations({ form, data, onCancel, onChange, onConfirm }) {
    const {
        type,
        name,
        description,
        validation,
        hasTitleKey,
        hasLabelKey,
        hasValueKey,
        hasMinKey,
        hasMaxKey,
        hasRequiredKey,
        hasBoldKey,
        isValueString,
        isValueRTE,
        isValueArray,
        infoText,
        isMediaGroup,
        isFile,
        isQuestionnaireGroup,
        hasFinalScoreKey,
        hasRatingsKey,
        hasIsColumnKey,
        hasForAdminKey,
        hasFormatKey,
        isTypeGroup,
        hasNoPastKey,
        hasNoFutureKey,
        hasRangeSpanKey,
        hasRangeSpanBaseKey
    } = useManageValidationInfo(data);

    const [error, setError] = useState({});

    const formClass = form[FIELD.CLASS];
    const formCategory = form[FIELD.CATEGORY];
    const isHRCategory = formCategory == FORM_CATEGORY.HR;
    const isEmployeeCategory = formCategory == FORM_CATEGORY.EMPLOYEE;
    const isProbationary = formClass == FORM_CLASS.PROBATIONARY_APPRAISAL;
    const isYearly = formClass == FORM_CLASS.YEARLY_APPRAISAL;
    const disableRemoveDynamicList = isProbationary || isYearly;

    // !TODO: @UNSUPPORTED disable forAdmin field temporarily, remove if supported by mobile
    const showAdminInput = false;
    // const showAdminInput = (hasForAdminKey && isHRCategory) || hasIsColumnKey;

    const handleChange = (e) => {
        const extra = {};
        const name = e.target.name;
        let value = e.target.value;

        switch (name) {
            case NO_PAST:
            case NO_FUTURE:
            case IS_COLUMN:
            case FOR_ADMIN:
            case WITH_FINAL_SCORE_RATING:
            case IS_BOLD:
            case REQUIRED:
                value = e.target.checked;
                break;
            case RANGE_SPAN_BASE:
                extra[RANGE_SPAN] = "";
                break;

            default:
                break;
        }
        if (error[name]) {
            let temp = cloneDeep(error || {});
            delete temp[name];
            setError(temp);
        }
        onChange?.(name, value, extra);
    };

    const handleSelectChange = (conf) => {
        if (conf.length >= MAX_OPTION_LIMIT) {
            setError({ [VALUE]: `A max of ${MAX_OPTION_LIMIT} options is allowed.` });
            return;
        }
        const values = conf.map((opt) => opt.value.trim().length);
        if (conf.length && values.find((charcount) => charcount > CHAR_OPTION_LIMIT)) {
            setError({ [VALUE]: `A max of ${CHAR_OPTION_LIMIT} characters is allowed.` });
            return;
        }
        if (error) {
            setError({});
        }
        onChange?.(VALUE, conf);
    };

    useEffect(() => {
        // change the for admin flag to false
        if (isEmployeeCategory && hasForAdminKey && validation[FOR_ADMIN]) {
            handleChange({ target: { name: FOR_ADMIN, value: false } });
        }
    }, [formCategory, hasForAdminKey, validation[FOR_ADMIN]]);

    const titleInput = useMemo(() => {
        if (hasTitleKey) {
            return (
                <Input
                    name={TITLE}
                    label={VALIDATION_LABEL[TITLE]}
                    type={INPUT_TYPE.TEXT}
                    parentStyle={PARENT_STYLE}
                    value={validation[TITLE] || ""}
                    maxLength={LABEL_MAX_LEN}
                    onChange={handleChange}
                    error={[error[TITLE]]}
                    useSubTextStyle
                    required
                />
            );
        }
        return <></>;
    }, [validation[TITLE], error]);

    const labelInput = useMemo(() => {
        if (hasLabelKey) {
            return (
                <Input
                    name={LABEL}
                    label={VALIDATION_LABEL[LABEL]}
                    type={INPUT_TYPE.TEXT}
                    parentStyle={PARENT_STYLE}
                    value={validation[LABEL] || ""}
                    tooltip={infoText[LABEL]}
                    maxLength={LABEL_MAX_LEN}
                    onChange={handleChange}
                    error={[error[LABEL]]}
                    useSubTextStyle
                    required
                />
            );
        }
        return <></>;
    }, [validation[LABEL], error]);

    const transformRenderForQuestions = (item, questionIdx, groupIdx) => {
        const handleQuestionValueChange = (e) => {
            if (error[ERROR_QUESTION_KEY + groupIdx] && error[ERROR_QUESTION_KEY + groupIdx].map((value) => value.index).includes(questionIdx)) {
                setError({
                    ...error,
                    [ERROR_QUESTION_KEY + groupIdx]: error[ERROR_QUESTION_KEY + groupIdx].filter((value) => value.index != questionIdx)
                });
            }
            const name = e.target.name;
            const value = e.target.value;
            const temp = cloneDeep(validation[VALUE]);
            if (!temp?.[groupIdx]?.["questions"]) {
                temp[groupIdx] = { ...temp[groupIdx], questions: [] };
            }
            temp[groupIdx]["questions"][questionIdx][name] = value;
            onChange?.(VALUE, temp);
        };

        return (
            <div className="flex column gap-05 w100">
                <Input
                    name="title"
                    label="Title"
                    parentStyle={PARENT_STYLE}
                    value={sanitizeWords(item.title)}
                    maxLength={50}
                    onChange={handleQuestionValueChange}
                    required
                />
                <Input
                    name="description"
                    label="Description"
                    type={INPUT_TYPE.TEXTAREA}
                    parentStyle={{
                        ...PARENT_STYLE,
                        maxHeight: "max-content"
                    }}
                    richTextHeight="5rem"
                    value={item.description || ""}
                    maxLength={TEXT_MAX_LEN}
                    onChange={handleQuestionValueChange}
                />
            </div>
        );
    };

    const transformRenderForGroup = (item, groupIdx) => {
        const handleValueChange = (e) => {
            if (error[VALUE] && error[VALUE].map((value) => value.index).includes(groupIdx)) {
                setError({
                    ...error,
                    [VALUE]: error[VALUE].filter((value) => value.index != groupIdx)
                });
            }
            const name = e.target.name;
            const value = e.target.value;
            const temp = cloneDeep(validation[VALUE]);
            temp[groupIdx][name] = value;
            onChange?.(VALUE, temp);
        };

        const renderQuestionNumberCount = (_, questionIdx) => {
            return (
                <Tag
                    style={{
                        padding: "3px 5px",
                        height: "min-content",
                        marginRight: ".5rem",
                        marginTop: ".3rem",
                        whiteSpace: "nowrap"
                    }}
                >
                    #{(getQuestionCount(validation[VALUE], groupIdx, questionIdx) + "").padStart(2, "0")}
                </Tag>
            );
        };

        return (
            <div className="flex column gap-05 w100">
                <Input
                    name="title"
                    label="Group Title"
                    parentStyle={PARENT_STYLE}
                    value={sanitizeWords(item.title)}
                    maxLength={50}
                    onChange={handleValueChange}
                />
                {item.title && (
                    <Input
                        name="description"
                        label="Description"
                        type={INPUT_TYPE.TEXTAREA}
                        parentStyle={{
                            ...PARENT_STYLE,
                            maxHeight: "max-content"
                        }}
                        richTextHeight="7rem"
                        value={item.description || ""}
                        maxLength={TEXT_MAX_LEN}
                        onChange={handleValueChange}
                    />
                )}
                <InputDynamicList
                    styles={{ parent: { marginTop: ".5rem" } }}
                    defaultObject={{
                        title: "",
                        description: ""
                    }}
                    addLabel="Add Question"
                    label="Questions"
                    value={validation[VALUE][groupIdx]["questions"] || []}
                    onChange={(value) => {
                        const temp = cloneDeep(validation[VALUE]);
                        temp[groupIdx].questions = value;
                        handleChange({
                            target: {
                                name: VALUE,
                                value: temp
                            }
                        });
                    }}
                    transformRender={(item, questionIdx) => transformRenderForQuestions(item, questionIdx, groupIdx)}
                    errors={
                        error?.[ERROR_QUESTION_KEY + groupIdx] &&
                        error?.[ERROR_QUESTION_KEY + groupIdx].reduce((prev, curr) => ({ ...prev, [curr.index]: curr.message }), {})
                    }
                    beforeItemExtra={renderQuestionNumberCount}
                    max={50}
                    min={1}
                    removeLabel="Remove Question"
                    disableRemove={disableRemoveDynamicList}
                    isLayoutColumn
                    useSubBorderColor
                />
            </div>
        );
    };

    const valueInput = useMemo(() => {
        if (hasValueKey && !isTypeGroup) {
            if (isQuestionnaireGroup) {
                return (
                    <InputDynamicList
                        addLabel="Add Group"
                        defaultObject={{
                            title: "",
                            description: "",
                            questions: []
                        }}
                        label={VALIDATION_LABEL[VALUE]}
                        value={validation[VALUE] || []}
                        tooltip={infoText[VALUE]}
                        transformRender={transformRenderForGroup}
                        onChange={(value) => handleChange({ target: { name: VALUE, value } })}
                        min={1}
                        max={50}
                        errors={error?.[VALUE] && error?.[VALUE].reduce((prev, curr) => ({ ...prev, [curr.index]: curr.message }), {})}
                        disableRemove={disableRemoveDynamicList}
                        required
                    />
                );
            } else {
                if (isValueArray) {
                    return (
                        <>
                            <Select
                                name={VALUE}
                                label={VALIDATION_LABEL[VALUE]}
                                tooltip={infoText[VALUE]}
                                value={validation[VALUE] || ""}
                                onChange={(conf) =>
                                    handleSelectChange(
                                        conf.map((option) => ({
                                            value: option.value,
                                            label: option.label
                                        }))
                                    )
                                }
                                styles={{
                                    multiValueLabel: (provided) => ({
                                        ...provided,
                                        wordBreak: "break-word",
                                        overflow: "unset",
                                        textOverflow: "unset",
                                        whiteSpace: "normal"
                                    })
                                }}
                                error={[error[VALUE]]}
                                useSubTextStyle
                                isMulti
                                isClearable
                                disabledOutline
                                isOutlined
                                isCustom
                                required
                            />
                            {!error[VALUE] && <Text useSubTextStyle>A max of {MAX_OPTION_LIMIT} options is allowed.</Text>}
                        </>
                    );
                }
                if (isValueString || isValueRTE) {
                    return (
                        <Input
                            name={VALUE}
                            label={VALIDATION_LABEL[VALUE]}
                            type={INPUT_TYPE.TEXTAREA}
                            parentStyle={{
                                ...PARENT_STYLE,
                                maxHeight: "max-content"
                            }}
                            value={isValueString ? validation[VALUE] : validation[VALUE]?.html || ""}
                            tooltip={infoText[VALUE]}
                            maxLength={type == FORM_FIELD_TYPE.PARAGRAPH ? TEXT_MAX_LEN : LABEL_MAX_LEN}
                            onChange={handleChange}
                            error={[error[VALUE]]}
                            useSubTextStyle
                            isRichTextEditor
                            required
                        />
                    );
                }
            }
        }
        return <></>;
    }, [validation[VALUE], isQuestionnaireGroup, error]);

    const minInput = useMemo(() => {
        if (hasMinKey) {
            return (
                <Input
                    name={MIN}
                    label={VALIDATION_LABEL[MIN]}
                    type={INPUT_TYPE.NUMBER}
                    parentStyle={PARENT_STYLE}
                    value={validation[MIN]}
                    tooltip={infoText[MIN]}
                    onChange={handleChange}
                    constraint={{
                        min: isMediaGroup ? 1 : 0,
                        max: isMediaGroup ? (isFile ? 10 : 5) : null
                    }}
                    error={[error[MIN]]}
                    subtext={{
                        // only show for media group
                        hide: !isMediaGroup,
                        message: `Accepted values are between ${isMediaGroup ? 1 : 0} and ${isMediaGroup ? (isFile ? 10 : 5) : null}.`
                    }}
                    useSubTextStyle
                />
            );
        }
        return <></>;
    }, [validation[MIN], error]);

    const maxInput = useMemo(() => {
        if (hasMaxKey) {
            return (
                <Input
                    name={MAX}
                    label={VALIDATION_LABEL[MAX]}
                    type={INPUT_TYPE.NUMBER}
                    parentStyle={PARENT_STYLE}
                    value={validation[MAX]}
                    tooltip={infoText[MAX]}
                    onChange={handleChange}
                    constraint={{
                        min: isMediaGroup ? 1 : 0,
                        max: isMediaGroup ? (isFile ? 10 : 5) : null
                    }}
                    error={[error[MAX]]}
                    subtext={{
                        // only show for media group
                        hide: !isMediaGroup,
                        message: `Accepted values are between 1 and ${isFile ? 10 : 5}.`
                    }}
                    useSubTextStyle
                />
            );
        }
        return <></>;
    }, [validation[MAX], error]);

    const requiredInput = useMemo(() => {
        if (hasRequiredKey) {
            return (
                <Input
                    name={REQUIRED}
                    label={VALIDATION_LABEL[REQUIRED]}
                    type={INPUT_TYPE.TOGGLE}
                    parentStyle={PARENT_STYLE}
                    value={validation[REQUIRED] || ""}
                    tooltip={infoText[REQUIRED]}
                    onChange={handleChange}
                />
            );
        }
        return <></>;
    }, [validation[REQUIRED]]);

    const ratingInput = useMemo(() => {
        if (hasRatingsKey) {
            const handleRatingInputChange = (e, idx) => {
                if (error[RATINGS] && error[RATINGS].map((rating) => rating.index).includes(idx)) {
                    setError({
                        ...error,
                        [RATINGS]: error[RATINGS].filter((rating) => rating.index != idx)
                    });
                }

                const name = e.target.name;
                const value = e.target.value;
                const temp = cloneDeep(validation[RATINGS]);
                temp[idx][name] = value;
                onChange?.(RATINGS, temp);
            };

            return (
                <InputDynamicList
                    addLabel="Add Rating"
                    defaultObject={{
                        label: "",
                        level: 0
                    }}
                    label={VALIDATION_LABEL[RATINGS]}
                    value={validation[RATINGS] || []}
                    tooltip={infoText[RATINGS]}
                    transformRender={(item, idx) => {
                        const level = idx + 1;
                        return (
                            <div className="flex column">
                                <Input
                                    name="label"
                                    label={`Label (${level})`}
                                    parentStyle={PARENT_STYLE}
                                    value={sanitizeWords(item.label)}
                                    onChange={(e) => handleRatingInputChange(e, idx)}
                                    maxLength={24}
                                    required
                                />
                            </div>
                        );
                    }}
                    onChange={(value) =>
                        handleChange({
                            target: {
                                name: RATINGS,
                                value: value.map((item, idx) => ({ label: item.label, level: idx + 1 }))
                            }
                        })
                    }
                    min={2}
                    max={5}
                    errors={error?.[RATINGS] && error?.[RATINGS].reduce((prev, curr) => ({ ...prev, [curr.index]: curr.message }), {})}
                    required
                    noBorder
                    isLayoutSingle
                />
            );
        }
        return <></>;
    }, [validation[RATINGS], error, onChange]);

    const withFinalScoreRatingInput = useMemo(() => {
        if (hasFinalScoreKey) {
            return (
                <Input
                    name={WITH_FINAL_SCORE_RATING}
                    label={VALIDATION_LABEL[WITH_FINAL_SCORE_RATING]}
                    type={INPUT_TYPE.TOGGLE}
                    parentStyle={PARENT_STYLE}
                    value={validation[WITH_FINAL_SCORE_RATING] || ""}
                    tooltip={infoText[WITH_FINAL_SCORE_RATING]}
                    onChange={handleChange}
                />
            );
        }
        return <></>;
    }, [validation[WITH_FINAL_SCORE_RATING]]);

    const hasForAdminInput = useMemo(() => {
        if (hasForAdminKey && isHRCategory) {
            return (
                <Input
                    name={FOR_ADMIN}
                    label={VALIDATION_LABEL[FOR_ADMIN]}
                    type={INPUT_TYPE.TOGGLE}
                    parentStyle={PARENT_STYLE}
                    value={validation[FOR_ADMIN] || ""}
                    tooltip={infoText[FOR_ADMIN]}
                    onChange={handleChange}
                />
            );
        }
        return <></>;
    }, [validation[FOR_ADMIN], formCategory]);

    const isColumnInput = useMemo(() => {
        if (hasForAdminKey && !validation[FOR_ADMIN]) {
            return <></>;
        }
        if (hasIsColumnKey) {
            return (
                <Input
                    name={IS_COLUMN}
                    label={VALIDATION_LABEL[IS_COLUMN]}
                    type={INPUT_TYPE.TOGGLE}
                    parentStyle={PARENT_STYLE}
                    value={validation[IS_COLUMN] || ""}
                    tooltip={infoText[IS_COLUMN]}
                    onChange={handleChange}
                />
            );
        }
        return <></>;
    }, [validation[IS_COLUMN], validation[FOR_ADMIN], hasForAdminKey]);

    const boldInput = useMemo(() => {
        if (hasForAdminKey && !validation[FOR_ADMIN]) {
            return <></>;
        }
        if (hasBoldKey) {
            return (
                <Input
                    name={IS_BOLD}
                    label={VALIDATION_LABEL[IS_BOLD]}
                    type={INPUT_TYPE.TOGGLE}
                    parentStyle={PARENT_STYLE}
                    value={validation[IS_BOLD] || ""}
                    tooltip={infoText[IS_BOLD]}
                    onChange={handleChange}
                />
            );
        }
        return <></>;
    }, [validation[IS_BOLD], validation[FOR_ADMIN], hasForAdminKey]);

    const formatInput = useMemo(() => {
        if (hasFormatKey) {
            return (
                <SelectConstant
                    name={FORMAT}
                    label={VALIDATION_LABEL[FORMAT]}
                    base={Object.keys(DATE_FORMAT_OPTION).reduce((prev, curr) => ({ ...prev, [curr]: curr }), {})}
                    value={validation[FORMAT] || ""}
                    parentStyle={PARENT_STYLE}
                    transformBase={(opt) => ({
                        value: opt,
                        label: <span className="small-font semi-bold">{sanitizeWords(opt)}</span>
                    })}
                    onChange={(value) =>
                        handleChange({
                            target: {
                                name: FORMAT,
                                value: value.value
                            }
                        })
                    }
                    tooltip={infoText[FORMAT]}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    required
                />
            );
        }
        return <></>;
    }, [validation[FORMAT]]);

    const hasNoPastInput = useMemo(() => {
        if (hasNoPastKey) {
            return (
                <Input
                    name={NO_PAST}
                    label={VALIDATION_LABEL[NO_PAST]}
                    type={INPUT_TYPE.TOGGLE}
                    parentStyle={PARENT_STYLE}
                    value={validation[NO_PAST] || ""}
                    tooltip={infoText[NO_PAST]}
                    onChange={handleChange}
                />
            );
        }
        return <></>;
    }, [validation[NO_PAST]]);

    const hasNoFutureInput = useMemo(() => {
        if (hasNoFutureKey) {
            return (
                <Input
                    name={NO_FUTURE}
                    label={VALIDATION_LABEL[NO_FUTURE]}
                    type={INPUT_TYPE.TOGGLE}
                    parentStyle={PARENT_STYLE}
                    value={validation[NO_FUTURE] || ""}
                    tooltip={infoText[NO_FUTURE]}
                    onChange={handleChange}
                />
            );
        }
        return <></>;
    }, [validation[NO_FUTURE]]);

    const rangeSpanBaseInput = useMemo(() => {
        if (hasRangeSpanBaseKey) {
            return (
                <SelectConstant
                    name={RANGE_SPAN_BASE}
                    label={VALIDATION_LABEL[RANGE_SPAN_BASE]}
                    base={RANGE_SPAN_BASE_OPTION}
                    value={validation[RANGE_SPAN_BASE] || ""}
                    parentStyle={PARENT_STYLE}
                    onChange={(value) =>
                        handleChange({
                            target: {
                                name: RANGE_SPAN_BASE,
                                value: value.value
                            }
                        })
                    }
                    tooltip={infoText[RANGE_SPAN_BASE]}
                    menuPortalTarget={document.body}
                    isOutlined
                    disabledOutline
                    isClearable
                />
            );
        }
        return <></>;
    }, [validation[RANGE_SPAN_BASE], validation[RANGE_SPAN]]);

    const rangeSpanInput = useMemo(() => {
        if (hasRangeSpanKey) {
            const rangeSpanBase = validation[RANGE_SPAN_BASE];
            const max =
                rangeSpanBase == RANGE_SPAN_BASE_OPTION.YEAR
                    ? 5
                    : rangeSpanBase == RANGE_SPAN_BASE_OPTION.MONTH
                      ? 12
                      : rangeSpanBase == RANGE_SPAN_BASE_OPTION.DAY
                        ? 365
                        : null;

            return (
                <Input
                    name={RANGE_SPAN}
                    label={VALIDATION_LABEL[RANGE_SPAN]}
                    type={INPUT_TYPE.NUMBER}
                    parentStyle={PARENT_STYLE}
                    value={validation[RANGE_SPAN]}
                    tooltip={infoText[RANGE_SPAN]}
                    onChange={handleChange}
                    constraint={{
                        min: 0,
                        max
                    }}
                    error={[error[RANGE_SPAN]]}
                    subtext={{
                        message: `Allowed Span: 0 to ${max} ${rangeSpanBase}(s)`,
                        hide: !max
                    }}
                    disabled={!validation[RANGE_SPAN_BASE]}
                    required={validation[RANGE_SPAN_BASE]}
                    useSubTextStyle
                />
            );
        }
        return <></>;
    }, [validation[RANGE_SPAN], validation[RANGE_SPAN_BASE], error]);

    const handleConfirm = () => {
        const fieldObj = validateField(data);
        if (!fieldObj.error) {
            onCancel?.();
            setError({});
        } else {
            setError(fieldObj.error);
        }
        onConfirm?.({ error: fieldObj.error });
    };

    return (
        <div className="form-validations">
            <span className="fade bold">Element Details</span>
            <div className="form-validations__box flex column gap-05">
                <div className="form-validations__content flex column">
                    <div className="flex column gap-05">
                        <div className="flex column gap-05">
                            <span className="fade small-font">Field Name</span>
                            <p className="small-font" style={{ marginTop: "0" }}>
                                {sanitizeWords(name)}
                            </p>
                        </div>
                        <div className="flex column gap-05">
                            <span className="fade small-font">Description</span>
                            <p className="small-font" style={{ marginTop: "0" }}>
                                {description}
                            </p>
                        </div>
                    </div>
                    <div className="flex column gap-05">
                        {titleInput}
                        {labelInput}
                        {valueInput}
                        {minInput}
                        {maxInput}
                        {requiredInput}
                        {ratingInput}
                        {showAdminInput && hasForAdminInput}
                        {showAdminInput && (
                            <div className="flex column gap-05" style={validation[FOR_ADMIN] ? { paddingLeft: "1.5rem" } : {}}>
                                {isColumnInput}
                                {boldInput}
                            </div>
                        )}
                        {withFinalScoreRatingInput}
                        {formatInput}
                        {hasNoPastInput}
                        {hasNoFutureInput}
                        {hasRangeSpanBaseKey && (
                            <div className="flex column">
                                <Tooltip
                                    className="flex align-center"
                                    message="Specifies the allowed date range."
                                    style={{ marginLeft: ".5rem", marginTop: "1rem", marginBottom: ".5rem" }}
                                    isIcon
                                >
                                    <span className="small-font">Limit Range Span</span>
                                </Tooltip>
                                <div className="flex column" style={{ paddingLeft: "1.5rem" }}>
                                    {rangeSpanBaseInput}
                                    {rangeSpanInput}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-validations__footer flex gap-05 center" style={{ marginTop: "1rem" }}>
                    <Button
                        className="secondary small-font"
                        beforeExtra={<CheckCircleIcon />}
                        onClick={handleConfirm}
                        options={{
                            style: { padding: "0rem .5rem", height: "2rem" }
                        }}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    );
}

Validations.propTypes = {
    type: PropTypes.oneOf(Object.values(FORM_FIELD_TYPE)),
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onConfirm: PropTypes.func,
    form: PropTypes.object,
    data: PropTypes.shape({
        id: PropTypes.any,
        name: PropTypes.string,
        description: PropTypes.string,
        group: PropTypes.string,
        status: PropTypes.string,
        type: PropTypes.string,
        validation: PropTypes.shape({
            max: PropTypes.any,
            min: PropTypes.any,
            value: PropTypes.any,
            label: PropTypes.string,
            required: PropTypes.bool
        })
    })
};

export default Validations;
