import React, { useState } from "react";
import Button from "../../../common/components/extra/Button";
import ViewModalHistory from "../employeeWorkHistories/ViewModal";
import PropTypes from "prop-types";
import { SUBMITTED_FORM_STATUS } from "./const";

function ViewHistoryButton({ id, currentFormStatus }) {
    const [open, setOpen] = useState(false);

    if (!id || currentFormStatus != SUBMITTED_FORM_STATUS.COMPLETED) {
        return <></>;
    }

    return (
        <>
            <Button onClick={() => setOpen(true)} small mini>
                View Work History
            </Button>
            {open && <ViewModalHistory id={id} open={open} onClose={() => setOpen(false)} />}
        </>
    );
}

export default ViewHistoryButton;

ViewHistoryButton.propTypes = {
    id: PropTypes.number,
    currentFormStatus: PropTypes.oneOf(Object.values(SUBMITTED_FORM_STATUS))
};
