import React from "react";
import { useAppSelector } from "../../../common/hooks/reduxHooks";

import { usePaginateEmployeeOtherIncomes } from "../employeesOtherIncomes/hooks";
import { usePaginateEmployeeDeductions } from "../employeesDeductions/hooks";
import { usePaginateEmployeePenalties } from "../employeesPenalties/hooks";

import OtherIncomeFilter from "../employeesOtherIncomes/Filter";
import EmployeeDeductionsFilter from "../employeesDeductions/Filter";
import EmployeePenaltiesFilter from "../employeesPenalties/Filter";
import EmployeeSalaryFilter from "../employeesSalary/Filter";

import {
    selectEmployeeDeductionsData,
    selectFilter as selectDeductionFilter,
    selectTableConfig as selectDeductionTableConfig,
    setTableConfig as setDeductionTableConfig
} from "../employeesDeductions/slice";
import {
    selectEmployeeOtherIncomesData,
    selectFilter as selectOtherIncomeFilter,
    selectTableConfig as selectOtherIncomeTableConfig,
    setTableConfig as setOtherIncomeTableConfig
} from "../employeesOtherIncomes/slice";

import {
    selectEmployeePenaltiesData,
    selectFilter as selectEmployeePenaltiesFilter,
    selectTableConfig as selectEmployeePenaltiesTableConfig,
    setTableConfig as seEmployeePenaltiesTableConfig
} from "../employeesPenalties/slice";

import {
    selectEmployeeSalaryData,
    selectFilter as selectEmployeeSalaryFilter,
    selectTableConfig as selectEmployeeSalaryTableConfig,
    setTableConfig as setEmployeeSalaryTableConfig
} from "../employeesSalary/slice";
import { useSalaryStateManager } from "../employeesSalary/hooks";

export const useOtherIncomeStates = () => {
    const [, { isLoading, onFilter, onSearch }] = usePaginateEmployeeOtherIncomes({
        readOnly: true
    });

    const config = useAppSelector(selectOtherIncomeTableConfig);
    const filter = useAppSelector(selectOtherIncomeFilter);
    const data = useAppSelector(selectEmployeeOtherIncomesData);

    return {
        Filter: <OtherIncomeFilter onFilter={onFilter} isLoading={isLoading} filter={filter} />,
        config,
        filter,
        onFilter,
        onSearch,
        setTableConfig: setOtherIncomeTableConfig,
        searchPlaceholder: "Search employee...",
        data
    };
};

export const useDeductionStates = () => {
    const [, { isLoading, onFilter, onSearch }] = usePaginateEmployeeDeductions({
        readOnly: true
    });

    const config = useAppSelector(selectDeductionTableConfig);
    const filter = useAppSelector(selectDeductionFilter);
    const data = useAppSelector(selectEmployeeDeductionsData);

    return {
        Filter: <EmployeeDeductionsFilter onFilter={onFilter} isLoading={isLoading} filter={filter} />,
        config,
        filter,
        isLoading,
        onFilter,
        onSearch,
        setTableConfig: setDeductionTableConfig,
        searchPlaceholder: "Search employee...",
        data
    };
};

export const usePenaltyStates = () => {
    const [, { isLoading, onFilter, onSearch }] = usePaginateEmployeePenalties({
        readOnly: true
    });

    const config = useAppSelector(selectEmployeePenaltiesTableConfig);
    const filter = useAppSelector(selectEmployeePenaltiesFilter);
    const data = useAppSelector(selectEmployeePenaltiesData);

    return {
        Filter: <EmployeePenaltiesFilter onFilter={onFilter} isLoading={isLoading} filter={filter} />,
        config,
        filter,
        isLoading,
        onSearch,
        onFilter,
        setTableConfig: seEmployeePenaltiesTableConfig,
        searchPlaceholder: "Search employee...",
        data
    };
};

export const useSalaryStates = () => {
    const [, { isLoading, onFilter, onSearch }] = useSalaryStateManager();

    const config = useAppSelector(selectEmployeeSalaryTableConfig);
    const filter = useAppSelector(selectEmployeeSalaryFilter);
    const data = useAppSelector(selectEmployeeSalaryData);

    return {
        Filter: <EmployeeSalaryFilter onFilter={onFilter} isLoading={isLoading} filter={filter} />,
        config,
        filter,
        isLoading,
        onFilter,
        onSearch,
        setTableConfig: setEmployeeSalaryTableConfig,
        searchPlaceholder: "Search employee...",
        data
    };
};
