import React, { useState } from "react";
import PropTypes from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import { setClearCache } from "./slice";
import UpdateModal from "./UpdateModal";
import { useLazyWorkDetails } from "./hooks";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import useSelectManageInfoLazy from "../../../common/components/extra/select/hooks";

function WorkDetailSelectLazy(props) {
    const initializing = props.isLoading;
    const isDisabled = props.isDisabled;

    const [openAddModal, setOpenAddModal] = useState(false);

    const [object, , { isLoading, loadMore, search, reset, createRowItem }] = useLazyWorkDetails({
        allowInUse: props.allowInUse,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        byEmployeeId: props.byEmployeeId
    });

    const [value, onChange] = useSelectManageInfoLazy({
        isMulti: props.isMulti,
        value: props.value,
        data: object.data,
        createRowItem,
        isLoading: initializing,
        onChange: props.onChange
    });

    const dispatch = useAppDispatch();

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (props.isFilter) {
            allowedActions.push("menu-close");
        }
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    const handleFinish = async () => {
        dispatch(setClearCache());
        await reset();
        setOpenAddModal(false);
    };

    return (
        <div className="flex gap-1 center">
            <AsyncSelectLazy
                {...props}
                filterOption={(options, newInputs) => {
                    const search = newInputs?.toLowerCase().trim();
                    if (options?.data?.keywords?.toLowerCase().includes(search)) {
                        return true;
                    } else {
                        return false;
                    }
                }}
                onChange={onChange}
                value={value}
                options={object.data}
                onLoadMore={loadMore}
                onSearch={handleSearch}
                isFetching={initializing || isLoading}
                style={{ flex: 1 }}
                noRequiredInput={props.isFilter}
            />
            {!props.noAdd && (
                <MyTooltip message={!isDisabled && "Add Work Type"} style={{ display: "flex" }}>
                    <AddIcon className={`hover-svg primary-color ${isDisabled ? "disabled" : ""}`} onClick={() => setOpenAddModal(!openAddModal)} />
                </MyTooltip>
            )}
            {openAddModal && <UpdateModal open={openAddModal} onChange={(bool) => setOpenAddModal(bool)} onFinish={handleFinish} nosite />}
        </div>
    );
}

WorkDetailSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    noAdd: PropTypes.bool,
    allowInUse: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    byEmployeeId: PropTypes.number,
    isFilter: PropTypes.bool
};

export default WorkDetailSelectLazy;
