import React from "react";
import PropTypes from "prop-types";
import Input from "../../../../../common/components/extra/form/Input";
import { sanitizeWords } from "../../../../../common/utilities/helper";

function MobileEmailInput({ placeholder, details, useValue }) {
    if (useValue) {
        return <Input label={sanitizeWords(details.label)} renderValue={details.value} parentStyle={{ gap: ".5rem" }} readOnly alignRight />;
    }
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade italic">{placeholder}</span>
            </div>
        </div>
    );
}

export default MobileEmailInput;

MobileEmailInput.propTypes = {
    placeholder: PropTypes.any,
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
