import React from "react";
import PropTypes from "prop-types";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";

function InvalidDeptWarning({ data, small }) {
    let missingMsg = "";

    if (!data.supervisor_id) {
        missingMsg += !missingMsg ? "Missing Manager" : "Manager";
    }
    if (!data.manager_id) {
        missingMsg += missingMsg ? " and Supervisor" : "Supervisor";
    }
    if (!missingMsg) {
        return <></>;
    }
    return (
        <div style={{ marginRight: "1rem" }}>
            <WarningColorSwitcher style={{ width: small ? "1.5rem" : "2rem" }} tooltip={{ message: missingMsg }} warning />
        </div>
    );
}

export default InvalidDeptWarning;

InvalidDeptWarning.propTypes = {
    data: PropTypes.shape({
        usageCount: PropTypes.any,
        supervisor_id: PropTypes.any,
        manager_id: PropTypes.any
    }),
    small: PropTypes.bool
};
