import React from "react";
import PropTypes from "prop-types";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Button from "./Button";
import useNavigate from "../../hooks/useNavigate";
import Navigation from "../../classes/Navigation";
import { createConfirmAlert, createPromiseToast } from "../../utilities/helper";
import { LOCAL_STORAGE_KEYS } from "../../../features/company/companyPayment/const";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useCreatePaymentToken } from "../../../features/company/companyPayment/hooks";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import { setSubscriptionToUpgradeId } from "../../../features/company/companyPayment/slice";
import { selectUserCompanySubscription } from "../../../features/common/slice";
import { useCompanyStatusCheck } from "../../../features/company/auth/hooks";

function ButtonRenew({ primary }) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const check = useCompanyStatusCheck();
    const companySubscription = useAppSelector(selectUserCompanySubscription);
    const subscription = companySubscription.Subscription;

    const [, setStorage] = useLocalStorage(LOCAL_STORAGE_KEYS.PAYMENT.key, LOCAL_STORAGE_KEYS.PAYMENT.default);
    const [createToken, { isCreatingToken }] = useCreatePaymentToken({ isRenew: true });

    const handleUpgrade = () => {
        const onSuccess = (result) => {
            if (result.data.token) {
                dispatch(setSubscriptionToUpgradeId(subscription.id));
                navigate(Navigation.Routes.SUBSCRIPTION_PAY.path.replace(":token", result.data.token), null, true);
                setStorage({ key: result.data.token, expires: result.data.PTLexpires });
                return `Please Pay within the time limit of ${result.data.PTL} ${result.data.PTLtype}.`;
            } else {
                // if no token was found make it like the page is tempory not yet avalable
                return "Upgrade page is under maintenance, please try again later.";
            }
        };

        createPromiseToast(createToken(), {
            render: {
                pending: () => "Confirming Subscription Renewal...",
                success: (result) => onSuccess(result),
                error: () => "Failed to go to upgrade page. Please try again later."
            }
        });
    };

    if (check.isRenewUpgradeDisabled || check.isRenewDontAllow) {
        return <></>;
    }

    return (
        <Button
            className={`with-shadow ${primary ? "secondary" : "transparent"}`}
            onClick={() =>
                createConfirmAlert({
                    title: "Confirm Subscription Renewal",
                    content: "You will be redirected to the payment page.",
                    onConfirm: (onClose) => {
                        onClose();
                        handleUpgrade();
                    }
                })
            }
            options={{ style: { height: "2.2rem" } }}
            isLoading={isCreatingToken}
            isDisabled={isCreatingToken}
            small
            mini
        >
            <AutorenewIcon style={{ opacity: 1 }} />
            <span>Renew</span>
        </Button>
    );
}

export default ButtonRenew;

ButtonRenew.propTypes = {
    primary: PropTypes.bool
};
