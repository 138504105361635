import { LOCATION_LOG_ACTIONS } from "./const";

export const siteIconProps = (type) => {
    switch (type) {
        case LOCATION_LOG_ACTIONS.CHECK_IN:
            return { color: "#039D00" };
        case LOCATION_LOG_ACTIONS.CHECK_OUT:
            return { color: "#BE0404" };
        case LOCATION_LOG_ACTIONS.TIME_IN:
            return { color: "#0052CC" };
        case LOCATION_LOG_ACTIONS.TIME_OUT:
            return { color: "#5B91E1" };
        case LOCATION_LOG_ACTIONS.OVERTIME_IN:
            return { color: "#F7941D" };
        case LOCATION_LOG_ACTIONS.OVERTIME_OUT:
            return { color: "#ffc178" };
        default:
            return { color: "" };
    }
};

export const tagIconProps = (type) => {
    switch (type) {
        case LOCATION_LOG_ACTIONS.CHECK_IN:
            return "green";
        case LOCATION_LOG_ACTIONS.CHECK_OUT:
            return "red";
        case LOCATION_LOG_ACTIONS.TIME_IN:
            return "";
        case LOCATION_LOG_ACTIONS.TIME_OUT:
            return "";
        case LOCATION_LOG_ACTIONS.OVERTIME_IN:
            return "yellow";
        case LOCATION_LOG_ACTIONS.OVERTIME_OUT:
            return "yellow";
        default:
            return "";
    }
};
