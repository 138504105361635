import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { WORK_HISTORY_SHIFT_STATUS } from "../../../common/utilities/const";
import MyTooltip from "../../../common/components/extra/Tooltip";

function ShiftStatusCapsule({ statuses = [], afterExtra = {} }) {
    const isHalfDay = useMemo(() => {
        return (statuses || []).includes(WORK_HISTORY_SHIFT_STATUS.HALF_DAY);
    }, [statuses]);
    const isOverBreak = useMemo(() => {
        return (statuses || []).includes(WORK_HISTORY_SHIFT_STATUS.OVER_BREAK);
    }, [statuses]);
    const isUnderTime = useMemo(() => {
        return (statuses || []).includes(WORK_HISTORY_SHIFT_STATUS.UNDERTIME);
    }, [statuses]);

    const mainClass = useMemo(() => {
        let temp = "st-main ";
        if (statuses.includes(WORK_HISTORY_SHIFT_STATUS.LATE) || statuses.includes(WORK_HISTORY_SHIFT_STATUS.LATE_SECOND_SHIFT)) {
            temp += "late";
        }
        return temp.trim();
    }, [statuses]);

    const createActiveClass = (base = "", flag) => {
        if (flag) {
            base += " active";
        }
        return base;
    };

    const withTooltip = (label, message, show) => {
        if (show) {
            return (
                <MyTooltip element="span" message={message}>
                    {label}
                </MyTooltip>
            );
        }
        return label;
    };

    if (!statuses.length) {
        return <></>;
    }

    return (
        <div className={`shift-status-capsule semi-bold ${afterExtra?.el ? "with-extra" : ""}`}>
            <div className={mainClass}>{mainClass.includes("late") ? "LATE" : "ON-TIME"}</div>
            <div className={createActiveClass("st-extra-hd", isHalfDay)}>{withTooltip("HD", "Half Day", isHalfDay)}</div>
            <div className={createActiveClass("st-extra-ob", isOverBreak)}>{withTooltip("OB", "Over Break", isOverBreak)}</div>
            <div className={createActiveClass("st-extra-ut", isUnderTime)}>{withTooltip("UT", "Under Time", isUnderTime)}</div>
            {afterExtra.el && (
                <div className={`st-extra-content ${afterExtra.className || ""}`.trim()} style={afterExtra.style || {}}>
                    {afterExtra.el}
                </div>
            )}
        </div>
    );
}

export default ShiftStatusCapsule;

ShiftStatusCapsule.propTypes = {
    afterExtra: PropTypes.shape({
        el: PropTypes.any,
        style: PropTypes.object,
        className: PropTypes.string
    }),
    statuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(WORK_HISTORY_SHIFT_STATUS)))
};
