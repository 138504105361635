import React, { Component, createRef, useMemo } from "react";
import PropTypes from "prop-types";
import { v4 as uuidV4 } from "uuid";
import { useApiIsLoaded, useApiLoadingStatus, APILoadingStatus, useMap, Map, useMapsLibrary } from "@vis.gl/react-google-maps";

import {
    COMPLETED_DRAWING_EVENTS,
    COMPONENT_LIBRARY,
    DEFAULT_RPOPS,
    FALLBACK_CENTER,
    LIBRARIES,
    MAP_CHANGE_TYPE,
    MAP_CONTROL_POSITION,
    MAP_EVENT,
    MAP_TYPE_ID,
    OVERLAY_TYPE,
    POLYGON_DEFAULT_OPTIONS
} from "./const";
import Loader from "../Loader";
import { getBoundsLiteral, movePacContainerInsideMap, mvcArrayGeoValues } from "./helper";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectUser } from "../../../../features/common/slice";
import { latLngType, zoomType } from "./types";
import PolygonManager from "./PolygonManager";
import DrawingManager from "./DrawingManager";
import CustomMapControls from "./CustomMapControls";
import PlaceSearch from "./PlaceSearch";
import Marker, { MARKER_TYPE } from "./Marker";

export const MAP_lOADING_STATUS = APILoadingStatus;

const defaultId = uuidV4();

class GoogleMapInner extends Component {
    #ref = createRef();
    #listeners = [];
    #libraries = [];

    #id = null;
    #drawingLib = null;
    #map = null;
    #maps = null;
    #polygons = null;
    #drawing = null;
    #markerConnection = null;
    #isApiLoaded = false;
    #mapLoadingStatus = APILoadingStatus.NOT_LOADED;

    #style = DEFAULT_RPOPS.styles.parent;
    #coordinates = [];
    #zoom = {
        default: DEFAULT_RPOPS.zoom,
        min: null,
        max: null,
        onChange: () => {}
    };

    constructor(props) {
        super(props);
        this.#libraries = props.libraries || [];
        this.#drawingLib = props.drawingLib;
        // map props
        this.#id = props.id;
        this.#style = {
            ...this.#style,
            ...props.style
        };
        this.#zoom = {
            ...this.#zoom,
            ...props.zoom
        };
        this.#coordinates = props.coordinates;
        this.#isApiLoaded = props.isApiLoaded;
        this.#mapLoadingStatus = props.loadingStatus;
        // state
        this.state = {
            isMapLoading: true,
            selectedPolyKey: null,
            zoomValue: this.#zoom.default
        };
    }

    get Libraries() {
        return this.#libraries;
    }

    get Options() {
        return {
            id: this.#id,
            maps: this.#maps,
            polygons: this.#polygons,
            drawing: this.#drawing,
            markerConnection: this.#markerConnection,
            map: this.#map,
            isApiLoaded: this.#isApiLoaded,
            isMapLoading: this.state.isMapLoading,
            mapLoadingStatus: this.#mapLoadingStatus,
            style: this.#style,
            editable: this.props.editable,
            zoom: this.#zoom,
            defaultCenter: this.props.defaultCenter,
            coordinates: this.#coordinates,
            markers: this.props.markers,
            allowedPolyCreateCount: this.props.allowedPolyCreateCount,
            selectedPolyKey: this.state.selectedPolyKey,
            allowControls: this.props.allowControls,
            gestureHandling: this.props.allowControls || this.props.editable ? "greedy" : "none",
            disableDefaultUI: !this.props.allowControls && !this.props.editable,
            onlyMap: this.props.onlyMap,
            noCenterMarkers: this.props.noCenterMarkers,
            markerProps: this.props.markerProps,
            // map actions
            onInitialize: this.props.onInitialize,
            onChange: this.props.onChange,
            onIdle: this.props.onIdle,
            onMapLoaded: this.props.onMapLoaded,
            onBoundsChange: this.props.onBoundsChange
        };
    }

    get PolyPaths() {
        return Object.values(this.#polygons || {})
            .filter((polygon) => polygon.length || !!polygon)
            .map((poly) => ({
                title: poly.title,
                polygon: mvcArrayGeoValues(poly.getPath().getArray())
            }));
    }

    componentDidUpdate(prevProps) {
        // initialize instances
        if (prevProps.isApiLoaded != this.props.isApiLoaded && this.props.isApiLoaded && this.props.map) {
            this.#isApiLoaded = this.props.isApiLoaded;
            this.#map = this.props.map;
            this.#maps = window.google.maps;
            if (this.Libraries.includes(COMPONENT_LIBRARY.POLYGON)) {
                this.#coordinates
                    .filter((coordinate) => coordinate?.polygon?.length)
                    .forEach((item, idx) => {
                        this.addPolygon(idx, item.polygon, item.title, { forceRender: false });
                    });
            }
            if (this.Libraries.includes(COMPONENT_LIBRARY.DRAWING)) {
                this.#drawing = new this.#drawingLib.DrawingManager({
                    drawingControl: true,
                    drawingControlOptions: {
                        position: MAP_CONTROL_POSITION.LEFT_CENTER,
                        drawingModes: [OVERLAY_TYPE.POLYGON]
                    },
                    polygonOptions: this.createPolyOptions()
                });
                this.#drawing.setMap(this.#map);
            }
            if (this.Libraries.includes(COMPONENT_LIBRARY.POLYLINE)) {
                this.#markerConnection = new this.#maps.Polyline({
                    path: this.props.markers.map((marker) => marker.point),
                    geodesic: true,
                    strokeColor: "#0052CC",
                    strokeOpacity: 1.0,
                    strokeWeight: 3
                });
                this.#markerConnection.setMap(this.#map);
            }
            this.init();
        }

        // check only map
        if (prevProps.onlyMap !== this.props.onlyMap) {
            if (this.#drawing) {
                if (this.props.onlyMap) {
                    this.#drawing.setMap(null);
                } else {
                    this.#drawing.setMap(this.#map);
                }
            }
        }
    }

    componentWillUnmount() {
        this.#listeners.length && this.#listeners.forEach((listener) => listener.remove());
    }

    init() {
        const { isApiLoaded, map, onInitialize, onIdle, onMapLoaded, onBoundsChange } = this.Options;

        if (!isApiLoaded || !map) {
            return;
        }

        document.onfullscreenchange = movePacContainerInsideMap;

        this.#listeners = [
            map.addListener(MAP_EVENT.BOUNDS_CHANGED, () => {
                onBoundsChange?.(map);
            }),
            map.addListener(MAP_EVENT.IDLE, () => {
                onIdle?.(map);
            }),
            map.addListener(MAP_EVENT.TILES_LOADED, () => {
                if (this.state.isMapLoading) {
                    // we only need to run this once we run it together with the flag for map loading
                    this.fitBounds();
                }
                this.setState({ isMapLoading: false }, () => onMapLoaded?.(map));
            }),
            map.addListener(MAP_EVENT.DRAG_START, () => {
                this.setState({ selectedPolyKey: null });
            })
        ];
        const params = this.createParams();
        onInitialize?.(params, MAP_CHANGE_TYPE.MAP_INTIIALIZED);
    }

    fitBounds() {
        const { map, maps, coordinates, markers } = this.Options;

        const bounds = getBoundsLiteral(
            maps,
            (coordinates.length && coordinates.map((item) => item.polygon).flat()) || (markers.length && markers.map((marker) => marker.point)) || []
        );
        bounds && map.fitBounds(bounds, 0);
        bounds && map.setCenter(bounds.getCenter());
    }

    addPolygon(idx, paths, title, { forceRender = true, limit = null } = {}) {
        if (!this.#polygons) {
            this.#polygons = {};
        }

        idx = idx || Object.values(this.#polygons).length;
        if (limit && idx + 1 > limit) {
            return null;
        }
        this.#polygons[idx] = new this.#maps.Polygon(this.createPolyOptions());
        this.#polygons[idx].setPaths(paths);
        this.#polygons[idx].setMap(this.#map);
        this.#polygons[idx].title = title || `Site ${idx + 1}`;
        const newPaths = this.PolyPaths;
        forceRender && this.setState({});
        return newPaths;
    }

    removePolygon(idx, { forceRender = true } = {}) {
        if (!this.#polygons?.[idx]) return;
        this.#polygons[idx].setPaths([]);
        this.#polygons[idx].setMap(null);
        delete this.#polygons[idx];
        const newPaths = this.PolyPaths;
        forceRender && this.setState({});
        return newPaths;
    }

    createParams(newObj = {}) {
        const { map, maps, coordinates } = this.Options;
        return {
            map: newObj.map || map,
            maps: newObj.maps || maps,
            coordinates: newObj.coordinates || coordinates,
            latlangLiteral: getBoundsLiteral(maps, (newObj.coordinates || coordinates).map((item) => item.polygon).flat())
        };
    }

    createPolyOptions = () => {
        const { editable } = this.Options;
        return {
            ...POLYGON_DEFAULT_OPTIONS,
            editable: editable,
            draggable: editable
        };
    };

    render() {
        const {
            id,
            style,
            editable,
            zoom,
            defaultCenter,
            gestureHandling,
            isMapLoading,
            map,
            maps,
            polygons,
            drawing,
            onChange,
            allowedPolyCreateCount,
            selectedPolyKey,
            disableDefaultUI,
            noCenterMarkers,
            markerProps,
            markers
        } = this.Options;

        const handleDrawingComplete = (conf, type) => {
            switch (type) {
                case COMPLETED_DRAWING_EVENTS.POLYGON_COMPLETE: {
                    conf.setMap(null); // remove the drawn shape
                    const createdPaths = mvcArrayGeoValues(conf.getPath());
                    const newPath = this.addPolygon(null, createdPaths, null, { limit: allowedPolyCreateCount });
                    if (newPath) {
                        onChange?.(this.createParams({ coordinates: newPath }), MAP_CHANGE_TYPE.DRAW_COMPLETE);
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        };

        const handlePolychange = (type, idx) => {
            switch (type) {
                case MAP_CHANGE_TYPE.POLY_REMOVE: {
                    const newPaths = this.removePolygon(idx);
                    onChange?.(this.createParams({ coordinates: newPaths }), type);
                    break;
                }
                case MAP_CHANGE_TYPE.POLY_PATH_CHANGED: {
                    onChange?.(this.createParams({ coordinates: this.PolyPaths }), type);
                    break;
                }
                default:
                    break;
            }
        };

        const handleZoom = (conf) => {
            zoom.onChange?.(conf.detail.zoom);
            this.setState({ zoomValue: conf.detail.zoom });
        };

        return (
            <div ref={this.#ref} className="tk-map" style={style}>
                {isMapLoading && <Loader hasOverlay />}
                <Map
                    id={id}
                    mapId={id}
                    mapTypeId={MAP_TYPE_ID.HYBRID}
                    defaultCenter={defaultCenter}
                    gestureHandling={gestureHandling}
                    disableDefaultUI={disableDefaultUI}
                    onZoomChanged={handleZoom.bind(this)}
                    zoom={this.state.zoomValue}
                    maxZoom={zoom.max}
                    minZoom={zoom.min}
                    fullscreenControl={!this.props.onlyMap}
                    disableDoubleClickZoom
                >
                    {this.Libraries.includes(COMPONENT_LIBRARY.SEARCH) && <PlaceSearch map={map} maps={maps} hide={this.props.onlyMap} />}
                    {drawing && <DrawingManager instance={drawing} maps={maps} map={map} onComplete={handleDrawingComplete.bind(this)} />}
                    {Object.keys(polygons || {}).map((key) => (
                        <React.Fragment key={key}>
                            <PolygonManager
                                index={key}
                                instance={polygons[key]}
                                map={map}
                                maps={maps}
                                editable={editable}
                                onChange={(value, type) => handlePolychange(type, key)}
                                onPolySelect={(newKey) => selectedPolyKey != newKey && this.setState({ selectedPolyKey: newKey })}
                                selectedPolyKey={this.state.selectedPolyKey}
                                withCenterMarker={!noCenterMarkers}
                            />
                        </React.Fragment>
                    ))}
                    {!this.props.onlyMap && (
                        <CustomMapControls
                            map={map}
                            maps={maps}
                            polygons={this.PolyPaths}
                            position={MAP_CONTROL_POSITION.TOP_RIGHT}
                            markers={(markers && markers.length && markers.map((marker) => marker.point)) || []}
                        />
                    )}
                    {(markers || []).map((marker, idx) => (
                        <Marker
                            key={idx}
                            map={map}
                            maps={maps}
                            type={marker.type}
                            lat={marker.point.lat}
                            lng={marker.point.lng}
                            background={marker.background}
                            color={marker.color}
                            title={marker.title}
                            scale={marker.scale || markerProps?.scale}
                            onClick={() => markerProps?.onClick?.(marker, idx)}
                            isCenter
                        >
                            {marker.children}
                        </Marker>
                    ))}
                </Map>
            </div>
        );
    }
}

const GoogleMap = ({ id, defaultCenter, isLoading, coordinates = [], markers = [], ...rest }) => {
    const ID = id || defaultId;

    const map = useMap(ID);
    const isApiLoaded = useApiIsLoaded();
    const loadingStatus = useApiLoadingStatus();
    const drawingLib = useMapsLibrary(LIBRARIES.DRAWING);

    const user = useAppSelector(selectUser);

    const defCenter = useMemo(() => {
        if (isLoading) {
            return;
        }

        const userDefaultLatLng = { ...(user?.country?.latLng || {}) };
        if (userDefaultLatLng.lat && userDefaultLatLng.lng) {
            return userDefaultLatLng;
        } else if (defaultCenter.lat && defaultCenter.lng) {
            return defaultCenter;
        }
        return FALLBACK_CENTER;
    }, [user?.country, defaultCenter, isLoading]);

    const libraries = useMemo(() => {
        if (isLoading) {
            return;
        }

        let supported = [];
        if (rest.editable) {
            supported = supported.concat([COMPONENT_LIBRARY.DRAWING, COMPONENT_LIBRARY.SEARCH]);
        }
        if (coordinates?.length) {
            supported.push(COMPONENT_LIBRARY.POLYGON);
        }
        if (markers?.length && rest.markerProps.withLine) {
            supported.push(COMPONENT_LIBRARY.POLYLINE);
        }
        return supported;
    }, [rest.editable, isLoading]);

    if (!drawingLib || isLoading) {
        return <Loader />;
    }

    return (
        <>
            <GoogleMapInner
                id={ID}
                map={map}
                isApiLoaded={isApiLoaded && !!map}
                loadingStatus={loadingStatus}
                defaultCenter={defCenter}
                drawingLib={drawingLib}
                libraries={libraries}
                coordinates={coordinates}
                markers={markers}
                {...rest}
            />
        </>
    );
};

const defPropTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.object,
    editable: PropTypes.bool,
    markers: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(Object.values(MARKER_TYPE)),
            title: PropTypes.string,
            point: latLngType
        })
    ),
    coordinates: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            // always assume multi coordinates for single we enclose it in an array
            polygon: PropTypes.arrayOf(latLngType)
        })
    ),
    defaultCenter: latLngType,
    zoom: zoomType,
    markerProps: PropTypes.shape({
        scale: PropTypes.number,
        onClick: PropTypes.func,
        withLine: PropTypes.bool,
        children: PropTypes.any
    }),
    // map actions
    onInitialize: PropTypes.func,
    onChange: PropTypes.func,
    onIdle: PropTypes.func,
    onMapLoaded: PropTypes.func,
    onBoundsChanged: PropTypes.func,
    allowedPolyCreateCount: PropTypes.number,
    allowControls: PropTypes.bool,
    onlyMap: PropTypes.bool,
    isLoading: PropTypes.bool,
    noCenterMarkers: PropTypes.bool
};

GoogleMapInner.propTypes = {
    ...defPropTypes,
    map: PropTypes.object,
    isApiLoaded: PropTypes.bool,
    loadingStatus: PropTypes.oneOf(Object.values(MAP_lOADING_STATUS)),
    libraries: PropTypes.arrayOf(PropTypes.oneOf(Object.values(COMPONENT_LIBRARY)))
};
GoogleMap.propTypes = defPropTypes;

export default GoogleMap;
