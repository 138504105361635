import MobileAddress from "./inputFields/MobileAddress";
import MobileCheckbox from "./inputFields/MobileCheckbox";
import MobileDate from "./inputFields/MobileDate";
import MobileDateTime from "./inputFields/MobileDateTime";
import MobileDropdown from "./inputFields/MobileDropdown";
import MobileTime from "./inputFields/MobileTime";
import MobileEmailInput from "./inputFields/MobileEmailInput";
import MobileFileUpload from "./inputFields/MobileFileUpload";
import MobileImageUpload from "./inputFields/MobileImageUpload";
import MobileLabel from "./MobileLabel";
import MobileNumberInput from "./inputFields/MobileNumberInput";
import MobileParagraph from "./MobileParagraph";
import MobilePhone from "./inputFields/MobilePhone";
import MobileRadio from "./inputFields/MobileRadio";
import MobileTextArea from "./inputFields/MobileTextArea";
import MobileTextInput from "./inputFields/MobileTextInput";
import MobileToggle from "./inputFields/MobileToggle";

import MobileField from "./MobileField";
import MobileQuestionnaire from "./questionnaire/MobileQuestionnaire";

import { FORM_FIELD_TYPE } from "../const";
import MobileDateRange from "./inputFields/MobileDateRange";
import GroupInfoFields from "../internalFields/GroupInfoFields";

const {
    PARAGRAPH,
    LABEL,
    TEXT_INPUT,
    TEXT_AREA,
    EMAIL,
    NUMBER_INPUT,
    DROPDOWN,
    RADIO,
    CHECKBOX,
    DATE,
    TIME,
    DATE_TIME,
    DATE_RANGE,
    FILE_UPLOAD,
    IMAGE_UPLOAD,
    PHONE_NUMBER,
    ADDRESS,
    TOGGLE,
    QUESTIONNAIRE,
    EMPLOYEE_NAME,
    EMPLOYEE_ID,
    EMPLOYEE_DESIGNATION,
    EMPLOYEE_NATIONALITY,
    EMPLOYEE_RESIDENCE_ID,
    EMPLOYEE_MOBILE,
    EMPLOYEE_JOINING_DATE,
    EMPLOYEE_JOB_GRADE,
    EMPLOYEE_DEPARTMENT,
    WORK_DETAIL_PROJECT_CODE,
    GROUP
} = FORM_FIELD_TYPE;

export default {
    [PARAGRAPH]: MobileParagraph,
    [LABEL]: MobileLabel,
    [TEXT_INPUT]: MobileTextInput,
    [TEXT_AREA]: MobileTextArea,
    [EMAIL]: MobileEmailInput,
    [NUMBER_INPUT]: MobileNumberInput,
    [DROPDOWN]: MobileDropdown,
    [RADIO]: MobileRadio,
    [CHECKBOX]: MobileCheckbox,
    [DATE]: MobileDate,
    [TIME]: MobileTime,
    [DATE_TIME]: MobileDateTime,
    [DATE_RANGE]: MobileDateRange,
    [FILE_UPLOAD]: MobileFileUpload,
    [IMAGE_UPLOAD]: MobileImageUpload,
    [PHONE_NUMBER]: MobilePhone,
    [ADDRESS]: MobileAddress,
    [TOGGLE]: MobileToggle,
    [QUESTIONNAIRE]: MobileQuestionnaire,
    [EMPLOYEE_NAME]: MobileField,
    [EMPLOYEE_ID]: MobileField,
    [EMPLOYEE_DESIGNATION]: MobileField,
    [EMPLOYEE_NATIONALITY]: MobileField,
    [EMPLOYEE_RESIDENCE_ID]: MobileField,
    [EMPLOYEE_MOBILE]: MobileField,
    [EMPLOYEE_JOINING_DATE]: MobileField,
    [EMPLOYEE_JOB_GRADE]: MobileField,
    [EMPLOYEE_DEPARTMENT]: MobileField,
    [WORK_DETAIL_PROJECT_CODE]: MobileField,
    [GROUP]: GroupInfoFields
};
