import React from "react";

function NotSupported() {
    return (
        <div className="mobile-input mobile-input--text-input">
            <span>Not Supported</span>
        </div>
    );
}

export default NotSupported;

NotSupported.propTypes = {};
