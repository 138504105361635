import React from "react";
import PropTypes from "prop-types";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import { useLazyDesignations } from "./hooks";
import useSelectManageInfoLazy from "../../../common/components/extra/select/hooks";

function DesignationsSelectLazy(props) {
    const initializing = props.isLoading;

    const [object, , { loadMore, search, isLoading, createRowItem }] = useLazyDesignations({
        initializing,
        value: props?.value,
        isMulti: props?.isMulti,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        isFilter: props.isFilter
    });

    const [value, onChange] = useSelectManageInfoLazy({
        isMulti: props.isMulti,
        value: props.value,
        data: object.data,
        createRowItem,
        isLoading: initializing,
        onChange: props.onChange
    });

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (props.isFilter) {
            allowedActions.push("menu-close");
        }
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    return (
        <AsyncSelectLazy
            {...props}
            filterOption={(options, newInputs) => {
                const index1 = options?.data?.title?.trim()?.toLowerCase() || "";
                const search = newInputs?.toLowerCase().trim();
                if (index1.includes(search)) {
                    return true;
                } else {
                    return false;
                }
            }}
            onChange={onChange}
            value={value}
            options={object.data}
            onLoadMore={loadMore}
            onSearch={handleSearch}
            isFetching={initializing || isLoading}
            noRequiredInput={props.isFilter}
        />
    );
}

DesignationsSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    allowOnShift: PropTypes.bool,
    isLoading: PropTypes.bool,
    allowSelectOnShift: PropTypes.bool,
    isFilter: PropTypes.bool
};

export default DesignationsSelectLazy;
