export const TIMELINE_STATUS = {
    STALE: "STALE",
    POSITIVE: "POSITIVE",
    NEGATIVE: "NEGATIVE",
    WAITING: "WAITING",
    CANCELED: "CANCELED"
};

export const TIMELINE_INFO = {
    [TIMELINE_STATUS.STALE]: {
        className: "stale",
        bgColor: "#C8C8C8",
        color: "#F1F1F1"
    },
    [TIMELINE_STATUS.POSITIVE]: {
        className: "positive",
        bgColor: "#10BD4B",
        color: "#FFFFFF"
    },
    [TIMELINE_STATUS.NEGATIVE]: {
        className: "negative",
        bgColor: "#D32D2D",
        color: "#FFFFFF"
    },
    [TIMELINE_STATUS.WAITING]: {
        className: "waiting",
        bgColor: "#D0C807",
        color: "#FFFFFF"
    },
    [TIMELINE_STATUS.CANCELED]: {
        className: "canceled",
        bgColor: "#505050",
        color: "#D0D0D0"
    }
};
