import React from "react";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent } from "./slice";
import { useGetLeaveExtraInfo, useUpdateStatus } from "./hooks";
import { createConfirmAlert } from "../../../common/utilities/helper";
import { FIELD, LEAVE_STATUS } from "./const";
import Button from "../../../common/components/extra/Button";

function CancelStatusButton({ onFinish }) {
    const current = useAppSelector(selectCurrent);
    const [, , { update }] = useUpdateStatus(current.id, current);

    const leaveDateInfo = useGetLeaveExtraInfo(current);
    const isStarted = leaveDateInfo.isStarted;
    const isCanceled = current[FIELD.STATUS] === LEAVE_STATUS.CANCELED;

    if (!current || isStarted || isCanceled) {
        return <></>;
    }

    return (
        <>
            <Button
                className="danger"
                options={{ style: { width: "max-content" } }}
                onClick={() =>
                    createConfirmAlert({
                        title: "Cancel Leave Record",
                        content: `Are you sure you want to cancel the selected leave record? This cannot be undone.`,
                        onConfirm: (onClose) => {
                            update({
                                status: LEAVE_STATUS.CANCELED
                            }).then(onFinish);
                            onClose();
                        }
                    })
                }
                disabled={isCanceled}
                afterExtra={<CancelIcon />}
                small
                mini
            >
                {isCanceled ? <span>Canceled</span> : <span>Cancel Leave Record</span>}
            </Button>
        </>
    );
}

export default CancelStatusButton;

CancelStatusButton.propTypes = {
    onFinish: PropTypes.func
};
