import React from "react";
import PropTypes from "prop-types";
import Field from "./Field";
import Loader from "../../../common/components/extra/Loader";
import Empty from "../../../common/components/extra/Empty";
import { sanitizeWords } from "../../../common/utilities/helper";
import { FIELD, FIELDS_NOT_AVAILABLE_BY_CLASS, FORM_CATEGORY, FORM_FIELD_GROUP } from "./const";

function ControlFields({ form, fields, isLoading }) {
    const toProperClassName = (type) => type && type.toLowerCase().split("_").join("-");
    const entries = Object.entries(fields);
    const isEmpty = !entries.length;

    const notAvailableFieldsByClassByGroup = FIELDS_NOT_AVAILABLE_BY_CLASS.byGroup[form[FIELD.CLASS]] || [];
    const notAvailableFieldsByClassByFields = FIELDS_NOT_AVAILABLE_BY_CLASS.byFields[form[FIELD.CLASS]] || [];

    const category = form[FIELD.CATEGORY];
    const isHR = category == FORM_CATEGORY.HR;

    return (
        <div className="form-control-fields">
            {isLoading ? (
                <div className="flex gap-05 center" style={{ marginTop: "1rem" }}>
                    <Loader style={{ width: "3rem" }} relative />
                    <span className="fade">Fetching Elements...</span>
                </div>
            ) : !isEmpty ? (
                entries
                    .filter(([group]) => !notAvailableFieldsByClassByGroup.includes(group))
                    // !TODO: @UNSUPPORTED Disabled work details fields when EMPLOYEE category
                    .filter(([group]) => (!isHR ? ![FORM_FIELD_GROUP.WORK_DETAILS].includes(group) : true))
                    .map(([group, rows]) => (
                        <section key={group} className="form-control-fields__group">
                            <span className="fade">{group == "datetime" ? "Date Inputs" : sanitizeWords(group)}</span>
                            <div className="form-control-fields__items">
                                {rows
                                    .filter((row) => !notAvailableFieldsByClassByFields.includes(row.type))
                                    .map((row) => (
                                        <Field key={row.type} className={toProperClassName(row.type)} type={row.type} data={row}>
                                            <div className="flex w-100 align-center" style={{ textAlign: "center" }}>
                                                <span className="small-font bold">{sanitizeWords(row.name)}</span>
                                            </div>
                                        </Field>
                                    ))}
                            </div>
                        </section>
                    ))
            ) : (
                <Empty
                    message="No Fields Available"
                    style={{
                        margin: "auto",
                        marginTop: "5rem",
                        maxWidth: "10rem"
                    }}
                    messageStyle={{
                        whiteSpace: "unset",
                        textAlign: "center"
                    }}
                />
            )}
        </div>
    );
}

export default ControlFields;

ControlFields.propTypes = {
    fields: PropTypes.object,
    isLoading: PropTypes.bool,
    form: PropTypes.object
};
