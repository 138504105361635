import { toFormObject } from "../../../common/utilities/helper";

export const BASE_CLASS = "tk-departments__modal-content-update";

export const FORM_FIELDS = {
    TITLE: "title",
    SUPERVISOR: "supervisor",
    MANAGER: "manager"
};

export const INITIAL_FORM = toFormObject(FORM_FIELDS);

export const LOAD_MORE_OFFSET = 10;
export const DEFAULT_SIZE = 20;

export const ACTION = {
    UPDATE: "UPDATE",
    TRANSFER: "TRANSFER",
    REMOVE: "REMOVE"
};
