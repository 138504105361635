import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, formattedJoiErrorMessage, toTimeWithTimeZone } from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { useUpdateRejoinedDetails } from "./hooks";
import { BASE_CLASS_EDIT_REJOINED_MODAL, FIELD, FIELD_OBJECT } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { NOTES_MAX_LEN } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

function EditRejoinedDetailsModal({ open, onClose, onFinish, id, current }) {
    const [error, setError] = useState({ all: null });

    const [form, updateForm, { update, hasChanges, updateIsLoading }] = useUpdateRejoinedDetails(id, current);

    const userSetting = useAppSelector(selectUserSetting);
    const timezone = userSetting.timezone;
    const disableSave = updateIsLoading || !hasChanges;

    const handleSave = async () => {
        const result = await update();
        if (!result.error) {
            if (error.all) {
                setError({ ...error, all: null });
            }
            typeof onFinish === "function" && onFinish(result);
        }
        if (result.error) {
            setError({
                ...error,
                all: result.error.message
            });
        }
        return result.error;
    };

    const handleFormChange = async ({ name, value } = {}) => {
        if (error.all) {
            setError({
                ...error,
                all: null
            });
        }
        updateForm({
            [name]: value
        });
    };

    return (
        <>
            <BaseUpdateModal
                open={open}
                onClose={onClose}
                onSave={(e) =>
                    createConfirmAlert({
                        title: "Update Rejoined Details",
                        content: `Are you sure you want to this record? This cannot be undone.`,
                        onConfirm: async (close) => {
                            close();
                            const hasError = await handleSave(e);
                            if (!hasError) {
                                onClose();
                            }
                        }
                    })
                }
                styles={{
                    content: {
                        height: "max-content",
                        maxHeight: "90vh",
                        maxWidth: "12rem"
                    },
                    body: {
                        overflow: "auto"
                    }
                }}
                className={createClass(BASE_CLASS_EDIT_REJOINED_MODAL)}
                title="Update Rejoined Details"
                disableSave={disableSave}
                isLoading={updateIsLoading}
                saveLabel="Confirm"
                hasHeaderStyle
                isForm
            >
                {createGroup({
                    base: createClass(BASE_CLASS_EDIT_REJOINED_MODAL),
                    body: (
                        <div className="flex column gap-05">
                            {error.all && (
                                <SectionCollapseError show={!!error.all} style={{ marginBottom: "1rem" }}>
                                    {formattedJoiErrorMessage({
                                        error: { message: error.all },
                                        isCreate: false
                                    })}
                                </SectionCollapseError>
                            )}
                            <Input
                                label={FIELD_OBJECT[FIELD.REJOINED_DATE].label}
                                type={INPUT_TYPE.DATE}
                                name={FIELD.REJOINED_DATE}
                                onChange={(date) => handleFormChange({ name: FIELD.REJOINED_DATE, value: date })}
                                selected={form[FIELD.REJOINED_DATE] && new Date(form[FIELD.REJOINED_DATE])}
                                error={[error[FIELD.REJOINED_DATE]]}
                                minDate={toTimeWithTimeZone(current[FIELD.END_DATE], timezone).add(1, "day").format()}
                                noFuture
                                required
                                useSubTextStyle
                                isFixed
                            />
                            <Input
                                name={FIELD.REJOINED_NOTES}
                                label={FIELD_OBJECT[FIELD.REJOINED_NOTES].label}
                                type={INPUT_TYPE.TEXTAREA}
                                value={form[FIELD.REJOINED_NOTES] || ""}
                                parentStyle={{ height: "10rem", minHeight: "5rem" }}
                                onChange={(e) =>
                                    handleFormChange({
                                        name: FIELD.REJOINED_NOTES,
                                        value: e.target.value
                                    })
                                }
                                maxLength={NOTES_MAX_LEN}
                            />
                        </div>
                    )
                })}
            </BaseUpdateModal>
        </>
    );
}

EditRejoinedDetailsModal.propTypes = {
    photo: PropTypes.string,
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    current: PropTypes.object
};

export default EditRejoinedDetailsModal;
