import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import FormSection from "../../../common/components/extra/FormSection";
import { VIEW_MODAL_TYPE } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { renderNA, addCommasToMoney } from "../../../common/utilities/helper";

const { SALARY } = VIEW_MODAL_TYPE;

function ViewModalSub({ open, onChange, data, type }) {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const contract = data?.EmployeeContract || {};
    const allowance = contract.allowance || {};
    return (() => {
        let children,
            title,
            style = {};
        switch (type) {
            case SALARY: {
                const COMMON_INPUT_PROPS = { type: INPUT_TYPE.TEXT, readOnly: true };
                title = "Salary Details";
                children = (
                    <FormSection style={{ minWidth: "20rem" }} nohead>
                        <Input
                            label="Basic Salary"
                            renderValue={addCommasToMoney(contract.basic_amount)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Accommodation Allowance"
                            renderValue={addCommasToMoney(allowance.accommodation)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Food Allowance"
                            renderValue={addCommasToMoney(allowance.food)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Transportation Allowance"
                            renderValue={addCommasToMoney(allowance.transportation)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Communication Allowance"
                            renderValue={addCommasToMoney(allowance.communication)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Education Allowance"
                            renderValue={addCommasToMoney(allowance.education)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Other"
                            renderValue={addCommasToMoney(allowance.other)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                        <Input
                            label="Air Ticket"
                            renderValue={addCommasToMoney(allowance.air_ticket)}
                            afterExtra={renderNA(setting.currency)}
                            {...COMMON_INPUT_PROPS}
                        />
                    </FormSection>
                );
                break;
            }
            default:
                break;
        }
        return (
            <Modal title={title} open={open} onChange={onChange} styles={{ title: { textAlign: "left" }, content: style }} small>
                <div className="tk-employees__modal-content-view" style={{ padding: "1rem" }}>
                    {children}
                </div>
            </Modal>
        );
    })();
}

ViewModalSub.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(Object.values(VIEW_MODAL_TYPE))
};

export default ViewModalSub;
