import React from "react";
import { CURRENCIES, TIMEZONES } from "../../../common/utilities/const";
import { createMockData, sanitizeWords, toReadableSelectOptions } from "../../../common/utilities/helper";
import { MONTHLY_SALARY_DAYS, SETTLEMENT_DAILY_RATE, TIME_FORMAT, TIMESHEET_TYPE } from "./const";

const TIME_FORMATS = Object.values(TIME_FORMAT);
const CURRENCY_VALUES = Object.values(CURRENCIES);
const CURRENCY_KEYS = Object.keys(CURRENCIES);

export const createGeneralOptions = ({ totalEmployees }) => {
    const label = (val, text) => (
        <div className="flex gap-05">
            {val} {text}
        </div>
    );
    return {
        currencies: CURRENCY_VALUES.map((currency, i) => ({
            ...currency,
            value: CURRENCY_KEYS[i],
            label: label(currency.name, `(${CURRENCY_KEYS[i]})`)
        })),
        hourFormats: TIME_FORMATS.map((tf) => ({ ...tf, label: label(tf.label, "hour") })),
        timezones: TIMEZONES.map((tz) => ({ value: tz, label: tz })),
        maxTimeInOutSVOpt: createMockData(totalEmployees + 1, (idx) => ({ label: label(idx, "max"), value: idx })),
        maxTimeInOutEMPOpt: createMockData(31, (idx) => ({ label: label(idx, "max"), value: idx })),
        minutes: ({ from, to } = {}) => createMockData(to + 1, (idx) => ({ label: label(idx, "min(s)"), value: idx }), from + 1, to + 1)
    };
};

export const createSalaryOptions = () => {
    const label = (val, text) => (
        <div className="flex gap-05">
            {val} {text}
        </div>
    );
    return {
        eligibilitiesOpt: ({ min = 3, max = 12 }) => createMockData(max, (idx) => ({ label: label(idx + 1, "month(s)"), value: idx + 1 }), min, max),
        entitlementOpt: ({ min = 21, max = 30 }) => createMockData(max, (idx) => ({ label: label(idx + 1, "day(s)"), value: idx + 1 }), min, max),
        daysOpt: createMockData(31, (idx) => {
            idx = idx + 1;
            const suffix =
                idx % 10 === 1 && idx % 100 !== 11
                    ? "st"
                    : idx % 10 === 2 && idx % 100 !== 12
                      ? "nd"
                      : idx % 10 === 3 && idx % 100 !== 13
                        ? "rd"
                        : "th";
            return { label: `Every ${idx}${suffix}`, value: idx };
        }),
        settlementDailyRateByOpt: toReadableSelectOptions(SETTLEMENT_DAILY_RATE),
        monthlySalaryDaysOpt: Object.values(MONTHLY_SALARY_DAYS),
        timesheetTypeOpt: Object.values(TIMESHEET_TYPE).map((ts) => ({ value: ts, label: sanitizeWords(ts) }))
    };
};
