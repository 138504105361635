import React from "react";
import PropTypes from "prop-types";
import { DragOverlay } from "@dnd-kit/core";
import Droppable from "../../../common/components/extra/DND/Droppable";
import SortableItem from "../../../common/components/extra/DND/SortableItem";
import DroppedItem from "./DroppedItem";
import MobileInputsPreview from "./MobileInputsPreview";
import { FORM_FIELD_GROUP, FORM_FIELD_TYPE } from "./const";
import FieldPreviewWrapper from "./FieldPreviewWrapper";

function MobileView({ activeId, data, items = [], onRemove, onSelect, selected, isLoading, isDisabled, loadingMessage }) {
    const isOld = activeId && items.some((item) => item.id === activeId);
    const inlineTypes = [FORM_FIELD_TYPE.TOGGLE];

    const renderPreview = (temp = {}) => {
        return <MobileInputsPreview name={temp.name} type={temp.type} details={temp.validation || {}} description={temp.description} data={temp} />;
    };

    return (
        <div className="form-mobile-view">
            <Droppable
                className="form-mobile-view__box"
                id="main-drop-zone"
                data={data}
                isDisabled={isDisabled}
                isLoading={isLoading}
                loadingMessage={loadingMessage}
            >
                {(!isOld || !items.length) && (
                    <DragOverlay dropAnimation={null}>
                        {activeId && data && <FieldPreviewWrapper>{renderPreview(data)}</FieldPreviewWrapper>}
                    </DragOverlay>
                )}
                {!!items.length &&
                    items.map((it, idx) => (
                        <SortableItem
                            key={it.id}
                            id={it.id}
                            idx={idx}
                            draggedItemIndex={data?.sortable?.index || 0}
                            className="form-mobile-view__sortable-box"
                            spacing={0.5}
                            isActive={(selected && selected.id == it.id) || false}
                            onRemove={() => onRemove(it)}
                            hasError={!!it.error}
                            simple={it.data.group == FORM_FIELD_GROUP.EMPLOYEE_DETAILS}
                            fixed={it.fixed}
                        >
                            <DroppedItem
                                id={it.id}
                                data={it.data}
                                onSelect={() => onSelect(it)}
                                inline={inlineTypes.includes(it.data.type)}
                                error={it.error}
                            >
                                {renderPreview(it.data)}
                            </DroppedItem>
                        </SortableItem>
                    ))}
                <SortableItem
                    id="xxx"
                    className="form-mobile-view__sortable-box"
                    spacing={1}
                    isActive={(selected && selected.id == "xxx") || false}
                    temp
                >
                    <div className="dropped-item">
                        <div className="dropped-item__header text-ellipsis"></div>
                    </div>
                </SortableItem>
            </Droppable>
        </div>
    );
}

export default MobileView;

MobileView.propTypes = {
    activeId: PropTypes.string,
    data: PropTypes.object,
    selected: PropTypes.object,
    items: PropTypes.array,
    onRemove: PropTypes.func,
    onSelect: PropTypes.func,
    isLoading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    loadingMessage: PropTypes.string
};
