import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import debounce from "lodash/debounce";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectUserSetting } from "../../../../features/common/slice";
import { STANDARD_REF_DATE } from "../../../utilities/const";
import { changeToBaseDate, toTimeWithTimeZone } from "../../../utilities/helper";
import { getDateSelectionAvailability } from "../dateTime/helper";

const DATE_FORMAT = "hh:mm aa";
const SELECTED_CLASS = "react-datepicker__time-list-item--selected";
const PARENT_LIST_CLASS = "react-datepicker__time-list";

function InputTime({ minDate, maxDate, isFixed, onChange, interval = 30, timezone, baseDate = STANDARD_REF_DATE, selected, constraint, ...props }) {
    const inputRef = useRef(null);

    const [isMounted, setMounted] = useState(false);
    const [, setKeys] = useState("");
    const [, setCalendarOpen] = useState(false);

    const setting = useAppSelector(selectUserSetting);

    const tz = timezone || setting.timezone;
    const base = useMemo(() => toTimeWithTimeZone(baseDate, tz), [baseDate, tz]);

    const handleKeyDown = (event) => setKeys(event.key);

    const isDateAllowed = useMemo(() => {
        return (date) => getDateSelectionAvailability(date, constraint, tz);
    }, [constraint, tz]);

    const handleChange = useCallback(
        (date, e, event) => {
            if (!date) {
                onChange?.("", event);
                return;
            }
            const tzdate = toTimeWithTimeZone(date, tz);
            const newDate = changeToBaseDate(base, tzdate, tz);

            if (event !== "mounting") {
                const allow = isDateAllowed(newDate);
                onChange?.(allow ? newDate : "", event);
            } else {
                onChange?.(newDate, event);
            }
        },
        [base, tz, isDateAllowed, onChange]
    );

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        // make sure to call onchange here so that date is always correct
        if (isMounted && selected) {
            handleChange(selected, null, "mounting");
        }
    }, [isMounted]);

    const onChangeRaw = useCallback(() => {
        const parent = document.querySelector("." + PARENT_LIST_CLASS);
        const selected = parent.querySelector("." + SELECTED_CLASS);

        if (parent && selected) {
            // Get the dimensions of the parent and the target
            const parentHeight = parent.clientHeight;
            const targetHeight = selected.clientHeight;

            // Calculate the offset of the target relative to the parent
            const targetPosition = selected.offsetTop;
            const scrollPosition = targetPosition - parentHeight / 2 + targetHeight / 2;

            // Ensure a minimum scroll change (e.g., 10px)
            const currentScroll = parent.scrollTop;
            const scrollDifference = Math.abs(scrollPosition - currentScroll);
            const MIN_SCROLL_THRESHOLD = 5; // Minimum scroll to trigger scrolling

            if (scrollDifference > MIN_SCROLL_THRESHOLD) {
                // Scroll to the calculated position
                parent.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth"
                });
            }
        }
    }, []);

    return (
        <div className="tk-input__time flex w100" style={{ justifyContent: "flex-end" }}>
            <ReactDatePicker
                ref={inputRef}
                {...props}
                selected={selected}
                wrapperClassName="date-picker-custom"
                style={{ width: "5rem", flex: "unset" }}
                placeholderText={props.placeholder || "00:00"}
                minDate={minDate}
                maxDate={maxDate}
                popperPlacement="bottom-left"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                showPopperArrow={false}
                timeCaption="Time"
                dateFormat={DATE_FORMAT}
                timeIntervals={interval}
                className="custom-react-date-picker"
                onCalendarOpen={() => setCalendarOpen(true)}
                onCalendarClose={() => setCalendarOpen(false)}
                onChangeRaw={debounce(onChangeRaw, 500)}
                showTimeSelect
                showTimeSelectOnly
                disabledKeyboardNavigation
                {...(isFixed ? { popperProps: { strategy: "fixed" } } : {})}
            />
        </div>
    );
}

export default InputTime;

InputTime.propTypes = {
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    placeholder: PropTypes.string,
    isFixed: PropTypes.bool,
    onChange: PropTypes.func,
    interval: PropTypes.number,
    timezone: PropTypes.string,
    baseDate: PropTypes.string,
    selected: PropTypes.any,
    isLoading: PropTypes.bool,
    constraint: PropTypes.shape({
        min: PropTypes.any,
        max: PropTypes.any
    })
};
