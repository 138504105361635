import React, { useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import UnApplyIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from "@mui/icons-material/Visibility";
import AssignIcon from "@mui/icons-material/AssignmentReturn";
import InfoIcon from "@mui/icons-material/Info";
import Card from "../../../common/components/extra/Card";
import Divider from "../../../common/components/extra/Divider";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Button, { BTN_TYPE } from "../../../common/components/extra/Button";
import TableList from "../../../common/components/extra/table/TableList";
import { createConfirmAlert, getCountryName, sanitizeWords } from "../../../common/utilities/helper";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import ModalViewUpdateLayout from "../../../common/components/layout/modalViewUpdateLayout/ModalViewUpdateLayout";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import Tag from "../../../common/components/extra/Tag";
import { selectCountry } from "../../common/slice";
import UpdateModalContent from "./UpdateModalContent";
import AssignLawPenaltiesModal from "./AssignLawPenaltiesModal";
import ViewModalContent from "./ViewModalContent";
import { MAX_CUSTOM, ROUTE_TYPE } from "./const";
import {
    useCreateCompanyPenalty,
    useLazyCompanyPenalties,
    useRemoveCompanyPenalty,
    useSetCompanyPenaltiesWithPenalties,
    useUpdateCompanyPenalty
} from "./hooks";
import MyTooltip from "../../../common/components/extra/Tooltip";

const { REMOVE, PREVIEW } = TABLE_ACTION_TYPE_COMMON;

const DESC_CUSTOM = `Custom penalties are penalties that are customizable depending on what the company needs unlike the Law Penalties where customization is limited.`;
const DESC_LAW = `Law penalties are penalties that are based on the current country. Select law penalties to apply via "Apply" button.`;

function CompanyPenalties({ type }) {
    const isByLaw = type == ROUTE_TYPE.LAW;

    const [isOpenPreviewModal, setOpenPreviewModal] = useState(false);
    const [isOpenAddModal, setOpenAddModal] = useState(false);
    const [isOpenAssignOpen, setOpenAssignModal] = useState(false);
    const [selected, setSelected] = useState(null);

    const [fetchedView, setFetchedView] = useState(null);
    const [formToSave, setFormToSave] = useState(null);

    const config = {};

    const [onCreate] = useCreateCompanyPenalty();
    const [onUpdate] = useUpdateCompanyPenalty();
    const [onRemove] = useRemoveCompanyPenalty();
    const [onSet] = useSetCompanyPenaltiesWithPenalties();
    const [object, isFetching, , { loadMore, onSort, onSearch, reset }] = useLazyCompanyPenalties(type, config);

    const checkedIds = object.companyPenalties.map((cp) => cp.penalty_id).filter(Boolean);
    const country = useAppSelector(selectCountry);
    const findSelected = selected && object.companyPenalties.find((obj) => obj.id == selected.id);
    const isCustomMaxedOut = !isByLaw && object.totalCount >= MAX_CUSTOM;

    const { data: headers } = CreateTableHeaders({
        isByLaw,
        onAction: async (data, type) => {
            switch (type) {
                case REMOVE: {
                    await onRemove({ id: data.id, title: data.title });
                    await reset();
                    setSelected(null);
                    break;
                }
                case PREVIEW: {
                    setSelected(data);
                    setOpenPreviewModal(true);
                    break;
                }
                default:
                    break;
            }
        }
    });

    const handleAssign = async (newSelections) => {
        return await onSet(newSelections)
            .then(reset)
            .then(() => setOpenAssignModal(false));
    };

    const handleClose = ({ isEditModalOpen, isViewModalOpen } = {}) => {
        isViewModalOpen === false && setOpenPreviewModal(false);
        isEditModalOpen === false && setOpenAddModal(false);
    };

    const handleUpdate = async () => {
        let result = false;
        if (!selected?.id) {
            result = await onCreate(formToSave);
            if (result) {
                setFormToSave(null);
                setFetchedView(null);
            }
        } else {
            result = await onUpdate(selected?.id, formToSave);
            setFetchedView(formToSave);
        }
        await reset();
        return result;
    };

    const renderDescription = () => {
        if (isByLaw) {
            return DESC_LAW;
        }
        return DESC_CUSTOM;
    };

    const renderCounter = () => {
        if (isByLaw) {
            if (!object.totalCount) return <></>;
            return <div className="flex center fade small-font">({object.totalCount})</div>;
        }
        return (
            <div className={`flex center fade small-font ${isCustomMaxedOut ? "danger-color" : ""}`.trim()}>
                ({object.totalCount}/{MAX_CUSTOM})
                {isCustomMaxedOut && (
                    <MyTooltip
                        style={{ marginLeft: ".2rem" }}
                        message="You've hit your limit of 100. To add a new one, please remove an existing penalty."
                        className="flex"
                    >
                        <InfoIcon className="flex hover-svg" style={{ width: "1rem", marginTop: "-2px" }} />
                    </MyTooltip>
                )}
            </div>
        );
    };

    const handleAdd = () => {
        setOpenAddModal(true);
        setSelected(null);
        setFetchedView(null);
        setFormToSave(null);
    };

    return (
        <>
            <ModalViewUpdateLayout
                onUpdate={handleUpdate}
                updateConfig={{
                    isCreate: !selected?.id,
                    oldData: fetchedView,
                    newData: formToSave,
                    title: "Penalty"
                }}
                modal={{
                    EditModalContent: <UpdateModalContent data={fetchedView} onChange={setFormToSave} isByLaw={isByLaw} />,
                    ViewModalContent: (
                        <ViewModalContent data={{ id: selected?.id }} onLoad={(result) => setFetchedView(result)} newData={findSelected} />
                    ),
                    isEditModalOpen: isOpenAddModal,
                    isViewModalOpen: isOpenPreviewModal,
                    onClose: handleClose,
                    updateStyles: {
                        content: {
                            width: "50vw"
                        }
                    }
                }}
            >
                <Card className="penalties-settings box-shadow-mini">
                    <form className="flex column">
                        <div className="flex column">
                            <Divider
                                title={
                                    <div className="flex gap-05 align-center">
                                        <div className="flex">{`Penalties - ${
                                            isByLaw ? `Applied ${getCountryName(country, true)} Laws` : "Custom"
                                        }`}</div>
                                        {renderCounter()}
                                    </div>
                                }
                            />
                            <p className="fade small-font">{renderDescription()}</p>
                            <div className="flex column gap-05">
                                <div className="flex" style={{ alignItems: "center" }}>
                                    <Input
                                        type={INPUT_TYPE.SEARCH}
                                        onChange={debounce(onSearch, 500)}
                                        placeholder="Search title..."
                                        parentStyle={{
                                            minHeight: "1rem",
                                            height: "1.6rem"
                                        }}
                                        wrapperStyle={{ flex: 1 }}
                                    />
                                    {isByLaw ? (
                                        <Button onClick={() => setOpenAssignModal(true)} style={{ gap: ".1rem" }} small transparent>
                                            <span style={{ whiteSpace: "nowrap" }}>View/Apply Law Penalties</span>
                                            <AssignIcon className="img-responsive" style={{ width: "2rem" }} />
                                        </Button>
                                    ) : (
                                        !isCustomMaxedOut && (
                                            <Button type={BTN_TYPE.ADD} onClick={() => handleAdd()} transparent>
                                                Add
                                            </Button>
                                        )
                                    )}
                                </div>
                                <TableList
                                    headers={headers}
                                    data={object.companyPenalties}
                                    onSort={onSort}
                                    activeSort={object.sort}
                                    onLoadMore={loadMore}
                                    height={35}
                                    isLoadingMore={isFetching}
                                    customEmptyEl={
                                        <span className="fade">
                                            {isByLaw ? `No Applied ${getCountryName(country, true)} Laws` : "No Custom Penalty"}
                                        </span>
                                    }
                                    small
                                />
                            </div>
                        </div>
                    </form>
                </Card>
            </ModalViewUpdateLayout>
            {isOpenAssignOpen && (
                <AssignLawPenaltiesModal
                    selectedIds={checkedIds.map((id) => ({ id }))}
                    open={isOpenAssignOpen}
                    onClose={setOpenAssignModal}
                    onSave={handleAssign}
                />
            )}
        </>
    );
}

export default CompanyPenalties;

CompanyPenalties.propTypes = {
    type: PropTypes.oneOf(Object.values(ROUTE_TYPE))
};

const CreateTableHeaders = ({ isByLaw, onAction }) => {
    const headers = {
        TITLE: {
            key: "title",
            sortKey: "title",
            label: "Title",
            render: (row) => (
                <span>
                    <span className="bold">{sanitizeWords(row.title)}</span>
                </span>
            )
        },
        DEDUCTION: {
            key: "deduction_type",
            sortKey: "deduction_type",
            label: "Deduction",
            render: (row) => <Tag>{sanitizeWords(row.deduction_type)}</Tag>
        },
        COUNTRY: {
            key: "description",
            label: "Description",
            render: (row) => <p>{row.description}</p>
        },
        ACTION: {
            key: "action",
            style: { display: "flex", justifyContent: "flex-end", paddingRight: ".5rem" },
            render: (row) => {
                return (
                    <div className="flex gap-05">
                        <Button
                            onClick={() => typeof onAction === "function" && onAction(row, PREVIEW)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <PreviewIcon className="fade hover-svg" style={{ width: "1.4rem" }} />
                        </Button>
                        <Button
                            onClick={() =>
                                createConfirmAlert({
                                    title: isByLaw ? "Remove Applied Law Penalty" : "Remove Custom Penalty",
                                    content: isByLaw
                                        ? "You can still reapply this penalty law by applying it again through Add button"
                                        : "Are you sure? This cannot be undone.",
                                    onConfirm: (onClose) => {
                                        typeof onAction === "function" && onAction(row, REMOVE);
                                        onClose();
                                    }
                                })
                            }
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            disabled={!!row.usageCount}
                            transparent
                        >
                            {isByLaw ? (
                                <UnApplyIcon className="hover-svg" style={{ width: "1.4rem", color: "red" }} />
                            ) : (
                                <DeleteIcon className="hover-svg" style={{ width: "1.4rem", color: "red" }} />
                            )}
                        </Button>
                    </div>
                );
            }
        }
    };
    return { data: Object.values(headers), original: headers };
};
