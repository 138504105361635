import { apiSlice } from "../../../app/apiSlice";

const BASE_PATH = "/api/company/employee/submitted-form";

const END_POINTS = {
    deleteEmployeeSubmittedForms: { method: "delete", path: "delete" },
    loadAllEmployeeSubmittedForms: { method: "post", path: "load-all" },
    loadAllLazyEmployeeSubmittedForms: { method: "post", path: "load-all-lazy" },
    getEmployeeSubmittedForms: { method: "get", path: "get-details" },
    sendEmployeeSubmittedForms: { method: "post", path: "send-form" },
    updateStatusEmployeeSubmittedForm: { method: "put", path: "update-status" },
    doFollowUpEmployeeSubmittedForm: { method: "put", path: "follow-up" }
};

export const employeeSubmittedFormsAPI = apiSlice.injectEndpoints({
    reducerPath: "employeeSubmittedFormsAPI",
    endpoints: (builder) => {
        const temp = {};
        Object.entries(END_POINTS).forEach(([key, config]) => {
            temp[key] = builder.mutation({
                query: (queryParams = {}) => {
                    const path = `${BASE_PATH}/${config.path}`;
                    const conf = {
                        url: path,
                        method: config.method,
                        credentials: "include"
                    };
                    if (typeof queryParams.extraPath === "string" || typeof queryParams.extraPath === "number")
                        conf.url = `${path}/${queryParams.extraPath}`;
                    if (Array.isArray(queryParams.extraPaths)) conf.url = `${path}/${queryParams.extraPaths.join("/")}`;
                    if (queryParams.params) conf.params = queryParams.params;
                    if (queryParams.body) conf.body = queryParams.body;
                    return conf;
                }
            });
        });
        return temp;
    }
});

export const {
    useSendEmployeeSubmittedFormsMutation,
    useApproveEmployeeSubmittedFormsMutation,
    useDeleteEmployeeSubmittedFormsMutation,
    useGetEmployeeSubmittedFormsMutation,
    useLoadAllEmployeeSubmittedFormsMutation,
    useLoadAllLazyEmployeeSubmittedFormsMutation,
    useUpdateStatusEmployeeSubmittedFormMutation,
    useDoFollowUpEmployeeSubmittedFormMutation
} = employeeSubmittedFormsAPI;

export default employeeSubmittedFormsAPI.reducer;
