import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../../../common/slice";
import { renderNA, toTimeWithTimeZone } from "../../../../../common/utilities/helper";
import Input from "../../../../../common/components/extra/form/Input";
import { TIME_FORMAT } from "../../const";

function MobileTime({ placeholder, useValue, details }) {
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    if (useValue) {
        return (
            <Input
                label={details.label}
                renderValue={(details.value && toTimeWithTimeZone(details.value, timezone).format(TIME_FORMAT)) || renderNA("N/A")}
                parentStyle={{ gap: ".5rem" }}
                readOnly
                alignRight
            />
        );
    }

    return (
        <div className="mobile-input mobile-input--dropdown input-size">
            <div className="input-box flex center">
                <span className="fade italic">{placeholder}</span>
            </div>
            <div className="dropdown-indicator">
                <ArrowDropDownIcon className="primary-color" style={{ width: "3rem", height: "auto" }} />
            </div>
        </div>
    );
}

export default MobileTime;

MobileTime.propTypes = {
    placeholder: PropTypes.any,
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
