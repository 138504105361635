import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import {
    createFullName,
    getTotalDaysFromDateRange,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toReadableFromDate,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { selectUser } from "../../common/slice";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { FIELD, LEAVE_STATUS } from "./const";

const HEADERS = {
    EMPLOYEE: "employee",
    DATE: "date",
    REJOINED_DATE: "rejoined_date",
    STATUS: "status",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;
    const today = toTimeWithTimeZone(new Date(), timezone);

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.EMPLOYEE: {
                temp.sortKey = "index1";
                temp.label = "Employee";
                temp.style.margin = ".5rem 0";
                temp.style.width = "20rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar
                                    name={createFullName(row.employee.first_name, row.employee.last_name)}
                                    src={row.employee.photo}
                                    size={30}
                                    small
                                />
                                <div className="flex column gap-05">
                                    <div
                                        className="link-hover"
                                        style={{ fontWeight: "bold" }}
                                        onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)}
                                    >
                                        {createFullName(row.employee.first_name, row.employee.last_name)}
                                    </div>
                                    <div className="flex gap-05 wrap">
                                        <span className="fade small-font flex" style={{ alignItems: "center" }}>
                                            Payment Type:
                                        </span>
                                        <div className="flex gap-05">
                                            <Tag>{sanitizeWords(row[FIELD.PAYMENT_TYPE])}</Tag>
                                        </div>
                                    </div>
                                    <div className="flex gap-05 wrap">
                                        <span className="fade small-font flex" style={{ alignItems: "center" }}>
                                            Leave Type:
                                        </span>
                                        <div className="flex gap-05">
                                            <Tag>{sanitizeWords(row[FIELD.LEAVE_TYPE])}</Tag>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.DATE: {
                temp.sortKey = "index2";
                temp.style.width = "20rem";
                temp.label = "Date";
                temp.render = (_, row) => {
                    const startTime = row[FIELD.START_DATE] && toTimeWithTimeZone(row[FIELD.START_DATE], timezone);
                    const endTime = row[FIELD.END_DATE] && toTimeWithTimeZone(row[FIELD.END_DATE], timezone);

                    const startddd = startTime && startTime.format("ddd");
                    const startFormat = startTime && startTime.format("MMM DD, YYYY");

                    const endddd = endTime && endTime.format("ddd");
                    const endFormat = endTime && endTime.format("MMM DD, YYYY");

                    const totalDays = getTotalDaysFromDateRange(row[FIELD.START_DATE], row[FIELD.END_DATE], timezone);
                    const isSingleDay = totalDays === 1;

                    return (
                        <div className="flex column gap-05">
                            <span className="flex gap-05">
                                <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                    <span className="fade semi-bold">{startddd}</span>&nbsp;
                                    <span className="semi-bold">{startFormat}</span>
                                    {isSingleDay && <span className="fade">&nbsp;(Whole Day)</span>}
                                </span>
                                {!isSingleDay && (
                                    <>
                                        {startddd && endddd && "-"}
                                        <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                            <span className="fade semi-bold">{endddd}</span>&nbsp;
                                            <span className="semi-bold">{endFormat}</span>
                                        </span>
                                    </>
                                )}
                            </span>
                            {!isSingleDay && (
                                <div className="flex gap-05 small-font">
                                    <span className="fade small-font flex align-center">Total Days:</span>
                                    <div className="flex gap-03">
                                        <span className="semi-bold">{totalDays}</span>
                                        <span className="fade">Day(s)</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                };
                break;
            }
            case HEADERS.REJOINED_DATE: {
                temp.sortKey = "index3";
                temp.label = "Rejoined Date";
                temp.render = (_, row) => {
                    const rejoinedDate = row[FIELD.REJOINED_DATE] && toTimeWithTimeZone(row[FIELD.REJOINED_DATE], timezone);
                    const startddd = rejoinedDate && rejoinedDate.format("ddd");
                    const startFormat = rejoinedDate && rejoinedDate.format("MMM DD, YYYY");
                    const endDate = row[FIELD.END_DATE] && toTimeWithTimeZone(row[FIELD.END_DATE], timezone);
                    const isEnded = today.isAfter(endDate);
                    const approved = row[FIELD.STATUS] == LEAVE_STATUS.APPROVED;

                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                {rejoinedDate ? (
                                    <span className="flex" style={{ whiteSpace: "nowrap" }}>
                                        <span className="fade semi-bold">{startddd}</span>&nbsp;
                                        <span className="semi-bold">{startFormat}</span>
                                    </span>
                                ) : (
                                    <span className="small-font">{renderNA(isEnded && approved && "Not Yet Rejoined")}</span>
                                )}
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.STATUS: {
                temp.label = "Status";
                temp.render = (_, row) => {
                    const cancelled = row[FIELD.STATUS] == LEAVE_STATUS.CANCELED;
                    const approved = row[FIELD.STATUS] == LEAVE_STATUS.APPROVED;
                    return (
                        <div className="flex column gap-05 overflow-hidden">
                            <span className="text-ellipsis flex gap-05">
                                <Tag className={cancelled ? "dark-gray" : approved ? "green" : ""}>{sanitizeWords(row[FIELD.STATUS])}</Tag>
                            </span>
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "end",
                    paddingRight: "1rem",
                    alignItems: "center"
                };
                temp.render = (_, row) => (
                    <>
                        <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                    </>
                );
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
