import React from "react";
import PropTypes from "prop-types";
import { useGetLeaveSummary } from "./hooks";
import Empty from "../../../common/components/extra/Empty";
import Loader from "../../../common/components/extra/Loader";

function LeaveSummary({ employeeId }) {
    const [summary, { isLoading }] = useGetLeaveSummary(employeeId, { runOnMount: true });

    const listItem = (label, value, subtext) => (
        <li className="leave-summary__item">
            <div className="leave-summary__item__content">
                <span className="fade small-font">{label}:</span>
                <span className="primary-color semi-bold small-font">{isLoading ? <Loader style={{ width: "1.5rem" }} relative /> : value}</span>
            </div>
            {subtext && !isLoading && (
                <div className="leave-summary__item__subtext">
                    <span className="fade small-font">{subtext.label}:</span>
                    <span className="primary-color semi-bold small-font">
                        {isLoading ? <Loader style={{ width: "1.5rem" }} relative /> : subtext.value}
                    </span>
                </div>
            )}
        </li>
    );

    const createValueWithAfterExtra = (value, afterExtra) => (
        <span className="semi-bold">
            <span className="primary-color">{value}</span>&nbsp;
            <span className="fade">{afterExtra}</span>
        </span>
    );

    return (
        <div className="leave-summary w100">
            <div className="leave-summary__inner">
                <div className="leave-summary__header">
                    <h3>Leave Summary</h3>
                </div>
                {!summary || isLoading ? (
                    <Empty style={{ margin: "auto" }} message={employeeId ? "No Leave Records" : "Select an Employee"} isLoading={isLoading} />
                ) : (
                    <>
                        <div className="leave-summary__content flex column gap-03">
                            <ul>
                                {!!summary?.annual &&
                                    "earned" in (summary?.annual || {}) &&
                                    listItem("Earned Annual Leaves", createValueWithAfterExtra(summary.annual.earned, "Day(s)"))}
                                {!!summary?.annual &&
                                    "taken" in (summary?.annual || {}) &&
                                    listItem("Total Annual Leaves Taken", createValueWithAfterExtra(summary.annual.taken, "Day(s)"))}
                                {!!summary?.annual &&
                                    "available" in (summary?.annual || {}) &&
                                    listItem("Remaining Annual Leaves", createValueWithAfterExtra(summary.annual.available, "Day(s)"))}

                                <div className="line"></div>
                                {!!summary?.casual &&
                                    "paid" in (summary?.casual || {}) &&
                                    listItem("Casual Leaves Taken (Paid)", createValueWithAfterExtra(summary.casual.paid, "Day(s)"))}
                                {!!summary?.casual &&
                                    "unpaid" in (summary?.casual || {}) &&
                                    listItem("Casual Leaves Taken (Unpaid)", createValueWithAfterExtra(summary.casual.unpaid, "Day(s)"))}

                                {!!summary &&
                                    "unpaid" in summary &&
                                    listItem("Unpaid/Personal Leaves", createValueWithAfterExtra(summary.unpaid, "Day(s)"))}
                                {!!summary && "sick" in summary && listItem("Sick Leaves", createValueWithAfterExtra(summary.sick, "Day(s)"))}
                                {!!summary &&
                                    "emergency" in summary &&
                                    listItem("Emergency Leaves", createValueWithAfterExtra(summary.emergency, "Day(s)"))}
                                {!!summary &&
                                    "bereavement" in summary &&
                                    listItem("Bereavement Leaves", createValueWithAfterExtra(summary.bereavement, "Day(s)"))}
                                {!!summary &&
                                    "maternity" in summary &&
                                    listItem("Maternity Leaves", createValueWithAfterExtra(summary.maternity, "Day(s)"))}

                                {!!summary &&
                                    "paternity" in summary &&
                                    listItem("Paternity Leaves", createValueWithAfterExtra(summary.paternity, "Day(s)"))}
                                {!!summary && "study" in summary && listItem("Study Leaves", createValueWithAfterExtra(summary.study, "Day(s)"))}
                                {!!summary && "hajj" in summary && listItem("Hajj Leaves", createValueWithAfterExtra(summary.hajj, "Day(s)"))}
                                {!!summary && "umrah" in summary && listItem("Umrah Leaves", createValueWithAfterExtra(summary.umrah, "Day(s)"))}
                                {!!summary &&
                                    "sabbatical" in summary &&
                                    listItem("Sabbatical Leaves", createValueWithAfterExtra(summary.sabbatical, "Day(s)"))}
                                {!!summary?.compensatory &&
                                    "days" in (summary?.compensatory || {}) &&
                                    listItem(
                                        "Compensatory Leaves Taken",
                                        createValueWithAfterExtra(summary.compensatory.days, "Day(s)"),
                                        "offset_hours" in (summary?.compensatory || {}) && {
                                            label: "Total Offset Hours Taken",
                                            value: createValueWithAfterExtra(summary.compensatory.offset_hours, "Hour(s)")
                                        }
                                    )}
                            </ul>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default LeaveSummary;

LeaveSummary.propTypes = {
    employeeId: PropTypes.number
};
