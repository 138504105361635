import { DATE_FILTER, EMPLOYEE_FILES } from "../../../common/utilities/const";
import { toFormFields } from "../../../common/utilities/helper";

export const BASE_CLASS = "tk-employees";
export const BASE_CLASS_UPDATE_MODAL = BASE_CLASS + "__modal-content-update";

export const UNIQUE_KEY = "id";

export const FILTER_TYPE = {
    DEPARTMENT: 0x1,
    DESIGNATION: 0x2,
    ROLE: 0x3,
    EXPIRY_DATE: 0x4,
    DEVICE: 0x5
};

export const MAIN_FILTER = {
    ALL_IDS: "ALL_IDS",
    BY_RESIDENCE_ID: "BY_RESIDENCE_ID",
    BY_VISA_ID: "BY_VISA_ID",
    BY_PASSPORT_ID: "BY_PASSPORT_ID",
    BY_CONTRACT_ID: "BY_CONTRACT_ID",
    BY_LABOR_CARD: "BY_LABOR_CARD",
    ALL_EXPIRED_ID: "ALL_EXPIRED_ID",
    ALL_EXPIRING_ID: "ALL_EXPIRING_ID"
};

export const CUSTOM_DATE_FILTER = {
    ...DATE_FILTER,
    EXPIRED_ID: "EXPIRED_ID",
    EXPIRING_ID: "EXPIRING_ID"
};

export const VIEW_MODAL_TYPE = {
    PROJECT: 0x1,
    GEOZONE: 0x2,
    CONTRACT_COPY: 0x3,
    SALARY: 0x4,
    PHOTO: 0x6,
    ID_COPY: 0x7,
    VISA_COPY: 0x8,
    PASSPORT_COPY: 0x9,
    LABOR_CARD_COPY: 0x10,
    SUBMITTED_FORM_PREVIEW_FILE: 0x11,
    SUBMITTED_FORM_PREVIEW_IMAGE: 0x12,
    LEAVE_PROOF_FILE: 0x13
};

export const CUSTOM_ACTION = {
    VIEW_FILES: "VIEW_FILES",
    VIEW_DEPARTMENT: "VIEW_DEPARTMENT",
    RESET_DEVICE: "RESET_DEVICE"
};

export const DEVICE_FILTER = {
    WITH_DEVICE: "WITH_DEVICE",
    NO_DEVICE: "NO_DEVICE"
};

export const STATUS = {
    CUSTOM: {
        key: "custom",
        color: "yellow",
        label: "CUSTOM"
    },
    MIXED: {
        key: "modified",
        color: "brown",
        label: "MIXED"
    },
    ORIGINAL: {
        key: "original",
        color: "",
        label: "ORIGINAL"
    }
};

export const PASSWORD_KEYS_TO_USE = {
    RESIDENCE_ID: {
        value: "residenceID",
        label: "Residence ID"
    },
    PASSPORT_ID: {
        value: "passportID",
        label: "Password ID"
    },
    VISA_ID: {
        value: "visaID",
        label: "Visa ID"
    },
    MOBILE_NUMBER: {
        value: "mobile_number",
        label: "Mobile Number"
    }
};

export const ASSIGN_TYPE = {
    SITE: 0x1,
    WORK_SHIFT: 0x2,
    DEFAULT: 0x3,
    EXPIRING: 0x4
};

export const LAZY_REQ_TYPE = {
    EXPIRING: "expiring"
};

export const EMPLOYEE_LOCATION_TYPES = {
    STATIONARY: "STATIONARY",
    MOBILE: "MOBILE",
    FIELD: "FIELD"
};

export const BANK_DETAIL_FIELDS = {
    SHORT_NAME: "short_name",
    ACCOUNT_NUMBER: "account_number",
    IBAN: "iban"
};

export const CONTRACT_FIELDS = {
    CONTRACT_STATUS: "status",
    CONTRACT_TERM: "contract_term",
    CONTRACT_TYPE: "contract_type",
    HIRING_DATE: "hiring_date",
    JOINING_DATE: "joining_date",
    END_DATE: "end_date",
    PROBATION_PERIOD: "probation_period",
    BASIC_AMOUNT: "basic_amount",
    SALARY_FREQUENCY: "salary_frequency",
    ALLOWANCE: "allowance",
    JOB_DESCRIPTION: "job_description"
};

export const CONTRACT_ALLOWANCE_FIELDS = {
    ACCOMMODATION: "accommodation",
    COMMUNICATION: "communication",
    EDUCATION: "education",
    FOOD: "food",
    AIR_TICKET: "air_ticket",
    TRANSPORTATION: "transportation",
    OTHER: "other"
};

export const TITLE_FIELDS = {
    ID: "id",
    TITLE: "title"
};

export const NAME_FIELDS = {
    ID: "id",
    NAME: "name"
};

export const PERSONAL_FIELDS = {
    USER_LEVEL: "user_level",
    COMPANY_ID: "companyID",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name",
    GENDER: "gender",
    NATIONALITY: "nationality",
    BIRTHDATE: "birthdate",
    MARITAL_STATUS: "marital_status",
    MOBILE_NUMBER: "mobile_number",
    EMAIL_ADDRESS: "email_address",
    NUMBER_WEEKLY_OFF_DAYS: "number_weekly_off_days",
    OFF_DAYS: "off_days",
    PASSWORD_KEY_TO_USE: "passwordKeyToUse",
    SALARY_PAYMENT_MODE: "salary_payment_mode",
    SCHEDULE_TYPE: "schedule_type",
    LOCATION_TYPE: "location_type",
    RESIDENCE_ID: "residenceID",
    RESIDENCE_ID_EXPIRATION: "residenceID_expiration",
    PASSPORT_ID: "passportID",
    PASSPORT_ID_EXPIRATION: "passportID_expiration",
    VISA_ID: "visaID",
    VISA_ID_EXPIRATION: "visaID_expiration",
    LABOR_CARD_NUMBER: "labor_card_number",
    LABOR_CARD_EXPIRATION: "labor_card_expiration",
    HOME_ADDRESS: "home_address",
    GRADE: "grade",
    DIRECT_SUPERVISOR: "directSupervisor",
    DIRECT_MANAGER: "directManager"
};

export const DEFAULT_FIELDS = {
    ...toFormFields(PERSONAL_FIELDS, null, null, (name) => {
        switch (name) {
            case PERSONAL_FIELDS.NUMBER_WEEKLY_OFF_DAYS:
                return 1;
            case PERSONAL_FIELDS.OFF_DAYS:
                return ["FRI"];
            default:
                return "";
        }
    }),
    uploads: toFormFields(EMPLOYEE_FILES, null, "key", () => null),
    CompanyDepartment: toFormFields(TITLE_FIELDS),
    CompanyDesignation: toFormFields(TITLE_FIELDS),
    EmployeeWorkShift: toFormFields(TITLE_FIELDS),
    EmployeeBankDetail: toFormFields(BANK_DETAIL_FIELDS),
    EmployeeContract: toFormFields(CONTRACT_FIELDS, null, null, (name) => {
        switch (name) {
            case CONTRACT_FIELDS.ALLOWANCE:
                return toFormFields(CONTRACT_ALLOWANCE_FIELDS, null, null, () => 0);
            case CONTRACT_FIELDS.BASIC_AMOUNT:
                return 0;
            case CONTRACT_FIELDS.PROBATION_PERIOD:
                return 1;
            default:
                return "";
        }
    }),
    CompanySites: [],
    Role: toFormFields(NAME_FIELDS)
};

export const DEFAULT_FILE_FIELDS = {
    residenceID: "",
    residenceID_expiration: "",
    passportID: "",
    passportID_expiration: "",
    visaID: "",
    visaID_expiration: "",
    labor_card_number: "",
    labor_card_expiration: "",
    uploads: toFormFields(EMPLOYEE_FILES, null, "key", () => null),
    EmployeeContract: {
        end_date: ""
    }
};

export const DEFAULT_DEPARTMENT_FIELDS = {
    CompanyDepartment: toFormFields(TITLE_FIELDS),
    CompanyDesignation: toFormFields(TITLE_FIELDS),
    directSupervisor: "",
    directManager: ""
};

export const ASSIGN_DEPARTMENT_FIELDS = {
    id: "",
    title: "",
    supervisor_id: "",
    manager_id: ""
};
