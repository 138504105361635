import PropTypes from "prop-types";
import React from "react";
import { WORK_HISTORY_SHIFT_STATUS } from "../../../common/utilities/const";
import Loader from "../../../common/components/extra/Loader";
import ShiftStatusCapsule from "./ShiftStatusCapsule";
import Tag from "../../../common/components/extra/Tag";
import { PROGRESS_TYPE } from "./const";
import { useGetWorkHistoryStatusByRangeAndStatus } from "./hooks";

function ShiftStatus({ isLoading, statuses = [], firstShift, secondShift, simple, showAvailable, onlyShiftOne, onlyShiftTwo, noTitle }) {
    const status = useGetWorkHistoryStatusByRangeAndStatus({
        shiftOne: firstShift,
        shiftTwo: secondShift,
        statuses
    });

    const shiftOneData = status.shiftOne || {};
    const shiftTwoData = status.shiftTwo || {};
    const shows1 = !isLoading && shiftOneData.label;
    const shows2 = !isLoading && shiftTwoData.label;
    const showAllShift = !onlyShiftTwo && !onlyShiftOne;
    const showShift1El = (onlyShiftOne && !onlyShiftTwo) || showAllShift;
    const showShift2El = (onlyShiftTwo && !onlyShiftOne) || showAllShift;

    const createAfterExtra = (label) => {
        const isOngoing = label == PROGRESS_TYPE.ONGOING;
        const isIncomplete = label == PROGRESS_TYPE.INCOMPLETE;
        return {
            el: simple ? <>{label}</> : "",
            className: isOngoing ? "dark-yellow" : isIncomplete ? "red" : "green"
        };
    };

    return (
        <div className={`shift-box wrap ${simple ? "simple" : ""}`}>
            {showShift1El && (
                <div className="b1 flex column gap-03">
                    {isLoading ? (
                        <Loader style={{ width: "2rem" }} relative />
                    ) : (
                        !simple && <span className="box__value bold">{shiftOneData.label}</span>
                    )}
                    {((shiftOneData.status?.length && simple) || !simple) && !noTitle && (
                        <span className={`box__label fade ${simple ? "small-font" : "xs-font bold"}`}>
                            {simple ? !!shiftTwoData.status?.length && "Shift 1" : "Shift 1 Status"}
                        </span>
                    )}
                    {shows1 &&
                        (shiftOneData.status?.length ? (
                            <div className="shift-box__tag">
                                <ShiftStatusCapsule
                                    statuses={shiftOneData.status}
                                    incomplete={shiftOneData.isIncomplete}
                                    afterExtra={createAfterExtra(shiftOneData.label)}
                                />
                            </div>
                        ) : (
                            showAvailable && (
                                <Tag className="light-gray solid">
                                    <span>NOT AVAILABLE</span>
                                </Tag>
                            )
                        ))}
                </div>
            )}
            {showShift2El && (
                <div className="b2 flex column gap-03">
                    {isLoading ? (
                        <Loader style={{ width: "2rem" }} relative />
                    ) : (
                        !simple && <span className="box__value bold">{shiftTwoData.label}</span>
                    )}
                    {((shiftTwoData.status?.length && simple) || !simple) && !noTitle && (
                        <span className={`box__label fade ${simple ? "small-font" : "xs-font bold"}`}>{simple ? "Shift 2" : "Shift 2 Status"}</span>
                    )}
                    {shows2 &&
                        (shiftTwoData.status?.length ? (
                            <div className="shift-box__tag">
                                <ShiftStatusCapsule
                                    statuses={shiftTwoData.status}
                                    incomplete={shiftTwoData.isIncomplete}
                                    afterExtra={createAfterExtra(shiftTwoData.label)}
                                />
                            </div>
                        ) : (
                            showAvailable && (
                                <Tag className="light-gray solid">
                                    <span>NOT AVAILABLE</span>
                                </Tag>
                            )
                        ))}
                </div>
            )}
        </div>
    );
}

export default ShiftStatus;

ShiftStatus.propTypes = {
    isLoading: PropTypes.bool,
    showAvailable: PropTypes.bool,
    noTitle: PropTypes.bool,
    statuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(WORK_HISTORY_SHIFT_STATUS))),
    firstShift: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string
    }),
    secondShift: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string
    }),
    timezone: PropTypes.string,
    simple: PropTypes.bool,
    onlyShiftOne: PropTypes.bool,
    onlyShiftTwo: PropTypes.bool
};
