import React from "react";
import PropTypes from "prop-types";
import Input from "../../../../../common/components/extra/form/Input";
import AddIcon from "@mui/icons-material/Add";
import { ReactComponent as ImageUploadIcon } from "../../../../../assets/images/image-upload-icon.svg";
import { renderNA, sanitizeWords } from "../../../../../common/utilities/helper";
import { VIEW_MODAL_TYPE } from "../../../employees/const";

function MobileImageUpload({ useValue, details, onViewFile }) {
    if (useValue) {
        const value = details.value || [];
        return (
            <Input
                label={sanitizeWords(details.label)}
                renderValue={
                    <div className="flex column gap-03">
                        {value.length
                            ? value.map((filename, idx) => (
                                  <span
                                      key={idx}
                                      className="link-hover primary-color"
                                      onClick={() =>
                                          onViewFile?.({
                                              type: VIEW_MODAL_TYPE.SUBMITTED_FORM_PREVIEW_IMAGE,
                                              filename
                                          })
                                      }
                                  >
                                      Preview Image {(idx + 1).toString().padStart(2, 0)}
                                  </span>
                              ))
                            : renderNA("N/A")}
                    </div>
                }
                parentStyle={{ gap: ".5rem" }}
                readOnly
                alignRight
            />
        );
    }

    return (
        <div className="mobile-input mobile-input--file-upload">
            <div className="box-file">
                <ImageUploadIcon className="image" />
            </div>
            <div className="box-add">
                <AddIcon />
            </div>
        </div>
    );
}

export default MobileImageUpload;

MobileImageUpload.propTypes = {
    placeholder: PropTypes.any,
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool,
    onViewFile: PropTypes.func
};
