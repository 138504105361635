import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import SendIcon from "@mui/icons-material/Send";
import PreviewIcon from "@mui/icons-material/Visibility";
import cloneDeep from "lodash/cloneDeep";
import { useSendSubmittedForm } from "./hooks";
import {
    createClass,
    createConfirmAlert,
    createGroup,
    createToast,
    formattedJoiErrorMessage,
    sanitizeWords,
    TOAST_TYPE,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import { BASE_CLASS_SEND_FORM_MODAL } from "./const";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import { SORT_ORDER } from "../../../common/utilities/const";
import { FORM_FIELD_GROUP, FORM_FIELD_TYPE } from "../companyForms/const";
import FieldInputSwitcher from "../companyForms/FieldInputSwitcher";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { useCheckAvailability } from "../companyForms/hooks";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import Tag from "../../../common/components/extra/Tag";
import WorkDetailSelectLazy from "../employeeWorkDetails/WorkDetailSelectLazy";
import ViewModal from "./ViewModal";

function SendFormModal({ open, onClose, onFinish, selected }) {
    const [selectedSubmittedFormId, setSelectedSubmittedFormId] = useState(null);
    const [fields, setFields] = useState(selected.fields);

    const [temp, setTemp] = useState({
        sentToEmployee: null,
        targetEmployee: null
    });

    const [form, setForm] = useState({
        employee_id: null,
        target_employee_id: null,
        target_work_detail_id: null
    });

    const [lastCheckedAvailability, setLastCheckedAvailability] = useState(null);
    const [error, setError] = useState(null);

    const [sendForm, isLoading, { resetCache }] = useSendSubmittedForm();
    const [checkAvailability, isCheckingAvailability] = useCheckAvailability();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const findGroupKey = (groupKey) =>
        groupKey &&
        fields.some(
            (field) =>
                field.data.group === groupKey ||
                (field.data.type === FORM_FIELD_TYPE.GROUP && field.data.validation.value.some((f) => f.data.group === groupKey))
        );

    const hasEmployeeGroup = findGroupKey(FORM_FIELD_GROUP.EMPLOYEE_DETAILS);
    const hasWorkDetailGroup = findGroupKey(FORM_FIELD_GROUP.WORK_DETAILS);

    const fieldValues = useMemo(() => {
        return fields
            .filter((field) => field.data.validation.forAdmin || field.data.type == FORM_FIELD_TYPE.GROUP)
            .reduce((acc, field) => {
                const fieldId = field.id;
                acc[fieldId] = cloneDeep(field);

                let temp = {
                    id: fieldId,
                    type: field.data.type,
                    group: field.data.group,
                    validation: field.data.validation
                };

                if (temp.type === FORM_FIELD_TYPE.GROUP) {
                    // If it's a group, structure subfields as objects keyed by their ids
                    acc[fieldId].data.validation.value = field.data.validation.value.reduce((groupAcc, subField) => {
                        const subFieldId = subField.id;
                        if (subField.data.validation.forAdmin) {
                            groupAcc[subFieldId] = {
                                id: subFieldId,
                                type: subField.data.type,
                                group: subField.data.group,
                                ...(subField.data.validation || {})
                            };
                        }
                        return groupAcc;
                    }, {});
                } else {
                    if (temp.validation.forAdmin) {
                        acc[fieldId] = {
                            ...temp,
                            ...temp.validation
                        };
                        delete acc[fieldId].validation;
                    }
                }

                return acc;
            }, {});
    }, [selected, fields]);

    const disableSave =
        isLoading ||
        isCheckingAvailability ||
        !form.employee_id ||
        !!error ||
        (hasEmployeeGroup && !form.target_employee_id) ||
        (hasWorkDetailGroup && !form.target_work_detail_id) ||
        !!lastCheckedAvailability?.alreadyExist;

    const normalizeFieldValues = () => {
        const clonedFieldValues = cloneDeep(fieldValues);

        const normalizeFields = (id, field, obj = {}) => {
            if (FORM_FIELD_TYPE.DATE_TIME == field.type) {
                if (obj[id].value) {
                    obj[id].value = toTimeWithTimeZone(new Date(obj[id].value), timezone).format();
                }
            } else if (FORM_FIELD_TYPE.DATE_RANGE == field.type) {
                if (obj[id].value.length) {
                    obj[id].value = obj[id].value.map((date) => toTimeWithTimeZone(new Date(date), timezone).format());
                }
            } else if (FORM_FIELD_TYPE.PHONE_NUMBER == field.type) {
                if (obj[id]?.value?.mobile) {
                    obj[id].value = obj[id].value.mobile;
                }
            } else if (FORM_FIELD_TYPE.NUMBER_INPUT == field.type) {
                if (typeof obj[id]?.value == "string") {
                    obj[id].value = Number(obj[id].value);
                }
            }
            return obj;
        };

        for (const pId in clonedFieldValues) {
            if (Object.prototype.hasOwnProperty.call(clonedFieldValues, pId)) {
                const field = clonedFieldValues[pId];
                const type = field?.data?.type || field.type;

                if (type == FORM_FIELD_TYPE.GROUP) {
                    const value = field.data.validation.value;
                    for (const id in value) {
                        if (Object.prototype.hasOwnProperty.call(value, id)) {
                            const groupField = value[id];

                            // remove "actualValue" and use the value for "value"
                            if ("actualValue" in groupField) {
                                clonedFieldValues[pId].data.validation.value[id].value = groupField.actualValue;
                                delete clonedFieldValues[pId].data.validation.value[id].actualValue;
                                normalizeFields(id, groupField, clonedFieldValues[pId].data.validation.value);
                            }
                        }
                    }
                } else {
                    // remove "actualValue" and use the value for "value"
                    if ("actualValue" in field) {
                        clonedFieldValues[pId].value = field.actualValue;
                        delete clonedFieldValues[pId].actualValue;
                        normalizeFields(pId, field, clonedFieldValues);
                    }
                }
            }
        }

        return clonedFieldValues;
    };

    const handleSendForm = async () => {
        if (isLoading || !form.employee_id) {
            return;
        }
        try {
            const clonedFieldValues = normalizeFieldValues();

            const result = await sendForm({
                ...form,
                form_id: selected.id,
                fieldValues: clonedFieldValues
            });
            if (result.error) {
                throw new Error(result.error.message);
            }
            createToast(`Form successfully sent.`, TOAST_TYPE.SUCCESS);
            onFinish?.(result);
            onClose?.();
            setError(null);
        } catch (error) {
            setError({ message: error?.message || error });
            createToast(`Failed to send form to an employee. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const handleFieldChange = (name, value, field, parentId, extra = {}) => {
        let temp = cloneDeep(fields).reduce(
            (prev, curr) => ({
                ...prev,
                [curr.id]: curr
            }),
            {}
        );

        if (!parentId) {
            // insert/update new extra validation fields
            temp[name].data.validation = {
                ...(temp[name].data.validation || {}),
                ...extra
            };
            temp[name].data.validation.actualValue = value;
        } else {
            if (temp[parentId]) {
                temp[parentId].data.validation.value = temp[parentId].data.validation.value.reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.id]: curr
                    }),
                    {}
                );
                // insert/update new extra validation fields
                temp[parentId].data.validation.value[name].data.validation = {
                    ...(temp[parentId].data.validation.value[name].data.validation || {}),
                    ...extra
                };

                temp[parentId].data.validation.value[name].data.validation.actualValue = value;
                temp[parentId].data.validation.value = Object.values(temp[parentId].data.validation.value);
            }
        }

        if (error) {
            setError(null);
        }

        setFields(Object.values(temp));
    };

    const handleFormChange = async (name, value) => {
        let clonedForm = { ...form };

        switch (name) {
            case "employee_id": {
                setTemp({
                    ...temp,
                    sentToEmployee: value
                });
                setForm({
                    ...clonedForm,
                    [name]: value.id
                });
                break;
            }
            case "target_employee_id": {
                if (
                    (lastCheckedAvailability &&
                        (lastCheckedAvailability.formId != selected.id || lastCheckedAvailability.targetEmployeeId != value.id)) ||
                    !lastCheckedAvailability
                ) {
                    const response = await checkAvailability(selected.id, value.id);

                    if (response?.error) {
                        setError({ message: "Failed to check availability. Please try again later." });
                        setLastCheckedAvailability(null);
                    } else {
                        setLastCheckedAvailability({
                            formId: selected.id,
                            targetEmployeeId: value.id,
                            alreadyExist: !!response,
                            ...(response || {})
                        });
                        if (error) {
                            setError(null);
                        }
                    }
                }
                clonedForm.target_work_detail_id = null;
                setTemp({
                    ...temp,
                    targetEmployee: value
                });
                setForm({
                    ...clonedForm,
                    [name]: value.id
                });
                break;
            }
            default:
                setForm({
                    ...clonedForm,
                    [name]: value
                });
                break;
        }
    };

    const onViewSubmittedFormClose = async () => {
        const response = await checkAvailability(selected.id, form["target_employee_id"], true);

        if (response?.error) {
            setError({ message: "Failed to check availability. Please try again later." });
            setLastCheckedAvailability(null);
        } else {
            setLastCheckedAvailability({
                formId: selected.id,
                targetEmployeeId: form["target_employee_id"],
                alreadyExist: !!response,
                ...(response || {})
            });
            if (error) {
                setError(null);
            }
        }
        setSelectedSubmittedFormId(null);
        resetCache();
    };

    return (
        <>
            <BaseUpdateModal
                title={`Send ${sanitizeWords(selected.name)}`}
                open={open}
                onClose={onClose}
                onSave={() =>
                    !disableSave &&
                    createConfirmAlert({
                        title: `Send ${sanitizeWords(selected.name)}`,
                        content: "Proceed on sending the form to the selected employee?",
                        onConfirm: (close) => {
                            close();
                            handleSendForm();
                        }
                    })
                }
                isLoading={isLoading || isCheckingAvailability}
                disableSave={disableSave}
                styles={{
                    content: {
                        margin: "1rem",
                        maxHeight: "90vh",
                        minWidth: "25rem",
                        maxWidth: "40rem"
                    },
                    body: {
                        overflow: "auto"
                    }
                }}
                saveLabel="Send"
                saveIcon={<SendIcon />}
                isForm
                small
            >
                <div className={createClass(BASE_CLASS_SEND_FORM_MODAL)}>
                    <div className={createClass("__inner", BASE_CLASS_SEND_FORM_MODAL)}>
                        <SectionCollapseError show={!!error}>{formattedJoiErrorMessage({ error, title: "Sending Failed" })}</SectionCollapseError>
                        <div className={createClass("__inner-row flex column gap-1", BASE_CLASS_SEND_FORM_MODAL)} style={{ marginBottom: "2rem" }}>
                            {createGroup({
                                base: BASE_CLASS_SEND_FORM_MODAL,
                                title: "",
                                body: (
                                    <>
                                        <EmployeeSelectLazy
                                            key={form.target_employee_id + "employee_id"}
                                            label="Send To"
                                            value={
                                                form.employee_id && {
                                                    ...temp.sentToEmployee,
                                                    id: form.employee_id,
                                                    value: form.employee_id
                                                }
                                            }
                                            onChange={(value) => handleFormChange("employee_id", value)}
                                            excludeIds={[form.target_employee_id].filter(Boolean)}
                                            isLoading={isLoading}
                                            sortBy="index3"
                                            sortOrder={SORT_ORDER.ASC}
                                            menuPortalTarget={document.body}
                                            isSearchable
                                            required
                                            allowOnShift
                                            isOutlined
                                            disabledOutline
                                            showDepartment
                                        />
                                        {hasEmployeeGroup && (
                                            <EmployeeSelectLazy
                                                key={form.employee_id + "target_employee_id"}
                                                label="Target Employee"
                                                value={
                                                    form.target_employee_id && {
                                                        ...temp.targetEmployee,
                                                        id: form.target_employee_id,
                                                        value: form.target_employee_id
                                                    }
                                                }
                                                onChange={(value) => handleFormChange("target_employee_id", value)}
                                                excludeIds={[form.employee_id].filter(Boolean)}
                                                isLoading={isLoading}
                                                sortBy="index3"
                                                sortOrder={SORT_ORDER.ASC}
                                                menuPortalTarget={document.body}
                                                isSearchable
                                                required
                                                allowOnShift
                                                isOutlined
                                                disabledOutline
                                                showDepartment
                                            />
                                        )}
                                        {hasWorkDetailGroup && form.target_employee_id && (
                                            <WorkDetailSelectLazy
                                                key={form.target_employee_id + "target_work_detail_id"}
                                                label="Target Work Type"
                                                value={
                                                    form.target_work_detail_id && {
                                                        id: form.target_work_detail_id,
                                                        value: form.target_work_detail_id
                                                    }
                                                }
                                                onChange={(value) => handleFormChange("target_work_detail_id", value.id)}
                                                menuPortalTarget={document.body}
                                                byEmployeeId={form.target_employee_id}
                                                required
                                                allowInUse
                                                isOutlined
                                                isClearable
                                                disabledOutline
                                                noAdd
                                            />
                                        )}
                                    </>
                                )
                            })}

                            {lastCheckedAvailability?.alreadyExist && (
                                <SectionCollapseWarning show alwaysOpen>
                                    <div>
                                        <div className="flex gap-05 align-center">
                                            <span>The target employee already have existing form:</span>
                                        </div>
                                        <ul className="indent-1" style={{ paddingTop: ".5rem" }}>
                                            <li className="has-list-style indent-1">
                                                <div className="flex gap-05 align-center">
                                                    <span>Ref #:</span>
                                                    <div
                                                        className="flex gap-05 align-center"
                                                        onClick={() => setSelectedSubmittedFormId(lastCheckedAvailability.id)}
                                                    >
                                                        <strong className="small-font link-hover">{lastCheckedAvailability.referenceNumber}</strong>
                                                        <PreviewIcon
                                                            className="hover-svg"
                                                            style={{
                                                                width: "1.2rem",
                                                                height: "auto"
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="has-list-style indent-1">
                                                <div className="flex gap-05 align-center">
                                                    <span>Status:</span>
                                                    <Tag>{lastCheckedAvailability.status}</Tag>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </SectionCollapseWarning>
                            )}

                            {!lastCheckedAvailability?.alreadyExist &&
                                !!Object.values(fieldValues).length &&
                                createGroup({
                                    base: BASE_CLASS_SEND_FORM_MODAL,
                                    title: "Custom Fields",
                                    body: (
                                        <>
                                            {Object.values(fieldValues).map((fieldValue, idx) => (
                                                <FieldInputSwitcher
                                                    key={idx}
                                                    item={fieldValue}
                                                    onChange={handleFieldChange}
                                                    form={form}
                                                    origin={selected}
                                                />
                                            ))}
                                        </>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </BaseUpdateModal>
            {!!selectedSubmittedFormId && (
                <ViewModal
                    id={selectedSubmittedFormId}
                    open={!!selectedSubmittedFormId}
                    onClose={() => onViewSubmittedFormClose()}
                    onBack={() => onViewSubmittedFormClose()}
                />
            )}
        </>
    );
}

SendFormModal.propTypes = {
    open: PropTypes.bool,
    onFinish: PropTypes.func,
    onClose: PropTypes.func,
    selected: PropTypes.object
};

export default SendFormModal;
