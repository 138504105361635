import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import { createFullName, renderNA, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { selectUser } from "../../common/slice";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { createStatusInfoFromStatusLog } from "./helper";

const HEADERS = {
    NAME: "name",
    FORM_NAME: "formName",
    FOLLOW_UP_AT: "status",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.label = "Employee";
                temp.style.width = "20rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const current = row.targetEmployee || row.sourceEmployee;
                    const fullName = createFullName(current.first_name, current.last_name);
                    const photo = current.photo;
                    const sentTo = !!row.targetEmployee && createFullName(row.sourceEmployee.first_name, row.sourceEmployee.last_name);
                    const statusInfo = createStatusInfoFromStatusLog(
                        row.lastStatus?.status,
                        row.lastStatus?.fullName,
                        row.lastStatus?.employee_user_type
                    );
                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center" }}>
                                <LetteredAvatar name={fullName} src={photo} size={30} small />
                                <div className="flex column gap-05 overflow-hidden">
                                    <strong className="link-hover small-font" onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)}>
                                        {row.reference_number}
                                    </strong>
                                    <div
                                        className="link-hover"
                                        style={{ fontWeight: "bold" }}
                                        onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)}
                                    >
                                        {sanitizeWords(fullName)}
                                    </div>
                                    {sentTo && (
                                        <div className="flex gap-05 wrap small-font align-center">
                                            <span className="fade flex">Sent To:</span>
                                            <span className="semi-bold">{sentTo}</span>
                                        </div>
                                    )}
                                    <div className="flex gap-05 wrap">
                                        <span className="fade small-font flex" style={{ alignItems: "center" }}>
                                            Last Status:
                                        </span>
                                        <Tag
                                            className={statusInfo.tagColor}
                                            tooltip={{ message: statusInfo.message || "No status logs" }}
                                            noTooltipIcon
                                        >
                                            <span className="text-ellipsis">{statusInfo.status || "N/A"}</span>
                                        </Tag>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                };
                break;
            }
            case HEADERS.FORM_NAME: {
                temp.style.width = "12rem";
                temp.label = <span style={{ whiteSpace: "nowrap" }}>Form Name</span>;
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden">
                        <span className="semi-bold">{sanitizeWords(row.formName)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.FOLLOW_UP_AT: {
                temp.sortKey = "last_follow_up_at";
                temp.label = <span style={{ whiteSpace: "nowrap" }}>Last Follow up</span>;
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.last_follow_up_at, setting.timezone) || renderNA()}</span>
                    </div>
                );
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {toReadableFromDate(
                                toReadableFromDate(row.updatedAt, setting.timezone) == toReadableFromDate(row.createdAt, setting.timezone)
                                    ? ""
                                    : row.updatedAt,
                                setting.timezone
                            ) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(row.createdAt, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    paddingRight: "1rem",
                    overflow: "unset"
                };
                temp.render = (_, row) => {
                    return (
                        <>
                            <PreviewIcon onClick={() => onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                        </>
                    );
                };
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
