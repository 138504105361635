export const BASE_CLASS = "tk-employees-leaves";
export const BASE_CLASS_VIEW_MODAL = BASE_CLASS + "__modal-content-view";
export const BASE_CLASS_UPDATE_MODAL = BASE_CLASS + "__modal-content-update";
export const BASE_CLASS_EDIT_REJOINED_MODAL = BASE_CLASS + "__modal-content-rejoined";

export const FILTER_TYPE = {
    EMPLOYEE: 0x1,
    LEAVE_TYPE: 0x2,
    PAYMENT_TYPE: 0x3,
    STATUS: 0x4,
    DATE: 0x5
};

export const FIELD = {
    EMPLOYEE: "employee",
    START_DATE: "start_date",
    END_DATE: "end_date",
    LEAVE_TYPE: "leave_type",
    STATUS: "status",
    PAYMENT_TYPE: "payment_type",
    RECORDED_DATE: "recorded_date",
    REJOINED_DATE: "rejoined_date",
    REJOINED_NOTES: "rejoined_notes",
    PROOF_FILE: "document_filename",
    OFFSET_HOURS: "offset_hours",
    REASON: "reason"
};

export const FIELD_OBJECT = {
    [FIELD.START_DATE]: {
        label: "Start Date",
        default: "",
        required: true
    },
    [FIELD.END_DATE]: {
        label: "End Date",
        default: "",
        required: true
    },
    [FIELD.PAYMENT_TYPE]: {
        label: "Payment Type",
        default: "",
        required: true
    },
    [FIELD.LEAVE_TYPE]: {
        label: "Leave Type",
        default: "",
        required: true
    },
    [FIELD.OFFSET_HOURS]: {
        label: "Offset",
        default: "",
        required: true
    },
    [FIELD.REASON]: {
        label: "Notes/Description",
        default: "",
        required: true
    },
    [FIELD.REJOINED_NOTES]: {
        label: "Notes/Description",
        default: "",
        required: true
    },
    [FIELD.REJOINED_DATE]: {
        label: "Rejoined Date",
        default: "",
        required: true
    },
    [FIELD.RECORDED_DATE]: {
        label: "Recorded Date",
        default: "",
        required: true
    },
    [FIELD.PROOF_FILE]: {
        label: "Supporting Document",
        default: "",
        required: true
    },
    [FIELD.STATUS]: {
        label: "Status",
        default: "",
        required: true
    }
};

export const LEAVE_STATUS = {
    APPROVED: "APPROVED",
    REJOINED: "REJOINED",
    CANCELED: "CANCELED" // this can only be applied when a record was already approved, coz record can not be deleted
};

export const LEAVE_TYPE = {
    UNPAID_LEAVE: "UNPAID_LEAVE", //(UL/PL) always UNPAID
    ANNUAL_LEAVE: "ANNUAL_LEAVE", //(AL) always PAID, ALSO KNOWN AS VACATION/EARNED LEAVE (show as Prorate)
    SICK_LEAVE: "SICK_LEAVE", //(SL) always 14days max (full salary), 14days >= 6weeks (50% full salary), >6 weeks (note for termination already) (values will be reset every calendar year)
    EMERGENCY_LEAVE: "EMERGENCY_LEAVE", //(EL) company descretion
    CASUAL_LEAVE: "CASUAL_LEAVE", //(CL) PAID for the first 3 days(should be 1 day at a time), ALSO KNOWN AS PERSONAL LEAVE (if duration is >1day, make as company descretion) (values will be reset every calendar year)
    MATERNITY_LEAVE: "MATERNAL_LEAVE", //(MT) always PAID, at least 50 days (allowed) if (>=1 year) (values will be reset every)
    BEREAVEMENT_LEAVE: "BEREAVEMENT_LEAVE", //(BL) 3 days max, company descretion
    COMPENSATORY_LEAVE: "COMPENSATORY_LEAVE", //(OL) always PAID, ALSO KNOWN AS OFFSET LEAVE
    PATERNITY_LEAVE: "PATERNAL_LEAVE", //(PT) max 5 days allowed but not required, company descretion,
    STUDY_LEAVE: "STUDY_LEAVE", //(STL)
    PARENTAL_LEAVE: "PARENTAL_LEAVE", // (PRL)
    HAJJ_LEAVE: "HAJJ_LEAVE", //HJL
    UMRAH_LEAVE: "UMRAH_LEAVE", //UML
    SABBATICAL_LEAVE: "SABBATICAL_LEAVE" // SBL
};

export const LEAVE_PAYMENT_TYPE = {
    UNPAID: "UNPAID",
    PAID: "PAID",
    PARTIAL: "PARTIAL"
};

export const UNPAID_LEAVE_TYPES = [LEAVE_TYPE.UNPAID_LEAVE, LEAVE_TYPE.HAJJ_LEAVE, LEAVE_TYPE.UMRAH_LEAVE];

export const PAID_LEAVE_TYPES = [
    LEAVE_TYPE.ANNUAL_LEAVE,
    LEAVE_TYPE.MATERNITY_LEAVE,
    LEAVE_TYPE.PARENTAL_LEAVE,
    LEAVE_TYPE.COMPENSATORY_LEAVE,
    LEAVE_TYPE.SABBATICAL_LEAVE
];

export const STANDARD_APPLICABLE_LEAVE_TYPES = [
    LEAVE_TYPE.UNPAID_LEAVE,
    LEAVE_TYPE.ANNUAL_LEAVE,
    LEAVE_TYPE.SICK_LEAVE,
    LEAVE_TYPE.EMERGENCY_LEAVE,
    LEAVE_TYPE.MATERNITY_LEAVE,
    LEAVE_TYPE.BEREAVEMENT_LEAVE,
    LEAVE_TYPE.COMPENSATORY_LEAVE,
    LEAVE_TYPE.HAJJ_LEAVE
];

export const QA_APPLICABLE_LEAVE_TYPES = [...STANDARD_APPLICABLE_LEAVE_TYPES, LEAVE_TYPE.CASUAL_LEAVE, LEAVE_TYPE.PATERNITY_LEAVE];

export const AE_APPLICABLE_LEAVE_TYPES = [
    ...STANDARD_APPLICABLE_LEAVE_TYPES,
    LEAVE_TYPE.STUDY_LEAVE,
    LEAVE_TYPE.PARENTAL_LEAVE,
    LEAVE_TYPE.UMRAH_LEAVE,
    LEAVE_TYPE.SABBATICAL_LEAVE
];
