import React from "react";
import PropTypes from "prop-types";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import { getEmployeeFileValidity } from "./helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

const EXPIRY_FIELDS = {
    RESIDENCE_ID: "residenceID_expiration",
    VISA_ID: "visaID_expiration",
    PASSPORT_ID: "passportID_expiration",
    CONTRACT_ID: "contract_expiration",
    LABOR_CARD_NUMBER: "labor_card_expiration"
};

const LABEL = {
    [EXPIRY_FIELDS.RESIDENCE_ID]: "Residence ID",
    [EXPIRY_FIELDS.VISA_ID]: "Visa ID",
    [EXPIRY_FIELDS.PASSPORT_ID]: "Passport ID",
    [EXPIRY_FIELDS.CONTRACT_ID]: "Contract",
    [EXPIRY_FIELDS.LABOR_CARD_NUMBER]: "Labor Card Number"
};

const createText = ({ label, isExpired, isWarning, fromNow }) => {
    if (!isWarning && !isExpired) {
        return "";
    }
    let msg = "is expiring " + fromNow + ".";
    if (isExpired) {
        msg = "is already expired.";
    }
    return `${label} ${msg}`;
};

function FileWarning({ data = {}, style }) {
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const object = Object.values(EXPIRY_FIELDS).reduce(
        (prev, curr) => ({ ...prev, [curr]: { key: curr, ...getEmployeeFileValidity(data[curr], timezone) } }),
        {}
    );

    const list = Object.values(object)
        .map((item) => createText({ label: LABEL[item.key], ...item }))
        .filter(Boolean);
    const show = !!list.length;

    const createTooltip = () => {
        return (
            <ul className="flex column semi-bold" style={{ gap: ".3rem" }}>
                {list.map((element, i) => (
                    <li key={i}>{element}</li>
                ))}
            </ul>
        );
    };

    if (!show) {
        return <></>;
    }

    return (
        <div className="employee-file-warning" style={style}>
            <WarningColorSwitcher tooltip={{ message: createTooltip() }} warning />
        </div>
    );
}

export default FileWarning;
FileWarning.propTypes = {
    style: PropTypes.object,
    data: PropTypes.shape({
        residenceID_expiration: PropTypes.string,
        visaID_expiration: PropTypes.string,
        passportID_expiration: PropTypes.string,
        contract_expiration: PropTypes.string,
        labor_card_expiration: PropTypes.string
    })
};
