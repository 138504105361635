import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useGetSubmittedForm } from "./hooks";
import { createClass, createFullName, createGroup, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { SUBMITTED_FORM_CLASS, SUBMITTED_FORM_STATUS, TIMELINE_ACTION } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import Timeline from "../../../common/components/extra/timeline/Timeline";
import DisplayFormData from "./DisplayFormData";
import Tooltip from "../../../common/components/extra/Tooltip";
import ViewExtraModal from "./ViewExtraModal";
import FollowUpButton from "./FollowUpButton";
import RejectButton from "./RejectButton";
import CancelButton from "./CancelButton";
import AttemptCompleteButton from "./AttemptCompleteButton";
import Loader from "../../../common/components/extra/Loader";
import ViewHistoryButton from "./ViewHistoryButton";
import { FORM_CLASS } from "../companyForms/const";
import ViewLeaveButton from "./ViewLeaveButton";

const BASE_CLASS = "tk-employees-submitted-forms__modal-content-view";

function ViewModal({ open, onClose, onEdit, id, onBack, onFollowUp }) {
    const [openExtra, setOpenExtra] = useState(null);

    const [current, { config, isLoading, clearCurrent, timelineData, refetch }] = useGetSubmittedForm(id);

    const setting = useAppSelector(selectUser);
    const timezone = setting.timezone;

    const showTimeline = !isLoading && !!timelineData?.statuses?.length;

    const sourceEmployee = current?.sourceEmployee;

    const isProbYearly =
        current?.companyForm &&
        (current.companyForm.class == FORM_CLASS.PROBATIONARY_APPRAISAL || current.companyForm.class == FORM_CLASS.YEARLY_APPRAISAL);

    useEffect(() => {
        return () => clearCurrent();
    }, []);

    const renderTimelineItem = (item, idx, config = {}) => {
        const obj = timelineData.statusesWithInfoObject[idx] || {};
        return (
            <section className="status-log-timeline-item flex column gap-1">
                <h3 style={config.bgColor && { color: config.bgColor }}>{sanitizeWords(obj.status)}</h3>
                <div className="">
                    <div className="status-log-timeline-item__timestamp__name semi-bold">
                        {!obj.Employee ? "You" : createFullName(obj.Employee?.first_name, obj.Employee?.last_name)}
                    </div>
                    <span className="status-log-timeline-item__timestamp fade small-font">
                        {toReadableFromDate(obj.createdAt, timezone, "MM/DD/YYYY hh:mm A")}
                    </span>
                    {!!(obj.remarks || obj.document_filename) && (
                        <div className="flex gap-03" style={{ margin: ".3rem 0" }}>
                            {!!obj.remarks && (
                                <Tooltip
                                    element="div"
                                    className="circle-tag"
                                    message="Remarks"
                                    onClick={() => setOpenExtra({ type: TIMELINE_ACTION.VIEW_REMARKS, value: obj.remarks })}
                                >
                                    <TextSnippetIcon
                                        className="hover-svg"
                                        style={{
                                            width: ".9rem",
                                            height: ".9rem"
                                        }}
                                    />
                                </Tooltip>
                            )}
                            {!!obj.document_filename && (
                                <Tooltip
                                    element="div"
                                    className="circle-tag"
                                    message="Attachment"
                                    onClick={() => setOpenExtra({ type: TIMELINE_ACTION.VIEW_ATTACHMENTS, value: obj.document_filename })}
                                >
                                    <FilePresentIcon
                                        className="hover-svg"
                                        style={{
                                            width: ".9rem",
                                            height: ".9rem"
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    )}
                </div>
            </section>
        );
    };

    const renderViewButtonsByClass = useMemo(() => {
        if (!current || !id || current?.status != SUBMITTED_FORM_STATUS.COMPLETED) {
            return <></>;
        }

        switch (current.class) {
            case SUBMITTED_FORM_CLASS.WORK_HISTORY: {
                const attachedHistoryId = current.formData.historyId;
                return <ViewHistoryButton id={attachedHistoryId} currentFormStatus={current?.status} />;
            }
            case SUBMITTED_FORM_CLASS.LEAVE: {
                const attachedHistoryId = current.formData.leaveId;
                return <ViewLeaveButton id={attachedHistoryId} currentFormStatus={current?.status} />;
            }
            default:
                return <></>;
        }
    }, [current]);

    return (
        <BaseViewModal
            open={open}
            onClose={onClose}
            onEdit={onEdit}
            disableEdit={false}
            onBack={onBack}
            styles={{
                content: {
                    height: "max-content",
                    minHeight: "17rem",
                    width: "max-content",
                    maxWidth: "80vw"
                },
                body: { flexGrow: 1 }
            }}
        >
            <div className={createClass(`${BASE_CLASS} flex column`)} style={{ flexGrow: 1 }}>
                <div className={createClass("__inner flex column", BASE_CLASS)} style={{ flexGrow: 1, justifyContent: "space-between" }}>
                    <div className="flex gap-05">
                        <div className={createClass("__form-data flex column", BASE_CLASS)} style={!showTimeline ? { borderRight: "none" } : {}}>
                            <div className={createClass("__inner-row", BASE_CLASS)}>
                                {isLoading ? (
                                    <Loader />
                                ) : (
                                    <>
                                        {createGroup({
                                            base: BASE_CLASS,
                                            title: current && (
                                                <div className={createClass("__header flex column", BASE_CLASS)}>
                                                    <span className="primary-color small-font" style={{ fontWeight: "bold" }}>
                                                        {current.reference_number}
                                                    </span>
                                                    <span className={createClass("__title fade small-font", BASE_CLASS)}>
                                                        {sanitizeWords(current?.formData.name)}
                                                    </span>
                                                    {current?.companySite?.title && (
                                                        <span className={createClass("__site fade small-font", BASE_CLASS)}>
                                                            {sanitizeWords(current?.companySite.title)}
                                                        </span>
                                                    )}

                                                    <span style={{ marginTop: ".5rem" }}>{sanitizeWords(current.fullName)}</span>
                                                    {current.targetEmployee && (
                                                        <span className="fade small-font">
                                                            Sent To:{" "}
                                                            {createFullName(current.sourceEmployee?.first_name, current.sourceEmployee?.last_name)}
                                                        </span>
                                                    )}
                                                </div>
                                            ),
                                            body: (
                                                <div className="flex column gap-05" style={{ marginTop: "1rem" }}>
                                                    <DisplayFormData
                                                        status={current?.status}
                                                        isLoading={isLoading}
                                                        formData={current?.formData}
                                                        targetEmployee={current?.targetEmployee || current?.sourceEmployee}
                                                        targetWorkDetail={current?.targetWorkDetail}
                                                        sentTo={sourceEmployee}
                                                        isProbYearly={isProbYearly}
                                                    />
                                                </div>
                                            )
                                        })}
                                    </>
                                )}
                            </div>
                        </div>
                        {showTimeline && (
                            <div className={createClass("__timeline flex column", BASE_CLASS)}>
                                <Timeline items={timelineData.statuses} renderItem={renderTimelineItem} startFromBottom />
                            </div>
                        )}
                    </div>
                    {!isLoading && (
                        <div
                            className={createClass("__footer flex gap-1 w100", BASE_CLASS)}
                            style={{ justifyContent: "space-between", marginTop: "2rem" }}
                        >
                            <div className="left flex gap-05">
                                <FollowUpButton
                                    id={id}
                                    lastFollowUp={current?.last_follow_up_at}
                                    currentFormStatus={current?.status}
                                    targetApprovalUserLevel={current?.target_approval_user_level}
                                    formCategory={current?.companyForm?.category}
                                    formClass={current?.class}
                                    lastUpdatedAt={current?.updatedAt}
                                    createdAt={current?.createdAt}
                                    disabled={isLoading}
                                    onFollowUp={() => Promise.all([refetch(), onFollowUp?.()])}
                                />
                                {renderViewButtonsByClass}
                            </div>
                            <div className="right flex gap-05">
                                <RejectButton
                                    id={id}
                                    currentFormStatus={current?.status}
                                    targetApprovalUserLevel={current?.target_approval_user_level}
                                    formCategory={current?.companyForm?.category}
                                    approvalLevels={current?.companyForm?.approval_levels || []}
                                    formClass={current?.class}
                                    disabled={isLoading}
                                    onReject={refetch}
                                />
                                <CancelButton
                                    id={id}
                                    currentFormStatus={current?.status}
                                    targetApprovalUserLevel={current?.target_approval_user_level}
                                    formCategory={current?.companyForm?.category}
                                    formClass={current?.class}
                                    disabled={isLoading}
                                    onCancel={refetch}
                                />
                                <AttemptCompleteButton
                                    id={id}
                                    currentFormStatus={current?.status}
                                    targetApprovalUserLevel={current?.target_approval_user_level}
                                    formCategory={current?.companyForm?.category}
                                    formClass={current?.class}
                                    disabled={isLoading}
                                    onComplete={refetch}
                                    config={config}
                                    employee={current?.sourceEmployee}
                                    siteId={current?.companySite?.id}
                                    fields={current?.formData?.fields}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {!!openExtra && (
                <ViewExtraModal
                    open={!!openExtra}
                    onClose={() => setOpenExtra(null)}
                    data={{
                        type: openExtra.type,
                        value: openExtra.value
                    }}
                />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func,
    onBack: PropTypes.func,
    onFollowUp: PropTypes.func
};

export default ViewModal;
