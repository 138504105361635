import React, { useState } from "react";
import PropTypes from "prop-types";
import PaymentIcon from "@mui/icons-material/Payment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Popover, { POPOVER_POSITION } from "../extra/Popover";
import { ReactComponent as LogoutSVG } from "../../../assets/images/logout.svg";
import Divider from "../extra/Divider";
import Button from "../extra/Button";
import useNavigate from "../../hooks/useNavigate";
import Navigation from "../../classes/Navigation";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectRole } from "../../../features/common/slice";
import { ROLE_TYPE } from "../../utilities/const";
import { TABS } from "../../../pages/Settings";
import { useLogout } from "../../../features/common/hooks";
import CompanyAvatar from "../../../features/admin/companies/CompanyAvatar";

function HeadLogo({ user }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const navigate = useNavigate();
    const role = useAppSelector(selectRole);
    const isSuperAdmin = role?.type === ROLE_TYPE.SUPER_ADMIN;
    const isSettingSetupDone = user.isSettingsVerified;
    const [logout, isLoggingOut] = useLogout();
    const gotoRoute = (newpath = "") => navigate(newpath);

    const content = (
        <div className="tk-header__logo__content flex column gap-05">
            <Divider />
            <Button
                className="item"
                onClick={() => gotoRoute(Navigation.Routes.SETTINGS.path + "#" + TABS.PROFILE.id)}
                disabled={!isSuperAdmin && !isSettingSetupDone}
                transparent
                small
            >
                <span>Profile</span>
                <AccountCircleIcon style={{ maxWidth: "1.2rem", width: "1.2rem" }} />
            </Button>
            {!isSuperAdmin && (
                <Button
                    className="item"
                    onClick={() => gotoRoute(Navigation.Routes.SETTINGS.path + "#" + TABS.BILLING.id)}
                    disabled={!isSettingSetupDone}
                    transparent
                    small
                >
                    <span>Subscription</span>
                    <PaymentIcon style={{ maxWidth: "1.2rem" }} />
                </Button>
            )}
            <Divider />
            <Button className="logout danger item" onClick={logout} disabled={isLoggingOut} transparent>
                <span>Logout</span>
                <LogoutSVG style={{ maxWidth: "1rem" }} />
            </Button>
        </div>
    );
    return (
        <Popover
            content={content}
            position={POPOVER_POSITION.BOTTOM_LEFT}
            styles={{ content: { right: "6px", padding: "1rem" }, parent: { minWidth: "3.25rem" } }}
            onChange={setDropdownOpen}
        >
            <div className={`tk-header__logo ${(isDropdownOpen && "active") || ""}`.trim()}>
                <CompanyAvatar />
                <div className="overlay abs-center show">
                    <ExpandMoreIcon className="more-icon" />
                </div>
            </div>
        </Popover>
    );
}

export default HeadLogo;

HeadLogo.propTypes = {
    user: PropTypes.object
};
