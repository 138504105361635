import React from "react";
import PropTypes from "prop-types";
import AsyncSelectLazy from "../../../common/components/extra/AsyncSelectLazy";
import { useLazyFormTemplates } from "./hooks";
import { FORM_CLASS } from "./const";
import useSelectManageInfoLazy from "../../../common/components/extra/select/hooks";

function TemplatesSelectLazy(props) {
    const initializing = props.isLoading;
    const hideOnEmpy = props.hideOnEmpy;
    const onDataLoad = props.onDataLoad;

    const createOptions = (data) => (props.filterByClass ? (data || []).filter((temp) => temp.class == props.filterByClass) : data);

    const [object, , { loadMore, search, isLoading, createRowItem }] = useLazyFormTemplates({
        initializing,
        value: props?.value,
        isMulti: props?.isMulti,
        defaultValue: Array.isArray(props.value) ? props.value.map((v) => v.id) : props?.value?.id,
        onFetch: (data) => onDataLoad?.(data),
        filterByClass: props.filterByClass
    });

    const [value, onChange] = useSelectManageInfoLazy({
        isMulti: props.isMulti,
        value: props.value,
        data: object.data,
        createRowItem,
        isLoading: initializing,
        onChange: props.onChange
    });

    const options = createOptions(object.data);

    const handleSearch = (newInput, type) => {
        const allowedActions = ["input-change"];
        if (props.isFilter) {
            allowedActions.push("menu-close");
        }
        if (allowedActions.includes(type.action)) {
            search(newInput);
        }
    };

    if (!isLoading && !options.length && hideOnEmpy) {
        return <></>;
    }

    return (
        <AsyncSelectLazy
            {...props}
            key={props.filterByClass || ""}
            filterOption={(options, newInputs) => {
                const index1 = options?.data?.title?.trim()?.toLowerCase() || "";
                const search = newInputs?.toLowerCase().trim();
                if (index1.includes(search)) {
                    return true;
                } else {
                    return false;
                }
            }}
            onChange={onChange}
            value={value}
            options={options}
            onLoadMore={loadMore}
            onSearch={handleSearch}
            isFetching={initializing || isLoading}
            styles={{
                menuPortal: { minWidth: "13rem" }
            }}
            menuPortalTarget={document.body}
            placeholder="Select an Option"
            noRequiredInput={props.isFilter}
        />
    );
}

TemplatesSelectLazy.propTypes = {
    onChange: PropTypes.func,
    isOutlined: PropTypes.bool,
    hideOnEmpy: PropTypes.bool,
    label: PropTypes.string,
    noborder: PropTypes.bool,
    style: PropTypes.object,
    value: PropTypes.any,
    isMulti: PropTypes.bool,
    isLoading: PropTypes.bool,
    isFilter: PropTypes.bool,
    chooseFirstAsDefault: PropTypes.bool,
    chooseFirstAsDefaultDept: PropTypes.array,
    onDataLoad: PropTypes.func,
    filterByClass: PropTypes.oneOf(Object.values(FORM_CLASS))
};

export default TemplatesSelectLazy;
