import React from "react";
import PropTypes from "prop-types";
import { FORM_CLASS, FORM_FIELD_TYPE, INPUT_FIELDS, DATE_FORMAT_OPTION } from "./const";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import Select from "../../../common/components/extra/select/Select";
import { sanitizeWords } from "../../../common/utilities/helper";
import InputFieldDateRange from "./InputField/InputFieldDateRange";

function FieldInputSwitcher({ item, onChange, origin }) {
    const formClass = origin.class;
    const isProbationaryClass = formClass === FORM_CLASS.PROBATIONARY_APPRAISAL;
    const isYearlyClass = formClass === FORM_CLASS.YEARLY_APPRAISAL;
    const data = item?.data || item;
    const isGroupType = data.type == FORM_FIELD_TYPE.GROUP;

    const handleChange = (e, field, parentId, extra) => {
        const fieldType = field.type;

        let name = e.target.name;
        let value = e.target.value;

        switch (fieldType) {
            case INPUT_FIELDS.TOGGLE:
                value = e.target.checked;
                break;
            case INPUT_FIELDS.PHONE_NUMBER:
                value = {
                    mobile: e.target.mobile,
                    value: e.target.value
                };
                break;
            default:
                break;
        }

        onChange?.(name, value, field, parentId, extra);
    };

    const renderFields = (field, parentId) => {
        const fieldType = field.type;

        const fieldProps = {
            name: field.id,
            label: sanitizeWords(field.label),
            required: field.required,
            value: field.actualValue || "", // actual value is the temp value for client
            onChange: (e) => handleChange(e, field, parentId)
        };

        switch (fieldType) {
            case INPUT_FIELDS.EMAIL:
                return <Input {...fieldProps} type={INPUT_TYPE.EMAIL} />;
            case INPUT_FIELDS.PHONE_NUMBER:
                return <Input {...fieldProps} type={INPUT_TYPE.MOBILE} menuPlacement="top" value={fieldProps.value?.value || ""} portalCodes />;
            case INPUT_FIELDS.TEXT_INPUT:
            case INPUT_FIELDS.ADDRESS:
                return <Input {...fieldProps} type={INPUT_TYPE.TEXT} minLength={field.min} maxLength={field.max} />;
            case INPUT_FIELDS.TEXT_AREA:
                return <Input {...fieldProps} type={INPUT_TYPE.TEXTAREA} richTextHeight="5rem" minLength={field.min} maxLength={field.max} />;
            case INPUT_FIELDS.NUMBER_INPUT:
                return <Input {...fieldProps} type={INPUT_TYPE.NUMBER} constraint={{ min: field.min, max: field.max }} />;
            case INPUT_FIELDS.DATE: {
                const option = DATE_FORMAT_OPTION[field.format];
                const dateFormat = option.fnsFormat;
                const isMonthYear = option.key == DATE_FORMAT_OPTION.MONTH_YEAR.key;

                const value = fieldProps.value;
                delete fieldProps.value;
                return (
                    <Input
                        {...fieldProps}
                        type={INPUT_TYPE.DATE}
                        selected={value}
                        placeholder="Select Date"
                        onChange={(value) =>
                            handleChange({ target: { name: fieldProps.name, value: value?.format?.() || value || "" } }, field, parentId)
                        }
                        noFuture={field.noFutureDate}
                        noPast={field.noPastDate}
                        dateFormat={dateFormat}
                        isMonthYear={isMonthYear}
                        showMonthYearPicker
                        isFixed
                    />
                );
            }
            case INPUT_FIELDS.TIME: {
                const value = fieldProps.value;
                delete fieldProps.value;
                return (
                    <Input
                        {...fieldProps}
                        type={INPUT_TYPE.TIME}
                        selected={value && new Date(value)}
                        onChange={(value) =>
                            handleChange({ target: { name: fieldProps.name, value: value?.format?.() || value || "" } }, field, parentId)
                        }
                        isFixed
                    />
                );
            }
            case INPUT_FIELDS.DATE_TIME: {
                const value = fieldProps.value;
                delete fieldProps.value;
                return (
                    <Input
                        {...fieldProps}
                        type={INPUT_TYPE.DATE}
                        selected={value && new Date(value)}
                        onChange={(value) =>
                            handleChange({ target: { name: fieldProps.name, value: value?.format?.() || value || "" } }, field, parentId)
                        }
                        placeholder="mmm dd, yyyy hh:mm"
                        dateFormat="MMM d, yyyy h:mm aa"
                        noFuture={field.noFutureDate}
                        noPast={field.noPastDate}
                        showTimeSelect
                        isFixed
                    />
                );
            }
            case INPUT_FIELDS.DATE_RANGE: {
                return (
                    <InputFieldDateRange
                        inputProps={fieldProps}
                        field={field}
                        onChange={(value, extra = {}) => handleChange({ target: { name: fieldProps.name, value } }, field, parentId, extra)}
                        isProbationaryClass={isProbationaryClass}
                        isYearlyClass={isYearlyClass}
                    />
                );
            }
            case INPUT_FIELDS.DROPDOWN:
                return (
                    <Select
                        {...fieldProps}
                        options={field.value || []}
                        onChange={(value) => handleChange({ target: { name: fieldProps.name, value: value } }, field, parentId)}
                        isOutlined
                        disabledOutline
                    />
                );
            case INPUT_FIELDS.RADIO:
                return <Input {...fieldProps} type={INPUT_TYPE.RADIO} options={field.value || []} />;
            case INPUT_FIELDS.CHECKBOX:
                return <Input {...fieldProps} type={INPUT_TYPE.CHECKBOX} options={field.value || []} hasOptions />;
            case INPUT_FIELDS.TOGGLE:
                return <Input {...fieldProps} type={INPUT_TYPE.TOGGLE} required={false} />;
            default:
                return null;
        }
    };

    return (
        <div className="flex column">
            {isGroupType
                ? Object.values(data.validation.value).map((field) => <React.Fragment key={field.id}>{renderFields(field, item.id)}</React.Fragment>)
                : renderFields(data)}
        </div>
    );
}

export default FieldInputSwitcher;

FieldInputSwitcher.propTypes = {
    item: PropTypes.object,
    onChange: PropTypes.func,
    form: PropTypes.object,
    origin: PropTypes.object
};
