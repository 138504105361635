import React, { useState } from "react";
import PropTypes from "prop-types";
import FileIcon from "@mui/icons-material/FilePresent";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { EMPLOYEE_FILES } from "../../../common/utilities/const";
import ViewFileModal from "./ViewFileModal";
import { VIEW_MODAL_TYPE } from "./const";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { blobToBase64 } from "../../../common/utilities/helper";

function InputEmployeeFile({ keyName, id, name, label, onChange, value, view, ...rest }) {
    const [src, setSrc] = useState("");
    const [preview, setPreview] = useState(false);

    const fileObject = Object.values(EMPLOYEE_FILES).find((file) => file.key == keyName);

    const getModalType = () => {
        switch (fileObject) {
            case EMPLOYEE_FILES.PHOTO:
                return VIEW_MODAL_TYPE.PHOTO;
            case EMPLOYEE_FILES.PASSPORT:
                return VIEW_MODAL_TYPE.PASSPORT_COPY;
            case EMPLOYEE_FILES.CONTRACT:
                return VIEW_MODAL_TYPE.CONTRACT_COPY;
            case EMPLOYEE_FILES.RESIDENCE:
                return VIEW_MODAL_TYPE.ID_COPY;
            case EMPLOYEE_FILES.VISA:
                return VIEW_MODAL_TYPE.VISA_COPY;
            case EMPLOYEE_FILES.LABOR_CARD:
                return VIEW_MODAL_TYPE.LABOR_CARD_COPY;
            default:
                return "";
        }
    };

    const handleChange = async (e, file) => {
        onChange?.(e, file || null);
        setSrc((file && (await blobToBase64(file))) || null);
    };

    if (!fileObject) {
        return <></>;
    }

    return (
        <>
            {view?.extra ? (
                <Input
                    type={INPUT_TYPE.TEXT}
                    label={label}
                    renderValue={view.extra}
                    isLoading={rest.isLoading}
                    afterExtra={
                        value && (
                            <MyTooltip message="View File">
                                <FileIcon className="hover-svg primary-color" onClick={() => setPreview(true)} />
                            </MyTooltip>
                        )
                    }
                    readOnly
                />
            ) : (
                <Input
                    type={INPUT_TYPE.UPLOAD}
                    label={label}
                    name={name}
                    internalIcon={fileObject.icon}
                    onChange={handleChange}
                    value={value}
                    onView={() => setPreview(true)}
                    accept={fileObject.accepts.toString()}
                    sizeLimit={fileObject.size}
                    {...rest}
                />
            )}
            {preview && (
                <ViewFileModal
                    open={preview}
                    onClose={() => setPreview(false)}
                    type={getModalType()}
                    data={typeof value == "string" || src ? { id, filename: value, src } : value}
                />
            )}
        </>
    );
}

export default InputEmployeeFile;

InputEmployeeFile.propTypes = {
    keyName: PropTypes.oneOf(Object.values(EMPLOYEE_FILES).map((file) => file.key)),
    name: PropTypes.string,
    label: PropTypes.any,
    onChange: PropTypes.func,
    value: PropTypes.any,
    id: PropTypes.any,
    view: PropTypes.shape({
        extra: PropTypes.any
    })
};
