import React from "react";
import PropTypes from "prop-types";
import Input from "../../../../../common/components/extra/form/Input";
import { sanitizeWords } from "../../../../../common/utilities/helper";

function MobileTextInput({ useValue, details }) {
    if (useValue) {
        return <Input label={details.label} renderValue={sanitizeWords(details.value)} parentStyle={{ gap: ".5rem" }} readOnly alignRight />;
    }
    return (
        <div className="mobile-input mobile-input--text-input">
            <div className="input-box flex center">
                <span className="fade italic">Input</span>
            </div>
        </div>
    );
}

export default MobileTextInput;

MobileTextInput.propTypes = {
    placeholder: PropTypes.any,
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
