import React, { useMemo } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import TransferIcon from "@mui/icons-material/CompareArrows";
import Modal from "../../../common/components/extra/Modal";
import { createClass, createConfirmAlert, createGroup, sanitizeWords } from "../../../common/utilities/helper";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { BASE_CLASS, FORM_FIELDS } from "./const";
import EmployeeSelectLazy from "../employees/EmployeeSelectLazy";
import { useTransferDepartment, useUpsertDepartments } from "./hooks";
import Text from "../../../common/components/extra/typography/Text";
import Button from "../../../common/components/extra/Button";
import { SORT_ORDER } from "../../../common/utilities/const";

function UpdateModal({ open, onClose, onBack, id, onFinish, attachedEmployee, readOnlyTitle, admin, withTransfer }) {
    const [form, isSaving, { onSave, onChange, isCreate, old, isGettingDepartment }] = useUpsertDepartments(id, onFinish);
    const [transfer, transferingId] = useTransferDepartment(id, !admin); // if admin transfer to other dept which is false isAdmin

    const isLoading = isSaving || transferingId;

    const transferingPosition = (currentData, isSuperVisor) => {
        const curroldId = isSuperVisor ? old?.supervisor?.id : old?.manager?.id;
        if (
            !isGettingDepartment &&
            currentData &&
            attachedEmployee.id != currentData.id &&
            curroldId != currentData.id &&
            attachedEmployee.id == curroldId
        ) {
            return (
                <Text className="warning-color" useSubTextStyle>
                    {`This will remove ${sanitizeWords(`${attachedEmployee.first_name} ${attachedEmployee.last_name}`)} from the role of 
                    ${isSuperVisor ? "Supervisor" : "Manager"} and transfer the position to the selected Employee`}
                </Text>
            );
        }
    };

    const title = useMemo(() => {
        if (admin) {
            return isCreate ? "Create Admin Department" : "Update Admin Department";
        } else {
            return isCreate ? "Create Other Department" : "Update Other Department";
        }
    }, [isCreate, admin]);

    return (
        <Modal
            open={open}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={() =>
                createConfirmAlert({
                    title,
                    content: `Are you sure you want to ${isCreate ? "create" : "update"} this department? This cannot be undone.`,
                    onConfirm: async (close) => {
                        close();
                        await onSave({ isAdmin: admin });
                    }
                })
            }
            disableSave={isEqual(form, old)}
            styles={{ content: { width: "50vw", maxWidth: "45rem", minWidth: "40rem", height: "max-content" } }}
            isLoading={isLoading}
            footMidExtra={
                !isCreate &&
                withTransfer && (
                    <Button
                        onClick={() =>
                            !isLoading &&
                            createConfirmAlert({
                                title: `Transfer Department to ${admin ? "Other" : "Admin"} Departments?`,
                                content: `You are about to transfer this department to ${admin ? "Other" : "Admin"} Departments. You can always reverse this action later if needed.`,
                                onConfirm: (onClose) => {
                                    transfer().then(onFinish);
                                    onClose();
                                }
                            })
                        }
                        disabled={isLoading}
                        beforeExtra={<TransferIcon style={{ width: "1.2rem" }} />}
                        small
                        mini
                    >
                        Transfer to {admin ? "Other Departments" : "Admin Departments"}
                    </Button>
                )
            }
            isForm
        >
            <div className={createClass("", BASE_CLASS)}>
                <div className={createClass("__inner", BASE_CLASS)}>
                    <div className={createClass("__inner-row", BASE_CLASS)}>
                        {createGroup({
                            base: BASE_CLASS,
                            title: !readOnlyTitle && title,
                            body: (
                                <>
                                    {readOnlyTitle ? (
                                        <div className="flex gap-05">
                                            <span className="semi-bold">{form[FORM_FIELDS.TITLE]}</span>
                                        </div>
                                    ) : (
                                        <Input
                                            type={INPUT_TYPE.TEXT}
                                            name={FORM_FIELDS.TITLE}
                                            label="Title"
                                            onChange={(e) =>
                                                onChange({
                                                    target: {
                                                        name: e.target.name,
                                                        value: (e.target.value && e.target.value.toUpperCase()) || ""
                                                    }
                                                })
                                            }
                                            value={form[FORM_FIELDS.TITLE] || ""}
                                            minLength={4}
                                            maxLength={50}
                                            isLoading={isGettingDepartment}
                                            autoFocus
                                            required
                                        />
                                    )}
                                    <div className="flex column gap-05" style={{ marginLeft: readOnlyTitle ? "1rem" : 0 }}>
                                        <EmployeeSelectLazy
                                            label="Supervisor"
                                            value={form[FORM_FIELDS.SUPERVISOR]}
                                            onChange={(target) =>
                                                onChange({
                                                    target: {
                                                        name: FORM_FIELDS.SUPERVISOR,
                                                        value: target
                                                    }
                                                })
                                            }
                                            menuPortalTarget={document.body}
                                            isLoading={isGettingDepartment}
                                            sortBy="index3"
                                            sortOrder={SORT_ORDER.ASC}
                                            isSearchable
                                            required
                                            allowOnShift
                                            isOutlined
                                            disabledOutline
                                            showDepartment
                                        />
                                        {!!attachedEmployee?.id && transferingPosition(form[FORM_FIELDS.SUPERVISOR], true)}
                                        <EmployeeSelectLazy
                                            label="Manager"
                                            value={form[FORM_FIELDS.MANAGER]}
                                            onChange={(target) =>
                                                onChange({
                                                    target: {
                                                        name: FORM_FIELDS.MANAGER,
                                                        value: target
                                                    }
                                                })
                                            }
                                            menuPortalTarget={document.body}
                                            isLoading={isGettingDepartment}
                                            sortBy="index3"
                                            sortOrder={SORT_ORDER.ASC}
                                            isSearchable
                                            required
                                            allowOnShift
                                            isOutlined
                                            disabledOutline
                                            showDepartment
                                        />
                                        {!!attachedEmployee?.id && transferingPosition(form[FORM_FIELDS.MANAGER])}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    withTransfer: PropTypes.bool,
    admin: PropTypes.bool,
    readOnlyTitle: PropTypes.bool,
    id: PropTypes.number,
    onClose: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    attachedEmployee: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string
    })
};

export default UpdateModal;
