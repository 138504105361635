import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, createToast, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectEmployeePenaltiesData,
    defaultConfig
} from "./slice";
import {
    useCancelEmployeePenaltiesMutation,
    useCreateEmployeePenaltiesMutation,
    useDeleteEmployeePenaltiesMutation,
    useGetEmployeePenaltiesMutation,
    useLoadAllEmployeePenaltiesMutation,
    useUpdateEmployeePenaltiesMutation
} from "./api";
import { CUSTOM_ACTION, FIELD, PENALTY_STATUS } from "./const";
import { selectUserSetting } from "../../common/slice";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";

const { EMPLOYEE, COMPANY_PENALTY, DEDUCTION_TYPE: TYPE, OCCURENCE_DATE, EFFECTIVE_DATE, REASON, STATUS, CONFIRMED_DATE } = FIELD;

export const useGetEmployeePenalties = (id, callback) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);
    const [getDetails] = useGetEmployeePenaltiesMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    // this means it only have the id key inside means we have to fetch more
    const isInitial = current && Object.keys(current).length == 1;
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = (data) => {
        if (!data) return {};

        return {
            [CONFIRMED_DATE]: (data?.[CONFIRMED_DATE] && toTimeWithTimeZone(data?.[CONFIRMED_DATE], timezone)) || "",
            [OCCURENCE_DATE]: (data?.[OCCURENCE_DATE] && toTimeWithTimeZone(data?.[OCCURENCE_DATE], timezone)) || "",
            [EFFECTIVE_DATE]: (data?.[EFFECTIVE_DATE] && toTimeWithTimeZone(data?.[EFFECTIVE_DATE], timezone).format?.("MMM YYYY")) || "",
            employee: data?.employee,
            companyPenalty: data?.CompanyPenalty,
            measure: data?.penalty_measure,
            hasConfirmedDate: !!data?.[CONFIRMED_DATE]
        };
    };

    const fetch = async () => {
        if (!id) return;

        try {
            if (!isInitial && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch record. Please try again later");
            }
            dispatch(setCurrent(result.data.data));
            callback?.(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateEmployeePenalties = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, tableConfig, onSort, onUpdate }] = usePaginateFetch(useLoadAllEmployeePenaltiesMutation, {
        redux: {
            dataSelector: selectEmployeePenaltiesData,
            tableConfigSelector: selectTableConfig,
            currentSelector: selectCurrent,
            setState
        },
        defaultConfig,
        onMountConfig: {},
        runOnMount: !readOnly
    });

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSearch, onFilter, tableConfig, onSort }];
};

export const useUpsertEmployeePenalties = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [EMPLOYEE]: "",
        [COMPANY_PENALTY]: "",
        [TYPE]: "",
        [OCCURENCE_DATE]: "",
        [EFFECTIVE_DATE]: "",
        [REASON]: "",
        [STATUS]: PENALTY_STATUS.ACTIVE
    });

    const [data, { isLoading }] = useGetEmployeePenalties(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateEmployeePenaltiesMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateEmployeePenaltiesMutation();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = () => {
        return {
            [OCCURENCE_DATE]: form[OCCURENCE_DATE],
            [EFFECTIVE_DATE]: form[EFFECTIVE_DATE],
            employee: { ...(data?.employee || {}), id: form[EMPLOYEE] },
            [COMPANY_PENALTY]: {
                ...(data?.CompanyPenalty || {}),
                ...(form[COMPANY_PENALTY]?.id ? form[COMPANY_PENALTY] : { id: form[COMPANY_PENALTY] })
            },
            hasConfirmedDate: !isCreate && !!data?.[CONFIRMED_DATE]
        };
    };

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            if (clonedform[COMPANY_PENALTY] && typeof clonedform[COMPANY_PENALTY] == "object") {
                if (isCreate) {
                    clonedform[TYPE] = clonedform[COMPANY_PENALTY][TYPE];
                }
                clonedform[COMPANY_PENALTY] = clonedform[COMPANY_PENALTY].id;
            }

            if (isCreate) {
                clonedform[EMPLOYEE] = clonedform[EMPLOYEE]?.id;
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Record ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} record. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        const temp = {
            [EMPLOYEE]: current?.[EMPLOYEE] || form[EMPLOYEE],
            [COMPANY_PENALTY]: current?.[COMPANY_PENALTY] || form[COMPANY_PENALTY],
            [TYPE]: current?.[TYPE] || form[TYPE],
            [OCCURENCE_DATE]: (current?.[OCCURENCE_DATE] && toTimeWithTimeZone(current?.[OCCURENCE_DATE], timezone).format()) || form[OCCURENCE_DATE],
            [EFFECTIVE_DATE]: (current?.[EFFECTIVE_DATE] && toTimeWithTimeZone(current?.[EFFECTIVE_DATE], timezone).format()) || form[EFFECTIVE_DATE],
            [REASON]: current?.[REASON] || form[REASON],
            [STATUS]: current?.[STATUS] || form[STATUS]
        };
        setForm(temp);
        setOld(temp);
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord: isLoading,
            isUpserting: createIsLoading || updateIsLoading,
            config: createVars(),
            old,
            hasChanges: !!(old && !isCreate && !isEqual(form, old))
        }
    ];
};

export const useDeleteEmployeePenalties = () => {
    const dispatch = useAppDispatch();

    const [deleteRecord] = useDeleteEmployeePenaltiesMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteRecord({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete penalty.");
            }
            createToast("Penalty successfully removed.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useCancelEmployeePenalties = () => {
    const [notes, setNotes] = useState("");

    const dispatch = useAppDispatch();

    const [cancelRecord] = useCancelEmployeePenaltiesMutation();

    const isLoading = useAppSelector(selectLoading);
    const current = useAppSelector(selectCurrent);

    const cancel = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await cancelRecord({ extraPath: id, body: { reason: notes } });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to cancel penalty.");
            }
            if (current) {
                dispatch(
                    setCurrent({
                        ...current,
                        status: PENALTY_STATUS.CANCELED,
                        reason: notes
                    })
                );
            }
            createToast("Penalty successfully cancelled.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [cancel, { isLoading, setNotes, notes }];
};

export const useManageActions = ({ cb } = {}) => {
    const [cancelId, setCancelId] = useState(null);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [, { fetch }] = usePaginateEmployeePenalties({ readOnly: true });
    const [remove] = useDeleteEmployeePenalties();

    const dispatch = useAppDispatch();

    const handleAction = async (row, actionType) => {
        switch (actionType) {
            case CUSTOM_ACTION.PREVIEW:
                setOpenViewModal(true);
                dispatch(setCurrent({ id: row.id }));
                return;
            case CUSTOM_ACTION.REMOVE:
                return await remove(row.id).then(async (result) => {
                    await fetch();
                    typeof cb == "function" && cb(result);
                });
            case CUSTOM_ACTION.CANCEL:
                setCancelId(row.id);
                return;
            default:
                return;
        }
    };

    return { onAction: handleAction, cancelId, setCancelId, openViewModal, setOpenViewModal };
};
