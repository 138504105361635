import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { TOAST_TYPE, addCommasToMoney, createToast, toReadableSelectOptions, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    selectCurrent,
    selectTableConfig,
    setCurrent,
    setState,
    selectLoading,
    setLoading,
    selectEmployeeOtherIncomesData,
    defaultConfig
} from "./slice";
import {
    useCreateEmployeeOtherIncomesMutation,
    useDeleteEmployeeOtherIncomesMutation,
    useGetEmployeeOtherIncomesMutation,
    useLoadAllEmployeeOtherIncomesMutation,
    useUpdateEmployeeOtherIncomesMutation
} from "./api";
import Tag from "../../../common/components/extra/Tag";
import { OTHER_INCOME_LABEL, OTHER_INCOME_STATUS, OTHER_INCOME_TYPE } from "./const";
import { selectUserSetting } from "../../common/slice";
import { FIELD } from "./const";
import { createStatusTag } from "./helper";
import moment from "moment-timezone";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";

const { EMPLOYEE, INCOME_TYPE, LABEL, AMOUNT, START_DATE, STATUS, CONFIRMED_DATE, NOTES } = FIELD;

const OFFSET = 1;

export const useGetEmployeeOtherIncomes = (id, callback) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);
    const [getDetails] = useGetEmployeeOtherIncomesMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    // this means it only have the id key inside means we have to fetch more
    const isInitial = current && Object.keys(current).length == 1;
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = (data) => {
        if (!data) return {};

        return {
            confirmedDate: (data?.[CONFIRMED_DATE] && toTimeWithTimeZone(data?.[CONFIRMED_DATE], timezone)) || "",
            start_date: (data?.[START_DATE] && toTimeWithTimeZone(data?.[START_DATE], timezone)) || "",
            amount: addCommasToMoney(data.amount)
        };
    };

    const fetch = async () => {
        if (!id) return;

        try {
            if (!isInitial && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch record. Please try again later");
            }
            dispatch(setCurrent(result.data.data));
            callback?.(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        dispatch(setCurrent({ ...current, ...(newCurrent || {}) }));
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateEmployeeOtherIncomes = ({ readOnly } = {}) => {
    const [load, isLoading, { onFilter, onSearch, data, tableConfig, onSort, onUpdate }] = usePaginateFetch(useLoadAllEmployeeOtherIncomesMutation, {
        redux: {
            dataSelector: selectEmployeeOtherIncomesData,
            tableConfigSelector: selectTableConfig,
            currentSelector: selectCurrent,
            setState
        },
        defaultConfig,
        onMountConfig: {},
        runOnMount: !readOnly
    });

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { isLoading, fetch, update: onUpdate, onSearch, onFilter, tableConfig, onSort }];
};

export const useUpsertEmployeeOtherIncomes = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [EMPLOYEE]: "",
        [INCOME_TYPE]: "",
        [LABEL]: "",
        [AMOUNT]: "",
        [START_DATE]: "",
        [STATUS]: "",
        [NOTES]: ""
    });

    const [data, { isLoading }] = useGetEmployeeOtherIncomes(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateEmployeeOtherIncomesMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateEmployeeOtherIncomesMutation();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const isPastMonth = () => {
        if (!current || isCreate) {
            return false;
        }
        const startDate = toTimeWithTimeZone(current?.[START_DATE], timezone);
        const today = toTimeWithTimeZone(moment(), timezone);
        const isPastBothLess = startDate.month() + OFFSET < today.month() && startDate.year() < today.year();
        const isPastMonthLess = startDate.month() + OFFSET < today.month() && startDate.year() == today.year();
        return isPastBothLess || isPastMonthLess;
    };

    const createVars = () => {
        const statusOpt = toReadableSelectOptions(OTHER_INCOME_STATUS).map((t) => ({ ...t, label: createStatusTag(t.value) }));
        const labelOpt = toReadableSelectOptions(OTHER_INCOME_LABEL).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));
        const typeOpt = toReadableSelectOptions(OTHER_INCOME_TYPE).map((t) => ({ ...t, label: <Tag className="flex">{t.label}</Tag> }));

        const status = statusOpt.find((level) => level.value == form[STATUS]) || "";
        const label = labelOpt.find((category) => category.value == form[LABEL]) || "";
        const type = typeOpt.find((type) => type.value == form[INCOME_TYPE]) || "";

        return {
            statusOpt,
            labelOpt,
            typeOpt,
            [STATUS]: status?.value == OTHER_INCOME_STATUS.APPROVED,
            [LABEL]: label,
            [INCOME_TYPE]: type,
            [START_DATE]: form[START_DATE],
            employee: { ...(data?.employee || {}), id: form[EMPLOYEE] },
            isPastMonth: isPastMonth(),
            hasConfirmedDate: !isCreate && !!data?.[CONFIRMED_DATE]
        };
    };

    const upsert = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            clonedform[STATUS] = clonedform[STATUS] == true ? OTHER_INCOME_STATUS.APPROVED : OTHER_INCOME_STATUS.PENDING;

            if (isCreate) {
                clonedform[EMPLOYEE] = clonedform[EMPLOYEE]?.id;
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Record ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return result.data.data;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} record. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        const temp = {
            [EMPLOYEE]: current?.[EMPLOYEE] || form[EMPLOYEE],
            [INCOME_TYPE]: current?.[INCOME_TYPE] || form[INCOME_TYPE],
            [LABEL]: current?.[LABEL] || form[LABEL],
            [AMOUNT]: current?.[AMOUNT] || form[AMOUNT],
            [START_DATE]: (current?.[START_DATE] && toTimeWithTimeZone(current?.[START_DATE], setting.timezone).format()) || form[START_DATE],
            [STATUS]: current?.[STATUS] || form[STATUS],
            [NOTES]: current?.[NOTES] || form[NOTES]
        };
        setForm(temp);
        setOld(temp);
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord: isLoading,
            isUpserting: createIsLoading || updateIsLoading,
            config: createVars(),
            old,
            hasChanges: !!(old && !isCreate && !isEqual(form, old))
        }
    ];
};

export const useDeleteEmployeeOtherIncomes = () => {
    const dispatch = useAppDispatch();

    const [deleteRecord] = useDeleteEmployeeOtherIncomesMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteRecord({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete other income.");
            }
            createToast("Other Income successfully removed.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};
