import React, { useState } from "react";
import PropTypes from "prop-types";
import EditRejoinedDetailsModal from "./EditRejoinedDetailsModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent } from "./slice";
import { FIELD, LEAVE_STATUS } from "./const";
import { useGetLeaveExtraInfo } from "./hooks";

function EditRejoinedDetailsButton({ onFinish }) {
    const [open, setOpen] = useState(false);

    const current = useAppSelector(selectCurrent);
    const config = useGetLeaveExtraInfo(current);

    const isRejoined = current[FIELD.STATUS] === LEAVE_STATUS.REJOINED;
    const isApproved = current[FIELD.STATUS] === LEAVE_STATUS.APPROVED;
    const isCanceled = current[FIELD.STATUS] === LEAVE_STATUS.CANCELED;

    if (!current || isCanceled || (isApproved && !config.isEnded)) {
        return <></>;
    }

    return (
        <>
            <span className="link-hover primary-color semi-bold" onClick={() => setOpen(true)}>
                {isRejoined ? "Edit Rejoined Details" : "Change Status to Rejoined"}
            </span>
            {!!open && <EditRejoinedDetailsModal open={open} onClose={() => setOpen(false)} id={current.id} current={current} onFinish={onFinish} />}
        </>
    );
}

export default EditRejoinedDetailsButton;

EditRejoinedDetailsButton.propTypes = {
    onFinish: PropTypes.func
};
