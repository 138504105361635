import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { TIMELINE_INFO, TIMELINE_STATUS } from "./const";
import { sanitizeWords } from "../../../utilities/helper";
import { cloneDeep } from "lodash";

const Timeline = ({ items, renderItem }) => {
    const renderItems = useMemo(() => {
        const clone = cloneDeep(items);
        return clone.map((status, idx) => {
            const CONFIG = TIMELINE_INFO[status];
            return (
                <div key={idx} className="timeline__item">
                    <div className={`timeline__item__status ${CONFIG.className}`}>
                        <div className="timeline__item__status-circle"></div>
                        <div className="timeline__item__status-line"></div>
                    </div>
                    <div className="timeline__item__content">
                        {typeof renderItem == "function" ? renderItem(status, idx, CONFIG) : sanitizeWords(status)}
                    </div>
                </div>
            );
        });
    }, [items]);

    return (
        <div className="timeline">
            <div className="timeline__inner">
                <div className="timeline__items">{renderItems}</div>
            </div>
        </div>
    );
};

export default Timeline;

Timeline.propTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TIMELINE_STATUS))),
    renderItem: PropTypes.func,
    startFromBottom: PropTypes.bool
};
