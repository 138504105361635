import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../../../common/components/extra/Button";
import { createClass, createToast, TOAST_TYPE } from "../../../common/utilities/helper";
import { BASE_CLASS, FINAL_STATUSES, SUBMITTED_FORM_ACTION_TYPE, SUBMITTED_FORM_CLASS, SUBMITTED_FORM_STATUS } from "./const";
import { FORM_CATEGORY, USER_LEVEL } from "../companyForms/const";
import { useUpdateSubmittedFormStatus } from "./hooks";
import UpdateStatusModal from "./UpdateStatusModal";

function CancelButton({ id, disabled, currentFormStatus, formCategory, onCancel }) {
    const [openModal, setOpenModal] = useState(false);
    const [updateStatus, isUpdating] = useUpdateSubmittedFormStatus();

    const isStatusSame = currentFormStatus == SUBMITTED_FORM_STATUS.CANCELED;
    const disableAction = isStatusSame || disabled || isUpdating;

    const handleUpdateStatus = async ({ remarks = "" } = {}) => {
        try {
            const result = await updateStatus(id, {
                status: SUBMITTED_FORM_STATUS.CANCELED,
                remarks
            });
            if (result.error) {
                throw new Error(result.error);
            }
            onCancel?.();
            return result;
        } catch (error) {
            createToast("Failed to update status, please try again later", TOAST_TYPE.ERROR);
        }
    };

    if (FINAL_STATUSES.includes(currentFormStatus) || formCategory !== FORM_CATEGORY.HR) {
        return <></>;
    }

    return (
        <div className={createClass("__cancel flex gap-03 align-center", BASE_CLASS)}>
            <Button className="danger" onClick={() => setOpenModal(true)} disabled={disableAction} isLoading={isUpdating} mini small>
                {isStatusSame ? "Canceled" : "Cancel"}
            </Button>
            {openModal && (
                <UpdateStatusModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    actionType={SUBMITTED_FORM_ACTION_TYPE.CANCEL}
                    onConfirm={(form) => !disableAction && handleUpdateStatus(form)}
                />
            )}
        </div>
    );
}

export default CancelButton;

CancelButton.propTypes = {
    id: PropTypes.number,
    currentFormStatus: PropTypes.oneOf(Object.values(SUBMITTED_FORM_STATUS)),
    targetApprovalUserLevel: PropTypes.oneOf(Object.values(USER_LEVEL)),
    formCategory: PropTypes.oneOf(Object.values(FORM_CATEGORY)),
    formClass: PropTypes.oneOf(Object.values(SUBMITTED_FORM_CLASS)),
    disabled: PropTypes.bool,
    onCancel: PropTypes.func
};
