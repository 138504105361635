import React from "react";
import Input, { INPUT_TYPE } from "../../../../../common/components/extra/form/Input";
import PropTypes from "prop-types";
import { sanitizeWords } from "../../../../../common/utilities/helper";

function MobileToggle({ details, useValue }) {
    if (useValue) {
        return (
            <Input
                label={sanitizeWords(details.label)}
                renderValue={details.value == true ? "Yes" : details.value == false ? "No" : null}
                parentStyle={{ gap: ".5rem" }}
                readOnly
                alignRight
            />
        );
    }

    return (
        <div className="mobile-input mobile-input--toggle input-size">
            <Input type={INPUT_TYPE.TOGGLE} />
        </div>
    );
}

export default MobileToggle;

MobileToggle.propTypes = {
    details: PropTypes.object,
    useValue: PropTypes.bool,
    renderAsMobile: PropTypes.bool
};
